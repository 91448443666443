/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;
export default {
  name: "templatelist",
  components: {
    VPagination,
  },
  data() {
    return {
      validations: false,
      reviewerId: "",
      reviewers: "",
      reviewTempId: "",
      defaultdate: "",
      roleprivileges: "",
      selectedversion: "",
      selectedtemplate: "",
      selectedmodelstudy: "",
      selectedmodelvisit: "",
      modelvisits: "",
      modelstudy: "",
      templateList: [],
      TemplateName: "",
      pageindex: 1,
      totalitems: 0,
      totalpages: 0,
      enableNext: true,
      enablePrevious: true,
      currentPage: 1,
      errors: [false, false],

      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/template/`,
      baseapi: process.env.VUE_APP_Service_URL,
      studyId: store.getters.getStudyIs
    };
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    await this.setroleprivileges();
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs
      await this.GetData();
    });
    this.GetData();
    this.getstudy();
    this.filter();
    this.getreviewers();
  },
  methods: {
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate)
      }
    },

    async getreviewers() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/forms/digitalform/getapprovalusers`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.reviewers = res.data;
          console.log("Reviewers", this.reviewers);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async assignreviewer() {
      const idtoken = store.getters.getIdToken;
      if (this.reviewerId != "" && this.reviewerId != null) {
        await axios
          .post(
            `${this.baseapi}/forms/digitalform/createapproval`,
            {
              templateId: this.reviewTempId,
              approval: [
                {
                  assignedTo: this.reviewerId,
                  assignedDate: "2022-06-20T07:43:11.064Z",
                  status: "",
                  comments: ""
                }
              ]
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.reviewerId = "";
            alert(this.$t('navigations.reviewer added successfully'))
            this.closeApprovalModal();
            this.GetData();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.validations = true
      }
    },

    async assignFun() {
      const idtoken = store.getters.getIdToken;
      console.log("assign function");

      this.validateIsNull();
      if (this.errors.includes(true)) {
        console.log("empty feild....");
      }
      else {
        await axios
          .put(`${this.baseapi}/management/visit/assigntemplate`,
            {
              "visitID": this.selectedmodelvisit,
              "templateID": this.selectedtemplate,
              "version": this.selectedversion
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            })
          .then((res) => {
            console.log(res);
            alert(this.$t('navigations.template assigned successfully'));
            this.closeModal();
          })
          .catch((err) => {
            console.log(err.response);
          });
      }

    },
    async getstudy() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/study/getallstudy`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.modelstudy = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async studychange(studyvalue) {
      this.getvisites(studyvalue);
    },
    async getvisites(currentstudy) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/getallvisit?studyId=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.modelvisits = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openApprovalModal: function (templateId) {
      this.reviewTempId = templateId;
      const modalWrappers = document.querySelector('.modal-wrapper1');
      modalWrappers.classList.add('active');
    },
    closeApprovalModal: function () {
      const modalWrappers = document.querySelector('.modal-wrapper1');
      modalWrappers.classList.remove('active');
      this.reviewerId = ""
      this.validations=false
    },
    async GetData() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(this.baseUrl + `listtemplate?TemplateName=` + this.TemplateName + `&StudyID=` + this.studyId +`&pageindex=` + this.pageindex + `&pagesize=10&SortOrder=1&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.totalitems = Math.ceil(res.data.totalCount / 10);
            this.templateList = res.data.results;
            console.log(this.formName, "template list is ", this.templateList);
          } else {
            console.log("no temlate found");
          }

        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });

    },
    async SearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.searchData();
    },
    async searchData() {
      this.pageindex = 1;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(this.baseUrl + `listtemplate?TemplateName=` + this.TemplateName + `&StudyID=` + this.studyId + `&pageindex=` + this.pageindex + `&pagesize=10&SortOrder=0&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data) {
            this.totalitems = Math.ceil(res.data.totalCount / 10);
            this.templateList = res.data.results;
            console.log(this.formName, "template list is ", this.templateList);
          } else {
            console.log("no temlate found");
          }

        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });

    },
    async getPreviouspage() {
      if (this.pageindex > 1) {
        if (this.formName == "") {
          this.pageindex = this.pageindex - 1;
          this.GetData();
        }
        else {
          this.pageindex = this.pageindex - 1;
          this.searchData();
        }
      }
    },
    async getNextpage() {
      if (this.pageindex < this.totalitems) {
        if (this.formName == "") {
          this.pageindex = this.pageindex + 1;
          this.GetData();
        }
        else {
          this.pageindex = this.pageindex + 1;
          this.searchData();
        }
      }
    },
    async viewforms(templateId) {
      this.$router.push(`/viewTemplate/templatelist/${templateId}`);
    },
    validateIsNull() {
      if (this.selectedmodelstudy == "") {
        this.errors[0] = true;
      }
      else {
        this.errors[0] = false;
      }

      if (this.selectedmodelvisit == "") {
        this.errors[1] = true;
      }
      else {
        this.errors[1] = false;
      }
    },
    async loadPage(page) {
      this.currentPage = page;
      this.pageindex = page;
      this.GetData();
    }
  },
};