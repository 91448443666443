/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
    authData: {
        token: localStorage.getItem('access_token'),
        refreshToken: localStorage.getItem('refresh_token'),
        tokenExp: localStorage.getItem('tokenExp'),
        idToken: localStorage.getItem('idToken'),
        timenow: localStorage.getItem('tockentime'),
    },
    roledata: localStorage.getItem('roledata'),
    loginStatus: "",
});
const getters = {
    authenticated(state: { authData: { token: number;} }){
        return !!state.authData.token;
      },
      getAccessToken(state: { authData: { token: number;} }){
        return state.authData.token;
      },
      getRolesData(state: { roledata: any }){
        return state.roledata;
      },
      getRefreshToken(state: { authData: { refreshToken: number;} }){
        return state.authData.refreshToken;
      },
      getIdToken(state: { authData: { idToken: number;} }){
        return state.authData.idToken;
      },
      isTokenActive(state: { authData: { tokenExp: number; timenow: string; }; }) {
        const tockentimethen =  parseInt(state.authData.timenow);
        const diff = Date.now() - tockentimethen;
        const tockenexptimes = (state.authData.tokenExp) * 1000;
        // const expdiff = (state.authData.tokenExp) - diff;300
        // console.log("expiration time is " + tockenexptimes);
        const expdiff = tockenexptimes - diff;
        if (expdiff <= 0 ) {
            return false;
        }
        else{
            return true;
        }
        // return tokenAlive(state.authData.tokenExp);
    },
    getAuthData(state: { authData: any; }) {
        return state.authData
    }
};

const actions = {
    async loginuser({commit}: any, data: any) {
        // console.log(data);
        commit("setUser", data);
                  },
     async setroles({commit}: any, data: any) {
          commit("setrole", data);
                    }
};

const mutations = {
    setUser(state: { user: any; authData: any }, userData: any){
        // console.log(userData.accessToken);
        const timenow = Date.now();
        state.authData = {
            token: userData.accessToken,
            refreshToken: userData.refreshToken,
            tokenExp: userData.expiresIn,
            idToken: userData.idToken,
            timenow: timenow,
        }
        // console.log(state.authData);
        if(userData != null || userData != ""){
        localStorage.setItem("access_token", userData.accessToken);
        localStorage.setItem("refresh_token", userData.refreshToken);
        localStorage.setItem("tokenExp", userData.expiresIn);
        localStorage.setItem("idToken", userData.idToken);
        localStorage.setItem("tockentime", timenow.toString());
      }
      },
      setrole(state: { roledata: any }, userroles: any){
          if(userroles){
        state.roledata = userroles;
        localStorage.setItem("roledata", userroles);
          }
          else{
            state.roledata = "";
          }
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}