/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from "axios";
import moment from "moment";
import store from "../../store/index";
import jwt_decode from "jwt-decode";
import signaturePopUp from "../../components/signaturePopUp/signaturePopUp.vue"
export default {
  name: "digitalformview",
  components:{
    signaturePopUp
  },
  props: {
        tempId:{
            type: String,
            default: ''
        },
        closedoption:{
            type: String,
            default: 'templatelist'
        },
        formId:{
            type: String,
            default: ''
        },
        comments:{
          type: String,
          default:''
        }
    },
    async beforeMount() {     
      this.defaultdate = localStorage.getItem("dateformat");
      await this.setTempId();
      await this.getselectedtemplate()
      await this.getStatus()          
      this.email=jwt_decode(this.idtoken).email      
    },
 mounted(){
    window.addEventListener("FormDigitalSignature", (event) => {
      if (event.detail.storage === true) {
        this.getStatus()
      }
    });  
    console.log("Entered")
    this.defaultTime = localStorage.getItem("timeformat");
    if(this.default == ""){
      this.defaultTime = "h:mm A";
    }   
  },
  unmounted(){
    this.urlStatus=false
  },
  data() {
    return {
      envvar: process.env.NODE_ENV,
      idtoken: store.getters.getIdToken,
      templateId: "",
      baseurl:process.env.VUE_APP_Service_URL,
      extLink:process.env.VUE_APP_logout_URL,
      templateName:"",
      postcomment:"",
      email:"",
      formid:"",
      status:"",
      lastComment:"",
      commnetlist: [],
      version:"",
      showSignBtn:false,
      signpopUp:false,
      showSignBlock:false,
      signStatus:false,
      showCommentBlock:false,
      showSign:false,
      urlStatus:true,
      signdate:"",
      signtime:"",
      signedby:"",
      defaultdate:"",
      storedStatus:"",
      getId:"",
      defaultTime: "",
    };
  },
  methods: {
    filter(date) {
      if (date) {
        return moment(date).format(this.defaultdate)
      }
    },
    openNewTab(){
      window.open(`${this.extLink}/templateview/${this.templateName}/${this.tempId}`,'_blank');
    },
    async opencomments(){
      this.commnetlist=[];
      this.getcommentlist();
      this.showCommentBlock=true
    },
    async getStatus(){
      await axios
        .get(
        `${this.baseurl}/forms/template/signstatus?TemplateId=${this.tempId}`,
        {
            headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
            },
        }
        )
        .then((res) => {
          this.getId=localStorage.getItem("setId")
          this.showSign=localStorage.getItem("showSign")
          if(res.data.signStatus === "Signed")
          {
            this.showSignBlock=true
            this.signStatus=true
            this.signdate=res.data.signedAt
            this.signtime=moment(this.signdate).format("hh:mm a")
            this.signedby=res.data.signedUserName
            
            if(this.showSignBtn===true)
            {
              console.log("first condtion")
              this.showSignBtn=false
            }
          }        
          else if((res.data.signStatus === "Unsign" || res.data.signStatus === null) && this.showSign==='true' && this.getId === this.tempId)
          {
            this.showSignBtn=true
          }
          console.log("Value is",this.showSignBtn)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async setTempId(){
        this.templateId = this.tempId;
        this.formid=this.formId ;
        if(this.comments == "null"){this.lastComment =""}
        else{this.lastComment = this.comments}  
    },
     filterTime(time) {
      if(time){
        return moment(time).format(this.defaultTime)
      }
    },
     filterDate(date) {
      if(date){
        return moment(date).format('DD/MM/YYYY')
      }
    },
    async onclosed() {     
    await this.$router.push(`/${this.closedoption}`);
    },
    async getselectedtemplate () {
      const idtoken = store.getters.getIdToken;
        await axios
            .get(
            `${this.baseurl}/forms/template/get/${this.templateId}?api-version=1.0`,
            {
                headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
                },
            }
            )
            .then((res) => {
            this.templateName=res.data.templateName;
            this.storedStatus=res.data.status
            if(this.storedStatus ==='Approved' || this.storedStatus ==='Rejected')
            {
              this.signStatus=true
              this.urlStatus=false
            }             
            if(this.storedStatus ==='Approved'){
              this.showSignBtn=true       
            }
            
              this.version = res.data.version;             
            })
            .catch((err) => {
            console.log(err);
            });
    },
    async getcommentlist(){      
      await axios
            .get(
            `${this.baseurl}/forms/digitalform/listcomments?digitaFormId=${this.formId}&pageindex=1&pagesize=10`,
            {
                headers: {
                Authorization:  "Bearer " + this.idtoken,
                "Content-Type": "application/json",
                },
            }
            )
            .then((res) => {
              res.data.entities.forEach(element => {
                if(element.comments !='' && element.comments!= null & element.comments != ""){
                  this.commnetlist.push(element);
                }
            });
            })
            .catch((err) => {
            console.log(err);
            });

    },
    async assigntemplate(status,comment) {     
      
        if(status == 'Approved')
          {
            if(confirm("Are you sure you want to approve this form?"))
            {
              this.showSignBtn=true
                await axios
                  .put(
                  `${this.baseurl}/forms/digitalform/updatedigitalform`,{
                      "createdUserEmail": "string",
                      "createdUserId": "string",
                      "createdAt": "2022-06-28T10:22:01.286Z",
                      "lastModifiedUserEmail": "string",
                      "lastModifiedUserId": "string",
                      "lastModifiedAt": "2022-06-28T10:22:01.286Z",
                      "digitalFormId": this.formid,
                      "templateId": this.templateId,
                      "approval": [
                        {
                          "assignedTo": "string",
                          "assignedDate": "2022-06-28T10:22:01.286Z",
                          "approvedBy": this.email,
                          "status": status,
                          "comments": comment
                        }
                      ]
                    },
                  {
                      headers: {
                      Authorization:  "Bearer " + this.idtoken,
                      "Content-Type": "application/json",
                      },
                  }
                  )
                  .then((res) => {     
                      this.signStatus=true
                      this.urlStatus=false
                      if(this.showSignBtn === true && this.signStatus === true){
                        localStorage.setItem("showSign",true)
                        localStorage.setItem("setId",this.tempId)
                      }
                      alert(this.templateName+" has been approved")         
                  })
                  .catch((err) => {
                  console.log(err);
                });
              
            }
          }
        else
          {            
            if(confirm("Are you sure you want to reject this form?"))
            {
              await axios
                  .put(
                  `${this.baseurl}/forms/digitalform/updatedigitalform`,{
                      "createdUserEmail": "string",
                      "createdUserId": "string",
                      "createdAt": "2022-06-28T10:22:01.286Z",
                      "lastModifiedUserEmail": "string",
                      "lastModifiedUserId": "string",
                      "lastModifiedAt": "2022-06-28T10:22:01.286Z",
                      "digitalFormId": this.formid,
                      "templateId": this.templateId,
                      "approval": [
                        {
                          "assignedTo": "string",
                          "assignedDate": "2022-06-28T10:22:01.286Z",
                          "approvedBy": this.email,
                          "status": status,
                          "comments": comment
                        }
                      ]
                    },
                  {
                      headers: {
                      Authorization:  "Bearer " + this.idtoken,
                      "Content-Type": "application/json",
                      },
                  }
                  )
                  .then((res) => {  
                      this.rejectTemplate(comment);
                      this.signStatus=true
                      alert(this.templateName+" has been rejected")
                  })
                  .catch((err) => {
                  console.log(err);
                });
              
            }
          }
        
    },
    async rejectTemplate(comment){
      axios.put(this.baseurl+"/template/formtemplate/rejecttemplate?templateName="+this.templateName+"&version="+this.version+"&comment="+comment) 
      .then(res =>console.log("result is",res ))
      .catch(err => console.log(err))   
    }

  },
};