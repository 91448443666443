<template>
  <div id="patientreport">
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{$t('navigations.patient summary')}}</h2>
        </div>
       <div class="col-lg-2">
           <i class="fa fa-times close my-2" @click="onclosed()"></i>
        </div>
    </div>
    <Pivot toolbar>
    </Pivot>
  </div>
</template>

<script src="./patient_report.js"></script>

<style>
#wdr-pivot-view .wdr-credits {
    display: none;
}
#wdr-toolbar-wrapper #wdr-toolbar li a svg, #wdr-toolbar-wrapper #wdr-toolbar li a svg path {
    transform: translateZ(0) !important;
    fill: var(--secondary-color) !important;
}
#wdr-pivot-view .wdr-grid-layout .wdr-filters .wdr-header, #wdr-pivot-view .wdr-grid-layout.wdr-flat-view .wdr-header {
    background-color: #ffffff !important;
    color: #111;
    font-weight: bold;
    text-transform: capitalize!important;
    position: relative;
    border: none;
    overflow: hidden;
    text-overflow: ellipsis;
}
#wdr-pivot-view a.wdr-ui-btn-dark, #wdr-toolbar-wrapper a.wdr-ui-btn-dark {
    color: #fff;
    background: var(--secondary-color) !important;
}
#wdr-pivot-view a.wdr-ui-btn-dark, #wdr-toolbar-wrapper a.wdr-ui-btn-dark :hover {
    color: rgb(0, 0, 0);
    background: var(--secondary-color) !important;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-content .wdr-list-wrap .wdr-list-content #wdr-lst-hierarchies li.wdr-checked:not(.wdr-level-folder) #wdr-icon-display {
    background: var(--secondary-color) !important;
}
#wdr-pivot-view a.wdr-ui-btn-dark:hover, #wdr-toolbar-wrapper a.wdr-ui-btn-dark:hover {
    font-weight: bold;
    color: #211717;
    background: #16a6fa !important;
}
#wdr-pivot-view #wdr-fields-view .wdr-popup-header #wdr-btn-add-measure:before {
    color: var(--secondary-color) !important;
}
/* .wdr-ui-element .wdr-wrapper{
background-color: #ffffff !important;
} */
</style>
