/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import sourcetree from "../formtransfertree/sourcetree.vue";
import targettree from "../formtransfertree/targettree.vue";

export default {
    components: {
        sourcetree,
        targettree
    },
    data() {
        return {

            idToken: "",
            baseapi: `${process.env.VUE_APP_Service_URL}`,
            currentStudyId: "",

            sourcePatientName: "",
            targetPatientName: "",
            sourceVisitName: "",
            targetVisitName: "",
            sourceSiteName: "",
            targetSiteName: "",
            sourceFormId: "",
            targetFormId: "",
            transferFormId: "",

            values:
            {
                formId: "",
                sourceScheduleID: "",
                targetScheduleID: "",

            }
        };
    },

    async mounted() {
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            this.currentStudyId = store.getters.getStudyIs;
            this.dispatchNull();
        });
        this.idToken = store.getters.getIdToken;
        this.currentStudyId = store.getters.getStudyIs;
        await this.dispatchNull();


    },
    computed: {
        sourceSiteClick() { return store.getters.getSourceSiteName; },
        sourcePatientClick() { return store.getters.getSourcePatientName; },
        sourceVisitClick() { return store.getters.getSourceVisitName; },
        targetSiteNameClick() { return store.getters.getTargetSiteName; },
        targetPatientNameClick() { return store.getters.getTargetPatientName; },
        targetVisitNameClick() { return store.getters.getTargetVisitName; },

    },
    methods: {
        async dispatchNull() {
            await store.dispatch("setIsFormTransfer", false);
            await store.dispatch("setSourceSiteName", "");
            await store.dispatch("setSourceFormId", "");
            await store.dispatch("setTargetFormId", "");
            await store.dispatch("setSourceVisitName", "");
            await store.dispatch("setSourcePatientName", "");
            await store.dispatch("setTargetVisitName", "");
            await store.dispatch("setTargetSiteName", "");
            await store.dispatch("setTargetPatientName", "");
        },

        async transferForm() {
            if (this.values.formId == null || this.values.formId == "")
            {
                alert(this.$t('navigations.source visit does not have a form'))
            }
            else
            {
                if (
                    confirm(
                        this.$t("navigations.are you sure you want to transfer this form to the selected subject")
                    )
                  )
                  {
                await axios
                    .post(`${this.baseapi}/forms/forms/transferform`, this.values, {
                        headers: {
                            Authorization: "Bearer " + this.idToken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("transfer result", res)
                        alert(this.$t("navigations.form has been transferred successfully"));
                        store.dispatch("setSourceFormId", "");
                        store.dispatch("setTargetFormId", "");
                        this.RefreshTree();
                    })
                    .catch((err) => {
                        console.log("err msg", err)
                    });
                }
            }
        },
        async RefreshTree() {
            await store.dispatch("setIsFormTransfer", true);
            window.dispatchEvent(
                new CustomEvent("formTransferred", {
                    detail: {
                        storage: store.getters.getIsFormTransfer,
                    },
                })
            );
        },
        async transferButton() {
            this.sourceFormId = await store.getters.getSourceFormId
            this.targetFormId = await store.getters.getTargetFormId
            this.sourceVisitName = await store.getters.getSourceVisitName
            this.targetVisitName = await store.getters.getTargetVisitName
            if (this.sourceVisitName == null || this.sourceVisitName == "") {
                alert(this.$t("navigations.please select a source visit"))
                return;
            }

            if (this.targetVisitName == null || this.targetVisitName == "") {
                alert(this.$t("navigations.please select a target visit"))
                return;
            }
            else {
                this.targetFormId = await store.getters.getTargetFormId,
                    this.values.formId = await store.getters.getSourceFormId,
                    this.values.sourceScheduleID = await store.getters.getSourceScheduleId,
                    this.values.targetScheduleID = await store.getters.getTargetScheduleId
                console.log("Values...", this.values)
                if (this.values.formId == null || this.values.formId == "") {

                    alert(this.$t("navigations.source visit does not have a form"))
                    return;
                }
                if (
                    this.sourceFormId == this.targetFormId
                    && this.values.sourceScheduleID == this.values.targetScheduleID) {
                    alert(this.$t("navigations.cannot transfer between same visits"))
                    return;
                }
                else {

                    if (this.targetFormId != null && this.targetFormId != "") {
                        if (confirm(this.$t("navigations.target visit already have a form uploaded, do you want to replace the form?"))) {
                            this.transferForm();
                        }
                    }
                    else {
                        this.transferForm();
                    }
                }
            }
        },

    }
}