<template>
  <editForm
  :formsesid = "formsid"
  :requiredsubmit = 'false'
  :closedoption = "whenclosed"
  :isfieldvalidate = "showpopup"
        type: Boolean,
        default: false
      }
   />
</template>

<script src="./editform.js"></script>