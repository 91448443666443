<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2> Form Summary</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 px-lg-0">
            <label class="mb-0">Site Code</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.SiteCode"
              name="account"
              @change="getpatients()"
            >
              <option value="All">All</option>
              <option
                v-for="site in sites"
                :key="site.sitetId"
                :value="site.siteID"
              >
                {{ site.siteCode }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">Subject ID</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.PatientId"
              name="account"
            >
              <option value="All">All</option>
              <option
                v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId"
              >
                {{ patient.subjectId }}
              </option>
            </select>
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">Form Name</label>
            <input
              type="search"
              v-model="searchdata.FormName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
            <span type="button"
              @click.prevent="filteredLists"
              class="col-12 col-lg-2 save_btn mx-lg-1 align-self-end"
              @click="searchData()"
            >
              Search
            </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
       <div class="ibox-title style_2">
          <h5 class="float-left">Form Summary List</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>Site Code</th>
                <th>Subject ID</th>
                <th>Visit Name</th>
                <th>Status</th>
                <th>Uploaded By</th>
                <th>Uploaded Date</th>
                <th class="text-center">Incorrect </th>
                <th class="text-center">Not Validated </th>
                <th class="text-center"> Open Queries </th>
                <th v-if="roleprivileges.includes('Form Summary View')" class="text-center">View </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filteredListing" :key="item.formId">
                <td>{{ item.siteId }}</td>
                <td>{{ item.subjectId }}</td>
                <td>{{ item.visitName }}</td>
                <td>{{ item.formStatus }}</td>
                <td>{{ item.createdUserEmail }}</td>
                <td>{{ filter(item.createdAt) }}</td>
                <td class="tdtext">
                  <a @click.prevent='openModal("Incorrect", item.formId)' href="#" class="modellink"
                    >{{ item.incorrectCount }}</a>
                </td>
                <td class="tdtext">
                  <a @click.prevent='openModal("Not Validated", item.formId)' href="#" class="modellink"
                    >{{ item.notvalidatedCount }}</a>
                </td>
                <td class="tdtext"> 
                  <a @click.prevent='openQueryModal(item.formId,"Open")' href="#" class="modellink"
                    >{{ item.openQueryCount }}</a>
                </td>
                <td v-if="roleprivileges.includes('Form Summary View')" class="tdtext">
                    <i
                      class="fa fa-eye editicon"
                      @click.prevent="viewforms(item.formId)"
                    ></i
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalLists"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal__wrapper_one">
    <div class="modal__container_one">
      <div class="titleclass">
        <h4 class="ml-lg-2">Field Status</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div class="table-responsive">
          <table class="tableFixHead table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>Page Name</th>
                <th>Page No</th>
                <th>Field Name</th>
                <th>Value</th>
                <th>Status</th>
                <th>View </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="statusdetails in fieldstatusdata" :key="statusdetails.fieldName">
                <td>{{ statusdetails.pageName }}</td>
                <td>{{ statusdetails.pageNo }}</td>
                <td>{{ statusdetails.fieldName }}</td>
                <td>{{ statusdetails.value }}</td>
                <td>{{ statusdetails.status }}</td>
                <td class="tdtext">
                  <a href="#" class="editicon"
                    ><i
                      class="fa fa-eye"
                    ></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- modal ends -->
  <!-- Query model starts -->
    <div class="modal__wrapper_two">
    <div class="modal__container_two">
      <div class="titleclass">
        <h4 class="ml-lg-2">Query Details</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeQueryModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div class="table-responsive">
          <table class="tableFixHead table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>Query ID</th>
                <th>Query Subject </th>
                <th>Query Against</th>
                <th>Page No.</th>
                <th>Field Name</th>
                <th>View </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="statusdetails in fieldstatusdata" :key="statusdetails.fieldName">
                <td>{{ statusdetails.queryId }}</td>
                <td>{{ statusdetails.query_Subject }}</td>
                <td>{{ statusdetails.queryFor }}</td>
                <td>{{ statusdetails.pageNo }}</td>
                <td>{{ statusdetails.fieldName }}</td>
                <td class="tdtext">
                  <a href="#" class="editicon"
                    ><i
                      class="fa fa-eye"
                    ></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- Query model ends -->
</template>


<script src="./formsummery.js" type="text/javascript">
</script>
<style scoped>
@import "./modal.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.tableFixHead          { overflow: auto; height: 100px;}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
.tableFixHead tbody th { position: sticky; left: 0; }
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#fff; }
.modelbody{
  /* background-color: aquamarine; */
  width: 100%;
  height: 86%;
  margin: 0%;
  padding: 1%;
  overflow: auto;
}
.modal__container {
width: 50%;
}
.tdtext{
  text-align: center;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>
