/* eslint-disable */
const state = () => ({
    folderId :  localStorage.getItem('folder'),
    folderName:localStorage.getItem('folderName'),
    lastModifiedAt:localStorage.getItem('Folderdate'),
    showDetails:false,
    showHideDetails: [],
   });
   
   const getters = {
        getFolderId( state :{folderId : any}){
           return state.folderId;
            },
        getFolderName( state :{folderName : any}){
        return state.folderName;
            },
        getFolderdate( state :{lastModifiedAt : any}){
            return state.lastModifiedAt;
        },
        getDetails( state :{showDetails : boolean}){
            return state.showDetails;
        },
        getShowDetails( state :{showHideDetails : any}){
            return state.showHideDetails;
        },
   };
   
   const actions ={
    async setShowDetails({commit} : any , data : any){
        commit("setShowDetails" , data);
    },
        async setFolderId({commit} : any , data : any){
            commit("setFolderId" , data);
        },
        async setFolderName({commit} : any , data : any){
        commit("setFolderName" , data);
        },
        async setFolderdate({commit} : any , data : any){
            commit("setFolderdate" , data);
        },
        async setDetails({commit} : any , data : boolean){
            commit("setDetails" , data);
        }   
   };
   
   const mutations ={
    setShowDetails(state : {showHideDetails : any } , showData : any ){
        state.showHideDetails = showData;
     },
        setFolderId(state : {folderId : any } , folderData : any ){
           state.folderId = folderData;
           localStorage.setItem("folder", folderData);
        },
       setFolderName(state : {folderName : any } , folderNameData : any ){
        state.folderName = folderNameData;
        localStorage.setItem("folderName", folderNameData);
        },
        setFolderdate(state : {lastModifiedAt : any } , lastModifiedAt : any ){
            state.lastModifiedAt = lastModifiedAt;
            localStorage.setItem("folderdate", lastModifiedAt);
        },
        setDetails(state : {showDetails : boolean } , showDetailsData : any ){
            state.showDetails = showDetailsData;
            localStorage.setItem("showDetails", showDetailsData);
        }
    };
   
   export default {
       state,
       getters,
       actions,
       mutations
   }