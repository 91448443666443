<template>
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container custom-width">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.retire form") }}</h4>
        <div class="cursor-pointer" @click.prevent="closeTemplateModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-3">
        <table class="table table-striped table-bordered ibox-body mb-0">
          <thead>
            <tr>
              <th class="text-center" width="10%">{{ $t("navigations.select") }}</th>
              <th>{{ $t("navigations.form name") }}</th>
              <th>{{ $t("navigations.version") }}</th>
              <th>{{ $t("navigations.date") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="version in versionData" :key="version.templateId">
              <td class="text-center">
                <input
                  class="mt-1"
                  type="checkbox"
                  :value="version.templateId"
                  @change="selectarray(version)"
                  :checked="temparray.includes(version.templateId)"
                  name="checkbox"
                />
              </td>
              <td>{{ version.templateName }}</td>
              <td>{{ version.version }}</td>
              <td>{{ filterDate(version.createdDate) }}</td>
            </tr>
          </tbody>
        </table>
        <div class="important">
          *
          {{
            $t(
              "navigations.after submission retired forms will not be available for study"
            )
          }}
        </div>
        <div class="text-end">
          <button
            class="save_btn py-1 px-4 mt-2"
            @click.prevent="retireForm()"
            type="button"
          >
            {{ $t("navigations.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./retireFormPopUp.js"></script>
<style scoped>
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}

.study_profile_modal__container {
  position: fixed;
  border-radius: 4px;
  width: min(500px, 90%);
  background: #fff;
  overflow-x: hidden;
}

.modal__title {
  background-color: var(--pop-up-background);
  color: white;
}
.important {
  color: rgb(255, 0, 0);
}
</style>
