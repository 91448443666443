import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "ailist",
  components: {
    VPagination,
    Multiselect,
  },
  props: {
    getTemplateId: {
      default: "",
      type: String,
    },
    version: {
      default: "",
      type: String,
    },
    eventPayload: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      MaxNumValue: 100,
      MinNumValue: 0,
      baseurl: process.env.VUE_APP_Service_URL,
      pageIndex: 1,
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      scaleminval: 0,
      scalemaxval: 0,
      idtoken: "",
      getForm: "",
      selectedmenu: "",
      selectedFieldValidation: "",
      trueConsideration: "",
      falseConsideration: "",
      templateid: "",
      fieldname: "",
      loadingBlock: false,
      aitoolModal: false,
      featureValue: false,
      inclusiveLabel: false,
      exclusiveLabel: false,
      considerationRate: false,
      tableloadingBlock: false,
      errorBlock: "",
      scaleError: [false, false, false, false,false,false],
      listforms: [],
      templateFieldDetails: [],
      fieldsArray:[],
      dropdownValues: [],
      radioValues: [],
      dummySelect: [],
      dummySelect2: [],
      radiooptionsarray: [],
      dropdownoptions: [],
      selectedDropdown: [],
      selectedDropdown2: [],
      selectedDropdown3: [],
      saveData: {
        TemplatId: "",
        ProcessName: "PatientEnrollment",
        TargetField: "",
        IgnoreFields: "",
      },
      templateFieldProperties: {
        featureField: false,
        fieldsToConsider: [
          {
            considerationRate: 0,
            considerableFields: [],
          },
        ],
        dropdownFields: [
          {
            considerationRate: 0,
            considerableFields: [],
          },
        ],
        rangesToConsider: [
          {
            considerationRate: 0,
            min: 0,
            max: 0,
          },
        ],
        forBoolean: {
          considerationRate: 0,
        },
      },
      fieldsConsider: [
        {
          considerationRate: 0,
          considerableFields: [],
        },
      ],
      fieldstoConsiderText: [
        {
          considerationRate: 0,
          considerableFields: [],
        },
      ],
      rollsprivilages : ""
    };
  },
  async mounted() {
    this.idtoken = store.getters.getIdToken;
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    await this.getFormDetails();
  },
  computed: {
    filteredOptions1() {
      return this.dropdownoptions.filter(
        (option) => !this.selectedDropdown2.includes(option.optionname)
      );
    },
    filteredOptions2() {
      return this.dropdownoptions.filter(
        (option) => !this.selectedDropdown.includes(option.optionname)
      );
    },
  },
  watch: {
    selectedDropdown(newValue) {
      this.filteredOptions2 = this
      .dropdownoptions.filter(
        (option) =>
          !newValue.some(
            (selected) => selected.optionname === option.optionname
          )
      );
    },
    selectedDropdown2(newValue) {
      this.filteredOptions1 = this.dropdownoptions.filter(
        (option) =>
          !newValue.some(
            (selected) => selected.optionname === option.optionname
          )
      );
    },
    eventPayload() {
      this.tableloadingBlock = true;
      this.getFormDetails();
    },
  },
  methods: {
    async saveChanges() {
      this.saveData.TemplatId = this.templateid;
      await axios
        .post(
          `${this.baseurl}/forms-ml/patientenroll/createmodel`,
          this.saveData
        )
        .then((res) => {
          this.save = res.data;
          alert("Model updated successfully");
          this.$emit("reloadTemplate")
        })
        .catch((err) => console.log("error in screen list", err));
    },
    validateNumberInput(event,index,type) {
      let value = event.target.value;

      // Remove any non-numeric characters
      value = value.replace(/[^0-9]/g, "");

      // Convert to number
      if (value) {
        value = parseInt(value, 10);

        // Enforce the range 1-100 if number is not NaN
        if (!isNaN(value)) {
          if (value < 0) {
            value = 0;
          } else if (value > 100) {
            value = 100;
          }
        } else {
          value = "";
        }
      }
      if(type=='number')
        {
          this.templateFieldProperties.rangesToConsider[index].considerationRate=value
        }
      else if(type=='text'){

        this.templateFieldProperties.fieldsToConsider[index].considerationRate=value
                

       
      }
      else if(type=='dropdown'){
        this.fieldsConsider[index].considerationRate=value
      }
      else if(type == 'radio' || type == 'radiohorizondal')
      {
        this.fieldsConsider[index].considerationRate=value
      }
    },
    validatePossibleInput(event){
      let value = event.target.value;
      const invalidChars = ['e', 'E', '+', '-', '.'];
      if (invalidChars.includes(event.key)) {
        event.preventDefault();
      }
      else{
         // Remove any non-numeric characters
      value = value.replace(/[^0-9]/g, "");

      // Convert to number
      if (value) {
        value = parseInt(value, 10)
      }
      }
     
    },
    addItem(type) {
      if (type == "text" || type == "textbox") {

            this.templateFieldProperties.fieldsToConsider.push({
              considerableFields: [],
              considerationRate: 0,
            })
          
       
      } else if (type == "dropdown" || type == "radio") {
        this.fieldsConsider.push({
          considerableFields: [],
          considerationRate: 0,
        });
      } else if (type == "range") {
        this.templateFieldProperties.rangesToConsider.push({
          considerationRate: 0,
          min: 0,
          max: 0,
        });
      }
    },
    deleteitem(index, type) {
      if (type == "text" || type == "textbox") {
        this.templateFieldProperties.fieldsToConsider.splice(index, 1);
      } else if (type == "dropdown" || type == "radio") {
        this.fieldsConsider.splice(index, 1);
      } else if (type == "range") {
        this.templateFieldProperties.rangesToConsider.splice(index, 1);
      }
    },
    handleLabelChange(event, type) {
      if (type == "checkbox") {
        if (
          event.target.id == "exclusiveRadio" &&
          event.target.checked == true
        ) {
          this.inclusiveLabel = false;
          this.trueConsideration = "100";
          this.falseConsideration = "0";
          this.considerationRate = false;
          this.exclusiveLabel = true;
          this.selectedDropdown = [];
        } else if (
          event.target.id == "inclusiveRadio" &&
          event.target.checked == true
        ) {
          this.exclusiveLabel = false;
          this.trueConsideration = "100";
          this.falseConsideration = "0";
          this.considerationRate = false;
          this.inclusiveLabel = true;
          this.selectedDropdown2 = [];
        } else {
          this.fieldsConsider = [];
          this.trueConsideration = "";
          this.falseConsideration = "";
          this.considerationRate = true;
        }
      }
      if (type == "radio") {
        if (
          event.target.id == "exclusiveRadio" &&
          event.target.checked == true
        ) {
          this.inclusiveLabel = false;
          this.selectedDropdown = [];
          this.fieldsConsider.forEach(element => {
            element.considerableFields=[]
            element.considerationRate=""
          });
        } else if (
          event.target.id == "inclusiveRadio" &&
          event.target.checked == true
          
        ) {
          this.exclusiveLabel = false;
          this.selectedDropdown2 = [];
          this.fieldsConsider.forEach(element => {
            element.considerableFields=[]
            element.considerationRate=""
          });
        }
        else if(event.target.id == "inclusiveRadio" &&
          event.target.checked == false)
          {
            this.selectedDropdown = [];
            this.fieldsConsider.forEach(element => {
              element.considerableFields=[]
              element.considerationRate=""
            });
          }
          else if(event.target.id == "exclusiveRadio" &&
            event.target.checked == false)
            {
              this.selectedDropdown2 = [];
              this.fieldsConsider.forEach(element => {
                element.considerableFields=[]
                element.considerationRate=""
              });
            }
      }
    },
    async getFormDetails() {
      this.templateFieldDetails = [];
      this.fieldsArray=[]
      console.log(" teplate changed",this.getTemplateId, this.version)
      if (this.getTemplateId && this.version) {
        await axios
          .get(
            `${this.baseurl}/forms/templatedesign/getpagesbyid?formId=${this.getTemplateId}&version=${this.version}`,
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            if (res.data) {
              this.tableloadingBlock = false;
              this.templateid = res.data.templateId;
              const filteredFields = res.data.pages.map((page) => {
                return {
                  ...page,
                  field: page.field.filter(
                    (fieldItem) =>
                      fieldItem.type !== "header" &&
                      fieldItem.type !== "subHeader"
                  ),
                };
              });
              if (filteredFields != undefined || filteredFields == null) {
                filteredFields.forEach((filteredField) => {
                  filteredField.field.forEach((fieldItem) => {
                    this.fieldsArray.push(fieldItem)
                    if (fieldItem.templateFieldProperties != null) {
                      fieldItem.templateFieldProperties.fieldsToConsider.forEach(
                        (fields) => {
                          if (
                            fieldItem.type === "dropdown" ||
                            fieldItem.type === "radio"
                          ) {
                            fields.considerableFields.forEach(
                              (consiField, index) => {
                                fieldItem.options.forEach((option) => {
                                  if (consiField === option.codevalue) {
                                    fields.considerableFields[index] =
                                      option.optionname;
                                  }
                                });
                              }
                            );
                          }
                        }
                      );
                    }
                  });
                });
                this.templateFieldDetails.push(...filteredFields);
              }
            } else {
              this.tableloadingBlock = false;
              this.errorBlock = true;
            }
          })
          .catch((err) => {
            this.tableloadingBlock = false;
            this.errorBlock = true;
            console.log("err", err);
          });
      } else {
        alert(this.$t("navigations.please select a form"));
        this.$emit("ReturnLog");
      }
    },
    async savepredictions(type) {
      this.loadingBlock = true;

      if (type == "dropdown") {
        this.dropdownValues = JSON.parse(JSON.stringify(this.fieldsConsider));
        this.templateFieldDetails.forEach((page) => {
          page.field.forEach((fieldItem) => {
            if (fieldItem.templateFieldProperties != null) {
              fieldItem.templateFieldProperties.fieldsToConsider.forEach(
                (fields) => {
                  if (fieldItem.type == "dropdown") {
                    this.dropdownValues.forEach((consiField) => {
                      consiField.considerableFields.forEach((field, index) => {
                        fieldItem.options.forEach((option) => {
                          if (field == option.optionname) {
                            consiField.considerableFields[index] =
                              option.codevalue;
                          }
                        });
                      });
                    });
                  }
                }
              );
            } else {
              if (fieldItem.type == "dropdown") {
                this.dropdownValues.forEach((consiField) => {
                  consiField.considerableFields.forEach((field, index) => {
                    fieldItem.options.forEach((option) => {
                      if (field == option.optionname) {
                        consiField.considerableFields[index] = option.codevalue;
                      }
                    });
                  });
                });
              }
            }
          });
        });
        await axios
          .post(
            `${this.baseurl}/forms/aiproperties/create`,
            {
              templateId: this.templateid,
              pageNumber: this.pageIndex,
              fieldName: this.fieldname,
              isRepetedField: false,
              templateFieldProperties: {
                featureField: this.featureValue,
                fieldsToConsider: [...this.dropdownValues],
                rangesToConsider: [
                  {
                    considerationRate: 100,
                    min: 0,
                    max: 0,
                  },
                ],
                forBoolean: {
                  considerationRate: 100,
                },
                possibleNumericRange: {
                  min: 0,
                  max: 0,
                },
                boolValueToConsider: [
                  {
                    considerationRate: 100,
                    value: true,
                  },
                ],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            this.aitoolModal = false;
            alert(this.$t("navigations.form details updated successfully"));
            this.getFormDetails();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err);
            if (
              err.response.data.errors.DomainValidations[0] ===
              "Invalid! Duplicates Found for Considarable Fields"
            ) {
              alert(
                "Same option(s)cannot be added in different consideration groups"
              );
            }
          });
      }
      if (type == "text" || type == "textbox") {
        await axios
          .post(
            `${this.baseurl}/forms/aiproperties/create`,
            {
              templateId: this.templateid,
              pageNumber: this.pageIndex,
              fieldName: this.fieldname,
              isRepetedField: false,
              templateFieldProperties: {
                featureField: this.featureValue,
                fieldsToConsider: [
  
                  ...this.templateFieldProperties.fieldsToConsider,
                ],
                rangesToConsider: [
                  {
                    considerationRate: 100,
                    min: 0,
                    max: 0,
                  },
                ],
                forBoolean: {
                  considerationRate: 100,
                },
                possibleNumericRange: {
                  min: 0,
                  max: 0,
                },
                boolValueToConsider: [
                  {
                    considerationRate: 100,
                    value: true,
                  },
                ],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            alert(this.$t("navigations.form details updated successfully"));
            this.aitoolModal = false;
            this.getFormDetails();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err);
            if (
              err.response.data.errors.DomainValidations[0] ===
              "Invalid! Duplicates Found for Considarable Fields"
            ) {
              alert("Duplicates Found for Considarable Fields");
            }
          });
      }
      if (type == "radio" || type == "radiohorizondal") {
        this.dummySelect = JSON.parse(JSON.stringify(this.selectedDropdown));
        this.dummySelect2 = JSON.parse(JSON.stringify(this.selectedDropdown2));
        this.radioValues = JSON.parse(JSON.stringify(this.fieldsConsider));
        this.templateFieldDetails.forEach((page) => {
          page.field.forEach((fieldItem) => {
            if (fieldItem.templateFieldProperties != null) {
              fieldItem.templateFieldProperties.fieldsToConsider.forEach(
                (fields) => {
                  if (fieldItem.type == "radio") {
                    this.dummySelect.forEach((selectField, index) => {
                      fieldItem.options.forEach((option) => {
                        if (selectField == option.optionname) {
                          this.dummySelect[index] = option.codevalue;
                        }
                      });
                    });
                    this.dummySelect2.forEach((selectField, index) => {
                      fieldItem.options.forEach((option) => {
                        if (selectField == option.optionname) {
                          this.dummySelect2[index] = option.codevalue;
                        }
                      });
                    });
                    this.radioValues.forEach((consiField) => {
                      consiField.considerableFields.forEach((field, index) => {
                        fieldItem.options.forEach((option) => {
                          if (field == option.optionname) {
                            consiField.considerableFields[index] =
                              option.codevalue;
                          }
                        });
                      });
                    });
                  }
                }
              );
            } else {
              if (fieldItem.type == "radio") {
                this.dummySelect.forEach((selectField, index) => {
                  fieldItem.options.forEach((option) => {
                    if (selectField == option.optionname) {
                      this.dummySelect[index] = option.codevalue;
                    }
                  });
                });
                this.dummySelect2.forEach((selectField, index) => {
                  fieldItem.options.forEach((option) => {
                    if (selectField == option.optionname) {
                      this.dummySelect2[index] = option.codevalue;
                    }
                  });
                });
                this.radioValues.forEach((consiField) => {
                  consiField.considerableFields.forEach((field, index) => {
                    fieldItem.options.forEach((option) => {
                      if (field == option.optionname) {
                        consiField.considerableFields[index] = option.codevalue;
                      }
                    });
                  });
                });
              }
            }
          });
        });
        await axios
          .post(
            `${this.baseurl}/forms/aiproperties/create`,
            {
              templateId: this.templateid,
              pageNumber: this.pageIndex,
              fieldName: this.fieldname,
              isRepetedField: false,
              templateFieldProperties: {
                featureField: this.featureValue,
                isInclusive: this.inclusiveLabel,
                isExclusive: this.exclusiveLabel,
                fieldsToConsider: [
                  {
                    considerationRate: 100,
                    considerableFields: this.dummySelect,
                  },
                  {
                    considerationRate: 0,
                    considerableFields: this.dummySelect2,
                  },
                  ...this.radioValues,
                ],
                rangesToConsider: [
                  {
                    considerationRate: 100,
                    min: 0,
                    max: 0,
                  },
                ],
                forBoolean: {
                  considerationRate: 100,
                },
                possibleNumericRange: {
                  min: 0,
                  max: 0,
                },
                boolValueToConsider: [
                  {
                    considerationRate: 100,
                    value: true,
                  },
                ],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            alert(this.$t("navigations.form details updated successfully"));
            this.aitoolModal = false;
            this.getFormDetails();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err);
          });
      }
      if (type == "number") {
        if(!this.MinNumValue || !this.MaxNumValue)
          {
          if(!this.MaxNumValue && this.featureValue==true)
          {
            this.scaleError[6]=true
            this.loadingBlock=false
          }
         if(!this.MinNumValue && this.featureValue==true)
            {
              this.scaleError[5]=true
              this.loadingBlock=false
            }
        if((!this.MinNumValue || !this.MaxNumValue) && this.featureValue==false)
          {
            
              this.scaleError[5]=false
              this.scaleError[6]=false
            await axios
              .post(
                `${this.baseurl}/forms/aiproperties/create`,
                {
                  templateId: this.templateid,
                  pageNumber: this.pageIndex,
                  fieldName: this.fieldname,
                  isRepetedField: false,
                  templateFieldProperties: {
                    featureField: this.featureValue,
                    fieldsToConsider: [
                      {
                        considerationRate: 100,
                        considerableFields: this.selectedDropdown,
                      },
                      {
                        considerationRate: 0,
                        considerableFields: this.selectedDropdown2,
                      },
                      ...this.fieldsConsider,
                    ],
                    rangesToConsider: this.templateFieldProperties.rangesToConsider,
                    forBoolean: {
                      considerationRate: 100,
                    },
                    possibleNumericRange: {
                      min: 0,
                      max: 0,
                    },
                    boolValueToConsider: [
                      {
                        considerationRate: 100,
                        value: true,
                      },
                    ],
                  },
                },
                {
                  headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then((res) => {
                this.loadingBlock = false;
                alert(this.$t("navigations.form details updated successfully"));
                this.aitoolModal = false;
                this.getFormDetails();
              })
              .catch((err) => {
                this.loadingBlock = false;
                console.log(err);
                if (
                  err.response.data.errors.DomainValidations[0] ===
                  "Invalid! Overlaping Ranges Found"
                ) {
                  alert("Values in range already defined in another range");
                }
              });
            
          }
        
        }        
       
         else{
          this.scaleError[5]=false
          this.scaleError[6]=false
        await axios
          .post(
            `${this.baseurl}/forms/aiproperties/create`,
            {
              templateId: this.templateid,
              pageNumber: this.pageIndex,
              fieldName: this.fieldname,
              isRepetedField: false,
              templateFieldProperties: {
                featureField: this.featureValue,
                fieldsToConsider: [
                  {
                    considerationRate: 100,
                    considerableFields: this.selectedDropdown,
                  },
                  {
                    considerationRate: 0,
                    considerableFields: this.selectedDropdown2,
                  },
                  ...this.fieldsConsider,
                ],
                rangesToConsider: this.templateFieldProperties.rangesToConsider,
                forBoolean: {
                  considerationRate: 100,
                },
                possibleNumericRange: {
                  min: this.MinNumValue,
                  max: this.MaxNumValue,
                },
                boolValueToConsider: [
                  {
                    considerationRate: 100,
                    value: true,
                  },
                ],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            alert(this.$t("navigations.form details updated successfully"));
            this.aitoolModal = false;
            this.getFormDetails();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err);
            if (
              err.response.data.errors.DomainValidations[0] ===
              "Invalid! Overlaping Ranges Found"
            ) {
              alert("Values in range already defined in another range");
            }
          });
        }
      }
      if (type == "checkbox") {
        await axios
          .post(
            `${this.baseurl}/forms/aiproperties/create`,
            {
              templateId: this.templateid,
              pageNumber: this.pageIndex,
              fieldName: this.fieldname,
              isRepetedField: false,
              templateFieldProperties: {
                featureField: this.featureValue,
                isInclusive: this.inclusiveLabel,
                isExclusive: this.exclusiveLabel,
                fieldsToConsider: [
                  {
                    considerationRate: 100,
                    considerableFields: ["string"],
                  },
                ],
                rangesToConsider: [
                  {
                    considerationRate: 100,
                    min: 0,
                    max: 0,
                  },
                ],
                forBoolean: {
                  considerationRate: 100,
                },
                possibleNumericRange: {
                  min: 0,
                  max: 0,
                },
                boolValueToConsider: [
                  {
                    considerationRate: 100,
                    value: true,
                  },
                ],
              },
            },
            {
              headers: {
                Authorization: "Bearer " + this.idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            this.loadingBlock = false;
            alert(this.$t("navigations.form details updated successfully"));
            this.aitoolModal = false;
            this.getFormDetails();
          })
          .catch((err) => {
            this.loadingBlock = false;
            console.log(err);
          });
      }
    },
    getFilteredOptions(index) {
      let options = this.dropdownoptions;
      for (let i = 0; i < this.fieldsConsider.length; i++) {
        if (i !== index) {
          options = options.filter(
            (option) =>
              !this.fieldsConsider[i].considerableFields.some(
                (selected) => selected.optionname === option.optionname
              )
          );
        }
      }
      return options;
    },    
    openModal(field, pageno) {
      window.scrollTo(0, 0);
      this.loadingBlock = false;
      this.MaxNumValue = "";
      this.MinNumValue = "";
      this.aitoolModal = true;
      this.selectedmenu = field.type;
      this.selectedMenuheader = field.label;
      this.fieldname = field.fieldName;
      this.pageIndex = pageno;
      this.selectedDropdown = [];
      this.selectedDropdown2 = [];
      this.fieldsConsider = [];
      this.selectedFieldValidation = "";
      this.radiooptionsarray = field.options;
      if (field.type == "text" || field.type == "textbox") {
        this.templateFieldProperties.fieldsToConsider = [];
      }

      if (field.type == "dropdown" || field.type == "radio") {
        this.dropdownoptions = this.radiooptionsarray;
      }
      if (field.validation.includes("Number")) {
        this.selectedFieldValidation = "Number";
      }

      if (field.templateFieldProperties == null) {
        this.featureValue = false;
        this.inclusiveLabel = false;
        this.exclusiveLabel = false;
        if (field.type == "dropdown") {
          this.fieldsConsider = [
            {
              considerationRate: 0,
              considerableFields: [],
            },
          ];
        }
        if (field.type == "radio") {
          this.selectedDropdown = [];
          this.selectedDropdown2 = [];
          this.templateFieldProperties.fieldsToConsider[1] = {
            considerationRate: 0,
            considerableFields: [],
          };
          this.templateFieldProperties.fieldsToConsider[2] = {
            considerationRate: 0,
            considerableFields: [],
          };
          this.fieldsConsider = [
            {
              considerationRate: 0,
              considerableFields: [],
            },
          ];
        }
        if (field.type == "textbox" || field.type == "text")
          if (field.validation.includes("Number")) {
            this.templateFieldProperties.rangesToConsider = [
              {
                considerationRate: 0,
                min: 0,
                max: 0,
              },
            ];
          } else {
            this.templateFieldProperties.fieldsToConsider = [
              {
                considerationRate: 0,
                considerableFields: [],
              }],
            this.templateFieldProperties[0].considerableFields[0] = "";
            this.templateFieldProperties[0].considerationRate = "";
          }
      } else {
        this.featureValue = field.templateFieldProperties.featureField;
        this.inclusiveLabel = field.templateFieldProperties.isInclusive;
        this.exclusiveLabel = field.templateFieldProperties.isExclusive;
        if (field.type == "dropdown") {
          field.templateFieldProperties.fieldsToConsider.forEach(
            (item, index) => {
              this.fieldsConsider.push(
                field.templateFieldProperties.fieldsToConsider[index]
              );
            }
          );
        }

        if (field.type == "textbox" || field.type == "text") {
          //Number range
          if (field.validation.includes("Number")) {
            this.MinNumValue =
              field.templateFieldProperties.possibleNumericRange.min;
            this.MaxNumValue =
              field.templateFieldProperties.possibleNumericRange.max;
            this.templateFieldProperties.rangesToConsider = [];
            field.templateFieldProperties.rangesToConsider.forEach(
              (item, index) => {
                (this.templateFieldProperties.rangesToConsider[index] = {
                  considerationRate: 0,
                  min: 0,
                  max: 0,
                }),
                  (this.templateFieldProperties.rangesToConsider[index].min =
                    item.min);
                this.templateFieldProperties.rangesToConsider[index].max =
                  item.max;
                this.templateFieldProperties.rangesToConsider[
                  index
                ].considerationRate = item.considerationRate;
              }
            );
          } else {
            //Textfield(not a number)
            this.fieldstoConsiderText[0].considerableFields[0] =
              field.templateFieldProperties.fieldsToConsider[0].considerableFields[0];
            this.fieldstoConsiderText[0].considerationRate =
              field.templateFieldProperties.fieldsToConsider[0].considerationRate;
            field.templateFieldProperties.fieldsToConsider.forEach(
              (item, index) => {
                if (index > 0) {
                  this.templateFieldProperties.fieldsToConsider.push(
                    field.templateFieldProperties.fieldsToConsider[index]
                  );
                }
              }
            );
          }
        }
        if (field.type == "radio") {
          this.selectedDropdown =
            field.templateFieldProperties.fieldsToConsider[0] != null
              ? field.templateFieldProperties.fieldsToConsider[0]
                  .considerableFields
              : [];
          this.selectedDropdown2 =
            field.templateFieldProperties.fieldsToConsider[1] != null
              ? field.templateFieldProperties.fieldsToConsider[1]
                  .considerableFields
              : [];

          if (field.templateFieldProperties.fieldsToConsider.length > 1) {
            field.templateFieldProperties.fieldsToConsider.forEach(
              (item, index) => {
                if (index > 1) {
                  if (
                    field.templateFieldProperties.fieldsToConsider[index] !=
                      null ||
                    field.templateFieldProperties.fieldsToConsider[index] != ""
                  ) {
                    this.fieldsConsider.push(
                      field.templateFieldProperties.fieldsToConsider[index]
                    );
                  }
                }
              }
            );
          } else {
            this.fieldsConsider = [
              {
                considerationRate: 0,
                considerableFields: [],
              },
            ];
          }
        }
      }
    },
    closemodal() {
      this.aitoolModal = false;
    },
  },
};
