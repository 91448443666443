<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_users.png" alt="Total Users">
              </div>
              <h5>{{ $t('navigations.total users')}}</h5>
          </div>
          <div class="ibox-content contentheight">
              <h1 class="no-margins">{{userscountstd}}</h1>
              <small>{{ $t('navigations.total users')}}</small>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'totalusers',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      userscountstd:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getuserscount();
		});
    await this.getuserscount();
  },
    methods:{
        async getuserscount() {
            const idtoken = store.getters.getIdToken;
            const studyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/study/getusercountinstudy?StudyId=${studyid}`,
          {
            headers: {
             "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.userscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>