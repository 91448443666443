<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
                <div class="ibox-title style_2">
                  <div class="label label-info float-right ibox-tools p-1">{{$t('navigations.last month')}}</div>
                    <h5>{{$t('navigations.completed visits')}}</h5>
                </div>
                <div class="ibox-content contentheight">
                    <h1 class="no-margins">{{completedCount}}</h1>
                    <small>{{$t('navigations.completed visits')}}</small>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'completedvisits',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      completedCount:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.GetCompletedCount();
		});
    await this.GetCompletedCount();
  },
    methods:{
        async  GetCompletedCount(){
            const idtoken = store.getters.getIdToken;
      const currentstuid =  store.getters.getStudyIs;   
      let status= "Completed";  
      await axios.get(`${this.baseapi}/management/patientschedule/overduecompletedcount?StudyId=${currentstuid}&DaysBefore=30&Status=${status}` , 
      {
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					})
      .then((res) => {
        this.completedCount = res.data.Completed;
      })
      .catch((err) => { console.log("error.." ,err);
      })
    },
     
       
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>