<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10 breakWord">
      <h2>{{ NewFile }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="$router.go(-1)"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div v-if="format == 'JPG' || format == 'JPEG'" >
      <img :src="source" alt="Italian Trulli" class="imgwidth">
    </div>
    </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div v-if="format == 'pdf'" class="pdfembeded">
      <vue-pdf-embed :source="source" />
    </div>
    <div v-if="office.includes(format)" class="outsideframe">
      <iframe
        :src="officeurl"
        width="100%"
        height="650px"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  props: {
    referenceId: String,
    filename: String,
    versionId: String,
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
       baseurl: process.env.VUE_APP_Service_URL,
       fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      office: ['xls', 'xlsx', 'doc', 'docx'],
      format: '',
      source: '',
      baseapi: process.env.VUE_APP_Service_URL,
      officeurl: '',
      NewFile:"",
  }
  },
  async mounted() {
    this.NewFile =this.filename;
    await this.setdoc();
    this.setfilename();
  },
  methods: {
    async setfilename() {
      await axios
        .get(`${this.fileuploadurl}/file/${this.referenceId}?versionid=${this.versionId}`,
        {
            headers: {
              "Content-Type": "application/octet-stream ",
            },
            responseType: 'arraybuffer',
          })
        .then((res) => {
          console.log(res)
          // this.NewFile =
          //   res.data.documentVersions[
          //     res.data.documentVersions.length - 1
          //   ].userUploadedFileName;
          // console.log("newfileee", this.NewFile);
          const contentDispositionHeader = res.headers['filename'];
                      // Extract the filename and format from the header
                      // const filenameMatch = contentDispositionHeader ? contentDispositionHeader.match(/filename="(_\d+)\.([^"]+)"/) : null;
                      // const filename = filenameMatch ? `${filenameMatch[1]}_${filenameMatch[2]}` : 'downloaded_file.octet-stream';
                      const format = contentDispositionHeader 
                      
                      const jsonData = res.data;
                      
                      // Create a Blob from the JSON data
                      const blob = new Blob([jsonData], { type: 'application/octet-stream' });                      
                      // Trigger a click on the link to initiate the download
        });
      this.format = this.NewFile.split(".").pop();
    },
    async setdoc() {
      this.source = `${this.fileuploadurl}/file/${this.referenceId}?versionid=${this.versionId}`;
      this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
    },
  },
};
</script>
<style>
.pdfembeded {
  height: 700px;
  overflow: scroll;
}
.imgwidth{
  max-width: 70vw;
}
.breakWord {
  word-break: break-word;
}
</style>
