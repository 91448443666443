<template>
  <div ref="forestPlot" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'ForestPlot',
  props: {
    data: Object
  },
  watch: {
    data: {
      handler() {
        this.createForestPlot();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.createForestPlot);
    this.createForestPlot();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.createForestPlot);
  },
  methods: {
    createForestPlot() {
      if (!this.data || !this.data.coef || !this.data["exp(coef)"] || !this.data["exp(coef) lower 95%"] || !this.data["exp(coef) upper 95%"]) {
        console.log('No data available for chart');
        return;
      }

      // Clear previous SVG elements
      d3.select(this.$refs.forestPlot).selectAll("*").remove();

      const plotData = Object.keys(this.data.coef).map(key => ({
        name: key,
        coef: this.data.coef[key],
        exp_coef: this.data["exp(coef)"][key],
        lower_ci: this.data["exp(coef) lower 95%"][key],
        upper_ci: this.data["exp(coef) upper 95%"][key]
      }));

      const margin = { top: 20, right: 20, bottom: 30, left: 200 };
      const container = this.$refs.forestPlot;
      const width = container.clientWidth - margin.left - margin.right;
      const height = plotData.length * 40;

      const x = d3.scaleLinear().range([0, width]);
      const y = d3.scaleBand().range([height, 0]).padding(0.1);

      const svg = d3.select(this.$refs.forestPlot).append("svg")
        .attr("viewBox", `0 0 ${width + margin.left + margin.right + 100} ${height + margin.top + margin.bottom + 100}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Define domains
      x.domain([d3.min(plotData, d => d.lower_ci), d3.max(plotData, d => d.upper_ci)]);
      y.domain(plotData.map(d => d.name));

      // Add x-axis
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      // Add y-axis
      svg.append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y));

        svg.append("text")
        .attr("class", "x-axis-label")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom + 10) // Position below the x-axis
        .attr("text-anchor", "middle")
        .text("exp(coef)");
      // Add zero line
      svg.append("line")
        .attr("class", "zero-line")
        .attr("x1", x(1))
        .attr("x2", x(1))
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "black")
        .attr("stroke-dasharray", "4,4");

      // Add error bars
      svg.selectAll(".error-bar")
        .data(plotData)
        .enter().append("line")
        .attr("class", "error-bar")
        .attr("x1", d => x(d.lower_ci))
        .attr("x2", d => x(d.upper_ci))
        .attr("y1", d => y(d.name) + y.bandwidth() / 2)
        .attr("y2", d => y(d.name) + y.bandwidth() / 2)
        .attr("stroke", "black")
        .attr("stroke-width", 2);

      // Add markers
      svg.selectAll(".marker")
        .data(plotData)
        .enter().append("circle")
        .attr("class", "marker")
        .attr("cx", d => x(d.exp_coef))
        .attr("cy", d => y(d.name) + y.bandwidth() / 2)
        .attr("r", 5)
        .attr("fill", "red");
    }
  }
};
</script>

<style>
.chart-container {
  width: 100%;
  height: auto;
}

.error-bar {
  stroke: black;
  stroke-width: 2;
}

.marker {
  fill: red;
}

.zero-line {
  stroke: black;
  stroke-dasharray: 4,4;
}
</style>
