<template>
    <div class="digital_form_field_wrapper">
        <div class="digital_form_field__containers">
            <div
                class="digital_form_field__containers--title text-white d-flex justify-content-between align-items-center px-3 py-2">
                <h4 class="my-0">Edit Field</h4>
                <div class=" cursor-pointer" @keyup.enter="$emit('closeModal')" @click.prevent="$emit('closeModal')"
                    role="button" tabindex="0">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="digital_form_field__containers--content p-3">
                <div class="form-group">
                    <label class="mb-0" for="labelText">Field label</label>
                    <textarea rows="auto" cols="auto" v-model="modalField" id="labelText"/>
                </div>
                <div class="text-end">
                    <button type="button" class="save_btn py-1 px-4" @click.prevent="saveChanges()">Save</button>
                </div>

            </div>
        </div>
    </div>
</template>
<script src="./digital_form_modal.js"></script>
<style scoped>
.digital_form_field_wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 3000;
    transition: all .3s ease-in-out;
}

.digital_form_field__containers {
    width: min(400px, 90%);
    background: #fff;
    border-radius: 4px;
    overflow: hidden;
}

.digital_form_field__containers--title {
    background-color: #768DD3;
}</style>