<template>
  <div ref="significancePlot" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3';
import axios from "axios";

export default {
  name: 'SignificancePlot',
  props: {
    data: Object
  },
  watch: {
    data: {
      handler() {
        this.createSignificancePlot();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.createSignificancePlot);
    this.createSignificancePlot();
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.createSignificancePlot);
  },
  methods: {
    createSignificancePlot() {
      if (!this.data || !this.data["p"]) {
        console.log('No data available for chart');
        return;
      }

      // Clear previous SVG elements
      d3.select(this.$refs.significancePlot).selectAll("*").remove();

      const plotData = Object.keys(this.data["p"]).map(key => ({
        name: key,
        p_value: this.data["p"][key],
        neg_log_p: -Math.log10(this.data["p"][key])
      }));

      const margin = { top: 20, right: 20, bottom: 50, left: 200 }; // Adjusted bottom margin for x-axis label
      const container = this.$refs.significancePlot;
      const containerWidth = container.clientWidth;
      const width = containerWidth - margin.left - margin.right;
      const height = plotData.length * 40;

      const x = d3.scaleLinear().range([0, width]);
      const y = d3.scaleBand().range([0, height]).padding(0.1);

      const svg = d3.select(this.$refs.significancePlot).append("svg")
        .attr("viewBox", `0 0 ${containerWidth + 100} ${height + margin.top + margin.bottom + 100}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Define domains
      x.domain([0, d3.max(plotData, d => d.neg_log_p)]);
      y.domain(plotData.map(d => d.name));

      // Add x-axis
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      // Add x-axis label
      svg.append("text")
        .attr("class", "x-axis-label")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 10) // Position below the x-axis
        .attr("text-anchor", "middle")
        .text("-log10(p-value)");

      // Add y-axis
      svg.append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y));

      // Add significance threshold line (e.g., at -log10(0.05) = 1.301)
      svg.append("line")
        .attr("class", "threshold-line")
        .attr("x1", x(1.301))
        .attr("x2", x(1.301))
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "red")
        .attr("stroke-dasharray", "4,4");

      // Add markers
      svg.selectAll(".marker")
        .data(plotData)
        .enter().append("circle")
        .attr("class", "marker")
        .attr("cx", d => x(d.neg_log_p))
        .attr("cy", d => y(d.name) + y.bandwidth() / 2)
        .attr("r", 5)
        .attr("fill", "blue");
    }
  }
};
</script>

<style>
.chart-container {
  width: 100%;
  height: auto;
}

.threshold-line {
  stroke: red;
  stroke-dasharray: 4,4;
}

.marker {
  fill: blue;
}

.x-axis-label {
  font-size: 14px;
  fill: black;
}
</style>
