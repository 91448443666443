<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t('navigations.change schedule template') }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="row mb-1">
        <div class="col-lg-4">
          <div class="form-group mb-lg-0">
            <label class="mb-0">{{ $t('navigations.site code') }}</label>
            <select class="form-select" v-model="siteCode" @change="getpatients($event.target.value)">
              <option value="Select">{{ $t('navigations.select') }}</option>
              <option v-for="site in sites"
                :key="site.sitetId"
                :value="site.siteID">{{site.siteCode}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group mb-lg-0">
            <label class="mb-0">{{ $t('navigations.subject id') }}</label>
            <select class="form-select" v-model="subjectID" @change="getSchedule($event.target.value),displaySearch=true">            
              <option v-for="patient in patients"
                :key="patient.patientId"
                :value="patient.patientId">{{ patient.subjectId }}
              </option>
            </select>
          </div>
        </div>        
      </div>
    </div>
    <div class="ibox-content mb-1 px-3 py-2" v-if="displaySearch==true">
      <div class="row mb-1 ">
        <div class="col-lg-5 my-1">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t('navigations.visit name') }}</label>
            <input v-model="searchVisit" class="form-control" type="text">
          </div>
        </div>
        <div class="col-lg-5 my-1">
          <div class="form-group mb-0">
            <label class="mb-0">{{ $t('navigations.visit no') }}</label>
            <input v-model="searchVisitNo" class="form-control" type="text">
          </div>          
        </div> 
        <div class="col-lg-2 my-1 py-lg-0 align-self-end">
            <span type="button"
              class="save_btn px-5 mx-lg-1 w-100"
              @click.prevent="getdata()"
            >
              {{ $t('navigations.search') }}
            </span>
          </div>     
      </div>
    </div>
    <div class="ibox-body" v-if="displayTable==true">
       <div class="ibox-title style_2">
          <h5 class="float-left">{{ $t('navigations.change schedule template') }}</h5>
      </div>
      <div class="ibox-content mb-0">
        <div class="table-responsive">
          <table class="table table-bordered dataTables">
            <thead>
              <tr>
                <th>
                  {{ $t('navigations.site code') }} 
                </th>
                <th>
                  {{ $t('navigations.subject id') }}                 
                </th>
                <th >
                    {{ $t('navigations.visit no') }}                                             
                </th>
                <th class="sort_block" @click="SortSelected('visitName',4)" id="4">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.visit name') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                                  
                </th>
                <th class="sort_block" @click="SortSelected('visitType',5)" id="5">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.visit type') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                                 
                </th>
                <th class="sort_block" @click="SortSelected('visitDate',6)" id="6">
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t('navigations.visit date') }}                   
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                              
                </th>
                <th class="sort_block" @click="SortSelected('visitStatus',7)" id="7">
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t('navigations.visit status') }}             
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th class="sort_block" @click="SortSelected('investigatorName',8)" id="8">
                  <div class="d-flex flex-row justify-content-between">       
                    {{ $t('navigations.investigator') }}       
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th> 
                <th class="sort_block" @click="SortSelected('templateName',9)" id="9">
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t('navigations.template name') }}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>                               
                <th class="sort_block" @click="SortSelected('version',10)" id="10">
                  <div class="d-flex flex-row justify-content-between">      
                    {{ $t('navigations.version') }}        
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>                  
                </th>
                <th v-if="roleprivileges.includes('Change Schedule Template Edit')" class="text-center">{{ $t('navigations.edit template') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="subject in subjectList" :key="subject.patientId"  :class="{ bgcolor3: subject.isUnScheduled }">
                <td>{{subject.siteCode}}</td>
                <td>{{subject.patientNo}}</td>
                <td>{{subject.visitNo}}</td>
                <td>{{subject.visitName}}</td>
                <td>{{subject.visitType}}</td>
                <td>{{filter(subject.visitDate)}}</td>               
                 <td><span class="badge badge-grn" v-if="subject.visitStatus=='Completed'">{{subject.visitStatus}}</span>
                  <span class="badge badge-danger" v-if="subject.visitStatus=='Overdue'">{{subject.visitStatus}}</span>
                  <span class="badge badge-warning" v-if="subject.visitStatus=='Imminent'">{{subject.visitStatus}}</span>
                  <span class="badge badge-genre" v-if="subject.visitStatus=='Planned' || subject.visitStatus=='Withdrawn'">{{subject.visitStatus}}</span>                
                </td>
                <td>{{subject.investigatorName}}</td>
                <td>{{subject.templateName}}</td>
                <td>{{subject.version}}</td>
                <td v-if="roleprivileges.includes('Change Schedule Template Edit')" class="text-center"> 
                   <span v-if="subject.visitStatus != 'Completed'"
                    ><i
                      class="fa fa-edit editicon"
                      @click.prevent="openmodal(subject.sheduleId)"
                    ></i
                  >
                  </span></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mx-0 mt-2">
            <div class="col-lg-12 mx-0 px-0">
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPage"
                  :range-size="0"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="visit-modal-container" v-if="visitModal">
      <div class="visit-modal-contents">
      <div class="titleclass d-flex justify-content-between py-2 px-3">
        <h4 class="my-0"> {{ $t('navigations.assign template') }}</h4>
        <div class="cursor-pointer"  @click.prevent="closemodal()">
          <i class="fa fa-times"></i>
        </div>
      </div>
          <div class="form-group row flex-column align-items-center justify-content-evenly my-lg-2">
            <div class="col-lg-8 my-4">
              <select
                class="form-control  my-2 my-lg-0"
                placeholder="Search by Status"
                v-model="selectedTemplate"
              >
                <option value="" disabled selected>{{ $t('navigations.select template') }}</option>
                <option
                  v-for="template in templatelist"
                  :key="template.templateId"
                  :value="template"
                >{{template.templateName}} ({{  template.version }})
                </option>
              </select>
            </div>
            <div class="col-md-4 my-2 mb-md-3">
                <button
                class="btn btn-primary save_btn px-5 mx-1"
                @click.prevent="assignTemplate()"
              >
                {{ $t('navigations.assign') }}
              </button>
            </div>        
        </div>
      </div>      
    </div>
  </div> 
</template>
<script src="./visit_template.js"></script>
<style scoped>
.site-block select {
	font-size: 1rem;
	border: 0px;
	padding-left: 0.2rem;
	padding-right: 0.7rem;
	background-color: transparent;
	outline: none;
}

.options {
	border: 0px;
}
.visit-modal-container{
  position: fixed;
  background: rgb(0 0 0 / 18%);
  inset: 0;
  z-index: 3000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.visit-modal-contents{
  background: #fff;
  width: min(600px,90%);
  border-radius: 4px;
  position: fixed;
  transition: all .3s ease-in-out;
  overflow:hidden
}

.fixed-height{
  height:60%;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
.badge-genre{
  background-color: #b6bdbf;
  color: #FFFFFF;
}
.badge-grn {
background-color: #1ab394;
color: #FFFFFF;
}
.height-170{
  height:170px;
}
.bgcolor3 {
  background-color: #d0f1f1;
}
</style>