<template>
  <div class="mainDiv">
    <div class="subDiv">
      <div class="userDiv" id="userVideoDiv"></div>
      <div class="callerDiv" id="remoteVideo"></div>
    </div>
    <div class="menuDiv">
      <button class="normalBtns" @click="StartStreamAsync" id="join-btn">
        Join
      </button>
      <button class="normalBtns" @click="leaveStream" id="leave-btn">
        leave
      </button>
      <button class="normalBtns" @click="stopTrack" id="leave-btn">Mute</button>
      <button class="normalBtns" @click="stopTrack" id="leave-btn">Hide</button>
      <button class="normalBtns" @click="stopTrack" id="leave-btn">
        Share
      </button>
    </div>
  </div>
</template>
  
  <script>
/* eslint-disable */
import AgoraRTC from "agora-rtc-sdk-ng";
import { markRaw } from "vue";

export default {
  name: "AgoraPage",
  props: {
    token: String,
    room: String,
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localtracks: [],
      remotetracks: [],
      client: {},
      currentUserId: "",
      AppID: "9175cb911f9047b5aca2014e39af4ae4",
      RoomID: "Test",
      AgoraToken:
        "007eJxTYHB5+jXOwznRbLLDqoO9v7/FNXU4cqyeLnXbrNxNUTPaQ0SBwdLQ3DQ5ydLQMM3SwMQ8yTQxOdHIwNAk1dgyMc0kMdVkroleSkMgI8OqO50MjFAI4rMwhKQWlzAwAADoXh3u",
    };
  },

  async mounted() {
    //InitilizeVaribles
    if (this.room !== "" || this.room !== null) {
      this.RoomID = this.room;
      this.AgoraToken = this.token;
    } else {
      await this.createNewChannel();
    }
    //create Client
    this.client = markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "vp8" }));
    //Join Channel
    this.currentUserId = await this.client.join(
      this.AppID,
      this.RoomID,
      this.AgoraToken,
      null
    );
    //Sub to New User Join
    this.client.on("user-published", this.aRemoteUserJoined);
    this.client.on("user-left", this.aRemoteUserLeft);
  },
  methods: {
    async createNewChannel() {
      await axios
        .get(`${this.baseurl}/management/videocall/callone`)
        .then((res) => {
          (this.RoomID = res.data.channel), (this.AgoraToken = res.data.token);
          console.log(res);
        });
    },
    async StartStreamAsync() {
      try {
        this.localtracks = await AgoraRTC.createMicrophoneAndCameraTracks();
        this.localtracks[1].play(`userVideoDiv`);
        await this.client.publish([this.localtracks[0], this.localtracks[1]]);
      } catch (res) {
        console.log(res);
      }
    },

    async leaveStream() {
      //Video
      this.localtracks[1].stop();
      this.localtracks[1].close();
      //Audio
      this.localtracks[0].stop();
      this.localtracks[0].close();
    },

    async aRemoteUserJoined(user, mediaType) {
      await this.client.subscribe(user, mediaType);
      if (mediaType === "audio") {
        user.audioTrack.play();
      }
      if (mediaType === "video") {
        user.videoTrack.play("remoteVideo");
      }
    },

    async muteLocalAudio() {
      this.localtracks[0].stop();
      this.localtracks[0].close();
    },
    async hideLocalVideo() {
      this.localtracks[1].stop();
      this.localtracks[1].close();
    },

    async aRemoteUserLeft(user) {},
  },
};
</script>
  
  
  <style>
.userDiv {
  background-color: aqua;
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
.callerDiv {
  background-color: #000;
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
.mainDiv {
  background-color: aliceblue;
  width: 100%;
  height: 600px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.subDiv {
  background-color: rgb(18, 111, 114);
  width: 95%;
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.menuDiv {
  background-color: orchid;
  width: 95%;
  display: flex;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
.normalBtns {
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  width: 10%;
}
</style>