<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-body p-3">
      <div class="form-group row">
        <h3>Create password</h3>
        <div class="col-lg-12 my-2">
          <label class="col-form-label"
            >Password<sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <div class="input-group date">
            <input
              type="password"
              autocomplete="off"
              class="form-control"
              maxlength="16"
              v-model="values.password"
            /><br>
            <span class="errormsg col-lg-12 my-2" v-if="errors[0] == false">
              Please enter a Password</span
            >
            <span class="errormsg col-lg-12 my-2" v-if="errors[3] == false"
              >Passwords must contain at least eight characters, including
              uppercase, lowercase letters, special characters and
              numbers.</span
            >
            <span class="errormsg col-lg-12 my-2" v-if="errors[5] == false"
              >Password and Confirm password mismatch!!</span
            >
          </div>
        </div>

        <div class="col-lg-12 my-2">
          <label class="col-form-label"
            >Confirm Password<sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <div class="input-group date">
            <input
              type="password"
              autocomplete="off"
              class="form-control"
              maxlength="16"
              v-model="confirmpassword"
            /><br>
            <span class="errormsg col-lg-12 my-2" v-if="errors[1] == false">
              Please enter confirm Password</span
            >
            <span class="errormsg col-lg-12 my-2" v-if="errors[4] == false">
              Passwords must contain at least eight characters, including
              uppercase, lowercase letters, special characters and
              numbers.</span
            >
          </div>
        </div>

        <div class="col-lg-6 my-2 d-flex justify-content-lg-end">
          <button
            class="btn save_btn py-2 px-5"
            type="submit"
            @click.prevent="setPassword()"
          >
            Verify
          </button>
        </div>

      </div>
    </div>
  </div>
</template>
<script >
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import { onUnmounted } from '@vue/runtime-core';
export default{
    name : "patientLoginPassword",
    props: { "patientLoginId": String },
    data(){
        return{   
          headerValues:"",  
           baseEnv:`${process.env.VUE_APP_Service_URL}/management/patientlogin/`,  
            values:{
                id :"",
                subjectID :"",
                emailID :"",
                patientPin :"",
                password :"",
                activeStatus : "",
            },
            confirmpassword:"",
            validations: [false, false, true, false, false],
            errors: []

        }
    },
    async onUnmounted(){
        this.EnableMenu();
    },
    async mounted(){
     this.Disablemenu();
      const idtoken = store.getters.getIdToken;
      this.headerValues =  {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }}
      this.values.id = this.patientLoginId;
      console.log("in moutned" ,this.values.id);
      await this.getPatientLoginById();
      this.isLinkExpire();
    },
    methods:{
      async Disablemenu() {
        store.dispatch("setNavbar", false);
        const changeWidth=document.querySelector("#page-wrapper")
        changeWidth.style.width="100%"
      },
      async EnableMenu(){
        store.dispatch("setNavbar", true);
      },
      async getPatientLoginById(){
          console.log("Testttttttttttttttttt by id")
        await axios.get(`${this.baseEnv}getpatientloginbyid/`+this.values.id , this.headerValues)
            .then((res) => {
              console.log("patient login details is " , res.data);
              this.values = res.data;
            })
            .catch((err) => console.log("error.." , err));
      },
      isLinkExpire(){
        if(this.values.activeStatus == "Activated"){
          alert("Password for this patient already set");
          this.$router.push(`/`);

        }
      },

      async setPassword(){
        this.validate();
        if (this.validations.includes(false)) {
                this.errors = this.validations;
        }
        else{
            console.log("Testttttttttttttttttt")
           await axios.put(`${this.baseEnv}updatepatientlogin`,this.values,this.headerValues)
                .then(res=>{ 
                  console.log("Updated succefully..." , res);
                  this.$router.push(`/PatientLoginScreen`);
                  })
                .catch(err => console.log("errors..." , err));

        }

      },
      validate(){
        this.Validatepassword(this.values.password);
        this.Validateconfirmpassword(this.confirmpassword);
        this.Validatematchpasswords(this.values.password,this.confirmpassword);
      },
      async Validatepassword(passwd) {

            let reNum = /[0-9]/;
            let reLow = /[a-z]/;
            let reUpp = /[A-Z]/;
            let spcl = /(?=.*[!@#$%^&*])/;
            if ((passwd == "") || (passwd == '') || (passwd == null)) {
              this.validations[3] = true;
              this.validations[0] = false;

            }
            else if ((passwd.length < 8) || (!reLow.test(passwd)) || (!reNum.test(passwd)) || (!spcl.test(passwd)) || (!reUpp.test(passwd))) {
              this.validations[0] = true;

              this.validations[3] = false;
            }
            else {
              this.validations[0] = true;
              this.validations[3] = true;
            }

      },

      async Validateconfirmpassword(passwd) {

      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if ((passwd == "") || (passwd == '') || (passwd == null)) {
        this.validations[4] = true;
        this.validations[1] = false;

      }
      else if ((passwd.length < 8) || (!reLow.test(passwd)) || (!reNum.test(passwd)) || (!spcl.test(passwd)) || (!reUpp.test(passwd))) {
        this.validations[1] = true;

        this.validations[4] = false;
      }
      else {
        this.validations[1] = true;
        this.validations[4] = true;
      }

      },

     async Validatematchpasswords(passwd, confirmpaswd) {
      if (this.validations[0] == true && this.validations[3] == true && this.validations[1] == true && this.validations[4] == true) {
        console.log("validate paswds")
        if (passwd == confirmpaswd) {
          this.validations[5] = true;
          console.log(" paswds ok")
        }
        else {
          this.validations[5] = false;
          console.log("paswds mismatch")
        }
      }
      else {
        console.log("final condtn paswds")
        this.validations[5] = true;
      }
     },

    },
};


</script>
<style scoped>
@import "../../assets/style.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.cancel_btn {
  background: var(--secondary-clr) !important;
  color: #ffffff;
}
.cancel_btn:hover {
  color: #fff;
  background-color: #22045a !important;
  border-color: #22045a !important;
}
.cancel_btn:active {
  color: #fff;
  background-color: #532eda !important;
  border-color: #532eda !important;
}
.btn-white {
  background: white;
  border: 1px solid #e7eaec;
}
.btn {
  border-radius: 3px;
  font-size: inherit;
}
.save_btn {
  background-color: #145faf;
  color: white;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.impcl {
  color: #ff4747;
  font-size: 25px;
  float: right;
}
.impcl:hover {
  color: #a30202;
  font-size: 25px;
  float: right;
}
.save_btn:hover {
  color: #fff;
  background-color: #2d91fb;
  border-color: #2d91fb;
}
.save_btn:active {
  color: #fff;
  background-color: #618bd7;
  border-color: #618bd7;
}
.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}
</style>