<template>
 <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
        <h2>Fill Form</h2>
    </div>
        <div class="col-lg-2">
        <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
</div>
<div class="wrapper wrapper-content">
    <dynamicform
        :templateid = "templateId"
        :stdyid = "stdyid"
        :visitids = "visitids"
        :issavefun = "saveactive"
        :isPatientVisit = "ispatientvalue"
    />
</div>
</template>

<script src="./formComponent.js"></script>