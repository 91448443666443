/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;
export default {
    name: 'formsummery',
    components: {
        VPagination,
    },
    data() {
        return {
            roleprivileges: "",
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
            searchdata: {
                SiteCode: "",
                PatientId: "",
                FormName: "",
                FormStatus: "",
                Pageindex: 1,
                Pagesize: 10,
                SortProperty: "",
            },
            fieldstatusdata: [],
            sites: "",
            patients: "",
            idToken: "",
            selectedType: "",
            selectedId: "",
            statusCheck: "",
            searchItem: "",
            currentPageSize: 0,
            currentPageNumber: 1,
            enableNext: true,
            enablePrevious: true,
            totalLists: 0,
            totalPages: 0,
            lists: [],
            filteredListing: [],
            queryCount :0,
            queryDetails :"",
        }
    },
    async mounted() {
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.idToken = store.getters.getIdToken;
            this.getpatients();
            this.getsites();
            this.searchData();
            this.filter();
          });
        await this.setroleprivileges(); 
        this.idToken = store.getters.getIdToken;
        this.getpatients();
        this.getsites();
        this.searchData();
        this.filter();
    },
    methods: {
        async setroleprivileges(){
            this.roleprivileges = await store.getters.getRolesprivilegeData;
          },
        filter(data) {
            if (data) {
                return moment(data).format('DD/MM/YYYY hh:mm')
            }
        },
        async viewforms(id) {
            const fromArchive = false
            this.$router.push(`/showform/summery/${id}/${fromArchive}`);
        },
        async getPrevious() {
            if (this.searchdata.Pageindex > 1) {
                console.log("previous page");
                this.searchdata.Pageindex = this.searchdata.Pageindex - 1;
                this.searchData();
            }
        },
        async getNext() {
            if (this.searchdata.Pageindex < this.totalLists) {
                this.searchdata.Pageindex = this.searchdata.Pageindex + 1;
                this.searchData();

            }
        },
        async searchData() {
            if(this.searchdata.SiteCode=="All"){
                this.searchdata.SiteCode=""
            }
            if(this.searchdata.PatientId=="All"){
                this.searchdata.PatientId=""
            }
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios
                .get(this.baseUrl +
                    "formlist?FormName=" + this.searchdata.FormName +
                    "&FormStatus=" + this.searchdata.FormStatus +
                    "&SiteCode=" + this.searchdata.SiteCode +
                    "&StudyId=" + stdid +
                    "&PatientId=" + this.searchdata.PatientId +
                    "&pageindex=" + this.searchdata.Pageindex +
                    "&pagesize=" + this.searchdata.Pagesize +
                    "&SortProperty=" + this.searchdata.SortProperty,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("search data", response.data);
                    if (response.data) {
                        console.log(response)
                        this.totalLists = parseInt(response.data.totalCount / this.searchdata.Pagesize);
                        if (response.data.totalCount % this.searchdata.Pagesize != 0) {
                            this.totalLists = this.totalLists + 1;
                          }
                        this.filteredListing = response.data.results;
                        console.log("The list is",this.filteredListing)
                    } else {
                        console.log("There are currently no list");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
          await this.filteredlistcounts();
        },
        async filteredlistcounts(){
          const currentstudy = store.getters.getStudyIs;
          const idtoken = store.getters.getIdToken;
          // console.log(JSON.stringify(this.filteredListing));
          const length = this.filteredListing.length;
           for(let i=0; i<length ; i++){
               console.log(JSON.stringify(this.filteredListing[i].formId));
               await axios
                   .get(`${this.baseUrl}formstatuscountlist?formID=${this.filteredListing[i].formId}`,
                       {
                           headers: {
                               Authorization: "Bearer " + idtoken,
                               "Content-Type": "application/json",

                           },
                       }
                   )
                   .then((res) => {
                       console.log(" get patients");
                       console.log(res.data);
                       this.filteredListing[i].incorrectCount = res.data.incorrectCount;
                       this.filteredListing[i].notvalidatedCount = res.data.notvalidatedCount;
                   })
                   .catch((err) => {
                       console.log(err);
                   });
               await axios
                    .get(`${this.baseapi}/forms/query/getquerycount?Studyid=${currentstudy}&FormId=${this.filteredListing[i].formId}&status=Open`,
                            {
                                headers: {
                                    Authorization: "Bearer " + idtoken,
                                    "Content-Type": "application/json",
                                },
                            }
                    )
                    .then((res) => {
                        console.log(" get Open Queries");
                        this.filteredListing[i].openQueryCount = res.data;
                    })
                    .catch((err) =>{
                        console.log(err);
                    })
           }
           ///forms/formstatusdetails
        },
        openModal: async function(status, formid) {
          const idtoken = store.getters.getIdToken;
          const currentstudy = store.getters.getStudyIs;
          await axios
              .get(                
                `${this.baseUrl}formfieldstatusdetails?StudyId=${currentstudy}&formID=${formid}&status=${status}`,
                  {
                      headers: {
                          Authorization: "Bearer " + idtoken,
                          "Content-Type": "application/json",

                      },
                  }
              )
              .then((res) => {
                  console.log(" get patients");
                  console.log(res.data);
                  this.fieldstatusdata = res.data;
              })
              .catch((err) => {
                  console.log(err);
              });
          const modalWrapper = document.querySelector('.modal__wrapper_one');
          modalWrapper.classList.add('active');
        },
        closeModal: function() {
          const modalWrapper = document.querySelector('.modal__wrapper_one');
          modalWrapper.classList.remove('active');
        },
        openQueryModal: async function(formid,status) {
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            
            await axios
                    .get(`${this.baseapi}/forms/query/getquerydetails?Studyid=${currentstudy}&FormId=${formid}&status=${status}`,
                                {
                                    headers: {
                                        Authorization: "Bearer " + idtoken,
                                        "Content-Type": "application/json",
                                    },
                                }
                    )
                    .then((res) => 
                    { 
                       
                       // this.queryDetails = res.data;
                        this.fieldstatusdata = res.data;
                        console.log(res);

                    })
                    .catch(err => {
                        console.log(err);
                    })
            const modalWrapperTwo = document.querySelector('.modal__wrapper_two');
            modalWrapperTwo.classList.add('active');
          },
        closeQueryModal: function() {
            const modalWrapperTwo = document.querySelector('.modal__wrapper_two');
            modalWrapperTwo.classList.remove('active');
          },
          
        async getpatients() {
            console.log("in get patient");
            this.searchdata.PatientId ="";
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            await axios
                .get(
                    `${this.baseapi}/management/patient/listpatient?SiteId=${this.searchdata.SiteCode}&Studyid=${currentstudy}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log("get patients", res);
                    this.patients = res.data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getsites() {
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            await axios
                .get(
                    `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&getAll=false`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    //console.log("sites", res);
                    this.sites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //filtering list
        filteredLists() {
            this.filteredListing = []
            if ((this.searchItem) || (this.selectedType) || (this.selectedId) || (this.statusCheck)) {
                this.lists.filter((list) => {
                    if ((this.searchItem == list.formName) || (this.selectedType == list.siteId) || (this.selectedId == list.subjectId) || (this.statusCheck == list.formStatus)) {
                        //push the filtered list to new array
                        this.filteredListing.push({
                            visitName: list.visitName,
                            siteId: list.siteId,
                            subjectId: list.subjectId,
                            formName: list.formName,
                            formStatus: list.formStatus
                        })
                        // console.log(this.filteredListing)
                        return (this.filteredListing)
                    }
                });

            }
            // else
            // {
            // //if no values are entered
            // alert('Enter a value to search')

        },
        async loadPage(page) {
            this.currentPageNumber = page;
            this.searchdata.Pageindex = page;
            this.searchData();
        },
    },

}

