/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
export default {
  props: { userid: String },
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/site/`,
      disableStatus: true,
      value: {
        accountId: "",
        studyId: "",
        siteCode: "",
        siteName: "",
        siteContactEmail: "",
        siteContactName: "",
        status: "",
        siteNotes: "",
        numberOfPatient: 0,
        active: true,
      },
      editValue: {
        siteID: "",
        accountId: "",
        studyId: "",
        siteCode: "",
        siteName: "",
        siteContactName: "",
        siteContactEmail: "",
        status: "",
        siteNotes: "",
        numberOfPatient: 0,
        active: true,
      },
      idToken: "",
      headerValues: {},
      errors: [false, false, false, false, false, false, false],

    };
  },
  async mounted() {
    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    this.errors[6] = false;
    if (this.userid != "0") {
      await this.getSite(this.userid);
      this.disableStatus = true;
      this.setValues();
    } else {
      console.log("will set disabled status to false");
      this.disableStatus = false;
    }

  },
  methods: {
    async clearEditValues() {
      this.editValue.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.editValue.studyId = store.getters.getStudyIs;
      this.editValue.siteCode = "";
      this.editValue.siteName = "";
      this.editValue.siteContactEmail = "";
      this.editValue.siteContactName = "";
      //this.editValue.status = "";
      this.editValue.siteNotes = "";
      this.editValue.numberOfPatient = 0;
      this.editValue.active = true;

      this.errors[6] = false;
      this.value.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.value.studyId = store.getters.getStudyIs;
      this.value.siteCode = "";
      this.value.siteName = "";
      this.value.siteContactEmail = "";
      this.value.siteContactName = "";
      //this.value.status = "";
      this.value.siteNotes = "";
      this.value.numberOfPatient = 0;
      this.value.active = true;
    },
    async clearValues() {
      this.errors[6] = false;
      this.value.accountId = "e75456d6-710d-4ad0-9eb7-2293623f889a";
      this.value.studyId = store.getters.getStudyIs;
      this.value.siteCode = "";
      this.value.siteName = "";
      this.value.siteContactEmail = "";
      this.value.siteContactName = "";
      this.value.status = "";
      this.value.siteNotes = "";
      this.value.numberOfPatient = 0;
      this.value.active = true;
    },
    ValidateAll() {
      //this.value.siteCode = this.value.siteCode.replace(/^\s*/, "");
      this.value.siteCode = this.value.siteCode.replace(/ +/g, ' ').trim();
      this.value.siteName = this.value.siteName.replace(/ +/g, ' ').trim();
      this.value.siteContactName = this.value.siteContactName.replace(/ +/g, ' ').trim();
      this.value.siteContactEmail = this.value.siteContactEmail.replace(/ +/g, ' ').trim();
      this.value.siteNotes = this.value.siteNotes.replace(/ +/g, ' ').trim();
      this.value.numberOfPatient = this.value.numberOfPatient.toString().replace(/\s/g, '')
      this.value.numberOfPatient = +this.value.numberOfPatient
      //Number of payients
      if (this.value.siteContactEmail) {
        if (this.value.siteContactEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
          this.errors[1] = false
        } else {
          this.errors[1] = true
        }
      }
      if (this.value.siteCode == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    setEditValues() {
      // this.editValue.siteID = "";
      this.editValue.accountId = this.value.accountId;
      this.editValue.studyId = this.value.studyId;
      this.editValue.siteCode = this.value.siteCode;
      this.editValue.siteName = this.value.siteName;
      this.editValue.siteContactEmail = this.value.siteContactEmail;
      this.editValue.siteContactName = this.value.siteContactName;
      this.editValue.status = this.value.status;
      this.editValue.siteNotes = this.value.siteNotes;
      this.editValue.numberOfPatient = this.value.numberOfPatient;
      this.editValue.active = this.value.active;
    },
    setValues() {
      this.value.accountId = this.editValue.accountId;
      this.value.studyId = this.editValue.studyId;
      this.value.siteCode = this.editValue.siteCode;
      this.value.siteName = this.editValue.siteName;
      this.value.siteContactEmail = this.editValue.siteContactEmail;
      this.value.siteContactName = this.editValue.siteContactName;
      this.value.status = this.editValue.status;
      this.value.siteNotes = this.editValue.siteNotes;
      this.value.numberOfPatient = this.editValue.numberOfPatient;
      this.value.active = this.editValue.active;
      console.log("Values Set");
      console.log(this.value);
    },
    async createSite() {
      this.value.status = "New";
      const valnow = store.getters.getStudyIs;
      this.value.studyId = store.getters.getStudyIs;
      if (valnow == "" | valnow == null) {
        alert(this.$t('navigations.please select a study'));
      }
      else {
        await axios
          //.put("https://localhost:5001/site/update", this.editValue, this.headerValues)
          .post(this.baseUrl + "create", this.value, this.headerValues)
          .then((res) => {
            console.log(res);
            alert(this.$t('navigations.site saved successfully'));
            this.$emit("closeModal")
          })
          .catch((err) => {
            if (err.response.data.detail == "Site code already exists.") {
              alert(this.$t('navigations.site code already exists'));
            }
          });
      }
    },
    async editSite() {
      console.log(this.editValue);

      await axios
        .put(this.baseUrl + "update", this.editValue, this.headerValues)
        // .put("https://localhost:5001/site/update", this.editValue, this.headerValues)
        .then((res) => {
          alert(this.$t('navigations.site updated successfully'));
        })
        .catch((err) => {
          if (err.response.data.detail == "Site code already exists.") {
            alert(this.$t('navigations.site code already exists'));
            this.$emit("closeModal")
          }
        });
    },
    async getSite(id) {
      await axios
        .get(this.baseUrl + "getbyid?id=" + id, this.headerValues)
        .then((res) => {
          this.editValue = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async onclosed() {
      await this.$router.push(`/sitelist`);
    },
    async save() {
      this.ValidateAll();
      if (
        !this.errors[0] &&
        !this.errors[1] &&
        !this.errors[2] &&
        !this.errors[3] &&
        !this.errors[4]
      ) {
        if (this.userid === "0") {
          await this.createSite()
        } else {
          this.setEditValues();
          await this.editSite()
            .then((res) => {
            })
            .catch((err) => {
              alert(this.$t('navigations.something went wrong.'));
            });
        }
      }
    },
    async closeModal() {
      this.$emit("closeModal")
    },
    async clear() {
      if (this.userid === "0") {
        this.clearValues();
      }
      else {
        this.clearEditValues();
      }
    },
    async numberonlyvalidation(fieldname) {
      //let isNumber = Number(this.values[fieldname])?true:false;
      let isNumber = true;
      this.value[fieldname] = this.value[fieldname].replace(/\s/g, '');
      let fieldval = this.value[fieldname];
      console.log("valtttttt is", fieldval);
      if (fieldval % 1 != 0) {
        isNumber = false;
      }
      console.log(this.value[fieldname]);
      console.log(isNumber);
      if (!isNumber) {
        this.value[fieldname] = 0;

      }
      else {
        if (parseInt(this.value[fieldname]) >= 0) {

        }
        else {
          this.value[fieldname] = 0;

        }

      }
    },
  },
};