/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from "axios";
import moment from "moment";
import store from "../../store/index";
export default {
  name: "templateview",
  props: {
        tempId:{
            type: String,
            default: ''
        },
        templateName:{
            type: String,
            default: ''
        }        
    },
    data() {
        return {
          idtoken: store.getters.getIdToken,
          baseurl:process.env.VUE_APP_Service_URL,
          templateId: ""
        }
    },
}