<template>
  <div class="mb-1 pr-0 pl-1">
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="mt-1">{{ $t("navigations.query activity") }}</h5>
      </div>
      <div class="ibox-content ">
        <div class="table-responsive fixed-height">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.query id") }}</th>
                <th>{{ $t("navigations.subject") }}</th>
                <th>{{ $t("navigations.raised by") }}</th>
                <th>{{ $t("navigations.raised date") }}</th>
                <th class="text-center">{{ $t("navigations.view") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="list in querylist" :key="list.formId">
                <td>{{ list.queryId }}</td>
                <td>{{ list.query_Subject }}</td>
                <td>
                  <span v-if="list.messages.length <= 1">
                    <span
                      v-for="(message, index) in list.messages"
                      :key="index"
                    >
                      {{ message.raisedByName }}
                    </span>
                  </span>
                  <span v-else>
                    {{ list.messages[0].raisedByName }}
                  </span>
                </td>
                <td>
                  <span v-if="list.messages.length <= 1">
                    <span
                      v-for="(message, index) in list.messages"
                      :key="index"
                    >
                      {{ filter(message.raisedDate) }}
                    </span>
                  </span>
                  <span v-else>
                    {{ filter(list.messages[0].raisedDate) }}
                  </span>
                </td>
                <td class="text-center">
                  <i
                    class="fa fa-eye editicon"
                    @click.prevent="viewquery(list.id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "@/store";
import moment from "moment";
export default {
  name: "queryactivitytable",
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      totalquerycount: "",
      sites: [],
      querylist: [],
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
        await this.sitedata();
		});
    await this.sitedata();
  },
  methods: {
    async viewquery(id) {
      this.$router.push(`/query/sitesummary/${id}`);
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async sitedata() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      this.sites = [];
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          console.log("The sites are", res.data);
          res.data.filter((item) => {
            this.sites.push(item);
          });
          console.log("Site is", this.sites);
          this.siteCode = this.sites[0].siteID;
          await this.querytabledata(this.siteCode);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async querytabledata(siteId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/forms/query/getlatestquerydetails?SiteID=${siteId}&Limit=15`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.querylist = res.data;
          console.log("The query details are", this.querylist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.dashlet{
    width:100%;
    height:100%;
  }
</style>
