<template>
    <div class="study_profile_modal__wrapper">
      <div class="study_profile_modal__container">
        <div class="modal__title d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">AI Forms</h4>
          <div class="cursor-pointer" @click.prevent="closeModal">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="ibox-content p-3">
          <div class="form-group row">
            <div class="col-12 mb-1">
              <label class="col-form-label"
                >Description<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <textarea row ="4"
              v-model="values.description"
              minlength="15" type="text" class="form-control"/>
              <span class="errormsg ml-0">Please enter  your description</span>
            </div>
            <div class="col-12 mb-1">
              <label class="col-form-label"
                >Threshold<sup><i class="fa fa-asterisk imp"></i></sup
              ></label>
              <input
              v-model="values.threshold"
              type="text" class="form-control"/>
            </div>
            <div class="col-12 text-right mt-2">
              <button class="save_btn py-1 px-3" @click.prevent="formGeneration()">Generate</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script src="./Forms_AI_PopUp.js"></script>
  <style scoped>
  @import "../../assets/css/style.css";
  .study_profile_modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
  }
  
  .study_profile_modal__container {
    position: fixed;
    width: min(30%, 90%);
    border-radius: 4px;
    background: #fff;
    overflow-x: hidden;
  }
  
  .modal__title {
    background-color: #618bd7;
    color: #fff;
  }
  .save_btn {
    text-align: center;
    background-color: #145faf !important;
    border-color: #145faf !important;
    color: white !important;
    padding: 0.43rem 0.4rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out !important;
  }
  
  .save_btn:hover,
  .save_btn:focus:focus-visible {
    background-color: #145faf !important;
    border-color: #145faf !important;
  }
  </style>
  