/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "visitList",
  components: {
    VPagination,
  },
  data() {
    return {
      roleprivileges: "",
      selectedvisitid: "",
      selectedmodeltemplate: "",
      templatelist: [],
      baseapi: process.env.VUE_APP_Service_URL,
      studyid: "",
      currentPageSize: 0,
      currentPageNumber: 0,
      visitList: [],
      totalItems: 0,
      totalCount: 0,
      totalPages: 0,
      searchData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      requestData: {
        pagesize: 10,
        pageindex: 0,
        visitNo: "",
        FormName: "",
        SortProperty: "",
        SortOrder: 0,
      },
      pageNumbers: [],
      visitides: [],
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.setstudy();
      console.log("from mount")
      this.currentPageSize = 10;
      this.currentPageNumber = 1;
      this.requestData.pagesize = 10;
      this.requestData.pageindex = 1;
      this.searchAndGetData();
      this.gettemplatedata();
    });
    await this.setroleprivileges();
    await this.setstudy();
    this.currentPageSize = 10;
    this.currentPageNumber = 1;
    this.requestData.pagesize = 10;
    this.requestData.pageindex = 1;
    console.log("pas data", this.requestData)
    this.searchAndGetData();
    await this.gettemplatedata();

  },
  // computed: {
  //   sortedArray() {
  //     function compare(a, b) {
  //       if (a.visitNo < b.visitNo)
  //         return -1;
  //       else if (a.visitNo > b.visitNo)
  //         return 1;
  //       else
  //         return 0;
  //     }
  //     return this.visitList.sort(compare);
  //   }
  // },
  methods: {

    SortSelected(sortProp,id){
      console.log('SortPropChanged',sortProp);
      console.log(this.requestData.SortOrder)
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id
      if(sortProp!=null && id==selectedElementID)
      {
        if(this.requestData.SortOrder == 0)
        {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
           if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      this.requestData.SortProperty=sortProp;
      this.requestData.SortOrder = this.requestData.SortOrder==0?1:0;
      this.searchAndGetData();
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async assignFun() {
      if (this.selectedmodeltemplate == "") {
        alert(this.$t('navigations.please select a template'))
      }
      else {
        const idtoken = store.getters.getIdToken;
        await axios
          .put(`${this.baseapi}/management/visit/assigntemplate`,
            {
              "createdUserEmail": "",
              "createdUserId": "",
              "createdAt": new Date(),
              "lastModifiedUserEmail": "",
              "lastModifiedUserId": "",
              "lastModifiedAt": new Date(),
              "visitID": this.selectedvisitid,
              "templateID": this.selectedmodeltemplate.templateId,
              "version": this.selectedmodeltemplate.version
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert(this.$t('navigations.template assigned successfully'));
            this.selectedmodeltemplate = ""
            console.log(res.data);
          })
          .catch((err) => {
            console.log("error");
            console.log(err);
          });
        this.closeModal();
        this.search();
      }
    },



    async gettemplatedata() {
      const idtoken = store.getters.getIdToken;
      console.log("template data function");
      await axios                          
        .get(`${this.baseapi}/forms/template/listtemplate?Status=Approved&StudyID=${this.studyid}&SortProperty=templateName&SortOrder=0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("template data is");
          console.log(res.data);
          this.templatelist = res.data.results;
        })
        .catch((err) => {
          console.log("error");
          console.log(err);
        });
    },
    async viewform(id) {
      this.$router.push(`/viewTemplate/visitlist/${id}`);
    },
    openModal(visitid) {
      this.selectedvisitid = visitid;
      const modalWrapper = document.querySelector('.modal-wrapper');
      modalWrapper.classList.add('active');
    },
    closeModal() {
      const modalWrapper = document.querySelector('.modal-wrapper');
      modalWrapper.classList.remove('active');
    },
    async setstudy() {
      this.studyid = store.getters.getStudyIs;
      await store.dispatch("setVisitStudyID", this.studyid);
    },
    async addvisit(id) {
      this.$router.push(`/addvisit/${id}`);
    },
    async search() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      console.log(this.requestData);
      this.currentPageNumber = 1;
      this.requestData.pageindex = 1;
      await this.searchAndGetData();
    },
    //function used for search 
    async searchbtn(){
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      this.requestData.pageindex = 1;
      this.currentPageNumber = 1;
      
      const pIndex = Number(this.requestData.pageindex);
      const pSize = Number(this.requestData.pagesize);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?VisitName=${this.requestData.FormName}&VisitNo=${this.requestData.visitNo}&StudyId=${this.studyid}&pageindex=${pIndex}&pagesize=${pSize}&SortProperty=${this.requestData.SortProperty}&SortOrder=${this.requestData.SortOrder}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data);
          if (res.data.data === "No Visit Found") {
            alert("No Visits Were Found");
            this.visitList = [];
          } else {
            this.visitList = res.data.results;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount)
            this.totalPages = Math.ceil(res.data.totalCount / 10);
            // if ((this.totalCount % pSize) != 0) {
            //   this.totalPages = this.totalPages + 1
            // }
            console.log("this.totalPages = "+this.totalPages);
          }

          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async deleted(visitId) {
      this.visitides.push(visitId);
      const idtoken = store.getters.getIdToken;
      console.log("visitId", visitId);
      console.log("Enter mounted");
      console.log("itsVisditId", this.visitides);
      if (confirm("Do you really want to delete this visit?")) {
        await axios
          .delete(
            `${this.baseapi}/management/visit`, { data: this.visitides },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            this.userList = res.data;
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.visitides = [];
      this.searchAndGetData();
    },
    async onPageClick(event) {
      this.requestData.pageindex = event;
      this.searchAndGetData();
    },
    //function to retrieve data by pagination click
    async searchAndGetData() {
      this.requestData.visitNo = this.searchData.visitNo;
      this.requestData.FormName = this.searchData.FormName;
      const pIndex = Number(this.requestData.pageindex);
      const pSize = Number(this.requestData.pagesize);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?VisitName=${this.requestData.FormName}&VisitNo=${this.requestData.visitNo}&StudyId=${this.studyid}&pageindex=${pIndex}&pagesize=${pSize}&SortProperty=${this.requestData.SortProperty}&SortOrder=${this.requestData.SortOrder}`,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("respp", res.data);
          if (res.data.data === "No Visit Found") {
            alert("No Visits Were Found");
            this.visitList = [];
          } else {
            this.visitList = res.data.results;
            this.totalItems = res.data.visitCont;
            this.totalCount = res.data.totalCount;
            console.log("total count is", this.totalCount)
            this.totalPages = Math.ceil(res.data.totalCount / 10);
            // if ((this.totalCount % pSize) != 0) {
            //   this.totalPages = this.totalPages + 1
            // }
            console.log("this.totalPages = "+this.totalPages);
          }

          this.totalItems = res.data.userCont;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadPage: function (page) {
      console.log("pAGE.....", page)
      this.currentPageNumber = page;
      this.requestData.pageindex = page;
      this.searchAndGetData();

    }
  }
}

