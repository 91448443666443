<template>
  <div ref="barChart" class="chart-container"></div>
</template>

<script>
import * as d3 from 'd3';

export default {
  name: 'HorizontalBarChart',
  props: {
    data: Object
  },
  watch: {
    data: {
      handler() {
        this.createBarChart();
      },
      immediate: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.createBarChart);
    this.createBarChart();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.createBarChart);
  },
  methods: {
    createBarChart() {
      if (!this.data || !this.data["exp(coef)"]) {
        console.log('No data available for chart');
        return;
      }

      const container = this.$refs.barChart;
      d3.select(container).selectAll("*").remove();

      const plotData = Object.keys(this.data["exp(coef)"]).map(key => ({
        name: key,
        log2_p: this.data["exp(coef)"][key]
      }));

      const margin = { top: 20, right: 20, bottom: 30, left: 200 };
      const width = container.clientWidth - margin.left - margin.right;
      const height = plotData.length * 40;

      const x = d3.scaleLinear().range([0, width]);
      const y = d3.scaleBand().range([0, height]).padding(0.1);

      const colors = d3.scaleOrdinal(d3.schemeCategory10);

      const svg = d3.select(container).append("svg")
        .attr("viewBox", `0 0 ${width + margin.left + margin.right+100} ${height + margin.top + margin.bottom+100}`)
        .attr("preserveAspectRatio", "xMidYMid meet")
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      // Define domains with some padding
      x.domain([0, d3.max(plotData, d => d.log2_p) * 1.1]);
      y.domain(plotData.map(d => d.name));

      // Add x-axis
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(x));

      // Add y-axis
      svg.append("g")
        .attr("class", "y axis")
        .call(d3.axisLeft(y));
      // Add x-axis label
          svg.append("text")
        .attr("class", "x-axis-label")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom + 10) // Position below the x-axis
        .attr("text-anchor", "middle")
        .text("Hazard ratio(exp(coef))");
        svg.append("line")
        .attr("class", "zero-line")
        .attr("x1", x(1))
        .attr("x2", x(1))
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "black")
        .attr("stroke-dasharray", "4,4");
      // Add bars
      svg.selectAll(".bar")
        .data(plotData)
        .enter().append("rect")
        .attr("class", "bar")
        .attr("x", 0)
        .attr("y", d => y(d.name))
        .attr("width", d => x(d.log2_p))
        .attr("height", y.bandwidth())
        .attr("fill", (d, i) => colors(i));

      // Add labels
      svg.selectAll(".label")
        .data(plotData)
        .enter().append("text")
        .attr("class", "label")
        .attr("x", d => x(d.log2_p) + 5)
        .attr("y", d => y(d.name) + y.bandwidth() / 2 + 5)
        .attr("dy", ".35em") // adjust to vertically center the text
        .text(d => d.log2_p.toFixed(2));
    }
  }
};
</script>

<style>
.chart-container {
  width: 100%;
  height: auto;
}

.bar {
  fill-opacity: 0.8;
}

.label {
  font-size: 12px;
  fill: black;
}

.axis path,
.axis line {
  fill: none;
  stroke: #000;
  shape-rendering: crispEdges;
}
</style>
