<template>
    <div>Pivot</div>
</template>

<script>
/* eslint-disable */
import store from "../../store/index";
import axios from 'axios';
import WebDataRocks from "webdatarocks";
import "webdatarocks/webdatarocks.toolbar.min.js";
import "webdatarocks/webdatarocks.js";
export default {
  name: "visitReportGrid",
  props: {
    afterchartdraw: Function,
    aftergriddraw: Function,
    beforegriddraw: Function,
    beforetoolbarcreated: Function,
    cellclick: Function,
    celldoubleclick: Function,
    componentFolder: String,
    customizeCell: Function,
    customizeContextMenu: Function,
    datachanged: Function,
    dataerror: Function,
    datafilecancelled: Function,
    dataloaded: Function,
    fieldslistclose: Function,
    fieldslistopen: Function,
    filterclose: Function,
    filteropen: Function,
    fullscreen: Function,
    global: Object,
    height: [String, Number],
    loadingdata: Function,
    loadinglocalization: Function,
    loadingolapstructure: Function,
    loadingreportfile: Function,
    localizationerror: Function,
    localizationloaded: Function,
    olapstructureerror: Function,
    olapstructureloaded: Function,
    openingreportfile: Function,
    querycomplete: Function,
    queryerror: Function,
    ready: Function,
    report: [String, Object],
    reportchange: Function,
    reportcomplete: Function,
    reportfilecancelled: Function,
    reportfileerror: Function,
    reportfileloaded: Function,
    runningquery: Function,
    toolbar: Boolean,
    update: Function,
    width: [Number, String]
  },
      data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      reportdata: []
    }
      },
       beforeMount() {
         this.reportfun();
       },
  mounted: function() {
    window.addEventListener("studyIdChanged", (event) => {
      this.reportfun();
    });
    this.webdatarocks = new WebDataRocks({
      ...this.$props,
      container: this.$el,
      beforetoolbarcreated: this.customizeToolbar,
      report: {}
    });
  },
  beforeUpdate() {
    return false;
  },
  methods: {
    async reportfun(){
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
          //.get(`https://localhost:5001/visit/getvisitreport?StudyId=${currentstudy}`, 
          .get(`${this.baseapi}/report/visit/getvisitreport?StudyId=${currentstudy}`,
              {
                  headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",

                  },
              }
          )
          .then((res) => {
              this.reportdata = res.data;
              // this.webdatarocks.updateData({data:res.data
              // });
              let setrept = {
        dataSource: {
        data: res.data
        },
        "options": {
        "grid": {
            "type": "flat",
            "showGrandTotals": "off"
        }
    },
        "slice": {
                    "rows": [
            {
                "uniqueName": "visitFormName"
            },
            {
                "uniqueName": "visitNo"
            },
            {
                "uniqueName": "visitType"
            },
             {
                "uniqueName": "visitDayPeriod"
            },
             {
                "uniqueName": "visitWeekPeriod"
            },
            {
                "uniqueName": "visitMonthPeriod"
            },
            {
                "uniqueName": "visitYearPeriod"
            },
            {
                "uniqueName": "preVisit"
            },
            {
                "uniqueName": "postVisit"
            },
            {
                "uniqueName": "active"
            }
        ],
        "columns": [
             {
                "uniqueName": this.$t('navigations.visit form name')
            },
            {
                "uniqueName": "Visit No."
            },
            {
                "uniqueName": "Visit Type"
            },
            {
                "uniqueName": "Visit Period (Days)"
            },
             {
                "uniqueName": "Visit Period (Weeks)"
            },
            {
                "uniqueName": "Visit Period (Months)"
            },
            {
                "uniqueName": "Visit Period (Years)"
            },
             {
                "uniqueName": "Pre Visit"
            },
            {
                "uniqueName": "Post Visit"
            },
            {
                "uniqueName": "Active"
            }
        ],
        "measures": [
            {
                "uniqueName": this.$t('navigations.visit form name')
            },
            {
                "uniqueName": "Visit No."
            },
            {
                "uniqueName": "Visit Type"
            },
            {
                "uniqueName": "Visit Period (Days)"
            },
             {
                "uniqueName": "Visit Period (Weeks)"
            },
            {
                "uniqueName": "Visit Period (Months)"
            },
            {
                "uniqueName": "Visit Period (Years)"
            },
             {
                "uniqueName": "Pre Visit"
            },
            {
                "uniqueName": "Post Visit"
            },
            {
                "uniqueName": "Active"
            }
        ]


    },
      }
      this.webdatarocks.setReport(setrept);
          });
    },
    customizeToolbar(toolbar) {
        var tabs = toolbar.getTabs(); // get all tabs from the toolbar
        toolbar.getTabs = function () {
            delete tabs[0];
            delete tabs[1]; // delete the second tab
            return tabs;
        }

    }
  }
};
</script>
<style scoped>
@import '~webdatarocks/webdatarocks.min.css';
</style>