/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
<template>
  <div class="form-background">
    <div class="row d-flex justify-content-center align-items-center ">
      <div
        class="middle-box text-center loginscreen animated fadeInDown px-2 py-4"
      >
        <div>
          <div>
            <img src="../../assets/images/logo.png" alt="Form header image" />
          </div>
          <form class="m-t">
            <div class="form-group">
              <input
                type="email"
                class="form-control"
                v-model="patientemail"
                placeholder="Username"
                required=""
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                v-model="patientpassword"
                placeholder="Password"
                required=""
              />
            </div>
           
            <a
              href="forgot_password.html"
              class="d-flex justify-content-end mb-4"
              >Forgot password?</a
            >
            <button
              type="submit"
              class="btn btn-primary block full-width m-b btn-colour"
              @click.prevent="Loginclicked"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import store from "../../store/index";
export default {
  name: "PatientLoginScreen",
data() {
    return {
      patientemail:"",
      patientpassword:"", 
      result:"",    
      baseapi:`${process.env.VUE_APP_Service_URL}/management/patientlogin/`, 
    };
},
 async mounted() {

    this.idToken = store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
 },
 methods: {
 async Loginclicked(){
   console.log("in funccccc")
   await axios
   .get(`${this.baseapi}verifypatientlogin?patientemail=${this.patientemail}&patientpassword=${this.patientpassword}`)
     .then((res) => {
          console.log(res);
          this.result = res.data;
          console.log("resulttttt",this.result);
          if(this.result==true)
          {
            alert("Patient login success")
            this.$router.push(`/PatientDashboard`);
          }
          else{
          alert ("Login failed, please check the credentials!!")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
 }

}



</script>

<style>
@import "../../assets/css/style.css";
@media (max-width: 768px)
{
  .loginscreen.middle-box{
    width:clamp(200px,calc(100vw - 100px),400px);
    }
}

.errmsg{
font-size: 14px;
color: red;
float: left;
}
.social-icons {
  width: 16px;
  height: 16px;
}
.social-icons {
  width: 16px;
  height: 16px;
}
img {
  vertical-align: middle;
  border-style: none;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-colour {
  background-color: #145faf !important;
  border-color: #145faf !important;
  transition:background-color ease-in-out 500ms;
}
.btn-colour:hover {
  background-color: #2d91fb !important;
  border-color: #2d91fb !important;
}
.btn-white {
  background-color: white !important;
  margin-bottom: 10px !important;
}
.form-group {
  margin-bottom: 1rem;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
}
.form-background {
    margin-left:-15px;
}
</style>
