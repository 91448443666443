<template>
  <div class="wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Patient Dashboard</h2>
    </div>
  </div>
  </template>
  
  <script>
/* eslint-disable */

import store from "../../store/index";
export default {
     name: "PatientDashboard",
  async mounted() {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    await this.Disablemenu();
  },
  async unmounted(){
    await this.Enablemenu();
  },
  data() {
    return {

    };
  },
  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper")
      navbardefault.style.width="100%"
      navbardefault.style.paddingRight="0px"
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
  },
};
  </script>

<style>

@import "../../assets/style.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.cancel_btn {
  background: var(--secondary-clr) !important;
  color: #ffffff;
}
.cancel_btn:hover {
  color: #fff;
  background-color: #22045a !important;
  border-color: #22045a !important;
}
.cancel_btn:active {
  color: #fff;
  background-color: #532eda !important;
  border-color: #532eda !important;
}
.btn-white {
  background: white;
  border: 1px solid #e7eaec;
}
.btn {
  border-radius: 3px;
  font-size: inherit;
}
.save_btn {
  background-color: #145faf;
  color: white;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.impcl {
  color: #ff4747;
  font-size: 25px;
  float: right;
}
.impcl:hover {
  color: #a30202;
  font-size: 25px;
  float: right;
}
.save_btn:hover {
  color: #fff;
  background-color: #2d91fb;
  border-color: #2d91fb;
}
.save_btn:active {
  color: #fff;
  background-color: #618bd7;
  border-color: #618bd7;
}
.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}
</style>