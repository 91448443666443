<template>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
      <div
        class="ibox-title style_2 d-flex justify-content-between align-items-center"
      >
        <!-- <h5>{{ $t("navigations.ai list") }}</h5> -->
        <h5>Field Data</h5>
        <div >
          <button
            type="button"
            class="btn btn-primary py-1"
            @click="saveChanges()"
            v-if="rollsprivilages.includes('Patient Screening Train Model')"
          >
            {{ $t("navigations.save changes") }}
          </button>
        </div>
      </div>
      <div class="ibox-content">
        <div v-if="tableloadingBlock">
          <div v-if="errorBlock == true">
            <h5 class="text-center">Current no fields</h5>
          </div>
          <div v-else class="text-center">
            <img
              src="../../assets/img/loading.webp"
              class="table-load"
              width="30"
              alt="loading"
          /></div>
        </div>
        <div v-else class="table-responsive" :class="{'max-height-400' : fieldsArray.length>7}">
          <table class="table table-striped table-bordered templatefield-details-table">
            <thead>
              <th>
                {{ $t("navigations.label") }}
              </th>
              <th>
                {{ $t("navigations.field name") }}
              </th>
              <th class="text-center">
                {{ $t("navigations.feature field") }}
              </th>
              <th class="text-center">
                {{ $t("navigations.inclusive") }}
              </th>
              <th class="text-center">
                {{ $t("navigations.exclusive") }}
              </th>
              <th>
                {{ $t("navigations.consideration rate(%)") }}
              </th>
              <th class="text-center">
                {{ $t("navigations.edit") }}
              </th>
            </thead>
            <tbody>
              <template
                v-for="(item, index) in templateFieldDetails"
                :key="index"
              >
                <tr v-for="field in item.field" :key="field.fieldName">
                  <td>{{ field.label }}</td>
                  <td>{{ field.fieldName }}</td>
                  <td class="text-center non-clickable">
                    <input
                      class="mt-1"
                      type="checkbox"
                      :name="`checkbox_${field.id}`"
                      :id="`checkbox_${field.id}`"
                      :checked="
                        field.templateFieldProperties
                          ? field.templateFieldProperties.featureField
                          : false
                      "
                    />
                  </td>
                  <td class="text-center non-clickable">
                    <input
                      class="mt-1"
                      type="checkbox"
                      name=""
                      :checked="
                        field.templateFieldProperties
                          ? field.templateFieldProperties.isInclusive
                          : false
                      "
                    />
                  </td>
                  <td class="text-center non-clickable">
                    <input
                      class="mt-1"
                      type="checkbox"
                      name=""
                      :checked="
                        field.templateFieldProperties
                          ? field.templateFieldProperties.isExclusive
                          : false
                      "
                    />
                  </td>
                  <td v-if="field.templateFieldProperties != null">
                    <div v-if="field.validation.includes('Number')">
                      <div
                        v-for="(fieldDetails, index) in field
                          .templateFieldProperties.rangesToConsider"
                        :key="index"
                      >
                        {{ fieldDetails.min }} to {{ fieldDetails.max }} :
                        {{ fieldDetails.considerationRate }}%<br />
                      </div>
                    </div>
                    <div
                      v-else-if="
                        field.type == 'dropdown' || field.type == 'radio'
                      "
                    >
                      <div
                        v-for="(fieldDetails, index) in field
                          .templateFieldProperties.fieldsToConsider"
                        :key="index"
                      >
                        <span
                          v-for="(
                            fieldsitem, fieldsindex
                          ) in fieldDetails.considerableFields"
                          :key="fieldsindex"
                        >
                          {{ fieldsitem
                          }}<template
                            v-if="
                              fieldsindex !=
                              fieldDetails.considerableFields.length - 1
                            "
                            >,</template
                          > </span
                        ><template
                          v-if="fieldDetails.considerableFields.length != 0"
                          >: {{ fieldDetails.considerationRate }}%
                        </template>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="(fieldDetails, index) in field
                          .templateFieldProperties.fieldsToConsider"
                        :key="index"
                      >
                        {{ fieldDetails.considerableFields[0]
                        }}<template
                          v-if="fieldDetails.considerableFields.length != 0"
                          >: {{ fieldDetails.considerationRate }}%
                        </template>
                        <br />
                      </div>
                    </div>
                  </td>
                  <td v-else>{{}}</td>

                  <td class="text-center" v-if="rollsprivilages.includes('Patient Screening Set Field Consideration')">
                    <img
                      class="mb-0 cursor-pointer"
                      src="../../assets/img/ai_field_edit.png"
                      alt="Edit field"
                      width="15"
                      @click.prevent="openModal(field, item.pageNo)"
                    />
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <teleport to="#app">
    <div class="modal__wrapper__component" v-if="aitoolModal">
      <div class="component__modal__containers">
        <div
          class="titleclass d-flex justify-content-between align-items-center px-3 py-2"
        >
          <h4 class="my-0">Select Values</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="closemodal()"></i>
          </a>
        </div>
        <!-- textbox starts -->
        <div v-if="selectedmenu == 'textbox' || selectedmenu == 'text'">
          <div v-if="selectedFieldValidation == ''" class="fieldentry">
            <div class="ibox-modal-title">
              <h5>{{ selectedMenuheader }}</h5>
            </div>
            <div
              class="ibox-content-edit"
              :class="{
                'height-400':
                  templateFieldProperties.fieldsToConsider.length > 2 ||
                  fieldsConsider.length > 2,
              }"
            >
              <div class="form-group">
                <div class="align-content-center">
                  <input
                    class="mr-1"
                    type="checkbox"
                    id="flexCheckDefault"
                    value=""
                    v-model="featureValue"
                  />
                  <label for="flexCheckDefault">Feature Field&nbsp;?</label>
                </div>

                <div
                  v-for="(
                    item, index
                  ) in templateFieldProperties.fieldsToConsider"
                  :key="index"
                  class="repeatstring"
                >
                  <div class="row mb-2">
                    <div class="col-8">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Text Field"
                        v-model="item.considerableFields[0]"
                        :disabled="!featureValue"
                      />
                    </div>
                    <div class="col-4">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="%"
                        aria-label="Consideration rate"
                        v-model="item.considerationRate"
                        @input="validateNumberInput($event,index,'text')"
                        :disabled="!featureValue"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right mb-2">
                <button
                  class="btn btn-outline-secondary mr-2"
                  type="button"
                  @click="addItem('text')"
                >
                  +
                </button>
                <button
                  class="btn btn-outline-secondary mr-1"
                  type="button"
                  :disabled="
                    templateFieldProperties.fieldsToConsider.length == 0
                  "
                  @click="
                    deleteitem(
                      templateFieldProperties.fieldsToConsider.length - 1,
                      'text'
                    )
                  "
                >
                  -
                </button>
                <button
                  type="button"
                  class="btn btn-primary align-items-center"
                  @click="savepredictions('text')"
                  :disabled="loadingBlock"
                >
                  <span v-if="loadingBlock"
                    ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img"
                      alt="Waiting for login"
                  /></span>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
          <!-- number starts -->
          <div
            v-else-if="selectedFieldValidation == 'Number'"
            class="fieldentry"
          >
            <div class="ibox-modal-title">
              <h5>{{ selectedMenuheader }}</h5>
            </div>
            <div
              class="ibox-content-edit"
              :class="{
                'height-400':
                  templateFieldProperties.rangesToConsider.length > 2 ||
                  fieldsConsider.length > 2,
              }"
            >
              <div class="form-group">
                <b>Possible Ranges</b>
                <div class="row">
                  <div class="col-12">
                    <div class="row mb-2">
                      <div class="col-6">
                        <label class="mb-0">Min<span class="error-message px-1" v-if="featureValue">*</span></label>
                        <input
                          v-model="MinNumValue"
                          type="number"
                          class="form-control"
                          placeholder="Minimum value"
                          :disabled="!featureValue"
                          @input="validatePossibleInput($event)"
                        />
                        <div class="error-message" v-if="scaleError[5] && featureValue">{{$t("navigations.please enter a value")}} </div>
                      </div>
                      <div class="col-6">
                        <label class="mb-0">Max<span class="error-message px-1" v-if="featureValue">*</span></label>
                        <input
                          v-model="MaxNumValue"
                          type="number"
                          class="form-control"
                          placeholder="Maximum value"
                          :disabled="!featureValue"
                          @input="validatePossibleInput($event)"
                        />
                        <div class="error-message" v-if="scaleError[6] && featureValue">{{$t("navigations.please enter a value")}} </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="align-content-center">
                  <input
                    type="checkbox"
                    class="mr-1"
                    value=""
                    id="flexCheckDefault"
                    v-model="featureValue"
                  /><label for="flexCheckDefault">Feature Field? </label>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div
                      v-for="(
                        item, index
                      ) in templateFieldProperties.rangesToConsider"
                      :key="index"
                      class="row mb-2"
                    >
                      <div class="col-4">
                        <label class="mb-0">Min Value</label>
                        <input
                          v-model="item.min"
                          type="number"
                          class="form-control"
                          :disabled="!featureValue"
                          placeholder="Minimum value"
                        />
                        <span class="error-message" v-if="scaleError[2]"
                          >Only accepts digits and '-' (minus sign).</span
                        >
                      </div>
                      <div class="col-4">
                        <label class="mb-0">Max Value</label>
                        <input
                          v-model="item.max"
                          type="number"
                          class="form-control"
                          :disabled="!featureValue"
                          placeholder="Maximum value"
                        />
                        <span class="error-message" v-if="scaleError[3]"
                          >Only accepts digits and '-' (minus sign).</span
                        >
                      </div>
                      <div class="col-4">
                        <label class="mb-0">Consideration Rate(%)</label>
                        <input
                          v-model="item.considerationRate"
                          type="number"
                          class="form-control"
                          :disabled="!featureValue"
                          placeholder="Consideration value"
                          :id="`consideration_${getTemplateId}_${index}`"
                          @input="validateNumberInput($event,index,'number')"
                        />
                        <span class="error-message" v-if="scaleError[4]"
                          >Only accepts digits and '-' (minus sign).</span
                        >
                      </div>
                    </div>

                    <div>
                      <span class="error-message" v-if="scaleError[0]"
                        >Values entered on min value and max value fields cannot
                        be same.</span
                      >
                      <span class="error-message" v-if="scaleError[1]"
                        >Value entered on min value must be lower than value
                        entered on max value field.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="text-right mb-2">
                  <button
                    class="btn btn-outline-secondary mr-1"
                    type="button"
                    @click="addItem('range')"
                  >
                    +
                  </button>
                  <button
                    class="btn btn-outline-secondary mr-1"
                    type="button"
                    :disabled="
                      templateFieldProperties.rangesToConsider.length == 1
                    "
                    @click="
                      deleteitem(
                        templateFieldProperties.rangesToConsider.length - 1,
                        'range'
                      )
                    "
                  >
                    -
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="savepredictions('number')"
                    :disabled="loadingBlock"
                  >
                    <span v-if="loadingBlock"
                      ><img
                        src="../../assets/img/loading.webp"
                        width="20"
                        class="loading-img"
                        alt="Waiting for login"
                    /></span>
                    <span v-else>Save</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- number ends  -->
        </div>

        <!-- textbox ends -->
        <!-- dropdown starts -->
        <div v-else-if="selectedmenu == 'dropdown'" class="fieldentry">
          <div class="ibox-modal-title">
            <h5>{{ selectedMenuheader }}</h5>
          </div>
          <div
            class="ibox-content-edit"
            :class="{
              'height-400': fieldsConsider.length > 2,
            }"
          >
            <div class="form-group">
              <div class="align-content-center">
                <input
                  type="checkbox"
                  class="mr-1"
                  value=""
                  id="flexCheckDefault"
                  v-model="featureValue"
                /><label for="flexCheckDefault">Feature Field? </label>
              </div>
              <div class="row mb-2">
                <div v-for="(item, index) in fieldsConsider" :key="index">
                  <div class="row mx-0">
                    <div class="col-md-8 py-1 pl-0">
                      <label class="col-form-label">Fields to consider</label>
                      <Multiselect
                        v-model="item.considerableFields"
                        mode="multiple"
                        valueProp="optionname"
                        placeholder="Select a value"
                        label="optionname"
                        :options="getFilteredOptions(index)"
                        :hideSelected="false"
                        :disabled="!featureValue"
                        :closeOnSelect="false"
                        class="multiselect-theme ml-1 mr-0"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-md-4 py-1 pr-0">
                      <label class="col-form-label"
                        >Consideration Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.considerationRate"
                        @input="validateNumberInput($event,index,'dropdown')"
                        placeholder="%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mb-2">
                <button
                  class="btn btn-outline-secondary mr-2"
                  type="button"
                  @click="addItem('dropdown')"
                >
                  +
                </button>
                <button
                  class="btn btn-outline-secondary mr-1"
                  type="button"
                  :disabled="fieldsConsider.length == 1"
                  @click="deleteitem(fieldsConsider.length - 1, 'dropdown')"
                >
                  -
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="savepredictions('dropdown')"
                  :disabled="loadingBlock"
                >
                  <span v-if="loadingBlock"
                    ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img"
                      alt="Waiting for login"
                  /></span>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- dropdown ends dropdown -->

        <!-- radio starts  -->
        <div v-else-if="selectedmenu == 'radio'" class="fieldentry">
          <div class="ibox-modal-title">
            <h5>{{ selectedMenuheader }}</h5>
          </div>
          <div
            class="ibox-content-edit"
            :class="{
              'height-400':
                templateFieldProperties.fieldsToConsider.length > 2 ||
                fieldsConsider.length > 2,
            }"
          >
            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input mr-1"
                  v-model="featureValue"
                  id="checkboxtext"
                />
                <label for="checkboxtext">Is Feature field&nbsp;?</label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="inclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="inclusiveRadio"
                    :disabled="!featureValue"
                  />
                  <label for="inclusiveRadio">Inclusive Criteria</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="exclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="exclusiveRadio"
                    value="exclusive"
                    :disabled="!featureValue"
                  />
                  <label for="exclusiveRadio">Exclusive Criteria</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 py-1">
                <label class="col-form-label"
                  >Select Inclusve Criteria Values</label
                >
                <Multiselect
                  v-model="selectedDropdown"
                  mode="multiple"
                  :valueProp="'optionname'"
                  placeholder="Select a value"
                  :label="'optionname'"
                  :disabled="
                    featureValue == false ||
                    (exclusiveLabel && inclusiveLabel == false) ||
                    (!exclusiveLabel && !inclusiveLabel)
                  "
                  :options="filteredOptions1"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-theme ml-1 mr-0"
                >
                </Multiselect>
              </div>
              <div class="col-md-12 py-1">
                <label class="col-form-label"
                  >Select Exclusive Criteria Values</label
                >
                <Multiselect
                  v-model="selectedDropdown2"
                  mode="multiple"
                  :disabled="
                    featureValue == false ||
                    (inclusiveLabel && exclusiveLabel == false) ||
                    (!exclusiveLabel && !inclusiveLabel)
                  "
                  :valueProp="'optionname'"
                  placeholder="Select a value"
                  :label="'optionname'"
                  :options="filteredOptions2"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-theme ml-1 mr-0"
                >
                </Multiselect>
              </div>
              <div class="row mx-0 px-0">
                <div v-for="(item, index) in fieldsConsider" :key="index">
                  <div class="row mx-0">
                    <div class="col-md-8 py-1 pl-0">
                      <label class="col-form-label">Values to Consider</label>
                      <Multiselect
                        v-model="item.considerableFields"
                        mode="multiple"
                        valueProp="optionname"
                        :disabled="
                          (inclusiveLabel && exclusiveLabel) ||
                          !featureValue ||
                          inclusiveLabel ||
                          exclusiveLabel
                        "
                        placeholder="Select a value"
                        label="optionname"
                        :options="getFilteredOptions(index)"
                        :hideSelected="false"
                        :closeOnSelect="false"
                        class="multiselect-theme ml-1 mr-0"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-md-4 py-1 pr-0">
                      <label class="col-form-label"
                        >Consideration Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.considerationRate"
                        @input="validateNumberInput($event,index,'radio')"
                        :disabled="
                          (inclusiveLabel && exclusiveLabel) ||
                          !featureValue ||
                          inclusiveLabel ||
                          exclusiveLabel
                        "
                        placeholder="%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mb-2">
                <button
                  class="btn btn-outline-secondary mr-2"
                  type="button"
                  @click="addItem('radio')"
                >
                  +
                </button>
                <button
                  class="btn btn-outline-secondary mr-1"
                  type="button"
                  :disabled="fieldsConsider.length == 1"
                  @click="deleteitem(fieldsConsider.length - 1, 'radio')"
                >
                  -
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="savepredictions('radio')"
                  :disabled="loadingBlock"
                >
                  <span v-if="loadingBlock"
                    ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img"
                      alt="Waiting for login"
                  /></span>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- radio ends  -->
        <!-- radio horizontal starts  -->
        <div v-else-if="selectedmenu == 'radiohorizondal'" class="fieldentry">
          <div class="ibox-modal-title">
            <h5>{{ selectedMenuheader }}</h5>
          </div>
          <div
            class="ibox-content-edit"
            :class="{
              'height-400':
                templateFieldProperties.fieldsToConsider.length > 2 ||
                fieldsConsider.length > 2,
            }"
          >
            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input mr-1"
                  v-model="featureValue"
                  id="checkboxtext"
                />
                <label for="checkboxtext">Is Feature field&nbsp;?</label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="inclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="inclusiveRadio"
                    :disabled="!featureValue"
                  />
                  <label for="inclusiveRadio">Inclusive Criteria</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="exclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="exclusiveRadio"
                    value="exclusive"
                    :disabled="!featureValue"
                  />
                  <label for="exclusiveRadio">Exclusive Criteria</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 py-1">
                <label class="col-form-label"
                  >Select Inclusve Criteria Values</label
                >
                <Multiselect
                  v-model="selectedDropdown"
                  mode="multiple"
                  :valueProp="'optionname'"
                  placeholder="Select a value"
                  :label="'optionname'"
                  :disabled="
                    featureValue == false ||
                    (exclusiveLabel && inclusiveLabel == false) ||
                    (!exclusiveLabel && !inclusiveLabel)
                  "
                  :options="filteredOptions1"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-theme ml-1 mr-0"
                >
                </Multiselect>
              </div>
              <div class="col-md-12 py-1">
                <label class="col-form-label"
                  >Select Exclusive Criteria Values</label
                >
                <Multiselect
                  v-model="selectedDropdown2"
                  mode="multiple"
                  :disabled="
                    featureValue == false ||
                    (inclusiveLabel && exclusiveLabel == false) ||
                    (!exclusiveLabel && !inclusiveLabel)
                  "
                  :valueProp="'optionname'"
                  placeholder="Select a value"
                  :label="'optionname'"
                  :options="filteredOptions2"
                  :hideSelected="false"
                  :closeOnSelect="false"
                  class="multiselect-theme ml-1 mr-0"
                >
                </Multiselect>
              </div>
              <div class="row mx-0 px-0">
                <div v-for="(item, index) in fieldsConsider" :key="index">
                  <div class="row mx-0">
                    <div class="col-md-8 py-1 pl-0">
                      <label class="col-form-label">Values to Consider</label>
                      <Multiselect
                        v-model="item.considerableFields"
                        mode="multiple"
                        valueProp="optionname"
                        :disabled="
                          (inclusiveLabel && exclusiveLabel) ||
                          !featureValue ||
                          inclusiveLabel ||
                          exclusiveLabel
                        "
                        placeholder="Select a value"
                        label="optionname"
                        :options="getFilteredOptions(index)"
                        :hideSelected="false"
                        :closeOnSelect="false"
                        class="multiselect-theme ml-1 mr-0"
                      >
                      </Multiselect>
                    </div>
                    <div class="col-md-4 py-1 pr-0">
                      <label class="col-form-label"
                        >Consideration Rate(%)</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="item.considerationRate"
                        @input="validateNumberInput($event,index,type)"
                        :disabled="
                          (inclusiveLabel && exclusiveLabel) ||
                          !featureValue ||
                          inclusiveLabel ||
                          exclusiveLabel
                        "
                        placeholder="%"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-right mb-2">
                <button
                  class="btn btn-outline-secondary mr-2"
                  type="button"
                  @click="addItem('radio')"
                >
                  +
                </button>
                <button
                  class="btn btn-outline-secondary mr-1"
                  type="button"
                  :disabled="fieldsConsider.length == 1"
                  @click="deleteitem(fieldsConsider.length - 1, 'radio')"
                >
                  -
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="savepredictions('radio')"
                  :disabled="loadingBlock"
                >
                  <span v-if="loadingBlock"
                    ><img
                      src="../../assets/img/loading.webp"
                      width="20"
                      class="loading-img"
                      alt="Waiting for login"
                  /></span>
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- radio horizontal ends  -->
        <!-- checkbox starts -->
        <div v-else-if="selectedmenu == 'checkbox'" class="fieldentry">
          <div class="ibox-modal-title">
            <h5>{{ selectedMenuheader }}</h5>
          </div>
          <div
            class="ibox-content-edit"
            :class="{
              'height-400':
                templateFieldProperties.fieldsToConsider.length > 2 ||
                fieldsConsider.length > 2,
            }"
          >
            <div class="form-group">
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input mr-1"
                  v-model="featureValue"
                  id="checkboxtext"
                />
                <label for="checkboxtext">Is Feature field</label>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="inclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="inclusiveRadio"
                    :disabled="!featureValue"
                  />
                  <label for="inclusiveRadio">Inclusive</label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input mr-1"
                    v-model="exclusiveLabel"
                    @change="handleLabelChange($event, selectedmenu)"
                    id="exclusiveRadio"
                    value="exclusive"
                    :disabled="!featureValue"
                  />
                  <label for="exclusiveRadio">Exclusive</label>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <div class="d-flex">
                  <label for="truetext" class="mr-2 mb-0"
                    >If true consideration rate</label
                  >
                  <input
                    type="text"
                    placeholder="Enter a value"
                    class="mr-1"
                    v-model="trueConsideration"
                    id="truetext"
                    :disabled="!featureValue || !considerationRate"
                  />
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex">
                  <label for="falsetext" class="mr-2 mb-0"
                    >If false consideration rate</label
                  >
                  <input
                    type="text"
                    placeholder="Enter a value"
                    class="mr-1"
                    v-model="falseConsideration"
                    id="falsetext"
                    :disabled="!featureValue || !considerationRate"
                  />
                </div>
              </div>
            </div>
            <div class="text-right mb-2">
              <button
                class="btn btn-outline-secondary mr-2"
                type="button"
                @click="addItem('checkbox')"
              >
                +
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="savepredictions('checkbox')"
                :disabled="loadingBlock"
              >
                <span v-if="loadingBlock"
                  ><img
                    src="../../assets/img/loading.webp"
                    width="20"
                    class="loading-img"
                    alt="Waiting for login"
                /></span>
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </div>
        <!-- checkbox ends  -->
        <!-- Other option starts  -->
        <div v-else class="ibox-content-edit text-center">
          <h5 class="mb-3">Currently no editable option for this field</h5>
          <div class="mb-2">
            <button
              type="button"
              class="btn btn-primary"
              @click.prevent="closemodal()"
              :disabled="loadingBlock"
            >
              Ok
            </button>
          </div>
        </div>
        <!-- Other option ends  -->
      </div>
    </div>
  </teleport>
</template>
<script src="./ai_list.js"></script>
<style scoped>
/* Modal styles starts*/
.modal__wrapper__component {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100pc;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.titleclass {
  color: #ffffff;
  background: #768dd3;
  z-index: 2000;
}

.modalcontents {
  overflow-x: hidden;
  overflow-y: auto;
  height: 320px;
}

.ibox-modal-title {
  background-color: #eeedff;
  border-color: #e7eaec;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px;
  color: inherit;
  padding: 15px 15px 8px 15px;
  min-height: 48px;
  position: relative;
  clear: both;
  width: unset;
}

.component__modal__containers {
  width: min(500px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: auto;
  height: fit-content;
  margin-top: 100px;
}

.fieldentry {
  background-color: #ffffff;
  min-height: 200px;
}

.ibox-content-edit {
  background-color: #ffffff;
  padding: 15px 20px 0px 20px;
  border-color: #80ced7;
  -o-border-image: none;
  border-image: none;
  border-style: solid;
  border-width: 0px;
  display: flex;
  flex-direction: column;
}

.multiselect-theme {
  --ms-radius: 0;
  --ms-py: 0.2rem;
  --ms-option-font-size: 0.8rem;
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
/* Modal styles end*/
/* Table styles starts*/
.templatefield-details-table thead th {
  background-color: hsl(0, 0%, 100%);
  position: sticky;
  top: 0;
}

.templatefield-details-table thead th:nth-child(1) {
  width: 40%;
}

.templatefield-details-table thead th:nth-child(3) {
  width: 10%;
}

.templatefield-details-table tr td {
  text-wrap: balance;
}

.max-height-400,
.height-400 {
  max-height: 400px;
  overflow-y: scroll;
}
.non-clickable {
  pointer-events: none;
  opacity: 1; /* Ensures the checkbox doesn't appear faded */
}
.table-load {
  mix-blend-mode: difference;
  filter: brightness(0.5);
}
/* Table styles ends*/
.loading-img {
  mix-blend-mode: lighten;
}

.error-message {
  font-size: 14px;
  color: red;
}
</style>
