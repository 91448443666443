<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.visit templates") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-0 pr-1">
            <label class="mb-0">{{ $t("navigations.template name") }}</label>
            <input
              type="search"
              v-model="searchdata.templateName"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.date") }}</label>
            <input
              type="date"
              v-model="searchdata.createdDate"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 pr-1">
            <label class="mb-0">{{ $t("navigations.status") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="searchdata.status"
              name="account"
            >
              <option value="">--</option>
              <option value="New">{{ $t("navigations.new") }}</option>
              <option value="Approved">{{ $t("navigations.approved") }}</option>
              <option value="Retired">{{ $t("navigations.retired") }}</option>
              <option value="Design Complete">{{ $t("navigations.design complete") }}</option>
            </select>
          </div>
          <!-- <span type="button" @click.prevent="filteredLists" -->
          <span
            type="button"
            class="col-12 col-md-2 w-100 save_btn mx-lg-1 align-self-end"
            @click="setsearchdata()"
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="float-left">{{ $t("navigations.visit templates") }}</h5>
        <div
          v-if="rollsprivilages.includes('Visit Templates Create Template')"
          class="position-relative align-self-center"
          aria-label="Create site"
          tabindex="0"
        >
          <img
            class="cursor-pointer"
            src="../../assets/img/create.png"
            alt="Create site"
            @click="createTemplate()"
          />
          <div class="tool-tip">{{ $t("navigations.create template") }}</div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Language</th>
                <th>Version</th>
                <th>Created Date</th>
                <th>Status</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(template, index) in templateList"
                :key="template.templateId"
              >
                <td>{{ template.templateName }}</td>
                <td>{{ template.language }}</td>
                <td>{{ template.version }}</td>

                <td>{{ filter(template.createdDate) }}</td>
                <td>{{ template.status }}</td>
                <td
                  class="text-center"
                  v-on:clickout="showSidePopup[index] = false"
                >
                  <div
                    class="position-relative cursor-pointer"
                    @click.prevent="
                      showSidePopup[index] = !showSidePopup[index]
                    "
                  >
                    <i class="fa fa-ellipsis-v"></i>
                  </div>
                  <div class="plan-side-popup-form" v-if="showSidePopup[index]">
                    <div
                      v-if="rollsprivilages.includes('Visit Templates Create New Version')"
                      @click.prevent="
                        createNewVersion(
                          template.templateFormId,
                          template.version,
                          template.sourceTemplateId
                        )
                      "
                    >
                      Create new version
                    </div>
                    <!-- <div @click.prevent="deleteForms(template.templateId)">
                      Delete
                    </div> -->
                    <div
                      v-if="rollsprivilages.includes('Visit Templates Edit')"
                      @click.prevent="
                        editForm(
                          template.templateFormId,
                          template.version,
                          template.sourceTemplateId
                        )
                      "
                    >
                      Edit
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->

          <div class="col-lg-12 mx-0 px-0">
            <div class="row mx-0">
              <div
                class="dataTables_paginate paging_simple_numbers pagination justify-content-end"
              >
                <v-pagination
                  v-model="currentPage"
                  :pages="totalitems"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./formTemplate.js"></script>
<style scoped>
.plan-side-popup-form {
  position: absolute;
  text-align: left;
  right: 115px;
  border-radius: 5px;
  border: 1px solid #e7eaec;
  background-color: rgba(255, 255, 255, 1);
  width: min(133px, 90%);
  box-shadow: 3px 3px 3px rgba(160, 160, 160, 0.5);
  z-index: 200;
}

.plan-side-popup-form > div {
  cursor: pointer;
  padding: 0.5rem;
}

.plan-side-popup-form > div:hover {
  background-color: #e4eaf9;
}
</style>