<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_sites.png" alt="Total Sites">
              </div>
              <h5>{{ $t('navigations.total sites')}}</h5>
          </div>
          <div class="ibox-content contentheight">
              <h1 class="no-margins">{{sitecountstd}}</h1>
          <small>{{ $t('navigations.total sites')}}</small>  
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'totalsites',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      sitecountstd:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getsitecount();
		});
    await this.getsitecount();
  },
    methods:{
        async getsitecount() {
            const idtoken = store.getters.getIdToken;
            const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/site/getsitescount?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sitecountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>