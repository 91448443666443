/* eslint-disable */

import visitReportGrid from "./visit_report_grid";

export default {
  name: "visitSummaryReport",
  components: {
    visitReportGrid,
  },
    methods:{
      onclosed() {
        this.$router.go(-1)
      }
    }
};