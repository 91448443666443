<template>
        <div class="row wrapper border-bottom white-bg page-heading">
            <div class="col-lg-10">
            <h2>Manage Study</h2>
            </div>
            <div class="col-lg-2">
                <i class="fa fa-times close my-2" @click="onclosed()"></i>
            </div>
        </div>
            <div class="wrapper wrapper-content">
            <div class="ibox-content p-3">
                <div class="form-group row">
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study ID <sup><i class="fa fa-asterisk imp"></i></sup></label>
                        <input type="text" class="form-control" v-model="values.studyRef">
                        <span class="errormsg" v-if="errors[1] == false">Please enter a study ID</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study Name <sup><i class="fa fa-asterisk imp"></i></sup></label>
                        <input type="text" class="form-control" v-model="values.studyName">
                        <span class="errormsg" v-if="errors[0] == false">Please enter a study name</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Sponsor </label>
                        <input type="email" class="form-control" v-model="values.sponsor">
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Status </label>
                        <select class="form-select" name="account" v-model="values.status">
                            <option>Archived</option>
                            <option>Closed</option>
                            <option>New</option>
                            <option>Open</option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study Notes </label>
                        <input type="test" class="form-control" v-model="values.studyNotes">

                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Pre-visit </label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.preVisit" @change.prevent="numberonlyvalidation('preVisit')">
                        <span class="errormsg" v-if="numerrors['preVisit'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Post-visit </label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.postVisit" @change.prevent="numberonlyvalidation('postVisit')">
                        <span class="errormsg" v-if="numerrors['postVisit'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Number of Site </label>
                        <input type="text" class="form-control" v-model="values.numberOfSite" @change.prevent="numberonlyvalidation('numberOfSite')">
                        <span class="errormsg" v-if="numerrors['numberOfSite'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Number of Subjects </label>
                        <input type="text" class="form-control" v-model="values.numberOfPatient" @change.prevent="numberonlyvalidation('numberOfPatient')">
                        <span class="errormsg" v-if="numerrors['numberOfPatient'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Duration </label>
                        <input type="text" class="form-control" v-model="values.duration" @change.prevent="numberonlyvalidation('duration')">
                        <span class="errormsg" v-if="numerrors['duration'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 my-3 d-flex ">
                        <div class="input-group date">
                            <div class="i-checks aligncheckbox"><label class="mr-2">Active</label> <input type="checkbox" v-model="values.active"></div>
                        </div>
                    </div>
                    <div class="col-lg-12 my-3 d-flex justify-content-lg-end">
                        <button class="btn btn-primary cancel_btn py-2 px-5 mx-3" type="button" @click.prevent="clear">Clear</button>
                        <button class="btn btn-primary save_btn py-2 px-5" type="submit" @click.prevent="addStudy">Save</button>
                    </div>

                </div>
            </div>
        </div>
</template>
<script src="./studyComponent.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/savepages.css";
 .wrapper-content {
    width: 100%;
}
textarea {
    width: 100%;
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.col-form-label{
    font-size: 14px;
}
.impcl {
    color: #ff4747;;
    font-size: 25px;
    float: right;
}
.impcl:hover {
    color: #a30202;;
    font-size: 25px;
    float: right;
}
.errormsg {
    color: red;
    font-size: 15px;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}

</style>