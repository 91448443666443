/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "userlist",
  components: {
    VPagination,
   },
  data() {
    return {
      auditpopup:false,
      audithistortpopup:false,
      roleprivileges: "",
      baseurl: process.env.VUE_APP_Service_URL,
      studyName: "",
      searchStatus: "",
      status: "new",
      enableNext: true,
      enablePrevious: true,
      currentPageSize: 0,
      currentPageNumber: 1,
      ItemID:"",
      AuditId:"",
      auditLists:[],
      patientLists: [],
      studyList: [],
      auditHistoryLists:[],
      SortOrder:0,
      totalIteems: 0,
      totalPages: 0,
      pageSize: 0,
      pageNumber: 1,
      headerValue: {},
      token: "",
      visits :"",
      siteCode:"",
      SortProperty:"createdAt",
      SortOrder:1,
    };
  },
  async mounted() {
    this.token = store.getters.getIdToken;
    this.headerValue = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    }
    this.defaultdate = localStorage.getItem("dateformat");
    await this.setroleprivileges(); 
    this.GetData();
    this.isdisabled();
    this.filter();
  },
  methods: {
    SortSelected(sortProp,id){
      console.log('SortPropChanged');
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id
      if(sortProp!=null && id==selectedElementID)
      {
        console.log("Order is ",this.SortOrder)
        if(this.SortOrder == 0)
        {
          if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageNumber= 1;
      this.currentPageNumber = 1;
      this.SortProperty=sortProp;
      this.SortOrder = this.SortOrder==0?1:0;
      this.searchData();
    },
    async setroleprivileges(){
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    isdisabled(){
      return ;
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async createstudy(id) {
      this.$router.push(`/study/${id}`);
    },
    async visit(stdid) {
      this.$router.push(`/visitlist/${stdid}`);
    },
    async editstudy(id) {
      this.$router.push(`/study/${id}`);
    },
    openModelaudit(patID) {
      document.body.style.overflowY="hidden"
      this.auditpopup= true;
      this.ItemID = patID;
      console.log("ITEM ID Issssssssssssssssss...",this.ItemID);
      this.getAuditdetails();
    },
    async closemodallll() {
      console.log("close modal");
      document.body.style.overflowY="auto"
      this.auditpopup= false;
    },
    openModelauditOps(audID){
      console.log("audit id issssssssssssssssssssssssssssss",audID)
      this.audithistortpopup = true;
      this.AuditId = audID;
      console.log("audit id is......?",this.AuditId)
      this.getAuditHistory();
    },
    async closehistorymodal() {
      console.log("close modal");
      this.audithistortpopup= false;
    },
    async checkStudyHaveSites(id) {
      const idtoken = store.getters.getIdToken;
      await axios.get(`${this.baseurl}/management/site/getsitescount?studyId=${id}`,
        this.headerValue,
      ).then((res) => {

      if(res.data<1)
      {
          this.findVisits(id);
          this.deletestudy(id);
          
      }else{
        alert("This study contains 1 or more sites, studies with sites cannot be deleted.");
      }
      });
    },
    async deletestudy(id) {
      if (confirm("Are you sure you want to delete this study?")) {
        let delData = [id]
        await axios
          .delete(
             `${this.baseurl}/management/study/deleteone?ids=`+id
            ,
            this.headerValue
          )
          .then((res) => {
            store.dispatch("setStudyID","")
            if (res.data) {
              this.studyList = res.data.results;
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
            
            this.enableNext = false;
            this.enablePrevious = false;
          });
        this.GetData();
        alert("Deleted successfully.")
      }
      
    },
    async findVisits(id){
      console.log("id is", id);
      await axios.get(this.baseurl + '/management/visit/listvisit?StudyId='+id,this.headerValue)
      .then((res) => {
            console.log("visits are" , res.data.results);
            this.visits = res.data.results;
       })
      .catch((err) => {console.log("error.." , err);});
      console.log("inside if to delete visit");
          console.log(this.visits);
          console.log("*************************");
          this.visits.forEach(element => {
            console.log("To Delete"+element.visitId);
            this.deleteVisit(element.visitId)
          });
          console.log("deleting study");
         
    },
    async deleteVisit(id){

      console.log("in delete  Visits ",id);
      await axios.delete(this.baseurl + '/management/visit/delete?id=' + id , this.headerValue)
      .then((res) => {
        console.log(" visit delete success.." , res);
      })
      .catch((err) => {console.log("error in delete " , err)});    
    },

    async deleted(userid) {
      alert("Are you sure you want to delete this study?");
      const idtoken = store.getters.getIdToken;
      console.log(idtoken);
      console.log("Enter mounted");
    },

    async getPreviouspage() {
      if (this.pageNumber > 1) {
        if (this.studyName == "" && this.searchStatus == "") {
          this.pageNumber = this.pageNumber - 1;
          this.GetData();
        }
        else {
          this.pageNumber = this.pageNumber - 1;
          this.searchData();
        }
      }
    },
    async getNextpage() {
      if (this.pageNumber < this.totalIteems) {
        if (this.studyName == "" && this.searchStatus == "") {
          this.pageNumber = this.pageNumber + 1;
          this.GetData();
        }
        else {
          this.pageNumber = this.pageNumber + 1;
          this.searchData();
        }
      }
    },
    async GetData() {
      if(this.searchStatus=="All"){
        this.searchStatus=""
      }
      await axios
        .get(
          `${this.baseurl}/management/study/studylist?pageindex=${this.pageNumber}&pagesize=10&SortProperty=${this.SortProperty}&SortOrder=${this.SortOrder}&api-version=1.0`,
          this.headerValue
        )
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.totalIteems = Math.ceil(res.data.totalCount / 10);
            this.studyList = res.data.results;
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    // search datas 
    async SearchButtonClicked(){
      this.pageNumber=1;
      this.currentPageNumber=1;
      this.searchData()
    },

    async searchData() {
      
      if(this.searchStatus=="All"){
        this.searchStatus=""
      }
      await axios
        .get(
          `${this.baseurl}/management/study/studylist?SearchKey=${this.studyName}&Status=${this.searchStatus}&pageindex=${this.pageNumber}&pagesize=10&SortOrder=${this.SortOrder}&SortProperty=${this.SortProperty}&api-version=1.0`,
          this.headerValue
        )
        .then((res) => {
          console.log(res);
          if (res.data) {
            this.totalIteems = Math.ceil(res.data.totalCount / 10);
            this.studyList = res.data.results;
            this.siteCode=this.studyList.studyRef;
          } else {
            console.log("no studies found");
          }
        })
        .catch((err) => {
          console.log(err);
          this.enableNext = false;
          this.enablePrevious = false;
        });
    },
    async loadPage(page) {
      this.currentPageNumber = page;
      this.pageNumber = page;
      this.searchData();
   },
   async folderList(id,siteCode) {
    await store.dispatch("setStorageId", id);
    await store.dispatch("setStorageCode", siteCode);
    const storageId = store.getters.getStorageId;
    const storageCode = store.getters.getStorageCode;
    console.log("wowwwwwww", storageId);
    console.log("wowwwwwww", storageCode);
    this.$router.push(`/folderstudy/${id}`);
  },
  async getAuditdetails(){
    //this.ItemID = this.patientId;
    console.log("item id is",this.ItemID);
    await axios
    .get(`${this.baseurl}/management/audit/getaudits?AuditTable=Management&ItemID=${this.ItemID}&Sort=Desc`)
     .then((res) => {
        this.totalPages = parseInt(res.data.totalCount / this.pageSize);
        this.auditLists = res.data.data;
        if(res.data.count>4)
        {
          this.changeHeight=true
        }
        else
        {
          this.changeHeight=false
        }
        console.log("Audit Trial list is", this.auditLists);
        if (res.data.totalCount % this.pageSize != 0) {
          this.totalPages = this.totalPages + 1;
        }
        console.log(res);
      });
    
  },
  async getAuditHistory(){
    //this.ItemID = this.patientId;
    console.log("audit Id is...",this.AuditId);
    await axios
    .get(`${this.baseurl}/management/audit/getaudit?AuditId=${this.AuditId}&auditTable=Management`)
     .then((res) => {
        this.totalPages = parseInt(res.data.totalCount / this.pageSize);
        this.auditHistoryLists = res.data;
        console.log("Audit History List issssss", this.auditHistoryLists);
        if (res.data.totalCount % this.pageSize != 0) {
          this.totalPages = this.totalPages + 1;
        }
        console.log(res);
      });
    
  },
  },
};