import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "createFormModal",
  components: {
    VPagination,
  },
  props: { studyId: String },
  data() {
    return{
        baseurl: process.env.VUE_APP_Service_URL,
        createdformid:"",
        formName:"",
        idtoken:""
    }
  },
  mounted(){
    window.scrollTo(0, 0);
    this.idtoken=store.getters.getIdToken
    document.body.style.overflow="hidden"
  },
  unmounted(){
    document.body.style.overflow="auto"
  },
  methods:{
  async createForm(){
    if(this.formName==""){
        alert(
            "Please enter a form name"
        )
    }
    else{
        await axios
        .post(
          `${this.baseurl}/forms/templatedesign/createform`,
          {
              "formName": this.formName,
              "folderName": "",
              "folderId": this.studyId
          },
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            }
          }
        )
        .then((res) => {
          this.createdformid = res;
          this.createtemplate();
          // this.$emit("closeModal")
        })
        .catch((err) => {
          console.error("Form error:", err);
        });
    }
   
  },
  
  async createtemplate(){
    let data = { 
      templateId: "",
      templateName: this.formName,
      languageCode: "en",
      totalPages: 0,
      version: "1.0",
      isDeleted: false,
      language: "English",
      folder: "",
      createdUserEmail: store.getters.getEmailIs,
      createdAt: new Date(),
      createdUserId: null,
      lastModifiedUserEmail: null,
      lastModifiedAt: null,
      lastModifiedUserId: null,
      formname: this.formName,
      formId: this.createdformid["data"],
      pages: [],
      studyId: this.studyId
};
await axios
        .post(
          `${this.baseurl}/forms/templatedesign/saveformtemplate`,
          data,
          {
            headers: {
              Authorization: "Bearer " + this.idtoken,
              "Content-Type": "application/json",
            }
          }
        )
        .then((res) => {
          console.log(res.data);
          alert("Form created successfully.");
          this.$emit("closeModal");
          this.$emit("SubmitForm");
          this.$emit('sendIdToParent', res.data);
          // emitIdToParent(res.data);
        })
        .catch((err) => {
          console.error("Form error:", err);
        });
  },
  async emitIdToParent(idToSend) {
    this.$emit('sendIdToParent', idToSend);
  },
  
}

}