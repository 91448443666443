<template>
  <div class="row wrapper border-bottom white-bg page-heading">
      <div class="col-lg-10">
        <h2>{{$t('navigations.unassigned forms')}}</h2>
      </div>
  </div>
  <div class="wrapper wrapper-content">  
      <div class="ibox-content mb-1 px-3 py-2">      
        <div class="form-group row mb-1">
          <div  class="col-lg-10 d-flex flex-column flex-lg-row justify-content-lg-start my-2 m-lg-0"> 
            <div class="form-group w-100 mb-0 pr-1">
              <label class="mb-0">{{$t('navigations.form name')}}</label>
              <input type="search" v-model="searchItem" class="form-control my-2 my-lg-0">         
            </div> 
          </div>  
          <div class="col-lg-2 align-self-end my-2 m-lg-0">                           
            <span type="button" @click.prevent="searchData()"  class="save_btn px-5 mx-lg-1 w-100">{{$t('navigations.search')}}</span>
          </div>  
        </div> 
      </div>
      <div class="ibox-body">
         <div class="ibox-title style_2">
            <h5 class="float-left">{{$t('navigations.unassigned forms')}}</h5>
        </div>
          <div class="ibox-content">
              <div class="table-responsive">
                  <table class="table table-striped table-bordered dataTables" >
                      <thead>
                      <tr>                
                          <th class="sort_block" @click="SortSelected('formName',1)" id="1">
                            {{$t('navigations.form name')}}
                            <span>
                                <i class="fa fa-long-arrow-up"></i>
                                <i class="fa fa-long-arrow-down"></i>
                           </span>
                          </th>
                          <th class="sort_block" @click="SortSelected('createdUserName',2)" id="2">
                            {{$t('navigations.uploaded by')}}
                            <span>
                                <i class="fa fa-long-arrow-up"></i>
                                <i class="fa fa-long-arrow-down"></i>
                            </span>
                          </th>
                          <th class="sort_block" @click="SortSelected('createdAt',3)" id="3"> 
                            {{$t('navigations.uploaded date')}}
                            <span>
                                <i class="fa fa-long-arrow-up"></i>
                                <i class="fa fa-long-arrow-down"></i>
                          </span>
                          </th>
                          <th class="sort_block" @click="SortSelected('formStatus',4)" id="4">
                            {{$t('navigations.status')}}
                            <span>
                                <i class="fa fa-long-arrow-up"></i>
                                <i class="fa fa-long-arrow-down"></i>
                          </span>
                          </th>                          
                          <th class="sort_block" @click="SortSelected('version',5)" id="5">
                            {{$t('navigations.version')}}
                            <span>
                                <i class="fa fa-long-arrow-up"></i>
                                <i class="fa fa-long-arrow-down"></i>
                          </span>
                          </th>
                          <th v-if="roleprivileges.includes('Assign Form View')" class="text-center">{{$t('navigations.view')}}</th>                        
                      </tr>
                      </thead>
                      <tbody v-if="show">
                          <tr v-for="item in filteredListing" :key="item.formId">                             
                              <td>{{item.visitName}} </td>
                              <td>{{item.createdUserName}}</td>
                              <td>{{filter(item.createdAt)}}</td>                            
                              <td>{{item.formStatus}}</td>
                              <td>{{item.version}}</td>
                              <td v-if="roleprivileges.includes('Assign Form View')" ><a><i class="fa fa-eye" @click.prevent="mapthisform(item.formId)"></i></a></td>
                          </tr>                      
                      </tbody>
                      <tbody v-else>
                          <tr v-for="list in lists" :key="list.formId">                             
                             <td>{{list.visitName}}</td>
                              <td>{{list.createdUserName}}</td>
                              <td>{{filter(list.createdAt)}}</td>                            
                              <td>{{list.formStatus}}</td>
                              <td>{{list.version}}</td>
                              <td v-if="roleprivileges.includes('Assign Form View')" class="text-center"><i class="fa fa-eye editicon" @click.prevent="mapthisform(list.formId)"></i></td>
                          </tr>                      
                      </tbody>
                  </table>
              </div>
               <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalLists"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  /> 
                </div>
              </div>
            </div>             
          </div>       
          </div>
    </div>
</div>
    
</template>


<script src="./formComponent.js" type="text/javascript">

</script>
<style scoped>
@import "../../assets/bootstrap.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group{
  margin-bottom:0rem;
}
.save_btn{
height:auto!important;
}
.previousbtn,.nextbtn{
  margin:0;
  border-radius:0px;
  border:0.5px solid #eeeeee;
}
.page-links{
  border-radius:0px;
}
</style>