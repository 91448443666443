/* eslint-disable */
import config from "../../../auth_config";
import createAuth0Client from "@auth0/auth0-spa-js";
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { router } from "../../router";
import auth0 from "auth0-js";
import axios from "axios";
import store from "../../store/index";
var webAuth = new auth0.WebAuth({
  domain: "dev-ikz5y2bz.us.auth0.com",
  clientID: "Ha6TPgiisWCGcn3TcTsFXDJDbSUQSFoX",
  scope: "openid profile",
  responseType: "token id_token",
});
export default {
  name: "App",
  data() {
    return {
      store: "",
      auth0Client: "",
      auth0: "",
      username: "",
      password: "",
      name: "",
    };
  },
  mounted() {
    this.store = useStore();
    this.auth0Client = ref();
  },
  methods: {
    async Signup() {
      console.log("inside signup function");

      let tockenval = this.loginfun();
      // console.log(tockenval);
    },
    async loginfun() {
      return webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          name: this.name,
          email: this.username,
          password: this.password,
        },
        function(err) {
          if (err) return console.log("Something went wrong: " + err.message);
          return alert("success signup without login!");
        }
      );
    },
    // vipin.v88@outlook.com
    // Test@123

    async googleLogin() {
      console.log("login with google");
      webAuth.popup.authorize(
        {
          connection: "google-oauth2",
        },
        function(err, authResult) {
          //do something
        }
      );
    },
  },
};
