<template>
  <showForm
  :fromArchive = "archive"
  :formsesid = "formsid"
  :requiredsubmit = 'false'
  :closedoption = "whenclosed"
  :isfieldvalidate = "showpopup"
        type: Boolean,
        default: false
   />
</template>

<script src="./showformComponent.js"></script>
