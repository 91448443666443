/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import Digitalformview from "../View_digital_form/view_digital_form.vue";
import jwt_decode from "jwt-decode";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "patientList",
  components: {
    VPagination,
    Digitalformview
  },
  props:{
    modalFieldName:{
        type:String,
        default:""
    },
    pageNo:{
      type:Number,
      default:0
  },
  pageArray:{
      type:Object,
      default:{}
  },
  },
  data(){
    return{
      modalField:"",
      baseurl: process.env.VUE_APP_Service_URL
    }
  },
  mounted(){
    this.modalField=this.modalFieldName
    document.body.style.overflowY="hidden"
  },
  unmounted() {
    document.body.style.overflowY="auto"
  },
  methods:{
    async saveChanges(){
      const idtoken = store.getters.getIdToken;           
      await axios
        .put(`${this.baseurl}/forms/template/updatelabel`,
        {
          "templateId": this.pageArray.templateId,
          "pageNo": this.pageNo,
          "oldLabelName": this.modalFieldName,
          "newLabelName": this.modalField
        },
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res)=>{
          alert("Field updated successfully")
          this.$emit('reloadForm')
          this.$emit('closeModal')         
        }
        )
        .catch((err)=>{
          if (err.response && err.response.data && err.response.data.errors) {
            alert("Field label is required");
          } 
          else if(err.response.data.messages[0].includes("Label With Same Name Already Exists")){
            alert("No changes made on field label");
          }
          console.log(err)
        }
        );
    
    }
  }
}