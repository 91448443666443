/* eslint-disable */

import SiteReportGrid from "./site_report_grid";

export default {
  name: "siteReport",
  components: {
   SiteReportGrid,
  },
    methods:{
      onclosed() {
        this.$router.go(-1)
      }
    }
};