<template>
  <ul class="tree">
    <li v-for="(site, index) in sites" :key="site.siteID + 1">
      <input
        type="checkbox"
        :id="site.siteID + 1"
        @click="getpatient(site.siteID, site.siteCode, site.patientNo, index)"
      />
      <label :class=" selectedId == site.siteID
                    ? 'tree_label tree_label_bold'
                    : 'tree_label'
                " :for="site.siteID + 1">{{
        site.siteCode
      }}</label>
      <ul
        v-for="patient in patientLists[index]"
        :for="site.siteID + 1"
        :key="patient.patientId + 1"
      >
        <li>
        
          <label :for="patient.patientId + 1" class="tree_label">{{
            patient.subjectId
          }}</label>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script src="./TreeSiteTo.js"></script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";
.linkstyle {
  cursor: pointer;
}
</style>