<template>

  <div :class="[radiogroupclass,{'cursor-pointer':  sourceValue===true}]">
      {{label}}<sup v-if="requiredname == 'required' || requiredname == 'Required'"><i class="fa fa-asterisk imp px-1"></i></sup>
      <div :class="radiowrappclass">
  <baseradio
  v-for="option in options"  :key="option"
  :value="option.codevalue"
  :privilege="privilege || fromarchive == 'true'"
  :label="option.optionname"
  :name="groupname"
  :modelValue="modelValue"
  @update:modelValue="$emit('update:modelValue',$event)"
  />
  </div>
</div>
</template>
<script>
/* eslint-disable */
import baseradio from "./baseradio";
export default{
  components: {
    baseradio
  },
  props:{
    sourceValue:{
        type: Boolean,
        default: false
    },
    options:{
      type: Array,
      Required: true
    },
    groupname:{
      type: String,
      Required:true
    },
    label:{
      type: String,
      Required: true
    },
    modelValue:{
      type: [String, Number],
      Required: true
    },
      classname:{
        type: String,
        default: 'form-control'
      },
      requiredname:{
        type: String,
        default: ''
      },
       privilege:{
        type: String,
        default: ''
      },
       fromarchive: {
        type: String,
        default: 'false'
      },
      orientation: {
        type: String,
        default: 'vertical'
      }
  },
   data() {
        return {
          radiowrappclass:"radiogroupclass",
          radiogroupclass: "radiogroupouter mainbgchange"
        }
   },
   async mounted() { 
    if(this.orientation == "horizondal"){
      this.radiowrappclass = "radiogroupclass alignhorizondal"
    }
   },
   watch : {
               classname(val) {
                   if(val == "form-control errorlist"){
                  this.radiogroupclass = "radiogroupouter errorradio mainbgchange";
                   }
                   else {
                  this.radiogroupclass = "radiogroupouter mainbgchange";
                   }
               },
   }
}
</script>
<style scoped>
.radiogroupcontainer{
  margin-left: 15%;
}
.errorradio{
  border-width: 2px !important;
  border-color: red !important;
}
.radiogroupclass{
  margin-left: 10px;

}
.hightlight{
  /* border-color: red !important; */
  border-bottom: 6px solid #f8ac59;
  
}
.imp {
    color: #ff0000;
    font-size: 7px;
}
.alignhorizondal{
  display: flex;
    flex-wrap: wrap;
    gap: 2px;
}
</style>
