import axios from "axios";
import VPagination from "@hennge/vue3-pagination";
export default {
  name: "survivaldata_popup",
  components: {
    VPagination,
  },
  props: {},
  data() {
    return {
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms-ml/`,
      refreshFlags: {
        predictionReport: true,
        analyticalReport: false,
      },
      accuracy: "",
      precision: "",
      recall: "",
      f1_score: "",

      retentionDetails: {},
      totalCount: 0,
      trueCount: 0,
      falseCount: 0,
      retainedPercentage: 0,
      dropoutPercentage: 0,
      isLoading: false,
      currentPage: 1,
      totalPage: 1,
      retentionCount: {},
      showData: true,
      ML_confidence: 0,
      ML_prediction: 0,
      feature_interaction: {},
      tableloadingBlock : false,
      changeHeight: true,
      newfeaturesValue: {},
      survivalData:[]

    };
  },
  async mounted() {
    await this.getSurvivalData();
  },
  computed: {
    featuresWithShap() {
      return Object.keys(this.features)
        .filter(key => this.getShapValue(key) !== 0)
        .map(key => ({ key, value: this.features[key] }));
    },
  },
  methods: {
    async getSurvivalData() {
        this.showData = true;
        this.changeHeight = true;
        //this.tableloadingBlock = true;
      await axios
        .get(`${this.baseUrl}report/getreports?table_name=SurvivalReport`)
          .then((res) => {
            this.survivalData = res.data;   
            window.scrollTo(0, 0);     
          })
          .catch((err) => console.log("error in getting model info...", err));
    },
    loadPage(page) {
      this.currentPage = page;
      this.searchData.PageNo = page;
      this.getPatientRetentionInfo();
     
    },
    async closemodal() {
      this.$emit("libactive")
    },
    async ViewFeatures(features,feature_interaction,ML_confidence,ML_prediction) {
      this.feature_interaction = feature_interaction;
      this.ML_prediction = ML_prediction;
      this.ML_confidence = ML_confidence;
      this.showData = true;
      this.changeHeight = true;
      this.newfeaturesValue = features;
      this.features = features
      //this.getPagesInfo();
      this.features.length != 0 ? this.tableloadingBlock = false : true;
    },

    handleTabClick(tab) {
      this.refreshFlags = [false, false];
      this.refreshFlags[tab] = true;
    },
  },
};
