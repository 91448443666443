<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.iot patients')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-0 w-100 px-1">
            <label class="mb-0">{{$t('navigations.subject id')}}</label>
            <input
              type="search"
              v-model="searchdata.subjectid"
              class="form-control my-2 my-lg-0"
            />
          </div>
                    <div class="form-group mb-0 w-100">
            <label class="mb-0">{{$t('navigations.device name')}}</label>
              <input
              type="search"
              v-model="searchdata.devicename"
              class="form-control my-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-0 w-100 px-1">
            <label class="mb-0">{{$t('navigations.form name')}}</label>
            <input
              type="search"
              v-model="searchdata.FormName"
              class="form-control my-2 my-lg-0"
            />
          </div>
            <span type="button"
              @click.prevent="filteredLists"
              class="save_btn px-5 mx-lg-1 align-self-end"
              @click="OnSearchButtonClicked()"
            >
            {{$t('navigations.search')}}
            </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
       <div class="ibox-title style_2">
          <h5 class="float-left">{{$t('navigations.iot patients list')}}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{$t('navigations.subject id')}}</th>
                <th>{{$t('navigations.device name')}}</th>
                <th>{{$t('navigations.form name')}}</th>
                <th>{{$t('navigations.view')}}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in iotPatientlist" :key="item.iotPatientId">
                <td>{{ item.subjectId }}</td>
                <td>{{ item.deviceName }}</td>
                <td>{{ item.formName }}</td>
                <td class="tdtext">
                    <i
                      class="fa fa-eye editicon"
                      @click.prevent="viewforms(item.formID)"
                    ></i
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPageNumber"
                    :pages="totalLists"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>


<script src="./IOTPatients.js" type="text/javascript">
</script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.tableFixHead          { overflow: auto; height: 100px;}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }
.tableFixHead tbody th { position: sticky; left: 0; }
table  { border-collapse: collapse; width: 100%; }
th, td { padding: 8px 16px; }
th     { background:#fff; }
.modelbody{
  /* background-color: aquamarine; */
  width: 100%;
  height: 86%;
  margin: 0%;
  padding: 1%;
  overflow: auto;
}
.modal__container {
width: 50%;
}
.tdtext{
  text-align: center;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>
