<template>
<div>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.approve template") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0">{{ $t("navigations.form name") }}</label>
            <input type="text" class="form-control" v-model="templateName" />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1 pl-lg-0">
            <label class="mb-0"> {{ $t("navigations.status") }}</label>
            <select class="form-select my-md-2 my-lg-0" v-model="Status">
              <option value="All">{{ $t("navigations.all") }}</option>
              <option value="Approved">{{ $t("navigations.approved") }}</option>
              <option value="Rejected">{{ $t("navigations.rejected") }}</option>
              <option value="Assigned">{{ $t("navigations.assigned") }}</option>
            </select>
          </div>
          <span
            type="button"
            @click.prevent="onSearchButtonClicked()"
            class="
              col-sm-12 col-lg-2
              save_btn
              my-1 my-lg-0
              mx-lg-1
              align-self-end
            "
          >
            {{ $t("navigations.search") }}
          </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.approve templates") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.form name") }}</th>
                <th>{{ $t("navigations.language") }}</th>
                <th> {{ $t("navigations.language code") }}</th>
                <th>{{ $t("navigations.version") }}</th>
                <th>{{ $t("navigations.status") }}</th>
                <th>{{ $t("navigations.uploaded date") }}</th>
                <th class="text-center">{{ $t("navigations.view") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="form in formlist" :key="form.id">
                <td>{{ form.template.templateName }}</td>
                <td>{{ form.template.language }}</td>
                <td>{{ form.template.languageCode }}</td>
                <td>{{ form.template.version }}</td>
                <td v-if="form.approval.length <= 1">
                  <span v-for="(approval, index) in form.approval" :key="index">
                    {{ approval.status }}
                  </span>
                </td>
                <td v-else>
                  {{ form.approval[form.approval.length - 1].status }}
                </td>

                <td>{{ filter(form.template.CreatedAt) }}</td>
                <td class="text-center" v-if="form.approval.length <= 1">
                  <span v-for="(approval, index) in form.approval" :key="index">
                    <i
                      class="fa fa-eye editicon"
                      @click="
                        viewtemplate(
                          form.template.id,
                          form.id,
                          approval.status,
                          form.approval.comments
                        )
                      "
                    >
                    </i>
                  </span>
                </td>
                <td class="text-center" v-else>
                  <i
                    class="fa fa-eye editicon"
                    @click="
                      viewtemplate(
                        form.template.id,
                        form.id,
                        form.approval[form.approval.length - 1].status,
                        form.approval[form.approval.length - 1].comments
                      )
                    "
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalItems"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
          <!---Pagination ends-->
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script src="./digital_form.js">
</script>