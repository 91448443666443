<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.subject transfer") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="ibox-body mb-1 p-2">
      <div class="row my-2 my-lg-0 mb-lg-0">
        <div class="col-lg-5">
          <h4>{{ $t("navigations.from") }}:</h4>
          <div class="ibox-contents p-1">
            <div>
              <label class="mb-0"
                >{{ $t("navigations.site") }}: <strong>{{ displayFromSite }}</strong></label
              >
            </div>
            <div>
              <label class="mb-0"
                >{{ $t("navigations.patient") }}: <strong>{{ displayFromPatient }}</strong></label
              >
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <h4>{{ $t("navigations.to") }}:</h4>

          <div class="ibox-contents p-1">
            <div>
              <label class="mb-0"
                >{{ $t("navigations.site") }}: <strong>{{ displayToSite }}</strong></label
              >
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2 align-self-end">
          <span
            type="button"
            @click="transferButton()"
            class="btn btn-primary save_btn mx-lg-1 w-100"
          >
          {{ $t("navigations.transfer") }}
          </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.patient schedule") }}</h5>
          </div>
          <div class="ibox-content">
            <TreeSiteFrom> </TreeSiteFrom>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="float-left">{{ $t("navigations.patient schedule") }}</h5>
          </div>
          <div class="ibox-content">
            <TreeSiteTo> </TreeSiteTo>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./transferSubject.js"></script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.linkstyle {
  cursor: pointer;
}
.ibox-contents {
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: transparent;
  border-image: none;
  border-style: solid;
  border-width: 1px;
}

.treegridnew {
  padding-right: initial;
}
</style>
