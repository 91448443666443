<template>
  <div class="wrapper wrapper-content pb-0">
    <div class="mt-1">
      <h4>AI Forms</h4>
    </div>
    <div class="col-lg-12 my-2 d-flex justify-content-lg-end float-center">
      <button
        class="btn btn-primary save_btn py-1 px-5 mr-0"
        type="submit"
        @click.prevent="AIpopup = true"
      >
        Use IDS AI
      </button>
    </div>
    <div class="py-0 px-0">
      <div class="table table-responsive">
        <table class="table">
          <tbody>
            <tr
              class="border-top"
              v-for="(list, index) in formLibList"
              :key="index"
              
            >
              <td class="" style="width: 80%">{{ list.formLibraryName }}</td>
              <td> <i
                    class="fa fa-eye editicon"
                    @click="retrieveName(list)"
                  ></i></td>
              <!-- <td class="" style="width:10%"><button class="btn btn-outline-ai btn-success-ai py-0 px-4" type="submit">Add</button></td> -->
              <td class="" style="width: 10%">
                <i
                      class="fa fa-trash edit-delete"
                      aria-hidden="true"
                      @click="removeItem(index)"
                    ></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <hr />
    <div class="col-lg-12 my-2 d-flex justify-content-lg-end float-center">
      <button class="btn btn-primary save_btn py-1 px-5 mr-0" type="submit" @click="save">Save</button>
    </div>
  </div>
  <Forms_AI_PopUp
    v-if="AIpopup"
    @studyCreated="loadstudy"
    @updateList="getData"
    @closeModal="AIpopup = false"
  />
</template>
<script src="./FormSuggestions.js"></script>
<style scoped>
@import "../../assets/css/style.css";
.btn-success-ai .btn-outline-ai {
  color: white;
}
.btn-outline-ai {
  color: white;
  background-color: red;
  transition: all 0.5s;
}
.btn-success-ai {
  color: white;
  border-color: red;
}
.btn-xs-ai {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}
.btn-outline-ai:hover {
  color: #fff;
}

.btn-success-ai.btn-outline:hover {
  color: #fff;
}
.btn-success-ai:hover,
.btn-success-ai:focus,
.btn-success-ai.focus {
  color: #fff;
  background-color: red;
  border-color: red;
}
.btn-success-ai.disabled,
.btn-success-ai:disabled {
  color: #fff;
  background-color: #1a7bb9;
  border-color: #1a7bb9;
}
.btn-success-ai:not(:disabled):not(.disabled):active,
.btn-success-ai:not(:disabled):not(.disabled).active,
.show > .btn-success-ai.dropdown-toggle {
  color: #fff;
  background-color: red;
  border-color: red;
}
.btn-success-ai:not(:disabled):not(.disabled):active:focus,
.btn-success-ai:not(:disabled):not(.disabled).active:focus,
.show > .btn-success-ai.dropdown-toggle:focus {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-success-ai.btn-outline-ai:hover {
  color: #fff;
}
</style>
