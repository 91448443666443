<template>
    <div class="study_profile_modal__wrapper">
        <div class="study_profile_modal__container">
            <div class="modal__title d-flex justify-content-between py-2 px-3">
                <h4 class="my-0">{{ $t('navigations.edit study') }}</h4>
                <div class="cursor-pointer" @click.prevent="closeModal">
                    <i class="fa fa-times"></i>
                </div>
            </div>
            <div class="ibox-content p-3">
                <div class="form-group row">
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study ID <sup><i class="fa fa-asterisk imp"></i></sup></label>
                        <input type="text" class="form-control" v-model="values.studyRef">
                        <span class="errormsg" v-if="errors[1] == false">Please enter a study ID</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study Name <sup><i class="fa fa-asterisk imp"></i></sup></label>
                        <input type="text" class="form-control" v-model="values.studyName">
                        <span class="errormsg" v-if="errors[0] == false">Please enter a study name</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Sponsor </label>
                        <input type="email" class="form-control" v-model="values.sponsor">
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Status </label>
                        <select class="form-select" name="account" v-model="values.status">
                            <option>Archived</option>
                            <option>Closed</option>
                            <option>New</option>
                            <option>Open</option>
                        </select>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Study Notes </label>
                        <input type="test" class="form-control" v-model="values.studyNotes">

                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Pre-visit </label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.preVisit"
                            @change.prevent="numberonlyvalidation('preVisit')">
                        <span class="errormsg" v-if="numerrors['preVisit'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Post-visit </label>
                        <input type="text" class="form-control" maxlength="3" v-model="values.postVisit"
                            @change.prevent="numberonlyvalidation('postVisit')">
                        <span class="errormsg" v-if="numerrors['postVisit'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Number of Site </label>
                        <input type="text" class="form-control" v-model="values.numberOfSite"
                            @change.prevent="numberonlyvalidation('numberOfSite')">
                        <span class="errormsg" v-if="numerrors['numberOfSite'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-4 mb-1">
                        <label class="col-form-label">Number of Subjects </label>
                        <input type="text" class="form-control" v-model="values.numberOfPatient"
                            @change.prevent="numberonlyvalidation('numberOfPatient')">
                        <span class="errormsg" v-if="numerrors['numberOfPatient'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-6 mb-1">
                        <label class="col-form-label">Duration </label>
                        <input type="text" class="form-control" v-model="values.duration"
                            @change.prevent="numberonlyvalidation('duration')">
                        <span class="errormsg" v-if="numerrors['duration'] == false">Please enter a number</span>
                    </div>
                    <div class="col-lg-6 mb-1">
                        </div>
                    <div class="col-lg-6 my-3 d-flex ">
                            <div class="i-checks aligncheckbox"><label class="mr-2">Active</label> <input type="checkbox"
                                    v-model="values.active"></div>
                    </div>
                    <div class="col-lg-6 my-3 d-flex justify-content-lg-end">
                        <button class="cancel_btn py-1 px-4 mx-3" type="button"
                            @click.prevent="clear">Clear</button>
                        <button class="save_btn py-1 px-4" type="submit"
                            @click.prevent="addStudy">Save</button>
                    </div>

                </div>
            </div>
        </div>
        </div>
    
</template>
<script src="./EditStudyPopUp.js"></script>
<style scoped>
.study_profile_modal__wrapper {
    position: fixed;
    inset: 0;
    background: rgb(0 0 0 / 18%);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 3000;
}

.study_profile_modal__container {
    position: fixed;
    width: min(50%, 90%);
    border-radius: 4px;
    background: #fff;
    overflow-x: hidden;
}
.modal__title {
    background-color: var(--pop-up-background);
    color: #fff
}
.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
</style>