/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
// import from 'jstree'
export default {
  name: "TreeSiteFrom",
  components: {
  },
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      sites: [],
      patientLists: [],
      visitobj: {},
      selectedId: "",
      recentsiteId :"",
      recentindex:"",
      patientCheck:false

    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.getsites();
      this.dispatchNull();
    });

    window.addEventListener("patientTransferred", (event) => {
      console.log("patientTransferred == " + event.detail.storage);
      if (event.detail.storage == true) {
        this.selectedId="";
        this.getsites();
        this.getRefreshedPatient();
      }
    });
    this.getsites();
    await this.dispatchNull();
  },
  methods: {
    async dispatchNull() {
      await store.dispatch("setFromSiteName", "");
      await store.dispatch("setToSiteName", "");
      await store.dispatch("setFromSiteId", "");
      await store.dispatch("setToSiteId", "");
      await store.dispatch("setFromPatientName", "");
      await store.dispatch("setFromPatientId", "");
    },

    async getsites() {
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          console.log("Sites check are", this.sites);
          console.log("Current study is", currentstudyid);
          console.log(idtoken);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getpatient(siteId, siteName, patientName, index) {
      this.recentindex = index
      console.log("From Site Name", siteName)
      console.log("From Site Id", siteId)
      await store.dispatch("setFromSiteId", "");
      await store.dispatch("setFromSiteName", "");
      await store.dispatch("setFromPatientName", "");
      await store.dispatch("setFromSiteId", siteId);
      await store.dispatch("setFromSiteName", siteName);
      this.patientLists[index] = [];
      console.log("Site id is", siteId);
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          let data = res.data.results;
          await data.forEach((patientdata) => {
            this.visitobj[patientdata.patientId] = [];
          });
          this.patientLists[index] = data;
          console.log("patient list is", this.visitobj);
        });
    },

    async patientClick(patientId, patientName, siteCode, siteId, index) {
      this.selectedId = patientId
      console.log("from patientId...", patientId);
      console.log("from siteName...", siteCode);
      console.log("From Patient Name", patientName)
      console.log("From Site Id", siteId)
      await store.dispatch("setFromPatientId", "");
      await store.dispatch("setFromSiteName", "");
      await store.dispatch("setFromPatientName", "");
      await store.dispatch("setFromSiteId", siteId);
      await store.dispatch("setFromPatientId", patientId);
      await store.dispatch("setFromSiteName", siteCode);
      await store.dispatch("setFromPatientName", patientName);


    },
    async getRefreshedPatient() {
      console.log("Inside source getRefreshedPatient")
      const idtoken = store.getters.getIdToken;
      this.recentsiteId= store.getters.getFromSiteId;
      this.patientLists[this.recentindex] = [];
      const currentstudyid = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${this.recentsiteId}&Studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          let data = res.data.results;
           data.forEach((patientdata) => {
            this.visitobj[patientdata.patientId] = [];
          });
          this.patientLists[this.recentindex] = data;
          console.log("patient list is", this.visitobj);
          
          store.dispatch("setIsPatientTransfer", false);
          store.dispatch("setFromPatientId", "");

        })
        .catch((err) => {
          console.log("error is", err);
        });
    },
  },
};

