<template>
  <viewForm
  :formsesid="formsesid"
  templatebasedon="study">

  </viewForm>
</template>

<script src="./viewform.js"></script>

