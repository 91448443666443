<template>
  <div class="d-flex flex-column">
    <div ref="chart"></div>
    <div class="legend">
      <div class="legend-item">
        <span class="legend-color enrolled"></span>
        Control
      </div>
      <div class="legend-item">
        <span class="legend-color non-enrolled"></span>
        Experimental
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3';
import axios from "axios";

export default {
  name: 'KaplanMeierCurve',

  data() {
    return {
      sampleData: [],
      baseUrl: process.env.VUE_APP_Service_URL + "/forms-ml",
    };
  },
  async mounted() {
    await this.getmodeldata();
    this.createChart();
  },
  methods: {
    async getmodeldata() {
      await axios.get(
        `${this.baseUrl}/report/getreports?table_name=SurvivalReport`)
        .then((res) => {
          this.sampleData = res.data;
          console.log("sampleData for kaplermeir is", res.data);
        })
        .catch((err) => console.log("error in screen list", err));
    },
    createChart() {
      const data = this.sampleData;
      const margin = { top: 20, right: 20, bottom: 50, left: 70 }; // Adjusted margins
      const width = 900 - margin.left - margin.right;
      const height = 500 - margin.top - margin.bottom;

      const x = d3.scaleLinear().range([0, width]);
      const y = d3.scaleLinear().range([height, 0]);

      const line = d3
        .line()
        .x(d => x(d.time))
        .y(d => y(d.survival));

      const svg = d3
        .select(this.$refs.chart)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      const controlData = data.filter(d => d['Treatment Group'] === 'Control');
      const experimentalData = data.filter(d => d['Treatment Group'] === 'Experimental');

      const kmControl = this.computeKaplanMeier(controlData);
      const kmExperimental = this.computeKaplanMeier(experimentalData);

      x.domain([
        0,
        d3.max([...kmControl, ...kmExperimental], d => d.time),
      ]);
      y.domain([0, 1]);

      svg
        .append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(x));

      svg.append('g').call(d3.axisLeft(y));

      // Add x-axis label
      svg.append("text")
        .attr("class", "x-axis-label")
        .attr("x", width / 2)
        .attr("y", height + margin.bottom - 10) // Position below the x-axis
        .attr("text-anchor", "middle")
        .text("Time to Event (Months)");

      // Add y-axis label
      svg.append("text")
        .attr("class", "y-axis-label")
        .attr("transform", "rotate(-90)")
        .attr("x", -height / 2)
        .attr("y", -margin.left + 20) // Position to the left of the y-axis
        .attr("text-anchor", "middle")
        .text("Survival Probability");

      svg
        .append('path')
        .datum(kmControl)
        .attr('class', 'line line-blue')
        .attr('d', line);

      svg
        .append('path')
        .datum(kmExperimental)
        .attr('class', 'line line-red')
        .attr('d', line);
    },
    computeKaplanMeier(data) {
      let kmData = [];
      let n = data.length;
      let survival = 1;
      data.sort((a, b) => a['Time to Event (Months)'] - b['Time to Event (Months)']);

      data.forEach((d, i) => {
        if (d['Censoring Indicator']) {
          survival *= (n - i) / n;
        }
        kmData.push({ time: d['Time to Event (Months)'], survival });
      });

      return kmData;
    },
  },
};
</script>

<style>
.line {
  fill: none;
  stroke-width: 2px;
}

.line-blue {
  stroke: steelblue;
}

.line-red {
  stroke: red;
}
.legend {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.legend-item {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.legend-color.enrolled {
  background-color: steelblue;
}

.legend-color.non-enrolled {
  background-color: red;
}

.x-axis-label {
  font-size: 14px;
  fill: black;
}

.y-axis-label {
  font-size: 14px;
  fill: black;
}
</style>
