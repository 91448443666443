<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ document.documentName }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="$router.go(-1)"></i>
    </div>
  </div>
  <div class="ibox-content" v-if="isApproved.approvedStatus!='Approved' &&  isApproved.approvedStatus!='Rejected'">
        <div  class="row form-group">
            <div class="col-lg-11">
                <textarea
              class="form-control"
               :placeholder="$t('navigations.comments')"
              v-model="approver.comments"
            ></textarea>
            </div>
            
            <div class="col-lg-1 px-0 mx-0 text-right">
                <a
            href="#"
            class="btn-accept mr-3"
            @click="docApprover('Approved')"
          >
            <img src="../../assets/img/icons/approve.png" alt="Approve document" /><span
              class="pointer"
            >
              Approve
            </span>
          </a>

          <a
            href="#"
            class="btn-reject mr-3"
            @click="docApprover('Rejected')"
          >
            <img src="../../assets/img/icons/reject.png" alt="Reject document" /><span
              class="pointer"
            >
              Reject </span
            ></a
          >
            </div>
            
          </div>
    </div>
    <div v-if="format == 'pdf'" class="pdfembeded mt-2">
      <vue-pdf-embed :source="source" />
    </div>
    <div v-if="office.includes(format)" class="outsideframe mt-2">
      <iframe
        :src="officeurl"
        width="100%"
        height="650px"
        frameborder="0"
      ></iframe>
    </div>
</template>

<script>
import axios from 'axios';
import store from "../../store/index";
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  props: {
    documentId: String,
    versionNumber :String,
  },
  components: {
    VuePdfEmbed,
  },
  data() {
    return {
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
       baseurl: process.env.VUE_APP_Service_URL,
      office: ['xls', 'xlsx', 'doc', 'docx'],
      format: '',
      source: '',
      baseapi: process.env.VUE_APP_Service_URL,
      officeurl: '',
      NewFile:"",
      approver :{
            documentId: "",
            versionNumber: 0,
            status: "",
            comments: ""
      },
      document:"",
      isApproved :"",
  }
  },
  async mounted() {
    this.approver.documentId = this.documentId;
    this.approver.versionNumber = this.versionNumber;
    await this.checkApproveStatus();
    await this.getDocumentById();
    await this.setdoc();
    this.setfilename();
  },
  methods: {
    async getDocumentById(){
         const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/management/document/getdocumentbyid?id=${this.approver.documentId}`,
         {
             headers: {
             Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
             },
        })
        .then((res) => {
            console.log("document is ", res.data);
            this.document = res.data
        })
    },
    async setfilename() {
      await axios
        .get(`${this.fileuploadurl}/getallfiles/` + this.document.documentReferenceId)
        .then((res) => {
          this.NewFile =
            res.data.documentVersions[
              res.data.documentVersions.length - 1
            ].userUploadedFileName;
          console.log("newfileee", this.NewFile);
        });
      this.format = this.NewFile.split(".").pop();
    },
    async setdoc() {
      const docversion = this.document.documentVersions[this.document.documentVersions.length -1].documentVersionId;
      console.log("document version is " , docversion);
      this.source = `${this.fileuploadurl}/file/${this.document.documentReferenceId}?versionid=${docversion}`;
      this.officeurl = `https://view.officeapps.live.com/op/embed.aspx?src=${this.source}`;
      console.log("url" , this.officeurl);
    },
    async docApprover(status){
       var alertmsg="";
      if(status =="Approved")
      {
        alertmsg = "navigations.are you sure you want to approve this document?";
      }
      else{
         alertmsg = "navigations.are you sure you want to reject this document?";
      }
      if (
        confirm(
          this.$t(alertmsg)
        )
      ) {
        this.approver.status = status;
        console.log("status" , status, "version" ,this.approver.versionNumber )
        const idtoken = store.getters.getIdToken;
        await axios.put(`${this.baseurl}/management/document/approver`, this.approver,
        {
                      headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                      },
        })
        .then((res =>{
            console.log("approver result" , res)
            if(status == 'Approved'){
                 alert(this.$t("navigations.document approved"));
            }
            else{
                 alert(this.$t("navigations.document rejected"));
            }
            this.$router.push(`/documentapprovallist`);
        }))
        .catch((err) => {
          console.log(err);
           
        })
      }
    },
    async checkApproveStatus(){
        const idtoken = store.getters.getIdToken;
        await axios.get(`${this.baseurl}/management/document/approvedstatus?documentid=${this.approver.documentId}&VersionNo=${this.approver.versionNumber}`,
         {
                      headers: {
                      Authorization: "Bearer " + idtoken,
                      "Content-Type": "application/json",
                      },
        })
        .then((res) =>{
            console.log("approve status", res.data.approvedStatus);
            this.isApproved = res.data;
        })
        .catch(err => {
             if( err.response.data.messages[0].includes("Email Not Existings")){
                 alert("Email Not Existing")
           }
        });
    }
  },
};
</script>
<style>
.pdfembeded {
  height: 700px;
  overflow: scroll;
}
</style>
