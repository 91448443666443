<template>
    <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ templateName }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <dynamicform
    :templateid="tempId"
    :requiredvalidate="false"
    :requiredsubmit="false"
    :requiredlanguage="false"
    :templateName="templateName"
    :isnewLink="true"
    />
    </div>
</template>
<script src="./view_template_form.js">
    
</script>
