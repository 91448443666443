/* eslint-disable  */
import axios from 'axios';
import store from '@/store';
async function apiReply(baseUrl,id,reply,raisedBy,values,userName,recipients){
  if(values){
    recipients=values
  }
  const idtoken=store.getters.getIdToken;
      await axios.put(
          `${baseUrl}/forms/query/response?api-version=1`,
          {
            "createdUserEmail": "string",
            "createdUserId": "string",
            "createdAt": "2021-11-05T14:59:31.707Z",
            "lastModifiedUserEmail": userName,
            "lastModifiedUserId": "string",
            "lastModifiedAt": "2021-11-05T14:59:31.707Z",
            "queryId":id,
            "messages": [
              {
                "queryMessage": reply,
                "raisedBy": raisedBy,
                "raisedDate": "2021-11-05T14:59:31.707Z",
                "messagesRecipients": [
                  {
                    "recipients": 
                      recipients
                    
                  }
                ]
              }
            ]
          },
          {
              headers: {
                Authorization: "Bearer " + idtoken
              },
          }                 
         )   
        .then((res) => {
            console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });    
}
async function apiClose(baseUrl,userName,id){
    const idtoken=store.getters.getIdToken;
    await axios.put(
        `${baseUrl}/forms/query/close?api-version=1`,{
          "createdUserEmail": "string",
          "createdUserId": "string",
          "createdAt": "2021-11-09T04:49:35.854Z",
          "lastModifiedUserEmail": userName,
          "lastModifiedUserId": "string",
          "lastModifiedAt": "2021-11-11T08:23:07.631Z",
          "queryId": id,
          "queryOpenStatus": "Closed",
          "closeBy": userName
        },
        {
            headers: {
              Authorization: "Bearer " + idtoken
            },
          }
        )   
        .then((response) => {
            console.log(response);
        })
        .catch((err) => {
        console.log(err);
        }); 
} 
export {apiReply,apiClose};