import store from "../../store/index";
import axios from "axios";
export default {
  name: "createstudypopup",
  async mounted() {
    this.getClassfications();
},
  data() {
    return{
        baseurl: process.env.VUE_APP_Service_URL,
        values: {
            accountId: "string",
            folderId: "string",
            studyRef: "",
            studyName: "",
            studyNotes:"",
            sponsor: "",
            studyId :"",
            active: true,
            isDeleted: false,
            studyProperties:""
          },
        validations: [false, false],
        errors: [],
        classficationList:[],
        selectedStudyType: '',
    enrolment: '',
    allocation: '',
    intervention: '',
    masking: '',
    purpose: '',
    title: ''

    }
  },
  methods:{
    generateDescription() {
      this.values.studyProperties = [
        `Study Type: ${this.selectedStudyType}`,
        `Estimated Enrollment: ${this.enrolment}`,
        `Allocation: ${this.allocation}`,
        `Intervention Model: ${this.intervention}`,
        `Masking: ${this.masking}`,
        `Primary Purpose: ${this.purpose}`,
        `Official Title: ${this.title}`
      ];
      console.log("DESCRIPTION",this.values.description)
    },
    selectedStudyTypeOption(classification) {
      // If it's the default classification, list its classifications
      if (classification.classifier === 'Study Design (Allocation)') {
          return classification.classifications.map(option => {
              return `<option>${option}</option>`;
          }).join('');
      }
      if (classification.classifier == "Nature of Interventions or Exposures"){
        return classification.classifications.map(option => {
          return `<option>${option}</option>`;
      }).join('');
      }
      if (classification.classifier == "Allocation"){
        return classification.classifications.map(option => {
          return `<option>${option}</option>`;
      }).join('');
      }
      if (classification.classifier == "Primary Purpose"){
        return classification.classifications.map(option => {
          return `<option>${option}</option>`;
      }).join('');
      }
      if (classification.classifier == "Masking"){
        return classification.classifications.map(option => {
          return `<option>${option}</option>`;
      }).join('');
      }
      return '';
  },
    async closeModal() {
        this.$emit("closeModal")
    },
    async validate() {
        this.values.studyName = this.values.studyName.replace(/ +/g, ' ').trim();
        this.values.sponsor = this.values.sponsor.replace(/ +/g, ' ').trim();

        if(this.values.studyName != ""){
        this.validations[0] = true;
        }
        else{
        this.validations[0] = false;
        }
        if(this.values.studyRef != ""){
        this.validations[1] = true;
        }
        else{
        this.validations[1] = false;
        }
    },
    async save() {
      this.generateDescription();
      console.log("VALUES",this.values)
        await this.validate();
        if (this.validations.includes(false)) {
          this.errors = this.validations;
        }
        else {
          const idtoken = store.getters.getIdToken;
          await axios
          .post(
            `${this.baseurl}/management/study/create?api-version=1.0`,
            this.values,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            alert(this.$t("navigations.study created successfully"))   
            this.$emit("closeModal")  
            this.$emit("studyCreated")
            this.studychange(res.data)
            this.$router.push("/studyProfile")
        })
        .catch((err) => {
          alert(err.response.data.detail);     
        });
      }
      },
      async studychange(val) {
        await store.dispatch("setStudyID", val);
        window.dispatchEvent(
          new CustomEvent("studyIdChanged", {
            detail: {
              storage: store.getters.getStudyIs,
            },
          })
        );
      },
      async getClassfications(){
        axios.get(`${this.baseurl}/management/study/getstudyclassifications`)
          .then((res) => {
            this.classficationList = res.data;
            console.log("Form list",res.data)
          })
          .catch(err => console.log("error in get study", err));
          
      }
  }
}