/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
export default {
    name:"retireFormPopUp",
    props:{
        sourceid:{
            type:String,
            default:""
        },
        version:{
            type:String,
            default:""
        }
    },
    data() {
        return {
            baseurl: process.env.VUE_APP_Service_URL,
            idtoken:"",
            versionData:[],
            temparray:[]
        }
    },
    mounted() {
        document.body.style.overflowY="hidden"
        this.idtoken=store.getters.getIdToken
        this.getversionhistory(this.sourceid)
    },
    unmounted() {
        document.body.style.overflowY="auto"
    },
    methods:{

        selectarray(version){
            const index = this.temparray.indexOf(version.templateId);
            if (index === -1) {
                this.temparray.push(version.templateId);
              } else {
                this.temparray.splice(index, 1);
              }
        },
        filterDate(date) {
            if (date) {
              return moment(date).format("DD-MM-YYYY");
            }
          },
        closeTemplateModal(){
            this.$emit("closeModal")
        },
        async getversionhistory(templateid){
            await axios
                .get(
                `${this.baseurl}/forms/template/getversionhistory?sourceId=${templateid}`,
                {
                    headers: {
                    Authorization: "Bearer " + this.idtoken,
                    "Content-Type": "application/json",
                    }
                }
                )
                .then((res) => {
                this.versionData = res.data;
                this.versionData.forEach(element => {
                    if(element.version==this.version)
                    {
                        this.temparray.push(element.templateId)
                    }
                });
                })
                .catch((err) => {
                console.error(err)
                });
        },
        async retireForm(){
            const idtoken=store.getters.getIdToken;
            if(this.temparray.length!=0)
            {
                if(confirm(this.$t('navigations.are you sure you want to retire forms ?')))
                {
                    await axios
                    .post(
                    `${this.baseurl}/forms/template/retiretemplate?studyId=${store.getters.getStudyIs}`,this.temparray,
                    {
                        headers: {
                        Authorization: "Bearer " + idtoken,
                        "Content-Type": "application/json",
                        }
                    }
                    )
                    .then((res) => {
                        console.log(res.data)
                        alert(this.$t('navigations.form retired successfully'))
                        this.$emit("closeModal")
                    })
                    .catch((err) => {
                        console.error(err)
                    });
                }
            }
            else{
                alert(this.$t('navigations.please choose a form to retire'))
            }
           
        }
    }
}