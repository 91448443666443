
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.visits") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 py-2 px-3">
      <div class="form-group mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="col-12 col-lg-5 my-1 my-lg-0 px-1 pl-lg-0">
            <label class="mb-0"> {{ $t("navigations.visit no") }} </label>
            <div class="form-group mb-0">
              <input
                type="text"
                v-model="searchData.visitNo"
                class="form-control my-lg-0"
              />
            </div>
          </div>
          <div class="col-12 col-lg-5 my-1 my-lg-0 px-1">
            <label class="mb-0"> {{ $t("navigations.visit name") }} </label>
            <div class="form-group mb-0">
              <input
                type="text"
                v-model="searchData.FormName"
                class="form-control my-lg-0"
              />
            </div>
          </div>
          <div class="col-12 col-lg-2 my-1 my-lg-0 align-self-end px-0">
            <span
              type="button"
              class="save_btn w-100"
              @click="searchbtn()"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
      <!-- <h5 class="linkstyle hoverstyle createLink" @click="addvisit(0)"> Add Visit </h5> -->
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="mt-1 float-left">{{ $t("navigations.visit list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <!-- <th>Study Ref No. </th> -->
                <th>
                  {{ $t("navigations.visit no") }}
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitFormName', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.visit name") }}          
                     <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('visitType', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.visit type") }}          
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('templateName', 4)"
                  id="4"
                >
                 
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.template name") }}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th>
                  {{ $t("navigations.version") }}
                </th>
                <th class="sort_block"
                  @click="SortSelected('preVisit', 6)"
                  id="6">
                  <div class="d-flex flex-row justify-content-between">  
                  {{ $t("navigations.pre-visit") }}
                  <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                </th>
                <th class="sort_block"
                  @click="SortSelected('postVisit', 6)"
                  id="6">
                  <div class="d-flex flex-row justify-content-between">
                  {{ $t("navigations.post-visit") }}
                  <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span></div>
                </th>
                <th>
                  {{ $t("navigations.visit period days") }}
                </th>
                <th>
                  {{ $t("navigations.visit period weeks") }}
                </th>
                <th>
                  {{ $t("navigations.visit period months") }}
                </th>
                <th>
                  {{ $t("navigations.visit period year") }}
                </th>
                <th class="text-center">{{ $t("navigations.active") }}</th>
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Visit Assign Template')"
                >
                  {{ $t("navigations.assign template") }}
                </th>
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Visit View')"
                >
                  {{ $t("navigations.view") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="visit in visitList" :key="visit.visitId">
                <td>{{ visit.visitNo }}</td>
                <td>{{ visit.visitFormName }}</td>
                <td>{{ visit.visitType }}</td>
                <td>{{ visit.template_Name }}</td>
                <td>{{ visit.version }}</td>
                <td>{{ visit.preVisit }}</td>
                <td>{{ visit.postVisit }}</td>
                <td>{{ visit.visitDayPeriod }}</td>
                <td>{{ visit.visitWeekPeriod }}</td>
                <td>{{ visit.visitMonthPeriod }}</td>
                <td>{{ visit.visitYearPeriod }}</td>
                <td class="text-center">
                  <div class="i-checks">
                    <input
                      type="checkbox"
                      :checked="visit.active == true ? true : false"
                      name="status"
                      onclick="return false"
                    />
                  </div>
                </td>
                <!-- <td><a class="editicon" @click="addvisit(visit.visitId)"><i class="fa fa-edit edit-delete"></i></a></td>
                <td><a class="editicon" @click="deleted(visit.visitId)"><i class="fa fa-trash edit-delete"></i></a></td> -->
                <td
                  v-if="roleprivileges.includes('Visit Assign Template')"
                  class="text-center"
                >
                  <i
                    class="fa fa-external-link-square edit-delete"
                    @click.prevent="openModal(visit.visitId)"
                  ></i>
                </td>
                <td
                  v-if="
                    roleprivileges.includes('Visit View') &&
                    visit.template_Name
                  "
                  class="text-center"
                >
                  <i
                    class="fa fa-eye edit-delete"
                    @click.prevent="viewform(visit.template_ID)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPageNumber"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->
  <div class="modal-wrapper">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.assign template") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="d-flex flex-column flex-lg-row my-2 mb-lg-0">
              <div class="col-lg-12 my-2">
                <select
                  class="form-control mx-lg-2 my-2 my-lg-0"
                  placeholder="Search by Status"
                  v-model="selectedmodeltemplate"
                >
                  <option value="" disabled selected>
                    {{ $t("navigations.select template") }}
                  </option>
                  <option
                    v-for="templates in templatelist"
                    :key="templates.templateId"
                    :value="templates"
                  >
                    {{ templates.templateName }} ( {{ templates.version }})
                  </option>
                </select>
              </div>
            </div>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary px-5 mx-1"
                  @click.prevent="assignFun()"
                >
                  {{ $t("navigations.assign") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- modal ends -->
</template>
<script src="./visitComponent.js"></script>
<style scoped>
@import "./modal.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.form-group {
  margin-bottom: 0rem;
}
/*pagination styles*/
.pagination {
  margin: 0;
}
.Page,
.PaginationControl {
  border: 1px solid #e7eaec;
}
.Page-active {
  padding: 1rem;
  color: #ffffff;
  border: 1px solid var(--pagination-active-clr);
  border-radius: inherit;
  margin: 0;
}
.Page {
  padding: 1rem;
  border-radius: inherit;
  margin: 0;
}
.PaginationControl {
  padding: 0.42rem;
}
.wrapper-content {
  position: relative;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.modelbody {
  overflow: unset !important;
}
/* .titleclass{
background-color:#6198fc;
}
.titlestyle {
    background-color: #6198fc;
} */
.modelbody {
  background: #fff;
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}

.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}

.headingunderline {
  text-decoration-line: underline;
}
</style>