/* eslint-disable */
// import config from "../../../config";
import baseinput from "./form_elements/baseinput";
import baseselect from "./form_elements/baseselect";
import basedate from "./form_elements/basedate";
import basecheckbox from "./form_elements/basecheckbox";
import baseradiogroup from "./form_elements/baseradiogroup"
import axios from "axios";
import moment from "moment";
import store from "../../store/index";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import QRCode from 'qrcode'
export default {
  name: "showform",
  components: {
    baseinput,
    baseselect,
    basecheckbox,
    baseradiogroup,
    basedate,
    VPagination
  },
  props:{
      formsesid:{
          type: String,
          default: ''
      },
      templatebasedon:{
        type: String,
        default: ''
    }
    },
  async beforeMount() {
    await this.setformid();
  },
  async mounted(){
    this.idtoken=store.getters.getIdToken
    this.getpreviouspage();
    console.log("formid issssssssssssssssss" + this.formsid)
    localStorage.setItem("isFormTemplateRoute","false");
    this.Disablemenu();
  },
  data() {
    return { idtoken:"",
    gridoptarray:{},
      gridlinkeddatas:{},
      griddata: [
        {
        Rows:[""],
        Columns:[""],
        Fieldtypes:["text"],
        Fielddata:{},
        Fieldoptions:{}
        }
        ],
      previousPage: "reviewlist",
      checkstatus:"",
      bindcoments:[],
      totalcomments:0,
      comments:"",
      approve:"Reviewed",
      reject:"Rejected",
      defaultdate: "",
      fieldName :"",
      pagesavefieldnames: [],
      showdiv:false,
      modelinputclass: "form-control",
      modelradioclass: "col-md-10 my-1",
      testvar:"",
      groups: {},
      audit: [],
      formsid: "",
      modelelvalidation: "",
      modelcomments: "",
      modelpgno: "",
      modelelement: "",
      modelname: "hello",
      modelvalue: "mvalue",
      modeltype: "mtype",
      receivedData: [],
      selecteduser:store.getters.getEmailIs,
      envvar: process.env.NODE_ENV,
      baseurl: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL,
      saveid: "",
      errormsg: {},
      errorclasslist: {},
      forms: [],
      isVerified:"",
      patientsId:"",
      sitesId:"",
      formName:"",
      pageName:[],
      formHeader:"",
      visits:[],
      pages:[],
      selectedValue:"",
      formdata: {},
      sndData: {
        formId: "string",
        pages: [],
      },
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "datetime-local",
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "time",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      htmlarray: [],
      openQueryCount : 0,
      closeQueryCount : 0,
      Querydetails : "",
      formOpenQueryCount :0,
      formClosedQueryCount :0,
      popUpmessage :"",
      popupResult :"",
      qrcodetxt:{},
      repeatingGroupOrders:[],
    };
  },
  methods: {
    async qrcodefun(fieldname,dataurl){
      // alert("hello qr code");
      try {
        console.log(await QRCode.toDataURL(dataurl));
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err)
      }
          },
    filter(date) {
      if(date){
        return moment(date).format(this.defaultdate)
      }
            console.log("Reverse is")
            console.log(date)
    },
    async onclosed() {
      await this.$router.push(`/${this.closedoption}`);
    },
    async Disablemenu() {
      console.log("Enterrrrrr");
      await store.dispatch("setSideNavbar", false);
    },
    async Enablemenu() {
      await store.dispatch("setNavbar", true);
      await store.dispatch("setSideNavbar", false);
    },
    async dashboard() {
      this.$router.push(`/dashboard`);
    },
    async formdesigner() {
      // alert(this.$router.options.history.state.back);
      this.$router.push(`/formdesigner`);
      // this.$router.go(-1)
      
    },
    async rejectedlist() {
      // alert(this.$router.options.history.state.back);
      this.$router.push(`/reviewlist`);
      // this.$router.go(-1)
      
    },
    async folder() {
      this.$router.push(`/folder`);
    },
    async gotopage() {
       this.$router.go(-1);
      
    },
    async getpreviouspage(){
      let previous = this.$router.options.history.state.back;
      if(previous.split('/')[1] == 'designform'){
        this.previousPage = previous.split('/')[1];
      }
      else if(previous.split('/')[1] == 'rejectedFormList'){
        this.previousPage = previous.split('/')[1];
      }
      
    },
    async getPages(){
      this.pages=[]
        this.pageName.filter((item) => {
          if(item)
          {
            this.pages.push(item)
          }
      });
    console.log("Page names are",this.pages);
    },


    async setformid(){
      // this.formsid = this.formsesid.trim();
      this.formsid = this.formsesid;
      if(this.templatebasedon == "general"){
        this.getformtemplategeneral(this.formsesid);
      }
      else{
      this.getformtemplate(this.formsesid);
      }
    },
    async testvarfun(field, value, pgn){
      if(this.isfieldvalidate){
        console.log(this.isfieldvalidate);
        this.openModal(field, value, pgn);
      }
    },
    getformtemplategeneral: async function(tempid) {
      console.log("id token is" + store.getters.getIdToken);
      let idtoken = store.getters.getIdToken;
      console.log("inside getformtemplate function ");
       await axios.get(`${this.baseurl}/forms/formslibrary/getbyid?id=${tempid}`,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.checkstatus = res.data.status;
        this.bindcoments = res.data.reviewData;
        
        this.bindlastcomment();
        this.htmlarray[0] = res.data;
      
        console.log("Template",this.htmlarray[0]);
      })
      .catch((err) => {
        console.log(err);
      });

      this.bindData();
    },
    getformtemplate: async function(tempid) {
      console.log("id token is" + store.getters.getIdToken);
      let idtoken = store.getters.getIdToken;
      console.log("inside getformtemplate function ");
       await axios.get(`${this.baseurl}/forms/templatedesign/getformtemplatedetails?id=${tempid}`,
      // await axios.get('https://eidsa.dev.datamatica.uk/v1/forms/templatedesign/getformtemplatedetails?id=5c8ba15f-67da-4b88-883f-50e9ec29c445',
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.checkstatus = res.data.status;
        this.bindcoments = res.data.reviewData;
        
        this.bindlastcomment();
        this.htmlarray[0] = res.data;
        this.orderRepeatingGroup();
      
        console.log("Template",this.htmlarray[0]);
      })
      .catch((err) => {
        console.log(err);
      });

      this.bindData();
    },

    async bindlastcomment(){
      this.totalcomments = this.bindcoments ? this.bindcoments.length : 0 ;
      this.comments = (this.bindcoments ? this.bindcoments[this.totalcomments-1].comments : "");
      console.log("LAST comnts ",this.comments);
    },

    async updatestatus(reviewstatus,formsesid){

      if(reviewstatus == 'Reviewed')
      { 
        this.popUpmessage = " Are you sure you want to approve this form?";
        this.popupResult = "Form has been approved"
      }
      else{
          this.popUpmessage = "Are you sure you want to reject this form?"
          this.popupResult = "Form has been rejected"
      }
      console.log("reviewstatus IS",reviewstatus)
      if (confirm(this.popUpmessage)) {
      await axios
      .put(
        `${this.baseurl}/template/formtemplate/addreviewdetails?api-version=1.0`,
       // `https://localhost:5001/formtemplate/addreviewdetails?api-version=1.0`,
        {
        "templateId":formsesid,
        "reviewerId": this.selecteduser,
        "status": reviewstatus,
        "reviewData": [
          {
            "comments": this.comments,
            "reviewStatus": reviewstatus,
            "reviewerId":this.selecteduser
          }
        ] 
      },
        
      {
        headers: {
          Authorization: "Bearer " + this.idtoken,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        alert(this.popupResult);
        this.reviewlist();
      })
      .catch((err) => {
        console.log(err);
      });
    } else {
      console.log("else");
    }
    },
    orderRepeatingGroup() {
      this.htmlarray[0].pages.forEach((page, pageIndex) => {
        this.repeatingGroupOrders[pageIndex] = [];
          page.field.forEach((newField, fIndex) => {
              let grpOrder =  { "feild" : fIndex, "groupOrders" : [] }
              this.repeatingGroupOrders[pageIndex].push(grpOrder)
              if (newField.type == 'repeatedmeasure' || newField.type == 'RepeatingMeasure' || newField.type == 'repeatingfield') {
                  if (newField.repeatingFields && newField.repeatingFields.length) {
                      newField.repeatingFields.forEach((newRepField)=> {
                          if(!this.repeatingGroupOrders[pageIndex][fIndex].groupOrders.includes(newRepField.groupOrderNo)){
                            this.repeatingGroupOrders[pageIndex][fIndex].groupOrders.push(newRepField.groupOrderNo);
                          }
                      });
                  }
              }
          });
      });
      console.log("RepeatingGroupOrders", this.repeatingGroupOrders);
    },
    isRequired: function(value, compid) {
      if (value == "") {
        this.errormsg[compid] = "please enter a value";
        this.isvalid.push("req");
        this.errorclasslist[compid] = "form-control errorlist"
      } else {
        // this.isvalid.pop();
      }
    },
    isnumber: function(value, compid) {
      if (isNaN(value) || value == "") {
        this.errormsg[compid] = "Please enter a number";
        this.isvalid.push("numb");
      } else {
        // this.isvalid.pop();
      }
    },
    isalphaneumeric: function(value, compid) {
      if (value.match(/^[0-9a-z]+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter Alphanumeric";
        this.isvalid.push("alph");
      }
    },
    lengthrange: function(value, compid, minleng, maxleng) {
      if (value.length >= minleng && value.length <= maxleng) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Number of characters should be between " + minleng + " and " + maxleng ;
        this.isvalid.push("minlength");
      }
    },
    valuerange: function(value, compid, minval, maxval) {
      if(/^\d+$/.test(value)){
      //let intval = Integer.parseInt(value);
      let intval = parseInt(value);
      if (intval <= maxval && intval >= minval) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Please enter a value between " + minval + " and "+ maxval;
        this.isvalid.push("maxval");
      }
    }
    else {
      this.errormsg[compid] = "Please enter a value between " + minval + " and "+ maxval;
      this.isvalid.push("maxval");
    }
    },
    isemail: function(value, compid) {
      if (value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
        // this.isvalid.pop();
      } else {
        this.errormsg[compid] = "Invalid email address!";
        this.isvalid.push("mail");
      }
    },
    async gridoptfun(fieldname,gridoptions){
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
      this.gridlinkeddatas[fieldname] = {};
      console.log(JSON.parse(gridoptions)[0].Fielddata);
      
      this.gridlinkeddatas[fieldname] = JSON.parse(gridoptions)[0].Fielddata;
        },
    bindData: function() {
      this.totalpages = this.htmlarray[0].pages.length;
      this.htmlarray[0].pages.forEach((item) => {
        this.groups[item.pageNo] = [];
        this.formdata[`pageNo${item.pageNo}`] = {};
        item.field.forEach((subitem) => {
          if(subitem.type == 'grid'){
            this.gridoptfun(subitem.fieldName, subitem.options[0])
          }
          if(!this.groups[item.pageNo].includes(parseInt(subitem.groupOrderNo))){
                  this.groups[item.pageNo].push(parseInt(subitem.groupOrderNo));
          }
          this.formdata[`pageNo${item.pageNo}`][subitem.fieldName] = null;
          });
      });
    
    },

    validate: function() {
      this.htmlarray[0].pages.forEach((item) => {
        let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
            item.field.forEach((subitem) => {
              this.errorclasslist[subitem.id] = "form-control";
              if (subitem.validation != "") {
                subitem.validation.forEach((valid) => {
                  if (valid == "Required") {
                    this.isRequired(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "Number" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isnumber(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "Alphanumeric" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isalphaneumeric(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid == "email" && this.formdata[pagenumber][subitem.fieldName] != "") {
                    this.isemail(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName
                    );
                  }
                  if (valid.includes("lengthrange") && this.formdata[pagenumber][subitem.fieldName] != ""){
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(valid);
                    const myArr = matches[1].split(",");
                    let minrange = parseInt(myArr[0]);
                    let maxrange = parseInt(myArr[1]);
                    this.lengthrange(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      minrange,
                      maxrange
                    );

                  }

                  if (valid.includes("valuerange") && this.formdata[pagenumber][subitem.fieldName] != ""){
                    console.log("inside valuerange functiuon");
                    let regExp = /\(([^)]+)\)/;
                    let matches = regExp.exec(valid);
                    const myArr = matches[1].split(",");
                    console.log("myArr is "+ myArr);
                    let minval = parseInt(myArr[0]);
                    let maxval = parseInt(myArr[1]);
                    this.valuerange(
                      this.formdata[pagenumber][subitem.fieldName],
                      subitem.fieldName,
                      minval,
                      maxval
                    );

                  }
                });
              }
            });
        }
      });
    },
// save and edit starts


async resetdata(){
  this.sndData = {
    "formId": "string",
    "pages": []
  }
},

// save and edit ends

    async editedenevt(){
alert(changed);
    },
    onpagechange: function (pageNumber){
      console.log("Selected value is",this.pageNumber)
      console.log("Page no is",this.pageno)
      this.pageno = pageNumber;
    },
    async onpagesave(htmlarray){
      this.htmlarray[0].pages.forEach((item) => {
    
      console.log("HTML array value is",htmlarray)
      this.validate();
      //console.log("HTML array value ERROR is",this.isvalid)
  
      
      this.htmlarray[0].pages.forEach((item) => {
       // let pagenumber = `pageNo${item.pageNo}`;
        if (item.pageNo == this.pageno) {
            item.field.forEach((subitem) => {
          
            if(subitem.fieldName.includes("header"))
            {}else{
              this.pagesavefieldnames.push(subitem.fieldName);
            }
              
            });
            let showalert=false;
            if(this.isvalid.length > 0)
            {
              showalert=true;
              console.log("Show confirm alert on page save")
            }
            this.pagesave(showalert);
          }
        });

      });

     
    },

    async pagesaveapicall(){
   
    console.log(this.pageno);
    console.log(this.formsid);
    console.log("3rd",this.pagesavefieldnames);
    let data = {
      "formId": this.formsid,
      "pageno": this.pageno,
      "fieldnames": this.pagesavefieldnames
        
    }
    await axios.put(`${this.baseurl}/forms/forms/updatefieldsfrompagesave`, data ,
    {
      headers: {
        Authorization: "Bearer " + this.idtoken,
        "Content-Type": "application/json",
      }
    })
        .then((res) => {
          alert("Page validated successfully")
        })
        .catch((err) => console.log(err));
       
    },

    async alertfun(){
      console.log("hello test");
    },
    previousFun: function() {
      if (this.pageno > 1) {
        console.log("previous page");
        this.pageno--;
      }
    },
    incrementcount: function() {
      this.totalpages++;
      console.log("increment" + this.totalpages);
    },
  },
};
