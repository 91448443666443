/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from "axios";
import jwt_decode from "jwt-decode";
import store from "../../store/index";
const idtoken = store.getters.getIdToken;
export default {
  name: "newtemplate",
  props: { formsesid: String },
  beforeMount() {
    this.formsid = this.formsesid;
    this.getformstudy().then(() => {
      this.getstudy();

    });
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      this.getsites();
    });
    if (document.body.classList.contains("wrapper-content")) {
      console.log("If condition")
      document.body.classList.remove("wrapper-content");
    }
  },
  data() {
    return {
      visitname:"",
       isFormFound: "",
       isVerified: false,
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
      baseapi: process.env.VUE_APP_Service_URL,
      selectedstudy: '',
      selstudy: "",
      selectedsite: '',
      selectedsubject: '',
      studies: [],
      sites: [],
      subjects: [],
      audit: [],
      formsid: "",
      receivedData: [],
      envvar: process.env.NODE_ENV,
      saveid: "",
      errormsg: {},
      formdata: {},
      sendData: {
        formId: "string",
        templateId: "test",
        studyId: "test",
        folderId: "test",
        siteId: "test",
        subjectId: "test",
        visitId: "test",
        visitName: "test",
        totalPages: 0,
        version: "test",
        formStatus: "true",
        formName: "string",
        isDeleted: true,
        pages: [],
      },
      isvalid: [],
      bindarray: {
        page1: { siteCode: "test value", subjectNo: "" },
        page2: "",
        page3: "",
      },
      isSchedule: "",
      pageno: 1,
      totalpages: 0,
      uploads: ["photo", "signature", "file"],
      inputElements: [
        "color",
        "date",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "image",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "time",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      htmlarray: [],
    };
  },
  methods: {
    onclosed() {
      this.$router.push(`/displayform`);
    },
    async isScheduled(selectSubject) {
      const idtoken = store.getters.getIdToken;
      if (this.selectedsite != "" && this.selectedstudy != "" && this.selectedsubject != "") {
        await axios
          .get(`${this.baseapi}/management/patient/isschedule?patientId=${selectSubject}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              }
            }).then((res) => {
              this.isSchedule = res.data
              this.mapfunction();
            }).catch((err) => {
              console.log(err)
            })
      }
      else {
        alert(this.$t('navigations.please select site code and subject id'));
      }
    },

    async assignNewForm() {
      let mapformvar = store.getters.getmapformvariables;
      console.log("mapform variables");
      console.log(mapformvar);
      const idtoken = store.getters.getIdToken;
      await axios
        .put(`${this.baseUrl}assignedform`,
          { "VisitName":this.visitname,
            "isFormfound": this.isFormFound,
            "formId": this.formsid,
            "studyId": this.selectedstudy,
            "subjectId": this.selectedsubject,
            "siteId": this.selectedsite,
            "createdUserEmail": mapformvar.createdUserEmail,
            "createdUserId": mapformvar.createdUserId,
            "createdAt": mapformvar.createdAt,
            "lastModifiedUserEmail": mapformvar.lastModifiedUserEmail,
            "lastModifiedUserId": mapformvar.lastModifiedUserId,
            "lastModifiedAt": mapformvar.lastModifiedAt
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
        .then((res) => {
          console.log(res);
          alert(this.$t('navigations.form mapped successfully'));
          this.$router.push(`/displayform`);
        })
        .catch((err) => {
          console.log(err);
        });

    },

    async mapfunction() {
      if (this.isSchedule == true) {
       await this.IsFormFoundInPS();
        if (this.isFormFound != null && this.isFormFound != "")
         {
           var confirmed = confirm(this.$t('navigations.a form is already saved/submitted against this visit for the selected subject, do you want to replace the existing form?'))
          if (confirmed == true) 
          {
           await this.isFormVerified();
            if (this.isVerified == true) {
              alert(this.$t('navigations.verified form cannot be updated'));
              this.$router.push(`/displayform`);
            }
            else {
              this.assignNewForm();
            }
          }
        }
        else
        {
        this.assignNewForm();
      }
    }
      else {
        alert(this.$t('navigations.the visit is not scheduled'));
        this.$router.push(`/displayform`);
      }
    },

    async IsFormFoundInPS() {
      console.log("form visitName",this.visitname);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}isformfound?StudyId=${this.selectedstudy}&SiteId=${this.selectedsite}&PatientId=${this.selectedsubject}&visitName=${this.visitname}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          }).then((res) => {
            this.isFormFound = res.data
            console.log("is form found?", this.isFormFound)
          }).catch((err) => {
            console.log(err)
          })
    },
    async isFormVerified() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseUrl}isformverified?formId=${this.isFormFound}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            }
          }).then((res) => {
            this.isVerified = res.data
            console.log("is form verified?", this.isVerified)
          }).catch((err) => {
            console.log(err)
          })
    },
    async studychange(val) {
      await this.getsites(val);
    },
    async sitechange(val) {
      await this.getsubject(val);
    },
    async getstudy() {
      const idtoken = store.getters.getIdToken;
      const usermail = jwt_decode(idtoken).email;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/study/getallstudy?user=${usermail} `,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.studies = res.data;
          if (this.selstudy != "") {
            this.getsites(this.selstudy);
            this.selectedstudy = this.selstudy;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.sites = res.data;
          console.log("The sites are", this.sites)
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getformstudy() {
      const idtoken = store.getters.getIdToken;
   
      const result = await axios
        .get(
          `${this.baseapi}/forms/forms/getbyid/${this.formsesid}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visitname = res.data.visitName;
          console.log("visit Name",this.visitname)
          if (res.data.studyId != "" && res.data.studyId != null) {
            this.selstudy = res.data.studyId; 
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getsubject(currentsite) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/patient/getallpatient?Siteid=${currentsite}&sortProperty=subjectId`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.subjects = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

  },
};
