/* eslint-disable */

import axios from "axios";
import store from "../../store/index";
export default {
  name: "managePatientModal",
  props: { patientId: String },
  async mounted() {
    console.clear();
    document.body.style.overflowY = "hidden"
    this.values.language = "English"
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.setstudyid();
      this.IsVsitCreated();
      this.getLanguage();
      this.getCountries();
      this.getsites();
    });

    await this.setstudyid();
    await this.IsVsitCreated();
    this.getLanguage();
    this.getCountries();
    this.getsites();
    this.getUsers();

  },
  async unmounted() {
    document.body.style.overflowY = "auto"
  },
  data() {
    return {
      sites: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/patient/`,
      baseapi: process.env.VUE_APP_Service_URL,
      maxLength: 100,
      maxZip: 50,
      countries: [],
      languages: [],
      users: [],
      isSchedule: false,
      createdPatientId: "",
      values: {
        patientId: "",
        siteCode: "",
        siteId: "",
        accountId: "",
        studyId: "",
        subjectId: "",
        enrollmentDate: "",
        status: "",
        patientNotes: "",
        active: true,
        languageCode: "",
        patientNo: "",
        country: "",
        nurseName: "",
        preVisit: 0,
        postVisit: 0,
        city: "",
        zipCode: "",
        language: "",
        isSchedule: false
      },
      showMandatory: false,
      validations: [false, false, true],
      errors: [],
      isVisitCreated: 0,
    };
  },
  methods: {
    handleCheck(e) {
      if (e.target.checked === true) {
        this.showMandatory = true
      }
      else {
        this.showMandatory = false
      }
    },
    closeModal() {
      this.$emit("closeModal")
    },
    async setstudyid() {
      this.values.studyId = store.getters.getStudyIs;
    },
    async IsVsitCreated() {
      const currentstudy = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/visit/listvisit?StudyId=${currentstudy}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.isVisitCreated = res.data.totalCount;
        })
        .catch((err) => console.log("error...", err));
    },
    async saveOrEditPatient() {
      if (this.values.language) {
        this.languages.filter((item) => {
          if (this.values.language == item.language) {
            this.values.languageCode = item.code
          }
        });
      }
      await this.validateIsNull();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        this.savePatient();
      }
    },
    async changeSiteStatus() {
      const idtoken = store.getters.getIdToken;
      if (this.values.isSchedule == true) {
        await axios
          .put(
            `${this.baseapi}/management/site/changesitestatus?siteId=` +
            this.siteId +
            `&status=Enrolled`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        await axios.put(`${this.baseapi}/management/site/changesitestatus?siteId=` + this.values.siteId + `&status=New`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
          .then((res) => { console.log("site status changed successfully...!") })
          .catch((err) => { console.log(err); console.log("error in change site status...!") })
      }
    },
    async getsites() {
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&getAll=false`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.sites = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getLanguage() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/forms/template/languages`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {

          this.languages = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCountries() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/management/patient/getallcountry`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.countries = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getUsers() {
      const valnow = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("get recipients" + valnow);
      await axios
        .get(
          `${this.baseapi}/management/study/getusersinstudy?StudyId=${valnow}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.users = res.data;
          console.log("Users......", this.users);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getPatient(patientId) {
      const idtoken = store.getters.getIdToken;
      console.log("in getPatient");
      await axios
        .get(this.baseUrl + "getpatientbyid/" + patientId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.isSchedule = ""
          this.values = res.data
          console.log("the values are", this.values)
          this.isSchedule = this.values.isSchedule
          if (this.values.country) {
            console.log("Country code is", this.values.country)
            this.countries.filter((item) => {
              if (this.values.country == item.code) {
                this.values.country = item.name
              }
            });
          }
        })
        .catch((err) => console.log(err));
      console.log("value.data", this.values);
    },
    async editPatient() {
      const idtoken = store.getters.getIdToken;
      this.values.patientId = this.patientId;
      await axios
        .put(this.baseUrl + "updatepatient", this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.changeSiteStatus();
          alert(this.$t('navigations.subject updated successfully'));
          this.$router.push(`/patientList`);
        })
        .catch((err) => {
          console.log("update err reponse: ", err);
          if (err.response.data.detail[0].includes("Subject ID already exists.")) {
            alert(this.$t('navigations.subject id already exists'));
          }
        });
    },
    async savePatient() {
      const idtoken = store.getters.getIdToken;
      console.log("save patient value.....!", this.values);
      await axios
        .post(this.baseUrl + "createpatient", this.values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.createdPatientId = res.data
          if (this.values.isSchedule == true) {
            this.postSchedule(this.createdPatientId)
          }

          this.changeSiteStatus();
          alert(this.$t('navigations.subject added successfully'));
          this.$emit("closeModal");
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            if (err.response && err.response.data.detail == "Subject ID already exists.") {
              alert(this.$t('navigations.subject id already exists'));
            }
          }
        });

    },
    async postSchedule(patientId) {
      const idtoken = store.getters.getIdToken;
      const valnow = store.getters.getStudyIs;
      axios
        .post(
          `${this.baseapi}/management/patientschedule/schedule`,
          {
            studyId: valnow,
            patientId: patientId,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
            },
          }
        )

        .then((res) => {
          this.changeSiteStatus();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearForm() {
      console.log("form cleared");
      this.values.country = "";
      this.values.nurseName = "";
      this.values.city = "";
      this.values.zipCode = "";
      this.values.patientNotes = "";
      this.values.active = "";
      if (this.patientId == 0 || this.patientId == "0") {
        this.values.siteId = "";
        this.values.subjectId = "";
        this.values.enrollmentDate = "";
        this.values.postVisit = "";
        this.values.preVisit = "";
        this.values.isSchedule = false;
      }
    },
    async onclosed() {
      this.$router.push(`/patientList`);
    },
    async validateIsNull() {
      this.values.subjectId = this.values.subjectId.replace(/ +/g, ' ').trim();
      if (this.values.siteId != "") {
        this.validations[0] = true;
      }
      if (this.values.subjectId != "") {
        this.validations[1] = true;
      }
      if (this.values.subjectId == "") {
        this.validations[1] = false;
      }
      if (this.values.enrollmentDate != "") {
        this.validations[2] = true;
      }
      if (this.values.enrollmentDate == "" && this.showMandatory === true) {
        this.validations[2] = false;
      }

    },
    async numberonlyvalidation(fieldname) {
      let isNumber = true;
      this.values[fieldname] = this.values[fieldname].replace(/\s/g, '');
      let fieldval = this.values[fieldname];
      console.log("valtttttt is", fieldval);
      if (fieldval % 1 != 0) {
        isNumber = false;
      }
      console.log(this.values[fieldname]);
      console.log(isNumber);
      if (!isNumber) {
        this.values[fieldname] = 0;
      }
      else {
        if (parseInt(this.values[fieldname]) >= 0) {
        }
        else {
          this.values[fieldname] = 0;
        }
      }
    },
  },

};

