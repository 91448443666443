/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import TreeSiteFrom from "../TreeSiteFrom/TreeSiteFrom.vue";
import TreeSiteTo from "../TreeSiteTo/TreeSiteTo.vue";
// import from 'jstree'
export default {
  components: {
    TreeSiteFrom,
    TreeSiteTo
  }, 
  data() {
    return {
      currentStudyId: "",
      baseapi: process.env.VUE_APP_Service_URL,

      sorceSiteName: "",
      targetSiteName: "",
      sorcePatientName: "",
      sourcePatientId: "",
      values:
      {
        patientId: "",
        sourceSiteId: "",
        targetSiteId: "",
      }
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.dispatchNull();
    });
    await this.dispatchNull();
  },

  computed: {
    displayFromSite() {
      return store.getters.getFromSiteName;
    },
    displayFromPatient() {
      return store.getters.getFromPatientName;
    },
    displayToSite() {
      return store.getters.getToSiteName;
    },
  },
  methods: {
    async dispatchNull() {
      await store.dispatch("setIsPatientTransfer", false);
      await store.dispatch("setFromSiteName", "");
      await store.dispatch("setToSiteName", "");
      await store.dispatch("setFromPatientId", "");
      await store.dispatch("setFromPatientName", "");
    },

    async transferPatient() {
      if (
        confirm(
          this.$t("navigations.are you sure you want to transfer this subject to the selected target site")
        )
      )
      {
      const idtoken = store.getters.getIdToken;
      console.log(idtoken + "idtoken")
      await axios
        .put(
          `${this.baseapi}/management/patient/transferpatient?patientId=${this.values.patientId}&sourceSiteId=${this.values.sourceSiteId}&targetSiteId=${this.values.targetSiteId}`, {},
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("transfer result", res.data)
          if (res.data == true) {
            alert(this.$t("navigations.subject has been transferred successfully"))
            this.RefreshTree();
            store.dispatch("setFromSiteName", "");
            store.dispatch("setToSiteName", "");
            store.dispatch("setFromPatientId", "");
            store.dispatch("setFromPatientName", "");

          }
          else if (res.data == false) {
            alert(this.$t('navigations.subject with same subject id already exists in the target site'))
          }
          else {
            alert(this.$t("navigations.something went wrong"))
          }
        })
        .catch((err) => {
          console.log("err msg", err)
        });
      }
    },

    async RefreshTree() {
      await store.dispatch("setIsPatientTransfer", true);
      window.dispatchEvent(
        new CustomEvent("patientTransferred", {
          detail: {
            storage: store.getters.getIsPatientTransfer,
          },
        })
      );
    },

    async transferButton() {
      this.sorceSiteName = store.getters.getFromSiteName;
      this.targetSiteName = store.getters.getToSiteName;
      this.sorcePatientName = store.getters.getFromPatientName;
      this.sourcePatientId = store.getters.getFromPatientId;

      if (this.sorceSiteName == "" || this.sorceSiteName == null) {
        alert(this.$t("navigations.please select a source site to transfer"))
        return;
      }
      if (this.sorcePatientName == "" || this.sorcePatientName == null) {
        alert(this.$t('navigations.please select a patient to transfer'))
        return;
      }
      if (this.targetSiteName == "" || this.targetSiteName == null) {
        alert(this.$t('navigations.please select a target site to transfer'))
        return;
      }
      else {
        this.values.patientId = store.getters.getFromPatientId;
        this.values.sourceSiteId = store.getters.getFromSiteId;
        this.values.targetSiteId = store.getters.getToSiteId;
        this.transferPatient();
      }
    },
  },
};

