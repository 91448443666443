<template>
    <label :class="{'cursor-pointer':  sourceValue===true}">{{label}}</label>
<input
:disabled="fromarchive == 'true'"
class="baseinput mainbgchange"
:class="{'cursor-pointer':  sourceValue===true}"
:checked="modelValue"
@change="$emit('update:modelValue',$event.target.checked)" 
type="checkbox"

/>
</template>

<script>

export default {
props:{
    sourceValue:{
        type: Boolean,
        default: false
    },
    label:{
        type: String,
        default: ''
    },
    modelValue:{
        type: Boolean,
        default: false
    },
     fromarchive: {
        type: String,
        default: 'false'
      }
}
}
</script>
