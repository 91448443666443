import store from "../../store/index";
import axios from "axios";
export default {
  name: "Forms_AI_PopUp",
  async mounted() {
    this.getStudyDescription();
},
  data() {
    return{
      baseurl: process.env.VUE_APP_Service_URL,
        values: {
          modelName:"StudyDIsName",
          description:"",
          threshold: 0.7
          },
        validations: [false],
        errors: []
    }
  },
  methods:{
    async closeModal() {
        this.$emit("closeModal")
    },
    
      async studychange(val) {
        await store.dispatch("setStudyID", val);
        window.dispatchEvent(
          new CustomEvent("studyIdChanged", {
            detail: {
              storage: store.getters.getStudyIs,
            },
          })
        );
      },
      async formGeneration() {
      this.validate();
      },
      async validate(){
        if(this.values.description == null||this.values.description =="" )
        {
          alert("Please fill the mandatory fields.")
        }
        else if(this.values.threshold == null||this.values.threshold =="" )
        {
          alert("Please fill the mandatory fields.")
        }
        else{
           this.FromStudy.push(this.values.description)
          axios.get(`${this.baseurl}/forms-ml/mlmodel/getvalues?model_name=${this.values.modelName}&data=${ this.FromStudy}&threshold=${this.values.threshold}`)
          .then((res) => {
            this.formList = res.data;
           
            console.log("Arrays extracted from studyProperties and stored in FromStudy:", this.FromStudy);
            console.log("Form list",res.data)
            this.closeModal();
            this.$emit("updateList",this.formList)
            this.$router.push({
              path: '/FormSuggestions',
              query: {
                formList: encodeURIComponent(JSON.stringify(this.formList))
              }
            });
          })
          .catch(err => console.log("error in get study", err));
          await store.dispatch("setformList", JSON.stringify(this.formList))
        }
      },
      async getStudyDescription() {
        const studyId = store.getters.getStudyIs;
        this.idToken = store.getters.getIdToken;
        
        try {
          const response = await axios.get(`${this.baseurl}/management/study/getbyid/${studyId}`, { 
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          });
          
          this.StudyDescription = response.data;
          console.log("StudyDescription", this.StudyDescription);
          
          if (Array.isArray(this.StudyDescription.studyProperties)) {
            this.FromStudy = this.StudyDescription.studyProperties;
         
          } else {
            console.log("No arrays found in studyProperties");
            this.FromStudy = [];
          }
        } catch (error) {
          console.log("Error in getting study:", error);
        }
      }
      
  }
}