
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// import jwtInterceptor from "../Shared/jwtInterceptor";
// import axios from "axios";
export default {
  name: "viewTemplate",
  props: {
    isPatientVisit: Boolean,
    template: String,
    study: String,
    visitid: String
  },
  async beforeMount() {
    await this.setTempId();
  },
  data() {
    return {
      envvar: process.env.NODE_ENV,
      templateId: "",
      stdyid: "",
      visitids: "",
      saveactive: true,
      ispatientvalue: false,
    };
  },
  methods: {
    async setTempId() {
      this.templateId = this.template;
      this.stdyid = this.study;
      this.visitids = this.visitid;
      this.ispatientvalue = this.isPatientVisit
    },
    onclosed() {
      // this.$router.push(`/visitforms`);
      this.$router.go(-1);
    }

  },
};