/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import "/src/assets/css/animate.css";
import "/src/assets/css/style.css";
import jwt_decode from "jwt-decode";
import Chart from "chart.js";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { useI18n } from 'vue-i18n';
const idtoken = store.getters.getIdToken;
export default {
    name: 'formlisting',
    components: {
        VPagination,
    },
    data() {
        return {
            defaultdate: "",
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
            searchdata: {
                SortOrder:1,
                SiteCode: "",
                PatientId: "",
                FormName: "",
                FormStatus: "",
                Pageindex: 1,
                Pagesize: 10,
                SortProperty: "",
            },
            dataList:[],
            roleprivileges:"",
            sites: "",
            patients: "",
            idToken: "",
            selectedType: "",
            selectedId: "",
            statusCheck: "",
            searchItem: "",
            currentPageSize: 0,
            currentPageNumber: 1,
            enableNext: true,
            enablePrevious: true,
            totalLists: 0,
            totalPages: 0,
            lists: [],
            filteredListing: [],

            //folder structure
            siteList :[],
            patientLists:[],
            visitobj: {},

            //form Chart 
            formchart: {
				charttype: "pie",
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						toolbar: {
							show: false,
						},
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories:[this.$t('navigations.new') ,this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					},
				},
				series: [
					{
						name: 'forms',
						data: [0,0,0,0],
					},
				],
				dataseries: [this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
				piechartOptions: {					
					chart: {
						customScale: 0.8,
						width: "100%",
						type: "pie",
					},
					labels:[this.$t('navigations.new'),this.$t('navigations.active'),this.$t('navigations.verified'),this.$t('navigations.unverified')],
					responsive: [
						{
							breakpoint: 480,
							options: {
								pie: {
									size: 200,
								},
								chart: {
									toolbar: {
										show: false,
									},
									width: 200,
								},
								legend: {
									position: "bottom",
								},
							},
						},
					],
				},
				piedataseries: [2, 3, 2, 1],
			},
            activeformcount: "",
            siteId :"",
            sndcharts : "",
            options: {
				scales: {
					y: {
						beginAtZero: true,
					},
				},
			},
        }
    },
    async mounted() {
        this.defaultdate = localStorage.getItem("dateformat");
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.idToken = store.getters.getIdToken;
            this.roleprivileges =  store.getters.getRolesprivilegeData;
            this.getpatients();
            this.getsites();
            this.searchData();
            this.filter();
            this.formchartdata(this.siteId);
          });
        this.idToken = store.getters.getIdToken;
        this.roleprivileges =  store.getters.getRolesprivilegeData;
        console.log("Privileges are ",this.roleprivileges);
        this.getpatients();
        this.getsites();
        this.searchData();
        this.filter();
        this.formchartdata(this.siteId);
        const snd = document.getElementById("lineChart");
        this.sndcharts = new Chart(snd, {
			type: "pie",
			data: this.sendData,
			options: this.options,
		});
		this.sndcharts;
    },
    methods: {
        filter(data) {
            if (data) {
                return moment(data).format(this.defaultdate)
            }
        }, 
        SortSelected(sortProp,id){
            console.log('SortPropChanged');
            const selectedElement=document.getElementById(id)
            const selectedElementID=document.getElementById(id).id
            if(sortProp!=null && id==selectedElementID)
            {
              this.sortDesc=!this.sortDesc
              if(this.searchdata.SortOrder == 0)
              {
                // if(selectedElement.classList.contains("sortDesc")){
                //   return
                // }
                 if(selectedElement.classList.contains("sortAsc"))
                {
                  selectedElement.classList.remove("sortAsc")
                  selectedElement.classList.add("sortDesc")
                }    
                else selectedElement.classList.add("sortDesc")
              }
              else
              {
                if(selectedElement.classList.contains("sortDesc")){
                  selectedElement.classList.remove("sortDesc")
                  selectedElement.classList.add("sortAsc")
                }
                // else if(selectedElement.classList.contains("sortAsc"))
                // {
                //   return
                // }          
                else selectedElement.classList.add("sortAsc")
              }
            }
            this.searchdata.Pageindex = 1;
            this.currentPageNumber = 1;
            this.searchdata.SortProperty=sortProp;
            this.searchdata.SortOrder = this.searchdata.SortOrder==0?1:0;
            this.searchData();
          },
        async viewforms(id) {
            store.dispatch("setFieldName"," ");
            const archive = false;
            this.$router.push(`/showform/forms/${id}/${archive}`);
            //this.$router.push(`/showform/forms/${id}`);
        },
        async getPrevious() {
            if (this.searchdata.Pageindex > 1) {
                console.log("previous page");
                this.searchdata.Pageindex = this.searchdata.Pageindex - 1;
                this.searchData();
            }
        },
        async getNext() {
            if (this.searchdata.Pageindex < this.totalLists) {
                this.searchdata.Pageindex = this.searchdata.Pageindex + 1;
                this.searchData();

            }
        },
        async searchData() {
           if(this.searchdata.SiteCode=="All") 
           {
            this.searchdata.SiteCode=""
           }    
           if(this.searchdata.PatientId=="All"){
            this.searchdata.PatientId=""
           }    
           if(this.searchdata.FormStatus=="All"){
             this.searchdata.FormStatus=""
            }
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios
                .get(this.baseUrl +
                    "formlist?FormName=" + this.searchdata.FormName +
                    "&FormStatus=" + this.searchdata.FormStatus +
                    "&SiteCode=" + this.searchdata.SiteCode +
                    "&StudyId=" + stdid +
                    "&PatientId=" + this.searchdata.PatientId +
                    "&pageindex=" + this.searchdata.Pageindex +
                    "&pagesize=" + this.searchdata.Pagesize +
                    "&SortProperty=" + this.searchdata.SortProperty + "&SortOrder=" + this.searchdata.SortOrder,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("search data", response);
                    if (response.data) {
                        this.totalLists = Math.ceil(response.data.totalCount / this.searchdata.Pagesize);
                        console.log(this.totalLists, " total list");
                        this.dataList =response.data.results;
                        //this.filteredListing = response.data.results;
                    } else {
                        console.log("There are currently no list");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },
        async getpatients() {
            this.searchdata.PatientId ="";
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            console.log(this.searchdata.SiteCode,currentstudy );
            await axios
                .get(
                    `${this.baseapi}/management/patient/listpatient?SiteId=${this.searchdata.SiteCode}&Studyid=${currentstudy}&SortProperty=subjectId`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",

                        },
                    }
                )
                .then((res) => {
                    console.log(" get patients", res);
                    this.patients = res.data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getsites() {
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            await axios
                .get(
                    `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&getAll=false`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log("sites", res);
                    this.sites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //filtering list
        filteredLists() {
            this.filteredListing = []
            if ((this.searchItem) || (this.selectedType) || (this.selectedId) || (this.statusCheck)) {
                this.lists.filter((list) => {
                    if ((this.searchItem == list.formName) || (this.selectedType == list.siteId) || (this.selectedId == list.subjectId) || (this.statusCheck == list.formStatus)) {
                        //push the filtered list to new array                     
                        this.filteredListing.push({
                            visitName: list.visitName,
                            siteId: list.siteId,
                            subjectId: list.subjectId,
                            formName: list.formName,
                            formStatus: list.formStatus
                        })
                        // console.log(this.filteredListing) 
                        return (this.filteredListing)
                    }
                });

            }
        },
        async loadPage(page) {
            this.currentPageNumber = page;
            this.searchdata.Pageindex = page;
            this.searchData();
        },

        // folder structure
         async getsites(){
            const currentstudyid = store.getters.getStudyIs;
            const idtoken = store.getters.getIdToken;  
            await axios
                      .get(
                        `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
                          {
                              headers: {
                                  Authorization: "Bearer " + idtoken,
                                  "Content-Type": "application/json",
                              },
                          }
                      )
              .then((res) => {
                this.siteList = res.data;
                console.log("Sites are",this.siteList)
              })
              .catch((err) => {
                console.log(err);
              });
          },
          async getpatient(siteId,index) {
              console.log("get patient function");
            this.patientLists[index]=[];
            console.log("Site id is",siteId)
            const idtoken = store.getters.getIdToken;
            const currentstudyid = store.getters.getStudyIs;
            await axios
              .get(
                `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}&SortProperty=subjectId`,
                {
                  headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                  },
                }
              )
              .then(async (res) => {
                  let data = res.data.results;
                await data.forEach(patientdata => {
                    this.visitobj[patientdata.patientId] = [];
                });
                this.patientLists[index] = data;
              });
            //   await this.setvisitobj(this.patientLists[index]);
          },
          async setvisitobj(data){
            console.log("set visit function");
            console.log("patient list is",data);
            data.forEach(patientdata => {
                this.visitobj[patientdata.patientId] = [];
            });
            console.log(this.visitobj);
          },
          async getVisits(patientId,index)
          { 
            console.log("get visit function");
            this.visitobj[patientId][index] = [];
            const idtoken = store.getters.getIdToken;
            console.log("patient ID is",patientId)
            
            await axios
            .get(
              `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}`,
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
                this.visitobj[patientId][index] = res.data.data;
                 console.log("The visit list is",this.visitobj)
            })
            .catch((err) =>{
                console.log("error is" , err);
            })
            this.displayTable=true
          },
          async gotoform(visits){
              if(visits.visitStatus == 'Completed'){
                    if(visits.formId == null || visits.formId == ""){
                    let tempvar = {
                        "Siteid": visits.siteId,
                        "Subject_id": visits.patientId,
                        "Visitname" : visits.visitName,
                        "Visitno" : visits.visitNo,
                        "sheduleId": visits.sheduleId
                    }
                    await store.dispatch("commitvisitdetails", tempvar);
                    this.$router.push(`/form/${visits.templateId}/${visits.studyId}/ `);
                    // this.$router.push(`/form/${visits.templateId}/${visits.studyId}/${visits.visitid}`);
                    }
                    else{
                   // this.$router.push(`/editForm/submitForm/${visits.formId} `);
                    //this.$router.push(`/showform/forms/${visits.formId}`);
                    const archive = false;
                    this.$router.push(`/showform/forms/${visits.formId}/${archive}`);
                    }
             }
          },
          filterMonth(date) {
            if(date){
              //console.log("dateeeeeeeeee",date)
              return moment(date).format('DD-MMM-YYYY')
            }
          },

          //chart 
        async secondchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchsecondbar(true);
				this.formchart.charttype = "bar";
			} else {
				await this.switchsecondbar(false);
				this.formchart.charttype = val;
			}
		}, 
        async switchsecondbar(res) {
			this.formchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.formchart.charttype = "";
		},
        async formchartdata(siteId) {
			const idtoken = store.getters.getIdToken;
            var currentStudy = store.getters.getStudyIs;
			await axios
				.get(                                
					`${this.baseapi}/forms/forms/formcountinsite?studyID=${currentStudy}&siteID=${siteId}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					console.log(res);
					let formValues=[]
					let formCategories=[]
					for (const [key, value] of Object.entries(res.data)) {
						if(key!='Total')
						{
							formValues.push(res.data[key])
						}
					  }
					
					console.log("forms value is ",formValues);
					console.log("forms data is ",formCategories);
					this.formchart.dataseries = [
						{
							'name':'forms',
							'data': [
								res.data.New == null ? 0 : res.data.New,
								res.data.Active == null ? 0 : res.data.Active,
								res.data.Verified == null ? 0 : res.data.Verified ,
								res.data.Unverified == null ? 0 : res.data.Unverified,
							]
						}
					];
					this.formchart.piedataseries = this.formchart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
				this.formchart.charttype = 'pie';
		},
    },

}