/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "visittemplate",
  components: {
    VPagination,
  },
  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
          // window.location.reload();
        this.displayTable=false
        this.getsites()
        this.subjectID=""
        });
    await this.setroleprivileges(); 
    this.getsites()
    },
  data(){
    return{
      defaultdate: "",
      roleprivileges: "",
      baseapi: process.env.VUE_APP_Service_URL,
      sites:[],
      subjectList:[],
      patients:[],
      templatelist:[],
      selectedTemplate:"",
      selectedScheduleID:"",
      searchedSiteID:"",
      siteCode:"",
      displayTable:false,
      displaySearch:false,
      visitModal:false,
      searchClicked:false,
      subjectID:"",
      siteCode:"",
      searchVisit:"",
      searchVisitNo:"",
      sProp:"",
      IsDesc:true,
      currentPage:1,
      totalPage:0,
      totalCount:1,
      totalItems:0,
      pageindex:0,
      pagesize:10
      
    }
  },
  methods:{
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    async setroleprivileges(){
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async getsites() {
      this.siteCode=""
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
            {
                headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((res) => {
            console.log("sites", res);
            this.sites = res.data;
        })
        .catch((err) => {
            console.log(err);
        });
    },
    async getpatients(siteId) {
      if(siteId=="Select")
      {
        this.siteCode=""
        this.subjectID=""
        this.displayTable=false
        this.displaySearch=false
      }
      this.subjectID=""
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
            `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudy}&SortProperty=subjectId`,
            {
                headers: {
                    Authorization: "Bearer " + idtoken,
                    "Content-Type": "application/json",

                },
            }
        )
        .then((res) => {
            this.patients = res.data.results;
            if(this.patients.length==0){
              alert(this.$t("navigations.no subjects found"))
              this.siteCode=""
            }
        })
        .catch((err) => {
            console.log(err);
        });
    },
    getdata(){
      this.searchClicked=true
      this.subjectList=[];

      this.pageindex=1;
      this.currentPage=1;
        this.getSchedule(this.searchedSiteID)
    },

    SortSelected(sortProp,id){
      console.log('SortPropChanged');
      const selectedElement=document.getElementById(id)
      const selectedElementID=document.getElementById(id).id     
      if(sortProp!=null && id==selectedElementID)
      {        
        if(this.IsDesc == true)
        {
          // if(selectedElement.classList.contains("sortDesc")){
          //   return
          // }
           if(selectedElement.classList.contains("sortAsc"))
          {
            selectedElement.classList.remove("sortAsc")
            selectedElement.classList.add("sortDesc")
          }    
          else selectedElement.classList.add("sortDesc")
        }
        else
        {
          if(selectedElement.classList.contains("sortDesc")){
            selectedElement.classList.remove("sortDesc")
            selectedElement.classList.add("sortAsc")
          }
          // else if(selectedElement.classList.contains("sortAsc"))
          // {
          //   return
          // }          
          else selectedElement.classList.add("sortAsc")
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.sProp=sortProp;
      this.IsDesc = this.IsDesc==true?false:true;
      this.getSchedule(this.searchedSiteID);
    },

    async getSchedule(patientId)
    {  
      if(this.siteCode=="Select")
       {
       patientId=""
      }
      if(this.searchClicked==false && (this.searchVisitNo!="" || this.searchVisit!=""))
      {
        this.searchVisitNo=""
        this.searchVisit=""
      }
      const idtoken = store.getters.getIdToken;
      this.subjectId=""
      this.searchedSiteID=patientId
      console.log("Patient id is",this.searchedSiteID)
      const pIndex=Number(this.pageindex);
      const pSize=Number(this.pagesize);
      if(this.searchVisitNo!="" || this.searchVisit!="" )
      {
        console.log("Visit no is",this.searchVisitNo)
        await axios
          .get(
            `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}&visit=${this.searchVisit}&visitNo=${this.searchVisitNo}&PageNo=${pIndex}&ItemCount=${pSize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`,
            {
              headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",

              },
          }
          )
          .then((res) => {
            this.subjectList = res.data.data;
              this.displayTable=true
              if(this.searchClicked==true){
                this.totalCount = res.data.totalCount;
                this.totalPage = parseInt( this.totalCount / this.pagesize);
                if((this.totalCount%this.pagesize!=0)){
                this.totalPage = this.totalPage+1  } 
                this.searchClicked=false
              }
              else{
                this.searchVisit=""
                this.searchVisitNo=""
                this.totalCount = res.data.totalCount;
                this.totalPage = parseInt( this.totalCount / this.pagesize);
                if((this.totalCount%this.pagesize!=0)){
                this.totalPage = this.totalPage+1 
              }           
            }
          })
          .catch((err) =>{
              console.log("error is" , err);
          })
      }
      else {
        await axios
        .get(
          `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}&PageNo=${pIndex}&ItemCount=${pSize}&sortprop=${this.sProp}&isDes=${this.IsDesc}`,
          {
            headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
            },
        }
        )
        .then((res) => {
            this.subjectList=[]
            this.subjectList = res.data.data;
            console.log("The patient list is",this.subjectList)
            this.totalCount = res.data.totalCount;
            this.totalPage = parseInt( this.totalCount / this.pagesize);
            if((this.totalCount%this.pagesize!=0)){
             this.totalPage = this.totalPage+1
           }
        })
        .catch((err) =>{
            console.log("error is" , err);
        })
        this.displayTable=true
      }      
    },
    async gettemplatedata(){
      const idtoken = store.getters.getIdToken;
      const currentstudy=store.getters.getStudyIs;
      console.log("template data function");
      await axios 
      .get(`${this.baseapi}/forms/template/listtemplate?Status=Approved&StudyID=${currentstudy}&SortProperty=templateName&SortOrder=0`,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {        
        this.templatelist = res.data.results;

      })
      .catch((err) => {
        console.log("error");
        console.log(err);
      });
    },
    async assignTemplate(){
      if(this.selectedTemplate == ""){
        alert(this.$t('navigations.please select a template'));
      }
      else{
         const idtoken = store.getters.getIdToken;
         await axios
           .put(`${this.baseapi}/management/patientschedule/templateupdate?TemplateId=${this.selectedTemplate.templateId}&SheduleID=${this.selectedScheduleID}`,{},
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              if(res.data=="Template Not Found")
              {
                alert(this.$t("navigations.template not found"));
              }
              else alert(this.$t('navigations.template assigned successfully'));
              console.log(res.data);
            })
            .catch((err) => {
              alert(err);
            });
            this.selectedTemplate="";
            this.closemodal();
            this.getSchedule(this.searchedSiteID);
      }
      
    },
    openmodal(selectedSchedule){
      this.selectedScheduleID=selectedSchedule
      this.gettemplatedata()
      this.visitModal=true
      document.body.style.overflowY="hidden"
    },
    closemodal() {
      this.visitModal=false
      document.body.style.overflowY="auto"
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex= page;
      this.getSchedule(this.searchedSiteID);
    },
  }
}