/* eslint-disable */
const state = () => ({
    viewformid :  "",
    viewtemplateid: "",
    newversion:"",
    sourceTemplateId:"",
    newversionformid:"",
    selecteddesignformid:localStorage.getItem('selecteddesignformid'),
    versionhistorynote:"",
    versionid:""

   });
   
   const getters = {
        getselecteddesignformid( state :{selecteddesignformid : any}){
        return state.selecteddesignformid;
        },
       getViewformid( state :{viewformid : any}){
           return state.viewformid;
       },
       getViewtemplateid( state :{viewtemplateid : any}){
            return state.viewtemplateid;
        },
        getnewversion( state :{newversion : any}){
            return state.newversion;
        },
        getversion( state :{versionid : any}){
            return state.versionid;
        },
        getsourceTemplateId( state :{sourceTemplateId : any}){
            return state.sourceTemplateId;
        },
        getnewversionformid( state :{newversionformid : any}){
            return state.newversionformid;
        },
        getversionhistorynote( state :{versionhistorynote : any}){
            return state.versionhistorynote;
        },

   };
   
   const actions ={
    async setselecteddesignformid({commit} : any , data : any){
        commit("setselecteddesignformid" , data);
    },
       async setViewformid({commit} : any , data : any){
           commit("setViewformid" , data);
       },
       async setViewtemplateid({commit} : any , data : any){
        commit("setViewtemplateid" , data);
       },
       async setnewversion({commit} : any , data : any){
        commit("setnewversion" , data);
       },
       async setversion({commit} : any , data : any){
        commit("setversion" , data);
       },
       async setsourceTemplateId({commit} : any , data : any){
        commit("setsourceTemplateId" , data);
       },
       async setnewversionformid({commit} : any , data : any){
        commit("setnewversionformid" , data);
       },
       async setversionhistorynote({commit} : any , data : any){
        commit("setversionhistorynote" , data);
       },

   
   };
   
   const mutations ={
    setselecteddesignformid(state : {selecteddesignformid : any } , selecteddesignformData : any ){
        state.selecteddesignformid = selecteddesignformData;
        localStorage.setItem("selecteddesignformid", selecteddesignformData);
    },
        setViewformid(state : {viewformid : any } , formidData : any ){
           state.viewformid = formidData;
       },
       setViewtemplateid(state : {viewtemplateid : any } , templateidData : any ){
        state.viewtemplateid = templateidData;
       },
       setnewversion(state : {newversion : any } , newtemplateversion : any ){
        state.newversion = newtemplateversion;
       },
       setversion(state : {versionid : any } , templateversion : any ){
        state.versionid = templateversion;
       },
       setsourceTemplateId(state : {sourceTemplateId : any } , newsourcetemplateid : any ){
        state.sourceTemplateId = newsourcetemplateid;
       },
       setnewversionformid(state : {newversionformid : any } , sourcenewversionformid : any ){
        state.newversionformid = sourcenewversionformid;
       },
       setversionhistorynote(state : {versionhistorynote : any } , sourcehistorynote : any ){
        state.versionhistorynote = sourcehistorynote;
       },

   };
   
   export default {
       state,
       getters,
       actions,
       mutations
   }