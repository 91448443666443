<template>
  <div class="form-background">
    
  </div>
</template>

<script src="./signUpComponent.js"></script>
<style>
@import "../../assets/css/style.css";

.social-icons {
  width: 16px;
  height: 16px;
}
.social-icons {
  width: 16px;
  height: 16px;
}
img {
  vertical-align: middle;
  border-style: none;
}
.font-weight-bold {
  font-weight: 700 !important;
}

.btn-block {
  display: block;
  width: 100%;
}
.btncolour {
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-white {
  background-color: white !important;
  margin-bottom: 10px !important;
}
.form-group {
  margin-bottom: 1rem;
}
.small,
small {
  font-size: 80%;
  font-weight: 400;
}
*,
::after,
::before {
  box-sizing: border-box;
}
.form-background {
  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  height: 100vh !important;
width: 100vw !important;
}
</style>
