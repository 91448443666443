/* eslint-disable */

import Pivot from "./Pivot";

export default {
  name: "App",
  components: {
    Pivot,
  },
    methods:{
      onclosed() {
        this.$router.push("./reportdashboard")
      }
    }
};