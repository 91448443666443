/* eslint-disable */
import "/src/assets/style.css";
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import VPagination from "@hennge/vue3-pagination";
import VueResizable from "vue-resizable";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import createFormModal from "../createFormModal/createForm.vue";
import jwt_decode from "jwt-decode";
import QRCode from "qrcode";
import createnewversion from "../newversion/createnewversion.vue";
import createrepeatedfield from "../Create_repeated_field/createrepeatedfield.vue"
export default {
  name: "formdesign",
  components: {
    Multiselect,
    VPagination,
    VueResizable,
    createFormModal,
    QRCode,
    createnewversion,
    createrepeatedfield
  },
  props: { 
    formid: String,
    repfieldstoedit: {
      type: Object,
      default: {}
    },
    popuptype: {
      type: String,
      default: 'save'
    },
    feildNames : {
      type: Array,
      default: []
    },
    templateId : {
      type : String,
      default :""
    }
  },
  data() {
    const tH = 90;
    const tW = "100%";
    return {
      qrData:"",
      repeatingfielddata:[
        {
          fieldName: "",
          label: "",
          repeatingFields: [
             
          ]
      }
      ],
      fieldkey:null,
      repeatedfieldpopup:false,
      editingOption: null,
      rangeoption: [],
      radioorientation: "vertical",
      cyrrentformid: "",
      currentsourceid: "",
      versionhistoryarray: [],
      isformlocked: false,
      replacetemplatevar: false,
      exisitingvisitsvar: false,
      reviewandapprovalvar: false,
      designcompletepopup: false,
      sourcetemplateidcreate: "",
      newversioncreate: "",
      currentformversion: "",
      newversionformid: "",
      creatversionpopup: false,
      repeatoptionss: "",
      calculate: {
        firstfield: "",
        secondfield: "",
        operations: "+",
      },
      presavedrepeatedmodel: "",
      repeatedtemplatename: "",
      repeatedtemplatefieldarray: [],
      calcfieldnamesarray: [],
      repeatedoptions: [],
      repeatingfieldname: "",
      repeatingtype: "text",
      addrepeatedoptfield: false,
      createrepeatedmodel: false,
      currentgrid: [],
      newValues:[],
      gridoptarray: {},
      optiongroupeditid: "",
      activeTab: "Tab 1",
      highlitedcolumn: "",
      modeloptiontype: "",
      selectedmodel: {},
      optiongroups: {},
      imagid: "",
      currentoptions: [],
      qrcodecontents: "",
      blankfielddetails: "",
      scaleminval: 0,
      scalemaxval: 0,
      viewtempicon: false,
      templateid: "",
      idtoken: "",
      griddata: [
        {
          Rows: [""],
          Columns: [""],
          Fieldtypes: ["text"],
          Fielddata: {},
          Fieldoptions: {},
        },
      ],
      griditm: "",
      selectedpagegroup: "",
      repeatingdata: [1],
      gridrows: [1],
      gridcolumns: [1],
      gridgeneral: "flex-sm-fill text-sm-center nav-link active",
      gridconfiguration: "flex-sm-fill text-sm-center nav-link",
      gridoption: "general",
      selectedpageid: "",
      qrcodetxt: {},
      width: [tW],
      height: [tH, tH, tH, tH, tH, tH],
      fit: true,
      left: [`calc( 50% - ${tW / 2}px)`],
      top: [`calc(50% - ${tH / 2}px)`],
      maxH: 90,
      minH: 10,
      issaved: false,
      minlength: "",
      maxlength: "",
      minvalue: "",
      maxvalue: "",
      baseurl: process.env.VUE_APP_Service_URL,
      fileuploadurl: process.env.VUE_APP_File_upload_URL,
      update: false,
      version: "",
      currentversion: "",
      columnselected: false,
      selectedgroup: {},
      selectedfield: 0,
      totalgroups: {},
      isedit: false,
      showCreateFormModal: false,
      fieldnamesarray: [],
      tempfieldname: "",
      totalpages: 0,
      selectedFile: "",
      pageno: 1,
      notcollapsed: true,
      checkboxoptions: "",
      checkboxoptionsarray: [],
      radiooptions: "",
      radiovalues: "",
      radiooptionsarray: [],
      collapseDiv: [false],
      showSideModal: [false],
      selectedmenu: "",
      value: [],
      values: [],
      inputElements: [
        "color",
        "datetime-local",
        "email",
        "file",
        "hidden",
        "month",
        "number",
        "password",
        "range",
        "search",
        "tel",
        "text",
        "url",
        "week",
        "boxed",
        "textbox",
        "boxed",
        "file",
      ],
      groups: {
        1: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12, 13],
      },

      options: [
        { value: "Required", label: "Required" },
        { value: "Number", label: "Number" },
        { value: "Alphanumeric", label: "alphaneumeric" },
        { value: "email", label: "Email" },
        { value: "lengthrange", label: "Length range" },
        { value: "valuerange", label: "Value range" },
      ],
      selectedrepeatedtemp: [],
      commonvalidations: ["Required"],
      newtemplate: [],
      templatename: "",
      pagename: "",
      pagearray: [],
      pageobj: {},
      grouparray: {},
      formHeader: "test",
      selectedpage: "",
      repgrouporderno: 1,
      headdingtext: "",
      fieldname: "",
      labeltext: "",
      componentKey: 1,
      type: "",
      showModalll: 0,
      temparray: [],
      totalgroupsforedit: [],
      testtempvar: [],
      listforms: [],
      formname: "",
      componentModal: false,
      pages: {},
      showDragCol: false,
      status: "New",
      dropdownError: false,
      emptyField: [false],
      scaleError: [false, false, false, false],
      newfieldname: "",
      showForm: false,
      showoptmodel: false,
      manageoptoptmodel: false,
      optiongroup: "",
      optiontype: "radio",
      optiongroupopts: "",
      groupoptions: [],
      optiongroupsearch: "",
      modeloptions: [],
      singlemodeloptions: "",
      singlemodelvalues: "",
      selectedrepeatedgroupno: 1,
      repeatedmeasurepopup: false,
      repeatedmeasurelistpopup: true,
      controlSelectError: false,
      newversionViewTemp: false,
      repeatedoptions: [],
      repeatedmeasurevalarray: [],
      temprepeatedmeasureval: {},
      repratmeasureid: new Date(),
      rollsprivilages: "",
      managerepeatfieldmodel: false,
      tempRepFieldName : "",
      rangeErrors :[false,false,false,false,false,false,false,false],
    };
  },
  beforeRouteEnter(to, from, next) {
    const previousRouteName = from.name;
    next((vm) => {
      vm.previousRouteName = previousRouteName;
    });
  },
  computed: {
    textFields() {
      return this.newtemplate[0].pages[this.pageno - 1].field.filter(
        (field) => field.type === "textbox"
      );
    },
  },
  async unmounted(){
    document.body.style.overflowY="auto"
  },
  async mounted() {
    document.body.style.overflowY="hidden"
    this.idtoken = store.getters.getIdToken;
    this.rollsprivilages = await store.getters.getRolesprivilegeData;
    this.fieldnamesarray = this.feildNames;
    await this.listoptiongroups();
    this.pagearray = [];
    this.newtemplate[0] = {
      "pages": [
        {
            "pageName": "string",
            "pageNo": 0,
            "Group":[],
            "field": []
        }
    ],
    "studyId": "string"
    };
    this.temparray[0] = {
      "pages": [
          {
              "pageName": "string",
              "pageNo": 0,
              "Group":[],
              "field": []
          }
      ],
      "studyId": "string"
    };
    await this.assigntemplate();
    this.tempRepFieldName = this.repeatingfielddata[0].fieldName;
    localStorage.setItem('tempFieldName', this.tempRepFieldName.trim().toLowerCase());
  },

  methods: {
    async listoptiongroups() {
      await axios
        .get(`${this.baseurl}/forms/optiongroups/listoptiongroups`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.optiongroups = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    async deleteRepeatingField(){
      if (confirm("Are you sure you want to delete this repeating field?")) {
        await this.onclosed();
        this.$emit('removeRField', this.repeatingfielddata[0].fieldName)
      }
    },
    async saverepeatedfield(){
      let tempfield = this.tempRepFieldName.trim().toLowerCase();
      let newFieldName = this.repeatingfielddata[0].fieldName.trim().toLowerCase();
      if(newFieldName == null || newFieldName == ""){
        alert("Repeating fieldname cannot be empty");
      }
      else if(tempfield != newFieldName && this.fieldnamesarray.includes(newFieldName)){
        alert("The given repeating field name is already in use");
      }
      else if(this.newtemplate[0].pages[0].field.length == 0){
        alert("Repeating measure should contains atleast one element")
      }
      else{
        this.repeatingfielddata[0].repeatingFields = this.newtemplate[0].pages[0].field;
        this.$emit('send-object', this.repeatingfielddata);
      }
    },
    async handleCheckboxChange() {
      if (this.reviewandapprovalvar == true) {
        this.replacetemplatevar = false;
        this.exisitingvisitsvar = false;
      }
    },
    async repeatedmeasuremodal(options) {
      this.repeatedoptions = JSON.parse(options);
      this.repeatedmeasurepopup = true;
    },
    async closerepeatedmeasuremodal() {
      this.repeatedmeasurepopup = false;
    },
    async repeatedmeasurlist() {
      this.repeatedmeasurelistpopup = true;
    },
    async repeatedmeasurdatapopup() {
      this.repratmeasureid = new Date();
      this.repeatedmeasurelistpopup = false;
    },
    async deleterepeatedmeasure(item) {
      let indexToDelete = this.repeatedmeasurevalarray.indexOf(item);

      if (indexToDelete !== -1) {
        // Remove the element at the found index
        this.repeatedmeasurevalarray.splice(indexToDelete, 1);
      }
    },
    async gridoptfun(fieldname, gridoptions) {
      this.gridoptarray[fieldname] = JSON.parse(gridoptions);
    },
    async closemanageoptmodel() {
      window.scrollTo(0, 0);
      this.manageoptoptmodel = false;
      this.optiongroupsearch = "";
      this.listoptiongroups();
    },
    async editOptionsfordropdown(codevalue, optionname) {
      //  Used in both radio and dropdown popups for edit click in table(template design)
      this.editingOption = { codevalue, optionname };

      this.radiovalues = codevalue;
      this.radiooptions = optionname;
    },
    async editOGOptions(codevalue, optionname) {
      this.editingOption = { codevalue, optionname };

      this.singlemodelvalues = codevalue;
      this.optiongroupopts = optionname;
    },
    async focusOnEdit() {
      //  Used in both radio and dropdown popups for focusing to the options when edit clicks

      this.$nextTick(() => {
        this.$refs.codeValueInput.focus();
        this.$refs.optionNameInput.focus();
      });
    },
    async cancelEditDropdown() {
      //  Used in both radio and dropdown popups to cancel edit mode
      this.editingOption = null;

      this.radiovalues = "";
      this.radiooptions = "";
      // below used in Option group popup
      this.singlemodelvalues = "";
      this.optiongroupopts = "";
    },
    async openoptmodel() {
      window.scrollTo(0, 0);
      this.showoptmodel = true;
    },
    async closeoptmodel() {
      window.scrollTo(0, 0);
      this.optiongroup, (this.optiongroup = "");
      this.singlemodelvalues = "";
      this.optiontype = "";
      this.optiongroupeditid = "";
      this.groupoptions = [];
      this.showoptmodel = false;
      this.optiongroupopts = "";

      this.editingOption = null;
    },
    async clearmodelfields() {
      this.fieldname = "";
      this.labeltext = "";
      this.radiooptionsarray = [];
      this.modeloptiontype = "";
      this.selectedmodel = {};
    },
    async addmodeloptions() {
      if (
        this.singlemodeloptions.trim() != "" &&
        this.singlemodeloptions.trim() !== null &&
        this.singlemodelvalues.trim() != "" &&
        this.singlemodelvalues.trim() != null
      ) {
        const isNameDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.optionname === this.singlemodeloptions
        );
        if (isNameDuplicate) {
          alert("Option group with the Option name already exists!");
          return;
        }
        const isCodeDuplicate = this.selectedmodel.groupoptions.some(
          (option) => option.codevalue === this.singlemodelvalues
        );
        if (isCodeDuplicate) {
          alert("Option group with the same Code value already exists!");
          return;
        }
        this.currentoptions = {
          optionname: this.singlemodeloptions.trim(),
          codevalue: this.singlemodelvalues.trim(),
        };
        this.selectedmodel.groupoptions.push(this.currentoptions);
        this.singlemodeloptions = "";
        this.singlemodelvalues = "";
      }
    },
    async modelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
    },
    async dropdownmodelchange() {
      this.modeloptiontype = this.selectedmodel.optiontype;
      this.modeloptions = this.selectedmodel.groupoptions;
      this.labeltext = this.selectedmodel.label;
      this.radiovalues = "";
      this.radiooptions = "";
      this.editingOption = null;
      this.radiooptionsarray = [];
      this.selectedmodel.groupoptions.forEach((option) => {
        this.radiooptionsarray.push(option);
      });
    },
    async editOptions(codeval, optname) {
      this.radiovalues = codeval;
      this.radiooptions = optname;
    },
    async radiomodelchange() {
      this.labeltext = this.selectedoptiongroupmodel.label;
      this.radiooptionsarray = this.selectedoptiongroupmodel.groupoptions;
    },
    async addmodel() {
      if (this.modeloptiontype === "") {
        this.controlSelectError = true;
      } else {
        this.controlSelectError = false;
        await this.setfieldname();
        this.labeltext = this.selectedmodel.label;
        this.radiooptionsarray = this.selectedmodel.groupoptions;
        this.addfield(this.modeloptiontype);
      }
    },
    async setfieldname() {
      this.fieldname = (Math.floor(Math.random() * 100) + 1).toString();
      if (this.fieldnamesarray.includes(this.fieldname)) {
        this.setfieldname();
      }
    },
    async openeditoptiongroup(data, id) {
      this.optiongroupeditid = id;
      this.optiongroup = data.optiongroup;
      this.optiontype = data.optiontype;
      this.groupoptions = data.groupoptions;
      this.showoptmodel = true;
    },
    async griditems() {
      this.griditm = [1, 2, 3, 4];
      return this.griditm;
    },
    async selectconf(val) {
      if (val == "general") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link active";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link";
      } else if (val == "configuration") {
        this.gridgeneral = "flex-sm-fill text-sm-center nav-link";
        this.gridconfiguration = "flex-sm-fill text-sm-center nav-link active";
      }
      this.gridoption = val;
    },
    async qrcodefun(fieldname, dataurl) {
      // alert("hello qr code");
      try {
        this.qrcodetxt[fieldname] = await QRCode.toDataURL(dataurl);
      } catch (err) {
        console.error(err);
      }
    },
    async showCollapse(id) {
      if (this.pages[id] == null || pages[id] == []) {
        alert("No pages found");
      }
    },
    async handleDropdownChange() {
      this.selectedpageid = "";
      this.getPages(
        this.cyrrentformid,
        this.currentsourceid,
        this.version,
        this.formname,
        true
      );
    },
    async assigntemplate() {
      if (this.popuptype == "edit") {
        await this.reloadtemplate();
        await this.tempvarassign();
      } 
    },
    async reloadtemplate() {
      console.log("Rep fields are",this.repfieldstoedit)
      this.repeatingfielddata[0].fieldName = this.repfieldstoedit.fieldName;
      this.repeatingfielddata[0].label = this.repfieldstoedit.label;
      this.newtemplate[0].pages[0].field = this.repfieldstoedit.repeatingFields;
      this.newtemplate[0].createdAt = new Date();
      let tempvar = this.newtemplate[0];
      this.version = 1;
      this.currentversion = 1;
      this.testtempvar = [];
      this.testtempvar = [
        {
          pages: [],
          formId: tempvar.formId,
          formname: tempvar.formname,
          language: "english",
          status: "new",
          version: this.version,
          totalPages: tempvar.totalPages,
        },
      ];
      this.totalpages = tempvar.pages.length;
      tempvar.pages.forEach(async (element) => {
        let pageindex = tempvar.pages.indexOf(element) + 1;
        this.pagearray.push(element.pageName);
        let testgrouporderarray = [];
        let pageforedit = {
          pageName: element.pageName,
          pageNo: element.pageNo,
          Group: [],
        };
        if (element.field.length == 0) {
          let emptypages = {
            pageName: element.pageName,
            pageNo: element.pageNo,
            Group: [],
          };

          this.testtempvar[0].pages.push(emptypages);
        } else {
          let pggropno = parseInt(
            element.field[element.field.length - 1].groupOrderNo
          );
          this.totalgroups["page" + pageindex] = pggropno;
          await element.field.forEach((subelement) => {
            if (!testgrouporderarray.includes(subelement.groupOrderNo)) {
              testgrouporderarray.push(subelement.groupOrderNo);
              let newgroup = {
                Groupno: subelement.groupOrderNo,
                components: subelement.groupOrderNo,
                field: [],
              };
              pageforedit.Group.push(newgroup);
            }
          });
          element.field.forEach((subelem) => {
           // this.fieldnamesarray.push(subelem.fieldName.trim().toLowerCase());
            if(subelem.type == "repeatingfield" ){
              this.repeatedgroupgroupnos[subelem.fieldName] = [];
              if(subelem.repeatingFields.length!=0){
                subelem.repeatingFields.forEach((repeatedgroupelement)=>{
                  if(!this.repeatedgroupgroupnos[subelem.fieldName].includes(repeatedgroupelement.groupOrderNo)){
                  this.repeatedgroupgroupnos[subelem.fieldName].push(repeatedgroupelement.groupOrderNo);
                  }
                })
              }
            }
            let fieldinfo = {
              fieldName: subelem.fieldName,
              label: subelem.label,
              fieldno: subelem.fieldno,
              value: subelem.value,
              status: subelem.status,
              validatedBy: subelem.validatedBy,
              validatedAt: subelem.validatedAt,
              comment: subelem.comment,
              repeatingFields: subelem.repeatingFields,
              groupOrderNo: subelem.groupOrderNo,
              type: subelem.type,
              limit: subelem.limit,
              validation: subelem.validation,
              imagePath: subelem.imagePath,
              options: subelem.options,
              required: subelem.required,
              qrData: subelem.qrData
            };
            pageforedit.Group.forEach((groupselect) => {
              if (groupselect.Groupno == subelem.groupOrderNo) {
                fieldinfo.fieldno = groupselect.field.length;
                groupselect.field.push(fieldinfo);
              }
            });
          });
          this.testtempvar[0].pages.push(pageforedit);
        }
      });
    },
    async tempvarassign() {
      this.temparray = this.testtempvar;
      this.testtempvar[0].pages.forEach((item) => {
        item.Group.forEach((groupitem) => {
          groupitem.field.forEach((subitem) => {
            this.calcfieldnamesarray.push(subitem.fieldName);
          });
        });
      });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async removerowsrows(index) {
      this.griddata[0].Rows.splice(index, 1);
      this.griddata[0].Fieldtypes.splice(index, 1);
      this.gridrows.splice(index, 1);
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");

      // this.griddata[0].Columns.push("");
    },
    async removecolumns(index) {
      this.griddata[0].Columns.splice(index, 1);
      this.gridcolumns.splice(index, 1);
    },
    createForm() {
      this.showCreateFormModal = !this.showCreateFormModal;
    },
    eHandler(key, data) {
      this.width[key] = data.width;
      this.height[key] = data.height;
      this.left[key] = data.left;
      this.top[key] = data.top;
    },
    drag(ev, element) {
      this.elementUsed = element;
      ev.dataTransfer.dropEffect = "copy";
      ev.dataTransfer.effectAllowed = "copy";
      ev.dataTransfer.setData("text", ev.target.id);
    },
    drop(ev, element) {
      if (element == "addone") {
        this.addonecolumn();
      }
      if (element == "addtwo") {
        this.addtwocolumn();
      }
      if (element == "addthree") {
        this.addthreecolumn();
      }
      if (element == "addfour") {
        this.addfourcolumn();
      }
      if (element == "addsix") {
        this.addsixcolumn();
      }
      if (element == "header") {
        this.openModeltwo("header");
      }
      if (element == "summary") {
        this.openModeltwo("summary");
      }
      if (element == "subHeader") {
        this.openModeltwo("subHeader");
      }
      if (element == "textbox") {
        this.openModeltwo("textbox");
      }
      if (element == "scales") {
        this.scalemaxval = 0;
        this.scaleminval = 0;
        this.openModeltwo("scales");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "dropdown") {
        this.openModeltwo("dropdown");
      }
      if (element == "date") {
        this.openModeltwo("date");
      }
      if (element == "time") {
        this.openModeltwo("time");
      }
      if (element == "dateandtime") {
        this.openModeltwo("dateandtime");
      }
      if (element == "numberanddate") {
        this.openModeltwo("numberanddate");
      }
      if (element == "uploadfile") {
        this.openModeltwo("uploadfile");
      }
      if (element == "grid") {
        this.griddata = [
          {
            Rows: [""],
            Columns: [""],
            Fieldtypes: ["text"],
            Fielddata: {},
            Fieldoptions: {},
          },
        ];
        this.gridrows = [1];
        this.gridcolumns = [1];
        this.openModeltwo("grid");
      }
      if (element == "qrcode") {
        this.openModeltwo("qrcode");
      }
      if (element == "year") {
        this.openModeltwo("year");
      }
      if (element == "radio") {
        this.openModeltwo("radio");
      }
      if (element == "checkbox") {
        this.openModeltwo("checkbox");
      }
      if (element == "textarea") {
        this.openModeltwo("textarea");
      }
      if (element == "image") {
        this.onFileChange();
      }
      if (element == "calculation") {
        this.openModeltwo("calculation");
      }
      if (element == "repeatedmodel") {
        this.openModeltwo("repeatedmodel");
      }
      if (element == "repeatedmeasure") {
        this.openModeltwo("repeatedmeasure");
      }
      if (element == "model") {
        this.clearmodelfields();
        this.openModeltwo("model");
      }
      this.highlitedcolumn = "";
    },
    async onFileChange() {
      if (this.columnselected) {
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        document.getElementById("fileInput").click();
      } else {
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        document.getElementById("fileInput").click();
      }
    },
    async helloButton(event) {
      this.selectedFile = "";
      this.imageData = "";
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.selectedFile = input.files[0];
      }
      await this.submitFile();
      this.openModelcomponent("image");
    },
    async submitFile() {
      var formData = new FormData();
      formData.append("Document", this.selectedFile);
      formData.append("DocumentTypeName", "TemplateImage");
      formData.append("DocumentTitle", "");
      formData.append("DocumentDescription", "");
      formData.append("OrganizationId", "");

      await axios
        .post(`${this.fileuploadurl}/create`, formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "multipart/form-data",
          },
        })

        .then((response) => {
          this.imagid = `${response.data.data[0]},${response.data.data[1]}`;
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    async incrementrows() {
      if (this.gridrows.length == 0) {
        this.gridrows.push(1);
      } else {
        let lastelement = this.gridrows[this.gridrows.length - 1];
        this.gridrows.push(lastelement + 1);
      }
      this.griddata[0].Rows.push("");
      this.griddata[0].Fieldtypes.push("text");
    },
    async incrementcolumns() {
      if (this.gridcolumns.length == 0) {
        this.gridcolumns.push(1);
      } else {
        let lastelement = this.gridcolumns[this.gridcolumns.length - 1];
        this.gridcolumns.push(lastelement + 1);
      }
      this.griddata[0].Columns.push("");
    },

    async savetestfun() {
      let cansave = true;
      if (this.newtemplate[0].pages == 0) {
        cansave = false;
      } else {
        await this.newtemplate[0].pages.forEach((data) => {
          if (data.repeatingFields.length == 0) {
            cansave = false;
          }
        });
      }
      if (cansave) {
        if (this.version == null || this.version == "") {
          alert("Please enter version");
        } else {
          if (this.currentversion == this.version) {
            this.updatefun("update");
          } else {
            this.savefun("newsave");
          }
        }
      } else {
        alert("Form cannot be saved with empty pages");
      }
    },
    async handleIdFromChild(idFromChild) {
      await this.getPagesInitially(idFromChild, "1.0");
    },
    async newtemplateversion() {
      let newformidd = store.getters.getnewversionformid;
      this.sourcetemplateidcreate = store.getters.getsourceTemplateId;
      this.version = store.getters.getnewversion;
      this.newversioncreate = true;
      this.isformlocked = false;
      this.newversionViewTemp = true;
      this.savefun("newversion");
      this.currentversion = this.version;
      this.getForms();
      this.closecreatenewversion();
    },
    closecreatenewversion() {
      this.newversionformid = "";
      this.creatversionpopup = false;
    },

    async openModel() {
      window.scrollTo(0, 0);
      this.showModalll = 1;
    },
    async closemodall() {
      // document.body.style.overflow = 'auto';
      this.showModalll = this.showModalll + 1;
    },
    async openModeltwo(val) {
      this.clearInput();
      if (this.columnselected) {
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        this.openModelcomponent(val);
      } else {
        this.adddefaultcolumn();
        this.tempfieldname = "";
        this.labeltext = "";
        this.fieldname = "";
        this.values = [];
        this.qrcodecontents = "";
        if(this.temparray.length != 0 && this.temparray[0].pages.length != 0)
        {
          this.openModelcomponent(val);        
        }
      }
    },
    async openModelcomponent(menuval) {
      this.selectedmenu = menuval;
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
      this.componentModal = true;
    },
    async closemodaltwo() {
      document.body.style.overflow = "auto";
      this.componentModal = false;
      this.isedit = false;
      this.columnselected = false;
      this.radiooptions = "";
      this.radiovalues = "";
      this.singlemodeloptions = "";
      this.singlemodelvalues = "";
      this.selectedoptiongroupmodel = "";
      this.selectedmodel = "";
      this.editingOption = null;
      this.maxvalue = "";
      this.minvalue = "";
      this.maxlength = "";
      this.minlength = "";
    },
    async deletecomponent() {
      this.newtemplate[0].pages[this.pageno - 1].field.forEach((element) => {
        if (element.fieldName == this.fieldname) {
          let index = this.newtemplate[0].pages[this.pageno - 1].field.indexOf(element);
          this.newtemplate[0].pages[this.pageno - 1].field.splice(index, 1);
          this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup
          ].field[this.selectedfield] = {
            fieldName: this.selectedgroup["page" + this.pageno] + "field2",
            fieldno: this.selectedfield,
            groupOrderNo: this.selectedgroup["page" + this.pageno],
            type: "blankdata",
          };
        }
        this.isedit = false;
      });
      this.deleteapicall();
      this.closemodaltwo();
      await this.removefieldname(this.fieldname);
    },
    async onFileeditchange() {
      document.getElementById("fileInput").click();
    },
    async removefieldname(n) {
      const index = this.fieldnamesarray.indexOf(n.trim().toLowerCase());
      if (index > -1) {
        this.fieldnamesarray.splice(index, 1);
      }
    },
    async deleteapicall() {
      let data = {
        createdUserEmail: "string",
        createdUserId: "string",
        createdAt: "2022-06-16T04:19:38.363Z",
        lastModifiedUserEmail: "string",
        lastModifiedUserId: "string",
        lastModifiedAt: "2022-06-16T04:19:38.363Z",
        templateId: this.templateId,
        pageNo: this.pageno,
        fieldName: this.fieldname,
      };
      await axios
        .delete(`${this.baseurl}/forms/templatedesign/deletefield`, {
          data: data,
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          alert("Field deleted successfully.");
          this.columnselected = false;
        })
        .catch((err) => {
          console.log("add err response:", err);
        });this.rangeErrors = [false,false,false,false,false,false,false,false]
    },
    // edit field ....................................................
    async editfield() {
      let validScale = true;
      this.rangeoption = [];
      this.currentoptions = this.radiooptionsarray;
      if (this.type == "textbox") {
        if(this.values.includes("valuerange") || this.values.includes("lengthrange")){
          await this.rangeValidations();

          if(!this.rangeErrors.includes(true) && (this.values.includes("lengthrange") || this.values.includes("valuerange"))){
            this.setvalidations();
          }
          else if(!this.rangeErrors.includes(true) && (!this.values.includes("lengthrange") && (!this.values.includes("valuerange")))){
            validScale = true;
          }
          else{
            validScale = false;
          }
        }
      
      } else if (this.type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
      } else if (this.type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          validScale = false;
        }
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      } else if (this.type == "radio"){
          if(this.radioorientation=='vertical'){
            this.type="radio"
          }
          else{
            this.type = "radiohorizondal"
          }
        }
        else if (this.type == "radiohorizondal") {
          if(this.radioorientation=='vertical'){
            this.type="radio"
          }
          else{
            this.type = "radiohorizondal"
          }
          
      } else if (this.type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          validScale = false;
        }
        this.qrData = this.qrcodecontents;
        //this.currentoptions[0] = this.qrcodecontents;
      }
      if (this.fieldname.trim() == null || this.fieldname.trim() == "") {
        alert("Fieldname cannot be empty");
      } else if (
        this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase()) &&
        this.tempfieldname !== this.fieldname.trim()
      ) {
        alert("This field name is already in use");
        validScale = false;
      } else if (this.type == "radio" && this.radiooptionsarray.length == 0) {
        alert("Please enter atleast one option");
      } else if (validScale) {
        this.newtemplate[0].pages[this.pageno - 1].field.forEach((element) => {
          if (element.fieldName == this.tempfieldname) {
            let index = this.newtemplate[0].pages[
              this.pageno - 1
            ].field.indexOf(element);
            if(this.type == 'textbox' && this.newValues.length!=0){
              this.newtemplate[0].pages[this.pageno - 1].field[index] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                comment: "",
                groupOrderNo: this.repgrouporderno,
                type: this.type,
                limit: this.rangeoption,
                validation: this.newValues,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
              };
              let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
              this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup].field[this.selectedfield] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                fieldno: this.selectedfield,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                limit: this.rangeoption,
                comment: "",
                groupOrderNo: this.repgrouporderno,
                type: this.type,
                validation: this.newValues,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
              };
            }
            else{
              this.newtemplate[0].pages[this.pageno - 1].field[index] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                comment: "",
                groupOrderNo: this.repgrouporderno,
                type: this.type,
                limit: this.rangeoption,
                validation: this.values,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
              };
              let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
              this.temparray[0].pages[this.pageno - 1].Group[this.selectedpagegroup].field[this.selectedfield] = {
                fieldName: this.fieldname.trim(),
                label: this.labeltext,
                fieldno: this.selectedfield,
                value: "",
                status: "",
                validatedBy: "",
                validatedAt: "",
                limit: this.rangeoption,
                comment: "",
                groupOrderNo: this.repgrouporderno,
                type: this.type,
                validation: this.values,
                imagePath: this.imagid,
                options: this.currentoptions,
                required: false,
                qrData: this.qrData,
              };
            }
           
            this.componentKey = this.componentKey + 1;
          }
        });
        console.log("Field is",this.newtemplate[0].pages[0])
        this.closemodaltwo();
      }
    },

    async resetvalidation(validations) {
      let valarray = [];
      validations.forEach((val) => {
        if (val.includes("lengthrange")) {
          this.minlength = val.substring(
            val.indexOf("(") + 1,
            val.indexOf(",")
          );
          this.maxlength = val.substring(
            val.indexOf(",") + 1,
            val.indexOf(")")
          );
          valarray.push("lengthrange");
        } else if (val.includes("valuerange")) {
          this.minvalue = val.substring(val.indexOf("(") + 1, val.indexOf(","));
          this.maxvalue = val.substring(val.indexOf(",") + 1, val.indexOf(")"));
          valarray.push("valuerange");
        } else {
          valarray.push(val);
        }
      });
      this.values = valarray;
    },
    async addgridoptions() {
      // grid value fields adding
      this.griddata[0].Rows.forEach((eachrow) => {
        this.griddata[0].Columns.forEach((eachcolumn) => {
          this.griddata[0].Fielddata[eachrow + eachcolumn] = "";
          this.griddata[0].Fieldoptions[eachrow + eachcolumn] = [];
        });
      });
      // grid value field ends
    },
    handleClick(event, newfield, groupno) {
      if ( event.target.tagName.toLowerCase() === 'iframe' || 
           event.target.ownerDocument.tagName.toLowerCase() === 'iframe') {
          this.inputfieldedit(newfield, groupno);
      }
  },
    async inputfieldedit(field, groupno, fieldkey) {
      this.fieldkey = fieldkey;
      this.isedit = true;
      let fieldtype = field.type;
      this.type = fieldtype;
      this.isedit = true;
      this.selectedfield = field.fieldno;
      this.imagid = field.imagePath;
      this.selectedpagegroup = this.temparray[0].pages[0].Group.indexOf(groupno);
      this.values = [];
        this.scaleError = [false, false, false, false];
        this.emptyField = false;
        if (field.type == "textbox") {
          await this.resetvalidation(field.validation);
        } else {
          this.values = field.validation;
        }
        if (field.type == "scales") {
          this.scaleminval = parseInt(field.limit[0].min);
          this.scalemaxval = parseInt(field.limit[0].max);
        }
        if (field.type == "radio") {
          fieldtype = "radio";
          this.radioorientation = "vertical";
        }
        if (field.type == "radiohorizondal") {
          fieldtype = "radio";
          this.radioorientation = "horizondal";
        }
        if (field.type == "qrcode") {
          this.qrcodecontents = field.qrData;
        }
        if (field.type == "grid") {
          this.currentgrid = this.gridoptarray[field.fieldName];
          this.gridrows = this.gridoptarray[field.fieldName][0].Rows;
          this.gridcolumns = this.gridoptarray[field.fieldName][0].Columns;
          this.griddata[0].Rows = this.gridoptarray[field.fieldName][0].Rows;
          this.griddata[0].Columns = this.gridoptarray[field.fieldName][0].Columns;
        }
        this.repgrouporderno = field.groupOrderNo;
        this.radiooptionsarray = field.options;
        this.selectedgroup["page" + this.pageno] = field.groupOrderNo;
        this.tempfieldname = field.fieldName;
        this.openModelcomponent(fieldtype);
        this.fieldname = field.fieldName;
        this.newfieldname = field.fieldName;
        this.labeltext = field.label;
    },


    setvalidations() {
      this.newValues=[...this.values]
      if (this.newValues.includes("lengthrange")) {
        let lengthindex = this.newValues.indexOf("lengthrange");
        this.newValues[lengthindex] =
          this.newValues[lengthindex] + `(${this.minlength},${this.maxlength})`;
      }
      if (this.newValues.includes("valuerange")) {
        let valueindex = this.newValues.indexOf("valuerange");
        this.newValues[valueindex] =
          this.newValues[valueindex] + `(${this.minvalue},${this.maxvalue})`;
      }
      
    },
    // add field ....................................................
    async addfield(type) {
      this.rangeErrors = [false,false,false,false,false,false,false,false];
      let fieldtyp = type;
      this.rangeoption = [];
      let vald = true;
      this.currentoptions = this.radiooptionsarray;
      if (type == "textbox") {
        if(this.values.includes("valuerange") || this.values.includes("lengthrange")){
          await this.rangeValidations();
        }
        if(!this.rangeErrors.includes(true) && (this.values.includes("lengthrange") || this.values.includes("valuerange"))){
          this.setvalidations();
        }
        else if(!this.rangeErrors.includes(true) && (!this.values.includes("lengthrange") && (!this.values.includes("valuerange")))){
          vald = true;
        }
        else{
          vald = false;
        }
      }
      if (type == "header" || type == "subHeader") {
        this.fieldname = this.labeltext;
      } else if (type == "scales") {
        this.scaleValidation();
        if (this.scaleError.includes(true)) {
          vald = false;
        }
        this.rangeoption = [
          {
            min: this.scaleminval.toString(),
            max: this.scalemaxval.toString(),
          },
        ];
      } else if (type == "radio" || type == "Radio") {
        if (this.radioorientation == "horizondal") {
          fieldtyp = "radiohorizondal";
        } else {
          fieldtyp = "radio";
        }
      } else if (type == "calculation") {
        let calculationdata = JSON.stringify(this.calculate);
        this.currentoptions = [calculationdata];
      } else if (type == "repeatedmeasure") {
        let repeatedmesuredata = JSON.stringify(this.selectedrepeatedtemp);
        this.currentoptions = [repeatedmesuredata];
      } else if (type == "dropdown" || type == "Dropdown") {
        if (this.radiooptionsarray.length == 0) {
          this.dropdownError = true;
          vald = false;
        }
      } else if (type == "grid") {
        await this.addgridoptions();
        let gridoptiondata = JSON.stringify(this.griddata);
        this.currentoptions = [gridoptiondata];
      } else if (type == "qrcode") {
        if (this.qrcodecontents == "" || this.qrcodecontents == null) {
          this.emptyField = true;
          vald = false;
        }
        this.qrData = this.qrcodecontents;
      }
      if (vald) {
        if (this.fieldname.trim() == null || this.fieldname.trim() == "") {
          alert("Fieldname cannot be empty");
        } else if (this.fieldnamesarray.includes(this.fieldname.trim().toLowerCase())) {
          alert("This field name is already in use");
        } else if (
          type == "calculation" &&
          (this.calculate.firstfield == "" || this.calculate.secondfield == "")
        ) {
          alert("Please enter First and Second field in calculation field");
        } else if (type == "radio" && this.radiooptionsarray.length == 0) {
          alert("Please enter atleast one option");
        } else {
          let selectedpageindx = this.pagearray.indexOf(this.selectedpage, 0);
          if(type == 'textbox' && this.newValues.length!=0){
            let fielddata = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
              type: fieldtyp,
              limit: this.rangeoption,
              validation: this.newValues,
              imagePath: this.imagid,
              qrData: this.qrData,
              options: this.currentoptions,
              required: false,
            };
            let tempfielddata = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              fieldno: this.selectedfield,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              limit: this.rangeoption,
              qrData: this.qrData,
              groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
              type: fieldtyp,
              validation: this.newValues,
              imagePath: this.imagid,
              options: this.currentoptions,
              required: false,
            };
            this.newtemplate[0].pages[0].field.push(tempfielddata);
            this.temparray[0].pages[0].Group[
              this.selectedpagegroup
            ].field[this.selectedfield] = tempfielddata;
            this.columnselected = false;
            this.closemodaltwo();
            this.calculate.firstfield = "";
            this.calculate.secondfield = "";
          }
          else{
            let fielddata = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
              type: fieldtyp,
              limit: this.rangeoption,
              validation: this.values,
              imagePath: this.imagid,
              qrData: this.qrData,
              options: this.currentoptions,
              required: false,
            };
            let tempfielddata = {
              fieldName: this.fieldname.trim(),
              label: this.labeltext,
              fieldno: this.selectedfield,
              value: "",
              status: "",
              validatedBy: "",
              validatedAt: "",
              comment: "",
              limit: this.rangeoption,
              qrData: this.qrData,
              groupOrderNo: this.selectedgroup["page" + this.pageno].toString(),
              type: fieldtyp,
              validation: this.values,
              imagePath: this.imagid,
              options: this.currentoptions,
              required: false,
            };
            this.newtemplate[0].pages[0].field.push(tempfielddata);
            this.temparray[0].pages[0].Group[
              this.selectedpagegroup
            ].field[this.selectedfield] = tempfielddata;
            this.columnselected = false;
            this.closemodaltwo();
            this.calculate.firstfield = "";
            this.calculate.secondfield = "";
          }
        
         console.log("Field is",this.newtemplate[0].pages[0])
        }
      }
    },
    async deletegroupfun() {
      let pagegroup = this.selectedgroup["page" + this.pageno] - 1;
      if (isNaN(pagegroup)) {
        alert("Please select a column");
      } else {
        if (this.columnselected != true) {
          alert("Please select a column");
        } else {
          let conformedcolumn = confirm(
            "Are you sure you want to delete this column?"
          );
          if (conformedcolumn) {
            let field = this.temparray[0].pages[this.pageno - 1].Group[
              this.selectedpagegroup].field[this.selectedfield];

            if (field.type == "blankdata") {
              this.temparray[0].pages[this.pageno - 1].Group[
                this.selectedpagegroup
              ].field[this.selectedfield] = "";
              this.columnselected = false;
            }
          }
        }
      }
    },
    scaleValidation() {
      const RegexCheck = /[^0-9-]/g;
      if (this.scaleminval == this.scalemaxval) {
        this.scaleError[0] = true;
      }
      if (parseInt(this.scaleminval) > parseInt(this.scalemaxval)) {
        this.scaleError[1] = true;
      }
      if (RegexCheck.test(this.scaleminval)) {
        this.scaleError[2] = true;
      }
      if (RegexCheck.test(this.scalemaxval)) {
        this.scaleError[3] = true;
      }
    },
    clearInput() {
      this.scaleError = [false, false, false, false];
      this.emptyField = false;
      this.dropdownError = false;
    },
    async createrepeatedfield() {
      this.repeatedfieldpopup = true;
    },
    async closerepeatedfield(){
      this.repeatedfieldpopup = false;
    },
    async closerepeatingmodel() {
      this.addrepeatedoptfield = false;
      this.createrepeatedmodel = false;
    },
    async addrepeatedoptionfield() {
      this.addrepeatedoptfield = !this.addrepeatedoptfield;
    },
    async cancelrepeatedoptionfield() {
      this.addrepeatedoptfield = false;
    },
    async addrepeatedopts() {
      if (!this.repeatedoptions.includes(this.repeatoptionss)) {
        this.repeatedoptions.push(this.repeatoptionss);
      }
      this.repeatedoptions = "";
    },
    async removedrepeatedoptions(index) {
      this.repeatedtemplatefieldarray.splice(index, 1);
    },
    async addrepeatedoptions() {
      let repeatoptarray = this.repeatedoptions;
      let repeatedtemplatefield = {
        fieldName: this.repeatingfieldname,
        type: this.repeatingtype,
        options: repeatoptarray,
      };
      if (!this.repeatedtemplatefieldarray.includes(repeatedtemplatefield)) {
        this.repeatedtemplatefieldarray.push(repeatedtemplatefield);
      }
      repeatedtemplatefield = {
        fieldName: "",
        type: "text",
        options: [],
      };
      this.repeatingfieldname = "";
      this.repeatingtype = "text";
      this.repeatedoptions = [];
      this.addrepeatedoptfield = false;
    },
    async addradiooptions() {
      if (
        this.radiovalues.trim() != "" &&
        this.radiovalues.trim() != null &&
        this.radiooptions.trim() != "" &&
        this.radiooptions.trim() != null
      ) {
        this.dropdownError = false;
        if (
          this.radiooptions.trim() != "" &&
          this.radiooptions.trim() !== null
        ) {
          const isNameDuplicate = this.radiooptionsarray.some(
            (option) => option.optionname === this.radiooptions.trim()
          );
          if (isNameDuplicate) {
            alert("Option group with the same option name already exists!");
            return;
          }
          const isCodeDuplicate = this.radiooptionsarray.some(
            (option) => option.codevalue === this.radiovalues.trim()
          );
          if (isCodeDuplicate) {
            alert("Option group with the same Code value already exists!");
            return;
          }
          this.currentoptions = {
            optionname: this.radiooptions.trim(),
            codevalue: this.radiovalues.trim(),
          };
          if (!this.radiooptionsarray.includes(this.radiooptions.trim())) {
            this.radiooptionsarray.push(this.currentoptions);
          }
        }
        this.radiooptions = "";
        this.radiovalues = "";
      }
    },
    async expandfun(id) {
      let expitem = document.getElementById(id);
      expitem.classList.toggle("collapse");
    },
    async selectitem(menuval) {
      this.isedit = false;
      this.fieldname = "";
      this.labeltext = "";
      this.values = [];
      this.newValues=[]
      this.radiooptionsarray = [];
      if (this.selectedmenu == menuval || this.selectedmenu == "") {
        let expitem = document.getElementById("addvalues");
        expitem.classList.toggle("collapse");
        // alert("selected an item");
        this.selectedmenu = menuval;
      } else {
        this.selectedmenu = menuval;
      }
    },
    async addrepeatedonecolumn(lastgroup) {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[0].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
        this.selectspot(inputelem.field[0], lastgroup);
      }
    },
    async adddefaultcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        let groupinfo = {
          Groupno: groupno,
          components: 1,
        };
        this.temparray[0].pages[0].Group.push(inputelem);
        this.selectspot(inputelem.field[0], inputelem);
      }
    },
    async addonecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addtwocolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addthreecolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addfourcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async addsixcolumn() {
      if (this.temparray.length == 0) {
        alert("Please create a page");
      } else if (this.temparray[0].pages.length == 0) {
        alert("Please create a page");
      } else {
        // let groupno = this.totalgroups["page" + this.pageno] + 1;
        // this.totalgroups["page" + this.pageno] = groupno;
        let grouparray = [];
        let groupno = 1;
        grouparray = this.temparray[0].pages[this.pageno - 1].Group;
        if (grouparray.length != 0) {
          groupno = parseInt(grouparray[grouparray.length - 1].Groupno) + 1;
        }
        let inputelem = {
          Groupno: groupno,
          components: 1,
          field: [
            {
              fieldName: groupno + "field1",
              fieldno: 0,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field2",
              fieldno: 1,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field3",
              fieldno: 2,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "fiel4",
              fieldno: 3,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field5",
              fieldno: 4,
              groupOrderNo: groupno,
              type: "blankdata",
            },
            {
              fieldName: groupno + "field6",
              fieldno: 5,
              groupOrderNo: groupno,
              type: "blankdata",
            },
          ],
        };
        this.temparray[0].pages[this.pageno - 1].Group.push(inputelem);
      }
    },
    async selectspot(selectedfield, groupno) {
      this.values = [];
      this.newValues=[]
      this.radiooptionsarray = [];
      this.radiooptions = "";
      this.columnselected = true;
      this.selectedgroup["page" + this.pageno] = selectedfield.groupOrderNo;
      this.selectedpagegroup = this.temparray[0].pages[0].Group.indexOf(groupno);
      this.selectedfield = selectedfield.fieldno;
      this.blankfielddetails = selectedfield;
      this.highlitedcolumn = selectedfield.fieldName;
    },
    async handleMouseLeave() {
      this.highlitedcolumn = "";
    },
    async onclosed() {
        this.$emit("repeatedmodelclose");
        this.ApproversName = [];
      },
      async rangeValidations(){
        this.rangeErrors = [false,false,false,false,false,false,false,false];
        if (this.values.includes("lengthrange")) {
          if (this.minlength === null || this.minlength === undefined || this.minlength === "") {
              this.rangeErrors[0] = true;
          }
       
         if(this.maxlength === "" || this.maxlength === null|| this.maxlength === undefined){
            this.rangeErrors[1] = true;
          }
          if(parseInt(this.maxlength) < parseInt(this.minlength)){
            this.rangeErrors[4] = true;
          }
          if(parseInt(this.maxlength)==0){
            this.rangeErrors[5] = true;
          }
          if(this.maxlength === "" || this.maxlength === null|| this.maxlength === undefined){
            this.rangeErrors[1] = true;
          }
        }
        if(this.values.includes("valuerange")){
          if(this.minvalue === "" || this.minvalue === null|| this.minvalue == undefined){
            this.rangeErrors[2] = true;
          }
          if(this.maxvalue === "" || this.maxvalue === null|| this.maxvalue == undefined){
            this.rangeErrors[3] = true;
          }
          if(parseInt(this.maxvalue) < parseInt(this.minvalue)){
            this.rangeErrors[6] = true;
          }
          if(parseInt(this.maxvalue)==0){
            this.rangeErrors[7] = true;
          }
        }
      },
      valuesChanged(){            
        this.rangeErrors = [false,false,false,false,false,false,false,false];             
      },
      deselectedOption(value){
        this.values= this.values.filter(item => item !== value);
        if (this.newValues.includes("lengthrange")) {
          let lengthindex = this.newValues.indexOf("lengthrange");
          this.newValues=this.newValues.filter(item => item !== this.newValues[lengthindex])
        }
        if (this.newValues.includes("valuerange")) {
          let valueindex = this.newValues.indexOf("valuerange");
          this.newValues=this.newValues.filter(item => item !== this.newValues[valueindex])
        }
        if(value=="lengthrange")
          {
            this.minlength=""
            this.maxlength=""
          }
          else if(value=="valuerange"){
            this.minvalue=""
            this.maxvalue=""
            this.newValues=[]
          }  
      },
      clearValues(){
        this.newValues=[]
        this.values=[]
      },
      async UpdateDropdownoptions() {
        //  Used in both radio and dropdown popups for options
        if (
          this.radiovalues.trim() !== ""  && this.radiovalues.trim() !== null &&
          this.radiooptions.trim() !== "" && this.radiooptions.trim() !== null
        ) {
          if (this.editingOption !== null) {
            if (
              this.radiovalues !== this.editingOption.codevalue ||
              this.radiooptions !== this.editingOption.optionname
            ) {
              const isNameDuplicate = this.radiooptionsarray.some(
                (option) =>
                  option.optionname === this.radiooptions.trim() &&
                  option.codevalue !== this.editingOption.codevalue
              );
              if (isNameDuplicate) {
                alert("Option group with the same name already exists!");
                return;
              }
  
              const isCodeDuplicate = this.radiooptionsarray.some(
                (option) =>
                  option.codevalue === this.radiovalues.trim() &&
                  option.codevalue !== this.editingOption.codevalue
              );
              if (isCodeDuplicate) {
                alert("Option group with the same Code value already exists!");
                return;
              }
            }
          } else {
            const isNameDuplicate = this.radiooptionsarray.some(
              (option) => option.optionname === this.radiooptions.trim()
            );
            if (isNameDuplicate) {
              alert("Option group with the same Option name already exists!");
              return;
            }
  
            const isCodeDuplicate = this.radiooptionsarray.some(
              (option) => option.codevalue === this.radiovalues.trim()
            );
            if (isCodeDuplicate) {
              alert("Option group with the same Code value already exists!");
              return;
            }
          }
  
          if (this.editingOption !== null) {
            const editedOptionIndex = this.radiooptionsarray.findIndex(
              (option) => option.codevalue === this.editingOption.codevalue
            );
            if (editedOptionIndex !== -1) {
              this.radiooptionsarray[
                editedOptionIndex
              ].codevalue = this.radiovalues;
              this.radiooptionsarray[
                editedOptionIndex
              ].optionname = this.radiooptions;
              this.editingOption = null;
  
              this.radiovalues = "";
              this.radiooptions = "";
              return;
            }
          }
  
          const existingIndex = this.radiooptionsarray.findIndex(
            (option) => option.codevalue === this.radiovalues
          );
          if (existingIndex !== -1) {
            if (
              this.radiooptions !==
              this.radiooptionsarray[existingIndex].optionname
            ) {
              this.radiooptionsarray[
                existingIndex
              ].optionname = this.radiooptions;
            }
          } else {
            this.radiooptionsarray.push({
              codevalue: this.radiovalues,
              optionname: this.radiooptions,
            });
          }
        } else {
          alert("Code value and Option name must not be empty.");
        }
  
        this.radiovalues = "";
        this.radiooptions = "";
      }, 
      async deleteoptions(codevalue) {
        if (confirm("Are you sure you want to delete this option?")) {

          if(  this.radiooptionsarray.length >1){
            const index = this.radiooptionsarray.findIndex((option) => option.codevalue === codevalue);
            if (index !== -1) {
              this.radiooptionsarray.splice(index, 1);
              console.log("Option deleted:", this.radiooptionsarray);
                  this.radiovalues = "";
                  this.radiooptions = "";
            }
            else{
              alert("Option not found")
              console.error("Option not found:", codevalue);
            }
          }
          else{
            alert("Cannot delete all options")
          }
      }
        // // Used in both radio and dropdown popups to delete options in the table
        // if (confirm("Are you sure you want to delete this option?")) {
        //   const index = this.radiooptionsarray.findIndex(
        //     (option) => option.codevalue === codevalue
        //   );
        //   if (index !== -1) {
        //     this.radiooptionsarray.splice(index, 1);
  
        //     console.log("Option deleted:", codevalue);
        //     this.radiovalues = "";
        //     this.radiooptions = "";
        //   } else {
        //     console.error("Option not found:", codevalue);
        //   }
        // }
      },
  
  },
};

   
