<template>
  <!-- modal starts -->
  <div class="study_profile_modal__wrapper">
    <div class="study_profile_modal__container">
      <div class="modal__title d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.create site") }}</h4>
        <div class="cursor-pointer" @click.prevent="closeModal">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-3">
        <div class="form-group row">
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site code") }}
              <sup><i class="fa fa-asterisk imp"></i></sup>
            </label>
            <input
              type="text"
              class="form-control"
              v-model="value.siteCode"
              maxlength="20"
            />
            <span class="errormsg animated bounce" v-if="errors[0]">
              {{ $t("navigations.please enter site code") }}
            </span>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site name") }}
            </label>
            <input
              type="text"
              maxlength="20"
              class="form-control"
              v-model="value.siteName"
            />
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site contact name") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model="value.siteContactName"
              maxlength="20"
            />
          </div>

          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site contact email") }}
            </label>
            <input
              type="email"
              class="form-control"
              v-model="value.siteContactEmail"
              maxlength="30"
            />
            <span class="errormsg" v-if="errors[1]">{{
              $t("navigations.please enter a valid email")
            }}</span>
          </div>
          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.status") }}
            </label>
            <select
              type="text"
              class="form-control"
              name="account"
              v-model="value.status"
              :disabled="value.status"
            >
              <option value="New">{{ $t("navigations.new") }}</option>
              <option v-if="disableStatus" value="Open">
                {{ $t("navigations.open") }}
              </option>
              <option v-if="disableStatus" value="Visit Completed">
                {{ $t("navigations.visit completed") }}
              </option>
              <option v-if="disableStatus" value="Archived">
                {{ $t("navigations.archived") }}
              </option>
              <option v-if="disableStatus" value="Closed">
                {{ $t("navigations.closed") }}
              </option>
              <option v-if="disableStatus" value="Enrolled">
                {{ $t("navigations.enrolled") }}
              </option>
              <option v-if="disableStatus" value="Terminated">
                {{ $t("navigations.terminated") }}
              </option>
              <option v-if="disableStatus" value="Completed">
                {{ $t("navigations.completed") }}
              </option>
              <option v-if="disableStatus" value="Close">
                {{ $t("navigations.close") }}
              </option>
            </select>
          </div>

          <div class="col-lg-4 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.number of subjects") }}
            </label>
            <input
              type="text"
              class="form-control"
              maxlength="4"
              v-model="value.numberOfPatient"
              @change.prevent="numberonlyvalidation('numberOfPatient')"
            />

            <span class="errormsg" v-if="errors[4]">{{
              $t("navigations.invalid number")
            }}</span>
          </div>
          <div class="col-lg-12 mb-1">
            <label class="col-form-label"
              >{{ $t("navigations.site notes") }}
            </label>
            <input
              type="text"
              class="form-control"
              v-model="value.siteNotes"
              maxlength="200"
            />
            <span class="errormsg" v-if="errors[5]">{{
              $t("navigations.invalid site note")
            }}</span>
          </div>
          <div class="col-lg-6 my-3 d-flex">
            <div class="input-group date">
              <div class="i-checks">
                <label class="mr-2">{{ $t("navigations.active") }}</label>
                <input
                  type="checkbox"
                  checked="checked"
                  value=""
                  v-model="value.active"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6 my-3 d-flex justify-content-lg-end">
            <button
              class="cancel_btn py-1 px-4 mx-3"
              type="submit"
              @click="clear()"
            >
              {{ $t("navigations.clear") }}
            </button>
            <button
              class="save_btn py-1 px-4"
              type="submit"
              @click="save()"
            >
              {{ $t("navigations.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal ends -->
</template>
<script src="./CreateSitePopUp.js"></script>
<style scoped>
.study_profile_modal__wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.study_profile_modal__container {
  position: fixed;
  width: min(50%, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.modal__title {
  background-color: var(--pop-up-background);
  color: #fff;
}
.cancel_btn {
  padding: 0.43rem 0.4rem;
  border-radius: 0.25rem;
}
</style>