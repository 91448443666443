<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.general queries") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <div class="form-group mb-lg-0 w-100 pl-lg-0 px-1">
            <label class="mb-0">{{ $t("navigations.query id") }}</label>
            <input
              v-model="this.QueryID"
              type="text"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.query subject") }}</label>
            <input
              v-model="this.QuerySubject"
              type="text"
              class="form-control my-md-2 my-lg-0"
            />
          </div>
          <div class="form-group mb-lg-0 w-100 px-1">
            <label class="mb-0">{{ $t("navigations.query status") }}</label>
            <select
              class="form-select my-md-2 my-lg-0"
              v-model="this.QueryStatus"
              placeholder="Search by query status"
            >
              <option value="All">{{ $t("navigations.all") }}</option>
              <option value="Closed">{{ $t("navigations.closed") }}</option>
              <option value="Open">{{ $t("navigations.open") }}</option>
            </select>
          </div>
          <div class="col-12 col-md-2 px-0 align-self-end">
            <span
              type="button"
              @click="search()"
              class="save_btn px-md-1 w-100"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>
        </div>
      </div>
      <div></div>
    </div>

    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="float-left" v-if="queryname == ''">
          {{ $t("navigations.general query list") }}
        </h5>
        <h5 class="float-left" v-if="queryname == 'Send'">
          {{ $t("navigations.general query sent list") }}
        </h5>
        <h5 class="float-left" v-if="queryname == 'Received'">
          {{ $t("navigations.general query received list") }}
        </h5>
        <div class="d-flex">
          <div
            v-if="showQuery == true"
            class="position-relative align-self-center mr-3"
            aria-label="All Query"
            tabindex="0"
          >
            <img
              class="cursor-pointer"
              src="../../assets/img/all_queries_icon.png"
              alt="All Query"
              @click="getAndSetAllData()"
            />
            <div class="tool-tip">{{ $t("navigations.all queries") }}</div>
          </div>
          <div
            v-if="sentQuery == true"
            class="position-relative align-self-center mr-3"
            aria-label="Sent Query"
            tabindex="0"
          >
            <img
              class="cursor-pointer"
              src="../../assets/img/send_icon.png"
              alt="Sent Query"
              @click="getAndSetDataForSent('Send')"
            />
            <div class="tool-tip">{{ $t("navigations.sent queries") }}</div>
          </div>
          <div
            v-if="receivedQuery == true"
            class="position-relative align-self-center"
            aria-label="Received Query"
            tabindex="0"
          >
            <img
              class="cursor-pointer"
              src="../../assets/img/received_icon.png"
              alt="Received Query"
              @click="getAndSetDataForSent('Received')"
            />
            <div class="tool-tip">{{ $t("navigations.received queries") }}</div>
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th
                  class="sort_block"
                  @click="SortSelected('queryId', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.query id") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('queryOpenStatus', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.status") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('queryFor', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.query for") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('priority', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.priority") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('query_Subject', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.query subject") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('raisedByName', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.raised by") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('createdAt', 7)"
                  id="7"
                >
                  {{ $t("navigations.raised date") }}
                  <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('closeByName', 8)"
                  id="8"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.closed by") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected('closedDate', 9)"
                  id="9"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.closed date") }}
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th class="text-center">{{ $t("navigations.view") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="query in returnList" :key="query.queryId">
                <td>{{ query.queryId }}</td>
                <td>{{ query.queryOpenStatus }}</td>
                <td>{{ query.queryFor }}</td>
                <td>{{ query.priority }}</td>
                <td>{{ query.query_Subject }}</td>
                <td>{{ query.raisedByName }}</td>
                <td v-if="query.createdAt">
                  {{ filter(query.createdAt) }}
                </td>
                <td v-else>{{}}</td>
                <td>{{ query.closeBy }}</td>
                <td v-if="query.closedDate">
                  {{ filter(query.closedDate) }}
                </td>
                <td v-else>{{}}</td>
                <td class="text-center">
                  <i
                    class="fa fa-eye editicon"
                    @click.prevent="viewforms(query.id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!------ pagination -------->
        <div class="row mt-3 mx-0">
          <div class="col-lg-12 mx-0 px-0">
            <div
              class="
                dataTables_paginate
                paging_simple_numbers
                pagination
                float-right
              "
            >
              <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPages"
                  :range-size="1"
                  active-color="#618bd7"
                  @update:modelValue="loadPage"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 <script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "general_querylist",
  components: {
    VPagination,
  },
  data() {
    return {
      defaultdate: "",
      searchQueryId: "",
      searchQuerySubject: "",
      querytype: "",
      searchQueryStatus: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/query/`,
      baseapi: process.env.VUE_APP_Service_URL,
      idToken: "",
      returnList: [],
      totalCount: 0,
      totalPages: 0,
      totalPage: 1,
      currentPage: 1,
      QueryID: "",
      QuerySubject: "",
      RaisedBy: "",
      QueryStatus: "",
      pageindex: 1,
      pagesize: 10,
      SortProperty: "createdAt",
      SortOrder: 1,
      queryname: "",
      showQuery: false,
      sentQuery: true,
      receivedQuery: true,
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.idToken = store.getters.getIdToken;
      this.queryname = store.getters.getQueryType;
      this.getAndSetData();
    });
    this.defaultdate = localStorage.getItem("dateformat");
    this.idToken = store.getters.getIdToken;
    this.queryname = store.getters.getQueryType;
    console.log("button", this.queryname);
    if (this.queryname == "Received") {
      this.receivedQuery = false;
    }
    if (this.queryname == "Sent") {
      this.sentQuery = false;
    }
    await this.getAndSetData();
    this.filter();
  },
  methods: {
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
      console.log("Reverse is");
      console.log(data);
    },
    async viewforms(id) {
      store.dispatch("setQueryType", this.queryname);
      this.$router.push(`/query/general_querylist/${id}`);
    },
    async getAndSetDataForSent(querytypeValue) {
      this.showQuery = true;
      console.log("sendddddddd", querytypeValue);
      if (querytypeValue == "Send") {
        this.sentQuery = false;
        this.receivedQuery = true;
      } else {
        this.receivedQuery = false;
        this.sentQuery = true;
      }

      this.queryname = querytypeValue;
      console.log("page index is..............", this.pageindex);
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      this.querytype = querytypeValue;
      await axios
        .get(
          this.baseUrl +
            "getallquerylist?QueryType=" +
            this.queryname +
            "&StudyId=" +
            currentstudy +
            "&QueryId=" +
            this.searchQueryId +
            "&QuerySubject=" +
            this.searchQuerySubject +
            "&QueryStatus" +
            this.searchQueryStatus +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder +
            "&QueryId=" +
            this.searchQueryId +
            "&QueryStatus=" +
            this.searchQueryStatus,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Query list");
          console.log(res);
          this.returnList = [];
          this.returnList = res.data.entities;
          console.log("general list", this.returnList);

          this.totalCount = res.data.totalCount;
          this.totalPages = parseInt(res.data.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async search() {
      this.setSearchData();
      this.pageindex = 1;
      this.currentPage = 1;
      this.getAndSetData();
    },
    async getAndSetData() {
      this.showQuery = false;
      this.querytype = "";
      if (this.queryname != "") {
        this.showQuery = true;
      }
      if (this.queryname == "Send") {
        this.sentQuery = false;
      }
      if (this.queryname == "Received") {
        this.receivedQuery = false;
      }
      console.log("page indexxxx is..............", this.pageindex);
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          this.baseUrl +
            "getallquerylist?StudyId=" +
            currentstudy +
            "&QueryType=" +
            this.queryname +
            "&QueryId=" +
            this.searchQueryId +
            "&QuerySubject=" +
            this.searchQuerySubject +
            "&QueryStatus" +
            this.searchQueryStatus +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder +
            "&QueryId=" +
            this.searchQueryId +
            "&QueryStatus=" +
            this.searchQueryStatus,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Query list");
          console.log(res);
          this.returnList = res.data.entities;
          console.log("general list", this.returnList);

          this.totalCount = res.data.totalCount;
          this.totalPages = parseInt(res.data.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAndSetAllData() {
      this.showQuery = false;
      this.sentQuery = true;
      this.receivedQuery = true;
      this.queryname = "";
      this.querytype = "";
      console.log("page indexxxx is..............", this.pageindex);
      const idtoken = store.getters.getIdToken;
      const currentstudy = store.getters.getStudyIs;
      await axios
        .get(
          this.baseUrl +
            "getallquerylist?StudyId=" +
            currentstudy +
            "&QueryType=" +
            this.querytype +
            "&QueryId=" +
            this.searchQueryId +
            "&QuerySubject=" +
            this.searchQuerySubject +
            "&QueryStatus" +
            this.searchQueryStatus +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder +
            "&QueryId=" +
            this.searchQueryId +
            "&QueryStatus=" +
            this.searchQueryStatus,

          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Query list");
          console.log(res);
          this.returnList = res.data.entities;
          console.log("general list", this.returnList);

          this.totalCount = res.data.totalCount;
          this.totalPages = parseInt(res.data.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPages = this.totalPages + 1;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async search() {
      this.setSearchData();
      this.pageindex = 1;
      this.currentPage = 1;
      if (this.querytype) {
        this.getAndSetDataForSent(this.querytype);
      } else {
        this.getAndSetData();
      }
    },
    setSearchData() {
      if (this.QueryStatus == "All") {
        this.QueryStatus = "";
      }
      this.searchQueryId = this.QueryID;
      this.searchQuerySubject = this.QuerySubject;
      this.searchQueryStatus = this.QueryStatus;
      console.log(
        "Hello" +
          this.searchQueryId +
          this.searchQuerySubject +
          this.searchQueryStatus
      );
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged");
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortDesc")) {
            return;
          } else if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else if (selectedElement.classList.contains("sortAsc")) {
            return;
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getAndSetData();
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = this.currentPage;
      this.getAndSetData();
    },
  },
};
</script>
 <style>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
</style>