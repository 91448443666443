
<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.templates") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-0">
        <div
          class="
            d-flex
            flex-column flex-lg-row
            justify-content-lg-start
            my-2 my-lg-1
          "
        >
          <div class="col-md-10 my-1 my-lg-0 pl-0 pr-1">
            <div class="form-group mb-md-0">
              <label class="mb-0">{{ $t("navigations.template name") }}</label>
              <input
                type="text"
                class="form-control my-md-2 my-lg-0"
                v-model="TemplateName"
              />
            </div>
          </div>
            <span class="save_btn col-12 col-md-2 px-0 px-md-2 align-self-end w-100" @click.prevent="SearchButtonClicked()">
              {{ $t("navigations.search") }}
            </span>
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t("navigations.template list") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.template name") }}</th>
                <th>{{ $t("navigations.folder") }}</th>
                <th>{{ $t("navigations.language") }}</th>
                <th>{{ $t("navigations.language code") }}</th>
                <th>{{ $t("navigations.version") }}</th>
                <th>{{ $t("navigations.status") }}</th>
                <th>{{ $t("navigations.uploaded date") }}</th>
                <th
                  v-if="roleprivileges.includes('Templates View Template')"
                  class="text-center"
                >
                  {{ $t("navigations.view") }}
                </th>
                <!-- <th
                  v-if="roleprivileges.includes('Templates Assign')"
                  class="text-center"
                >
                  Assign
                </th> -->
                <th
                  class="text-center"
                  v-if="roleprivileges.includes('Templates Assign Reviewer')"
                >
                  {{ $t("navigations.review") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="template in templateList" :key="template.templateId">
                <td>{{ template.templateName }}</td>
                <td>{{ template.folder }}</td>
                <td>{{ template.language }}</td>
                <td>{{ template.languageCode }}</td>
                <td>{{ template.version }}</td>
                <td>{{ template.status }}</td>
                <td>{{ filter(template.createdDate) }}</td>
                <td
                  v-if="roleprivileges.includes('Templates View Template')"
                  class="text-center"
                >
                  <i
                    class="fa fa-eye editicon"
                    @click="viewforms(template.templateId)"
                  ></i>
                </td>
                <!-- <td
                  v-if="roleprivileges.includes('Templates Assign')"
                  class="text-center"
                >
                  <i
                    class="fa fa-arrow-circle-o-right editicon"
                    @click.prevent="
                      openModal(template.version, template.templateId)
                    "
                  ></i>
                </td> -->
                <td
                  v-if="roleprivileges.includes('Templates Assign Reviewer')"
                  class="text-center"
                >
                  <span v-if="template.status == 'Design Complete'">
                    <i
                      class="fa fa-check-circle-o edit-delete"
                      aria-hidden="true"
                      @click.prevent="openApprovalModal(template.templateId)"
                    ></i
                  ></span>
                  <span v-else></span>
                </td>
                <!-- <td class="text-center">
                  <i class="fa fa-external-link-square edit-delete"></i>
                </td> -->
              </tr>
            </tbody>
          </table>
          <!------ pagination -------->
          <div class="row mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div
                class="
                  dataTables_paginate
                  paging_simple_numbers
                  pagination
                  float-right
                "
              >
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                  <v-pagination
                    v-model="currentPage"
                    :pages="totalitems"
                    :range-size="1"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal starts -->

  <!-- <div class="modal-wrapper">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="ml-lg-2">Assign Template</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody"> -->
  <!-- test -->
  <!-- <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="d-flex flex-column flex-lg-row my-2 mb-lg-0">
              <div class="col-lg-6 my-2">
                <select
                  class="form-control mx-lg-2 my-2 my-lg-0"
                  placeholder="Search by Status"
                  @change.prevent="studychange($event.target.value)"
                  v-model="selectedmodelstudy"
                >
                  <option value="" disabled selected>Select study</option>
                  <option
                    v-for="studye in modelstudy"
                    :key="studye.studyId"
                    :value="studye.studyId"
                  >
                    {{ studye.studyName }}
                  </option>
                </select>
                <span class="errorClass" v-if="errors[0]">
                  Please select study.</span
                >
              </div>
              <div class="col-lg-6 my-2">
                <select
                  class="form-control mx-lg-2 my-2 my-lg-0"
                  placeholder="Search by Status"
                  v-model="selectedmodelvisit"
                >
                  <option value="" disabled selected>Select visit</option>
                  <option
                    v-for="visits in modelvisits"
                    :key="visits.visitId"
                    :value="visits.visitId"
                  >
                    {{ visits.visitNo }}
                  </option>
                </select>
                <span class="errorClass" v-if="errors[1]">
                  Please select visit.</span
                >
              </div>
            </div>

            <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary save_btn px-5 mx-1"
                  @click.prevent="assignFun()"
                >
                  Assign
                </button>
              </div>
            </div>
          </div>
        </div> -->
  <!-- test -->
  <!-- </div>
    </div>
  </div> -->
  <!-- modal ends -->

  <!-- Approval modal starts -->
  <div class="modal-wrapper1">
    <div class="modal-container">
      <div class="titleclass">
        <h4 class="ml-lg-2">{{ $t("navigations.add a reviewer") }}</h4>
        <div class="closebutton">
          <a style="color: white" class="close-link">
            <i class="fa fa-times" @click.prevent="closeApprovalModal"></i>
          </a>
        </div>
      </div>
      <div class="modelbody">
        <!-- test -->
        <div class="mb-3 p-2">
          <div class="form-group row my-lg-2">
            <div class="input-group">
              <select class="form-control"
                v-model="reviewerId">
                <option value="" disabled selected>{{ $t("navigations.select a reviewer") }}</option>
                <option v-for="user in reviewers" :key="user.emailId" :value="user.emailId">
                  {{ user.userName }}
                </option>
              </select>
              <span class="input-group-append">
                <button
                  type="button"
                  class="btn-1"
                  @click="assignreviewer()"
                >
                  {{ $t("navigations.save") }}
                </button>
              </span>
            </div>
 <span class="errormsg" v-if="validations"
                    >{{ $t("navigations.please select a recipient") }}</span
                  >
            <!-- <div class="d-flex my-2 mb-lg-0">
              <div class="assignbtncontainer">
                <button
                  class="btn btn-primary save_btn px-5 mx-1"
                  @click.prevent="assignReviewer()"
                >
                  Assign
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <!-- test -->
      </div>
    </div>
  </div>
  <!-- Approval modal ends -->
</template>

<script src="./templateListComponent.js"></script>
<style scoped>
@import "./modal.css";
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.wrapper-content {
  position: relative;
}
.previousbtn,
.nextbtn {
  margin: 0;
  border-radius: 0px;
  border: 0.5px solid #eeeeee;
}
.page-links {
  border-radius: 0px;
}
.modelbody {
  overflow: unset !important;
  background-color: #ffffff !important;
}

.modelbody {
  /* background-color: aquamarine; */
  margin: 0;
  padding: 5%;
}
.btn-1 {
  display: inline-block;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  background-color: #e7e7e7;
  user-select: none;
  border: 1px solid transparent;
  border-radius: 0 3px 3px 0;
}
.btn-1:hover,
.btn-1.focus {
  background-color: #618bd7;
  color: #ffffff;
}
.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
</style>
