<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.user privilege") }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-2" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-10">
          <Multiselect v-model="values.Studies" mode="multiple" valueProp="studyId" placeholder="Select Study"
            label="studyRef" :options="studies" :hideSelected="false" :closeOnSelect="false" class="multiselect-theme">
          </Multiselect>
          <span class="errorClass" v-if="errors[0]">{{ $t("please select atleast one study") }} </span>
        </div>
        <div class="col-lg-2">
          <button @click="setprivilege()" class="btn btn-primary save_btn px-3 mx-0 w-100">
            {{ $t("navigations.set privilege") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
export default {
  props: { "userid": String },
  name: "App",
  components: { Multiselect },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      values: {
        Studies: [],
      },
      studies: "",
      useremail: "",
      selecteduser: "",
      userstudyid: "",
      previouslyselectedstudies: [],
      errors: [false],
    };
  },
  async mounted() {
    this.idToken = await store.getters.getIdToken;
    this.headerValues = {
      headers: {
        Authorization: "Bearer " + this.idToken,
        "Content-Type": "application/json",
      },
    };
    this.selecteduser = this.userid;
    await this.getEmailId();
    await this.getallStudies();
    await this.getselecteduserstudy();



  },


  methods: {
    async getEmailId() {
      console.log("inside getuser email function " + this.selecteduser);
      if (this.selecteduser != 0) {
        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            `${this.baseurl}/account-core/user/userbyid?id=${this.selecteduser}&api-version=1.0`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("data from db", res.data);
            if (res.data === "No User Found") {
              alert(this.$t('navigations.no user found'));
            }

            this.useremail = res.data.emailIdPrimary;
          })
          .catch((err) => {
            console.log(err.response.data.detail);
          });
      }
    },
    async getallStudies() {
      console.log("inside gett aall studies")
      await axios
        .get(`${this.baseurl}/management/study/getallstudy`,
          this.headerValues)
        .then((res) => {
          this.studies = res.data;
          console.log("Studies are......", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getselecteduserstudy() {
      console.log("inside selctd stdies", this.headerValues)
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/studyprivilege/getbyid?key=${this.selecteduser}`, this.headerValues)
        .then((res) => {

          console.log("if Studies already present......", res.statusText);
          console.log("if Studies ......", res.data.study);
          if (res.data != "No Content") {
            this.values.Studies = res.data.study;
            this.userstudyid = res.data.id;
          }
        })
        .catch((err) => {
          console.log("erorr is", err);
        });
    },
    async setprivilege() {
      await this.checkNoneSelected();
      if (!this.errors[0]) {
        if (this.userstudyid == "") {
          await this.addprivilege();
        }
        else {
          await this.updateprivilege();
        }
      }
    },
    async addprivilege() {
      await this.checkNoneSelected();
      if (!this.errors[0]) {
        this.useremail = this.useremail.toLowerCase()
        await axios
          .post(`${this.baseurl}/management/studyprivilege/create`, { UserId: this.selecteduser, EmailId: this.useremail, Study: this.values.Studies }, this.headerValues)
          .then((res) => {
            console.log(res);
            alert(this.$t('navigations.user study privilege saved successfully'));
            this.$router.push(`/userlisting`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async updateprivilege() {
      await this.checkNoneSelected();
      if (!this.errors[0]) {
        this.useremail = this.useremail.toLowerCase()
        await axios
          .put(`${this.baseurl}/management/studyprivilege/update`, { UserStudyId: this.userstudyid, UserId: this.selecteduser, EmailId: this.useremail, Study: this.values.Studies }, this.headerValues)
          .then(async (res) => {
            window.dispatchEvent(
              new CustomEvent("StudyListChanged", {

              })
            );
            alert(this.$t('navigations.user study privilege updated successfully'));
            await this.resetstudyselected();
            this.$router.push(`/userlisting`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    async resetstudyselected() {
      if (this.values.Studies.includes(store.getters.getStudyIs)) {
        console.log("study selected");
      }
    },
    async checkNoneSelected() {
      if (this.values.Studies == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
    },
    async onclosed() {
      this.$router.push(`/userlisting`);
    },





  },
};
</script>
<style src="@vueform/multiselect/themes/default.css">

</style>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/formpage.css";

.multiselect-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
</style>