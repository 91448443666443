<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
          <h2>General Query</h2>
        </div>
        <div class="col-lg-2">
          <i class="fa fa-times close my-2" @click="onclosed()"></i>
        </div>
    </div>

  <div class="wrapper wrapper-content">
    <div class="ibox-content p-2">
      <div class="form-group row">
        <div class="col-lg-12 mb-1">
          <label class="col-form-label">
            Query Subject <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <input type="text" class="form-control" v-model="values.Subject" />
          <span class="errorClass" v-if="errors[0]">
            Please enter query subject</span
          >
        </div>

        <div class="col-lg-12 mb-1">
          <label class="col-form-label"
            >Query Message <sup><i class="fa fa-asterisk required"></i></sup>
          </label>
          <textarea
            class="form-control my-2 my-lg-0"
            rows="4"
            cols="150"
            v-model="values.Message"
          >
          </textarea>
          <span class="errorClass" v-if="errors[1]"
            >Please enter query message</span
          >
        </div>

        <div class="col-lg-4 mb-1">
          <label class="col-form-label">Priority</label>
          <select
            class="priority-select"
            name="account"
            v-model="values.Priority"
          >
            <option value="">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >Role <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <Multiselect
            @close="onroleClosed()"
            @clear="closebtnclicked()"
            v-model="multiRoleSelectList"
            mode="multiple"
            valueProp="roleID"
            placeholder="Select roles"
            label="roleName"
            :options="roleList"
            :hideSelected="false"
            :closeOnSelect="false"
            :searchable="true"
            trackBy="roleName"
            class="multiselect-theme"
          >
            <template v-slot:option="{ option }">
              {{ option.roleName }}
            </template>
          </Multiselect>
         <span class="errorClass" v-if="errors[3]"
            >Please select role</span>
          
        </div>
        <div class="col-lg-4 mb-1">
          <label class="col-form-label"
            >Recipients <sup><i class="fa fa-asterisk required"></i></sup>
          </label>

         <Multiselect
            @close="onRecipientsClosed()"
            @clear="recepientclosebtnclicked()"
            v-model="values.Recipients"
            mode="multiple"
            valueProp="email"
            placeholder="Select Recipients"
            label="recipients"
            :options="recipients"
            :hideSelected="false"
            :closeOnSelect="false"
            :searchable="true"
             trackBy="email"
            class="multiselect-theme"
          >
            <template v-slot:option="{ option }">
              {{ option.email }}
            </template>
          </Multiselect>
          <span class="errorClass" v-if="errors[2]"
            >{{$t('navigations.please select a recipient')}}</span
          >
        </div>

         <div class="col-lg-12 my-2 d-flex">
					<div class="col-lg-4">
						<div class="form-group">
							<label>
                <input aria-expanded="true" v-model="chksendmail" aria-controls="collapseExample" type="checkbox" @click="check()" >
                  Send an email alert
              </label>
            </div>
            <div class="ibox " id="collapseOne" v-if="checkboxclick">
              <table class="table table-striped table-bordered table-hover dataTables-example dataTable">
                <tbody>
                  <tr v-for="options in emailrecipients" :key="options.id">
                    <td>
                      <input
                        type="checkbox" checked="checked"
                        :value="options.isChecked"
                        v-model="options.isChecked"
                        :id="options.id"
                      />
                      {{ options.evalu }}
                     
                    </td>
                  </tr>
                </tbody>
              </table>
						</div>
          </div>
        </div>

        <div class="col-lg-12 d-flex">
					<div class="col-lg-12 text-right">
          <button
            class="btn btn-primary cancel_btn py-2 px-5 mx-3"
            type="submit"
            @click="ClearData()"
          >
            Clear
          </button>
          <button
            class="btn btn-primary save_btn py-2 px-5"
            type="submit"
            @click="CreateQuery()"
          >
            Submit
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
import { router } from "@/router";
export default {
  name: "generalQuery",
  components: { Multiselect },

  props: { FormId: String },
  data() {
    return {
      chksendmail:"",
      options:{
        isChecked:"",
        },
      checkboxclick:false,
      baseapi: process.env.VUE_APP_Service_URL,
      baseUrl: `${process.env.VUE_APP_Service_URL}/forms/query/`,
      values: {
        Subject: "",
        Message: "",
        Priority: "",
        Recipients: [],
        studyId: "",
      },
      recipients: [],
      subject: "",
      userName: "",
      roleList: [],
      emailrecipients:[],
      multiRoleSelectList: [],
      selectedemailrecipients:[],
      errors: [false, false, false,false],
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
      // this.getRecipients();
       this.getUserName();
       this.setRoleList();
      this.values.studyId = store.getters.getStudyIs;
    });
    //await this.getRecipients();
    await this.getUserName();
    await this.setRoleList();
    this.values.studyId = store.getters.getStudyIs;
  },
  methods: {
    async CreateQuery() {
      const idtoken = store.getters.getIdToken;
      await this.validateIsNull();

      if (!this.errors[0] && !this.errors[1] && !this.errors[2] && !this.errors[3] ){
          await  this.getselectedemailrecepients();
         if(this.values.studyId == "" | this.values.studyId == null ){
        alert("Please select a study.")
      }
      else {
        await axios
              .post(
                `${this.baseapi}/forms/query/create`, {
                //`https://localhost:5001/query/create`,{
                queryId: "",
                formId: "",
                studyId: this.values.studyId,
                folderId: "",
                siteId: "",
                subjectId: "",
                visitId: "",
                queryFor: "General Query",
                query_Subject: this.values.Subject,
                priority: this.values.Priority =="" ? "Low" : this.values.Priority,
                pageNo: 0,
                fieldName: "",
                queryOpenStatus: "true",
                queryResponseStatus: true,
                closeBy: "",
                emailRecepients:this.selectedemailrecipients,
                messages: [
                  {
                    queryMessage: this.values.Message,
                    raisedBy: this.userName,
                    raisedDate: new Date().toISOString(),
                    messagesRecipients: [
                      {
                        recipients: this.values.Recipients,
                      },
                    ],
                  },
                ],
              },
              {
                headers: {
                  Authorization: "Bearer " + idtoken,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log("create Query ", res);
            })
            .catch((err) => console.log("error", err));
          alert(this.$t('navigations.query generated successfully'));
          await this.ClearData();
          this.$router.push(`/general_querylist`);
        }
      }
    },
    async ClearData() {
      this.chksendmail="",
      this.checkboxclick=false;
      this.multiRoleSelectList=[],
       await this.onroleClosed(),
      console.log("clear Query..!");
      this.values = {
        Subject: "",
        Message: "",
        Priority: "",
        Recipients: [],
        studyId:  store.getters.getStudyIs,

      };
    },
    async setRoleList() {
     //  console.log("roles here.");
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/account-core/roles/rolelist`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        ).then((res) => {

          this.roleList = res.data;
          console.log("roles are..", this.roleList);

        })
    },
    async onRecipientsClosed(){
      this.selectedemailrecipients.splice(0);

      this.values.Recipients.forEach((recipient) => {
        const exists = this.emailrecipients.find((emailRecipient) => emailRecipient.evalu === recipient);
        if (!exists) {
          let eusers = {
            id: String,
            evalu: String,
            isChecked: Boolean,
          };
          eusers.evalu = recipient;
          eusers.isChecked=true;
          this.emailrecipients.push(eusers);
        }
      });
      this.emailrecipients.forEach((emailRecipient) => {
        const exists = this.values.Recipients.find((recipient) => emailRecipient.evalu === recipient);
        if (!exists) {
          const index = this.emailrecipients.indexOf(emailRecipient);
          this.emailrecipients.splice(index,1);
        }
      });
         
    },
    async closebtnclicked(){
            this.emailrecipients.splice(0);
            this.recipients.splice(0);
            this.values.Recipients.splice(0);   
    },
    async check(){
    console.log("is check clicked",this.checkboxclick)  
          if(this.checkboxclick)
          { 
            this.checkboxclick =false;
            this.emailrecipients = [];
          }else{  
              this.checkboxclick =true;
              this.emailrecipients = [];
              this.values.Recipients.forEach((element) => {
                let eusers = {
                  id: String,
                  evalu: String,
                  isChecked: Boolean,
                };
              
                  eusers.evalu = element;
                  eusers.isChecked=true;
                  this.emailrecipients.push(eusers);
                
              });
          }
      
    },
    async getselectedemailrecepients()
    {
      if(this.checkboxclick ==true){
      this.emailrecipients.forEach((element) => {
         console.log("IIFREselectedemailrecipients",element)
          console.log("OOREselectedemailrecipients",element.evalu)
        if (element.isChecked) {
           console.log("BFREselectedemailrecipients",element)
          this.selectedemailrecipients.push(element.evalu);
        }
      });
      }else{
        console.log("Unchecked!")
      }
    },
    async onroleClosed(){
            this.emailrecipients.splice(0);
            this.recipients.splice(0);
            this.values.Recipients.splice(0);
            await this.getrolebasedrecepients();
           
    },
    async getrolebasedrecepients() {
      const valnow = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      // console.log("rolechangeEevent",this.multiRoleSelectList);
    var rcount = this.multiRoleSelectList.length;
    // console.log("rolechangeEevent_COunt",rcount);
     if(rcount>0){
    var fullroleids="";
    for(var i=0;i<rcount;i++){
      fullroleids =fullroleids+ this.multiRoleSelectList[i]+",";
     // console.log("rolechangeEevent_COunt",rcount);
    }
      await axios
        .get(
         // `https://localhost:5001/user/getusersbyrole?roleids=`+fullroleids+`&&studyid=`+valnow,
          `${this.baseapi}/account-core/user/getusersbyrole?roleids=`+fullroleids+`&&studyid=`+valnow,{
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.recipients=[];
           res.data.forEach(element => {
             console.log(this.userName , element.email)
             if(element.email!=this.userName){this.recipients.push(element)}
           }); 
           
         // console.log("Got existing screens and controls",res.data);
          
        })
        .catch((err) => {
        //  console.log("Have errorrrr After listed existingdata");
          console.log(err);
        });
        }
        else{
            this.recipients =[];
        }
    },
    async recepientclosebtnclicked(){
  
          console.log("RECP CLOSED",this.multiRoleSelectList);
          
              this.emailrecipients.splice(0);
          
    },
    /*async getRecipients() {
      const valnow = await store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("get recipients" + valnow);
      await axios
        .get(
          `${this.baseapi}/management/study/getusersinstudy?StudyId=${valnow}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.recipients = res.data;
          console.log("Users......", this.recipients);
        })
        .catch((err) => {
          console.log(err);
        });
    },*/
    async jwtDecrypt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      return JSON.parse(jsonPayload);
    },
    async getUserName() {
      const idToken = store.getters.getIdToken;
      const jwtDecodedValue = await this.jwtDecrypt(idToken);
      this.userName = jwtDecodedValue.name;
      console.log("token result", this.userName);
    },
    async onclosed() {
      this.$router.push(`/home`);
    },
    async validateIsNull() {
      if (this.values.Subject == "") {
        this.errors[0] = true;
      } else {
        this.errors[0] = false;
      }
      if (this.values.Message == "") {
        this.errors[1] = true;
      } else {
        this.errors[1] = false;
      }

      if (this.values.Recipients == "") {
        this.errors[2] = true;
      } else {
        this.errors[2] = false;
      }
      if (this.multiRoleSelectList == "") {
        this.errors[3] = true;
      } else {
        this.errors[3] = false;
      }
    },
  },
};
</script>
<style scoped>
@import "../../assets/savepages.css";
.multiselect-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
.priority-select{
  display: block;
  width: 100%;
  padding: .45rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .25rem;
}
.priority-select:focus,
.priority-select:focus-within,
.priority-select:focus-visible
{
  border-color:#145faf!important;
  box-shadow:none !important;
  outline: none;
}
</style>