<template>
  <div class="h-100 d-flex align-items-center">
    <div
      class="tablebox-design middlebox-design text-center reset-password-block p-3"
    >
      <div>
        <div class="text-design">Set a new password for your account</div>
      </div>
      <div class="text-two-design">
        Email : <strong>{{ this.ProfileData.emailIdPrimary }}</strong>
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="New Password"
          required=""
          maxlength="16"
          v-model="changepassword.newpassword"
        />
        <div class="text-start">
          <span class="errormsg" v-if="errors[0] == false">
            Please enter a password</span
          >
          <span class="errormsg" v-if="errors[1] == false"
            >Passwords must contain minimum eight characters, and maximum 16
            characters <br />
            including uppercase, lowercase letters, special characters and
            numbers.</span
          >
        </div>
      </div>
      <div class="form-group">
        <input
          type="password"
          class="form-control"
          placeholder="Confirm Password"
          required=""
          maxlength="16"
          v-model="changepassword.confirmpassword"
        />
        <div class="text-start">
          <span class="errormsg" v-if="errors[2] == false">
            Please enter confirm password</span
          >
          <span class="errormsg" v-if="errors[3] == false"
            >Passwords must contain minimum eight characters, and maximum 16
            characters <br />
            including uppercase, lowercase letters, special characters and
            numbers.</span
          >
          <span class="errormsg" v-if="errors[4] == false"
            >Password and confirm password mismatch!</span
          >
        </div>
      </div>
      <button
        type="submit"
        class="btn-primary btn-block m-b btn-colour"
        @click.prevent="confirmClicked()"
      >
        Submit
      </button>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  props: {
    token: {
      type: String,
    },
    resetType: {
      type: String,
    },
    source: {
      type: String,
      default: "",
    },
  },

  name: "ResetPassword",
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      accountBaseUrl: `${process.env.VUE_APP_Service_URL}/account-core/user/`,
      passwordData: "",
      ProfileData: {
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        active: true,
        emailIdPrimary: "",
        emailIdSecondary: "",
        phoneNumber: "",
        userType: "",
        addressLineOne: "",
        addressLineTwo: "",
        country: "",
        userId: "",
        password: "",
        userType: "",
        role: "",
      },

      changepassword: {
        newpassword: "",
        confirmpassword: "",
      },

      value: {
        createdUserEmail: "vipinv@datamatica.uk",
        createdUserId: "auth0|61f28ddadaa5cc006ace40ba",
        createdAt: "2021-10-27T09:30:08.205Z",
        lastModifiedUserEmail: "vipinv@datamatica.uk",
        lastModifiedUserId: "auth0|61f28ddadaa5cc006ace40ba",
        lastModifiedAt: "2022-02-02T03:18:19.026Z",
        userID: "auth0|61f28ddadaa5cc006ace40ba",
        userEmail: "vipinv@datamatica.uk",
        studyID: "ee36418d-c121-4fd7-b332-ffccc6b690ff",
        createdDate: "2021-10-27T09:30:08.205Z",
      },
      validations: [false, false, false, false, false],
      errors: [],
    };
  },
  async mounted() {
    console.log("Source is", typeof this.source);
    if (this.resetType == "reset" || this.resetType == "reset") {
      await this.expiredPasswordReset();
    } else {
      await this.getAndSetProfileData();
    }
    await this.Disablemenu();
    // await this.getAndSetProfileData();
  },

  async unmounted() {
    await this.Enablemenu();
  },

  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper");
      navbardefault.style.width = "100%";
      navbardefault.style.paddingRight = "0px";
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
    async getAndSetProfileData() {
      await axios
        .get(this.accountBaseUrl + "gettokenstatus?token=" + this.token)
        .then((res) => {
          res.data.dateOfBirth =
            res.data.dateOfBirth === null
              ? ""
              : res.data.dateOfBirth.slice(0, 10);
          this.ProfileData = res.data;
          console.log("user Data", res.data);
          console.log("profile", this.ProfileData.emailIdPrimary);
        })
        .catch((err) => {
          console.log(err);
          alert("The user already changed the password!");
          this.$router.push(`/`);
        });
    },
    async expiredPasswordReset() {
      await axios
        .get(this.accountBaseUrl + "isuserfound?emailId=" + this.token)
        // .get( "https://localhost:5001/user/gettokenstatus?token=" + this.token)
        .then((res) => {
          res.data.dateOfBirth =
            res.data.dateOfBirth === null
              ? ""
              : res.data.dateOfBirth.slice(0, 10);
          this.ProfileData = res.data;
          console.log("user Data", res.data);
          console.log("profile", this.ProfileData.emailIdPrimary);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async update() {
      if (this.ProfileData.dateOfBirth === "") {
        this.ProfileData.dateOfBirth = null;
      }
      this.passwordData = this.changepassword.newpassword;
      await axios
        .put(
          this.accountBaseUrl + "updatepassword",
          {
            userId: this.ProfileData.userId,
            password: this.passwordData,
          },
          this.Headers
        )
        .then((res) => {
          if (this.source === "Mobapp" || this.source === "Mobapp") {
            this.$router.push(`/ConfirmationScreen`);
          } else if (this.source === "Webapp" || this.source === "Webapp") {
            alert("Your password has been reset successfully");
            this.$router.push(`/`);
          }
        })
        .catch((err) => {
          alert(
            "This password has previously been used, Please use a new password"
          );
          this.passwordData = "";
        });
    },
    async confirmClicked() {
      await this.validateallpassword();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      } else {
        this.update();
      }
    },

    async validateallpassword() {
      this.Validatepassword(this.changepassword.newpassword);
      this.Validateconfirmpassword(this.changepassword.confirmpassword);
      this.Validatematchpasswords(
        this.changepassword.newpassword,
        this.changepassword.confirmpassword
      );
    },

    async Oldpasswordused() {
      this.validations[5] = false;
    },

    async Validatepassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        this.validations[1] = true;
        this.validations[0] = false;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        this.validations[0] = true;

        this.validations[1] = false;
      } else {
        this.validations[0] = true;
        this.validations[1] = true;
      }
    },

    async Validateconfirmpassword(passwd) {
      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if (passwd == "" || passwd == "" || passwd == null) {
        this.validations[3] = true;
        this.validations[2] = false;
      } else if (
        passwd.length < 8 ||
        !reLow.test(passwd) ||
        !reNum.test(passwd) ||
        !spcl.test(passwd) ||
        !reUpp.test(passwd)
      ) {
        this.validations[2] = true;

        this.validations[3] = false;
      } else {
        this.validations[2] = true;
        this.validations[3] = true;
      }
    },

    async Validatematchpasswords(passwd, confirmpaswd) {
      if (
        this.validations[0] == true &&
        this.validations[1] == true &&
        this.validations[2] == true &&
        this.validations[3] == true
      ) {
        console.log("validate paswds");
        if (passwd == confirmpaswd) {
          this.validations[4] = true;
          console.log(" paswds ok");
        } else {
          this.validations[4] = false;
          console.log("paswds mismatch");
        }
      } else {
        console.log("final condtn paswds");
        this.validations[4] = true;
      }
    },
    async onclosed() {
      this.$router.push(`/`);
    },
  },
};
</script>

<style>
@import "../../assets/css/style.css";

.tablebox-design {
  width: 400px;
  margin-top: 100px;
}

.middlebox-design {
  margin: 0 auto;
}

.reset-password-block {
  width: min(90%, 400px);
}

.text-design {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #145faf;
}

.text-two-design {
  margin-top: 0;
  margin-bottom: 1rem;
  color: #676a6c;
}

.small-text2 {
  font-size: 80%;
  font-weight: 400;
}

.msg {
  margin-bottom: 10px;
}

.textright {
  margin-left: 1750px;
  margin-top: 50px;
}
</style>

  
