/* eslint-disable */
const state = () => ({
    // targetPatientId: localStorage.getItem('targetPatient'),
    IsFormTransferred:localStorage.getItem('IsFormTransfer'),
    sourceScheduleId: localStorage.getItem('sourceSchedule'),
    targetScheduleId: localStorage.getItem('targetSchedule'),
    sourceFormId: localStorage.getItem('sourceForm'),
    targetFormId: localStorage.getItem('targetForm'),
    sourceVisitName: localStorage.getItem('sourceVisit'),
    targetVisitName: localStorage.getItem('targetVisit'),
    sourceSiteName: localStorage.getItem('sourceSiteName'),
    targetSiteName: localStorage.getItem('targetSiteName'),
    sourcePatientName: localStorage.getItem('sourcePatientName'),
    targetPatientName: localStorage.getItem('targetPatientName'),
    formPageRefresh:localStorage.getItem("formRefresh"),
});

const getters = {
    //  getTargetPatientId(state: { targetPatientId: any }) {
    //      return state.targetPatientId;
    //  },
    getFormRefresh(state: { formPageRefresh: any }) {
		return state.formPageRefresh;
	},
    getIsFormTransfer(state: { IsFormTransferred: any }) {
         return state.IsFormTransferred;
     },
    getSourceScheduleId(state: { sourceScheduleId: any }) {
        return state.sourceScheduleId;
    },
    getTargetScheduleId(state: { targetScheduleId: any }) {
        return state.targetScheduleId;
    },
    getSourceFormId(state: { sourceFormId: any }) {
        return state.sourceFormId;
    },
    getTargetFormId(state: { targetFormId: any }) {
        return state.targetFormId;
    },
    getSourceVisitName(state: { sourceVisitName: any }) {
        return state.sourceVisitName;
    },
    getTargetVisitName(state: { targetVisitName: any }) {
        return state.targetVisitName;
    },
    getSourceSiteName(state: { sourceSiteName: any }) {
        return state.sourceSiteName;
    },
    getTargetSiteName(state: { targetSiteName: any }) {
        return state.targetSiteName;
    },
    getSourcePatientName(state: { sourcePatientName: any }) {
        return state.sourcePatientName;
    },
    getTargetPatientName(state: { targetPatientName: any }) {
        return state.targetPatientName;
    },
};

const actions = {
    // async setTargetPatientId({ commit }: any, data: any) {
    //     commit("setTargetPatientId", data);
    // },
    async setFormRefresh({ commit }: any, data: any) {
		commit("setFormRefresh", data);
	},
    async setIsFormTransfer({ commit }: any, data: any) {
        commit("setIsFormTransfer", data);
    },
    async setSourceScheduleId({ commit }: any, data: any) {
        commit("setSourceScheduleId", data);
    },
    async setTargetScheduleId({ commit }: any, data: any) {
        commit("setTargetScheduleId", data);
    },
    async setSourceFormId({ commit }: any, data: any) {
        commit("setSourceFormId", data);
    },
    async setTargetFormId({ commit }: any, data: any) {
        commit("setTargetFormId", data);
    },
    async setSourceVisitName({ commit }: any, data: any) {
        commit("setSourceVisitName", data);
    },
    async setTargetVisitName({ commit }: any, data: any) {
        commit("setTargetVisitName", data);
    },
    async setSourceSiteName({ commit }: any, data: any) {
        commit("setSourceSiteName", data);
    },
    async setTargetSiteName({ commit }: any, data: any) {
        commit("setTargetSiteName", data);
    },
    async setSourcePatientName({ commit }: any, data: any) {
        commit("setSourcePatientName", data);
    },
    async setTargetPatientName({ commit }: any, data: any) {
        commit("setTargetPatientName", data);
    },
};

const mutations = {
    // setTargetPatientId(state: { targetPatientId: any }, sourcePatientData: any) {
    //     state.targetPatientId = sourcePatientData;
    //     localStorage.setItem("targetPatient", sourcePatientData);
    // },
    setFormRefresh(state: { formPageRefresh: any }, formData: any) {
		state.formPageRefresh = formData;
		localStorage.setItem("formRefresh", formData);
	},
    setIsFormTransfer(state: { IsFormTransferred: any }, transferData: any) {
        state.IsFormTransferred = transferData;
        localStorage.setItem("IsFormTransfer", transferData);
    },
    setSourceScheduleId(state: { sourceScheduleId: any }, sourceScheduleData: any) {
        state.sourceScheduleId = sourceScheduleData;
        localStorage.setItem("sourceSchedule", sourceScheduleData);
    },
    setTargetScheduleId(state: { targetScheduleId: any }, targetScheduleData: any) {
        state.targetScheduleId = targetScheduleData;
        localStorage.setItem("targetSchedule", targetScheduleData);
    },
    setSourceFormId(state: { sourceFormId: any }, sourceFormData: any) {
        state.sourceFormId = sourceFormData;
        localStorage.setItem("sourceForm", sourceFormData);
    },
    setTargetFormId(state: { targetFormId: any }, targetFormData: any) {
        state.targetFormId = targetFormData;
        localStorage.setItem("targetForm", targetFormData);
    },
    setSourceVisitName(state: { sourceVisitName: any }, sourceVisitData: any) {
        state.sourceVisitName = sourceVisitData;
        localStorage.setItem("sourceVisit", sourceVisitData);
    },
    setTargetVisitName(state: { targetVisitName: any }, targetVisitData: any) {
        state.targetVisitName = targetVisitData;
        localStorage.setItem("targetVisit", targetVisitData);
    },
    setSourceSiteName(state: { sourceSiteName: any }, sourceSiteNameData: any) {
        state.sourceSiteName = sourceSiteNameData;
        localStorage.setItem("sourceSiteName", sourceSiteNameData);
    },
    setTargetSiteName(state: { targetSiteName: any }, targetSiteNameData: any) {
        state.targetSiteName = targetSiteNameData;
        localStorage.setItem("targetSiteName", targetSiteNameData);
    },
    setSourcePatientName(state: { sourcePatientName: any }, sourcePatientNameData: any) {
        state.sourcePatientName = sourcePatientNameData;
        localStorage.setItem("sourcePatientName", sourcePatientNameData);
    },
    setTargetPatientName(state: { targetPatientName: any }, targetPatientNameData: any) {
        state.targetPatientName = targetPatientNameData;
        localStorage.setItem("targetPatientName", targetPatientNameData);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}