import store from "../../store/index";
import axios from "axios";
import Forms_AI_PopUp from "../../components/Forms_AI_PopUp/Forms_AI_PopUp.vue";
export default {
  name: "FormSuggestions",
  components: {
    Forms_AI_PopUp,
  },
  props: {
    formList: {
      type: Array,
      default: [],
    },
  },
  beforeMount() {
    const encodedFormList = this.$route.query.formList;
    const decodedFormList = JSON.parse(decodeURIComponent(encodedFormList));
    this.parsedFormList = decodedFormList;
    this.parsedFormList.forEach((element) => {
      this.allNameList.push(element.Label);
    });
  },
  async mounted() {
    this.head = {
      headers: {
        Authorization: "Bearer " + this.token,
        "Content-Type": "application/json",
      },
    };
    this.getListLib(this.allNameList);
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      token: store.getters.getIdToken,
      parsedFormList: [],
      AIpopup: false,
      formList: [],
      listNames: [],
      allNameList: [],
      formLibList: [],
    };
  },
  methods: {
    getData(data) {
      this.parsedFormList = data;
      this.parsedFormList.forEach((element) => {
        this.listNames.push(element.Label);
      });
      this.allNameList = [];
      this.parsedFormList.forEach((element) => {
        this.allNameList.push(element.Label);
      });
      this.getListLib(this.allNameList);
      console.log("Name is", this.listNames);
    },
    removeItem(index) {
      const confirmDelete = confirm(
        "Are you sure you want to remove this from ?"
      );
      if (confirmDelete) {
        this.formLibList.splice(index, 1);
      }
    },
    async retrieveName(item) {
      this.$router.push(
        `/viewFormTemplate/templatelisting/${item.formLibraryId}`
      );
    },
    async getListLib(names) {
      axios
        .post(
          `${this.baseurl}/forms/formslibrary/getbynames`,
          names,
          this.head
        )
        .then((res) => {
          console.log("Res From APi", res);
          this.formLibList = res.data;
          console.log("LIST IS", this.formLibList);
        })
        .catch((err) => console.log("error in get study", err));
    },
    async save() {
      await axios
        .post(
          `${this.baseurl}/forms/template/copyfromlibrary`,
          {
            formLibraryIds: this.formLibList.map((X) => X.formLibraryId),
            studyId: store.getters.getStudyIs,
          },
          this.head
        )
        .then((res) => {
          console.log("res from server", res);
          alert("Forms added to the study")
          this.$router.push(
            `/studyProfile`
          );
        })
        .catch((err) => {
          console.log("err",err);
        });
    },
  },
};
