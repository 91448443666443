/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
    roleprivilegesdata: localStorage.getItem('roleprivilegedata'),
});
const getters = {
      getRolesprivilegeData(state: { roleprivilegesdata: any }){
        return state.roleprivilegesdata;
      },
};

const actions = {
     async setrolesprivileges({commit}: any, data: any) {
      localStorage.setItem("roleprivilegedata", 'test test,');
         console.log("roleprivilegedata in vuex issssssssssssssssss");
         console.log(data);
            commit("setroleprivileges", data+',test');
                    }
};

const mutations = {
    setroleprivileges(state: { roleprivilegesdata: any }, userroleprivilege: any){
          if(userroleprivilege){
        state.roleprivilegesdata = userroleprivilege;
        localStorage.setItem("roleprivilegedata",userroleprivilege +',test');
          }
          else{
            state.roleprivilegesdata = 'test test,';
          }
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}