<template>
  <div class="pr-0 pl-1 graphminwidth">
    <div class="ibox-body">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5 class="mt-1">{{ $t("navigations.subjects") }}</h5>
        <select
          class="forms-control width-40"
          v-model="siteCode"
          @change="patientSitebased($event.target.value)"
          placeholder="Search"
        >
          <option value="" selected hidden>{{
            $t("navigations.filter by site code")
          }}</option>
          <option
            v-for="site in sites"
            :key="site.siteID"
            :value="site.siteID"
            >{{ site.siteCode }}</option
          >
        </select>
        <div class="graphbtncontainer">
          <div class="graphbutton">
            <img
              src="../../assets/images/bar_clr.png"
              @click.prevent="secondchartchange('bar')"
              alt="Bar Chart Button"
            />
          </div>
          <div class="graphbutton">
            <img
              src="../../assets/images/horiz_bar_clr.png"
              @click.prevent="secondchartchange('barhorizondal')"
              alt="Horizontal Bar Chart Button"
            />
          </div>
          <div class="graphbutton">
            <img
              src="../../assets/images/Line_clr.png"
              @click.prevent="secondchartchange('line')"
              alt="Line Chart Button"
            />
          </div>
          <div class="graphbutton">
            <img
              src="../../assets/images/Pie_clr.png"
              @click.prevent="secondchartchange('pie')"
              alt="Pie Chart Button"
            />
          </div>
          <div class="graphbutton">
            <img
              src="../../assets/images/bubble_clr.png"
              @click.prevent="secondchartchange('scatter')"
              alt="Scatter Chart Button"
            />
          </div>
        </div>
      </div>
      <div class="ibox-content">
        <div>
          <apexchart
            v-if="
              patientChart.charttype != 'pie' &&
                patientChart.charttype != 'barhorizondal'
            "
            width="100%"
            height="250px"
            :type="patientChart.charttype"
            :options="patientChart.chartOptions"
            :series="patientChart.dataseries"
          ></apexchart>
          <apexchart
            v-if="patientChart.charttype == 'barhorizondal'"
            width="100%"
            height="250px"
            type="bar"
            :options="patientbarchart.chartOptions"
            :series="patientbarchart.dataseries"
          ></apexchart>

          <apexchart
            v-if="patientChart.charttype == 'pie'"
            width="100%"
            height="250px"
            :type="patientChart.charttype"
            :options="patientChart.piechartOptions"
            :series="patientChart.piedataseries"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "@/store";
import Chart from "chart.js";

export default {
  name: "subjectchart",
  data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      activeformcount: "",
      sites:[],
      firstpiechartoptions: {
        chart: {
          width: "100%",
          type: "pie",
        },
        labels: [
          this.$t("navigations.new"),
          this.$t("navigations.open"),
          this.$t("navigations.enrolled"),
          this.$t("navigations.completed"),
          this.$t("navigations.terminated"),
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              pie: {
                size: 200,
              },
              chart: {
                toolbar: {
                  show: false,
                },
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      patientChart: {
        charttype: "line",
        chartOptions: {
          stroke: {
            width: 2,
          },
          chart: {
            toolbar: {
              show: false,
            },
            id: "vuechart-example",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          xaxis: {
            categories: [
              this.$t("navigations.new"),
              this.$t("navigations.enrolled"),
              this.$t("navigations.withdrawn"),
              this.$t("navigations.completed"),
              this.$t("navigations.visit ongoing"),
            ],
          },
        },
        series: [
          {
            name: this.$t("navigations.patient"),
            data: [0, 0, 0, 0],
          },
        ],
        dataseries: "",
        piechartOptions: {
          chart: {
            width: "100%",
            type: "pie",
          },
          labels: [
            this.$t("navigations.new"),
            this.$t("navigations.enrolled"),
            this.$t("navigations.withdrawn"),
            this.$t("navigations.completed"),
            this.$t("navigations.visit ongoing"),
          ],
          responsive: [
            {
              breakpoint: 380,
              options: {
                chart: {
                  toolbar: {
                    show: false,
                  },
                  width: 100,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        piedataseries: [2, 3, 2, 1],
      },
      patientbarchart: {
        charttype: "bar",
        chartOptions: {
          stroke: {
            width: 2,
          },
          chart: {
            toolbar: {
              show: false,
            },
            id: "vuechart-example",
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          xaxis: {
            categories: [
              this.$t("navigations.new"),
              this.$t("navigations.enrolled"),
              this.$t("navigations.withdrawn"),
              this.$t("navigations.completed"),
              this.$t("navigations.visit ongoing"),
            ],
          },
        },
        series: [
          {
            name: this.$t("navigations.patient"),
            data: [0, 0, 0, 0],
          },
        ],
        dataseries: [],
      },
    };
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      this.sitedata();
      await this.GetpatientChartData();
    });
    this.sitedata();
    await this.GetpatientChartData();
  },
  methods: {
    async patientSitebased(siteID) {
      let patientArray = [];
      const idtoken = store.getters.getIdToken;
      const StudyId = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/patientsummery?studyId=${StudyId}&siteId=${siteID}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          patientArray = [
            res.data.New == null ? 0 : res.data.New,
            res.data.Enrolled == null ? 0 : res.data.Enrolled,
            res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
            res.data.Completed == null ? 0 : res.data.Completed,
            res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"],
          ];
          this.patientChart.dataseries = [
            {
              name: this.$t("navigations.patient"),
              data: [
                res.data.New == null ? 0 : res.data.New,
                res.data.Enrolled == null ? 0 : res.data.Enrolled,
                res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
                res.data.Completed == null ? 0 : res.data.Completed,
                res.data["Visit Ongoing"] == null
                  ? 0
                  : res.data["Visit Ongoing"],
              ],
            },
          ];
          this.patientbarchart.dataseries = [
            {
              data: patientArray,
            },
          ];
          this.patientChart.piedataseries = this.patientChart.dataseries[0].data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetpatientChartData() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      let patientArray = [];
      await axios
        .get(
          `${this.baseapi}/management/patient/patientsummery?studyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          patientArray = [
            res.data.New == null ? 0 : res.data.New,
            res.data.Enrolled == null ? 0 : res.data.Enrolled,
            res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
            res.data.Completed == null ? 0 : res.data.Completed,
            res.data["Visit Ongoing"] == null ? 0 : res.data["Visit Ongoing"],
          ];
          this.patientChart.dataseries = [
            {
              name: this.$t("navigations.subjects"),
              data: [
                res.data.New == null ? 0 : res.data.New,
                res.data.Enrolled == null ? 0 : res.data.Enrolled,
                res.data.Withdrawn == null ? 0 : res.data.Withdrawn,
                res.data.Completed == null ? 0 : res.data.Completed,
                res.data["Visit Ongoing"] == null
                  ? 0
                  : res.data["Visit Ongoing"],
              ],
            },
          ];
          this.patientChart.piedataseries = this.patientChart.dataseries[0].data;
          this.patientbarchart.dataseries = [
            {
              name: this.$t("navigations.subjects"),
              data: patientArray,
            },
          ];
        })
        .catch((err) => {
          console.log(err);
        });
      this.patientChart.charttype = "barhorizondal";
    },
    async secondchartchange(val) {
      if (val == "barhorizondal") {
        await this.switchbar(true);
        this.patientChart.charttype = "bar";
      } else {
        await this.switchbar(false);
        this.patientChart.charttype = val;
      }
    },
    async switchbar(res) {
      this.patientChart.chartOptions.plotOptions = { bar: { horizontal: res } };
      this.patientChart.charttype = "";
    },
    async sitedata() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      this.sites = [];
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&sortProperty=siteCode&IsDes=false&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("The sites are", res.data);
          res.data.filter((item) => {
            this.sites.push(item);
          });
          console.log("Site is", this.sites);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.dashlet{
    width:100%;
    height:100%;
  }
.graphbutton {
  width: fit-content;
  padding: 3px;
}
.graphbutton:hover {
  cursor: pointer;
  border-color: #3c4192;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.graph-block {
  background-color: #ffffff;
  border-radius: 7px;
  box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
}
.graph-block__content {
  padding: 0.5rem 1.3rem;
}
.graphminwidth {
  min-width: 27rem;
}
.site-block {
  position: relative;
}
</style>
