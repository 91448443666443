<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/new_forms.png" alt="New Forms">
              </div>
              <h5>{{ $t('navigations.new forms')}}</h5>
          </div>
          <div class="ibox-content contentheight">
              <h1 class="no-margins">{{formcountstd}}</h1>
              <small>{{ $t('navigations.new forms')}}</small>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'newforms',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      formcountstd:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getformcount();
		});
    await this.getformcount();
  },
    methods:{
      async getformcount() {
      const idtoken = store.getters.getIdToken;
      const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/forms/forms/getcounts?studyId=${currentstudyid}`,
          {
            headers: {
              "Access-Control-Allow-Origin": 'localhost:8080', 
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.formcountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>