<template>
    <div>
    <div class="add_form_modal_wrapper">
      <div class="add_form_modal__containers">
        <div class="add-block-title d-flex justify-content-between align-items-center px-3 py-2">
          <h4 class="my-0">Create Form</h4>
          <a class="close-link">
            <i class="fa fa-times" @click.prevent="$emit('closeModal')"></i>
          </a>
        </div>
        <div class="row w-100 mx-auto">
          <div class="col-lg-12 py-3">
            <label for="form-name">Name</label>
            <input type="text" maxlength="50" class="form-control mb-3" id="form-name" v-model="formName" placeholder="Enter form name"
              aria-label="Form name" aria-describedby="form name">
            <button type="button" class="btn add_btn d-flex mx-auto px-3 mb-3" @click.prevent="createForm()">Submit</button>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>
<script src="./createForm.js"></script>

<style scoped>
.add_form_modal_wrapper {
  position: absolute;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
  overflow: hidden;
  z-index: 3000;
}

.add_form_modal__containers {
  width: min(400px, 90%);
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.add-block-title {
  color: #ffffff;
  background-color: #768DD3;
}
.add_btn{
    color: #ffffff;
    background-color: #768DD3;
}
.add_btn:is(:hover,:focus,:active){
    background-color: #768DD3;
}
</style>