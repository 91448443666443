/* eslint-disable */
// import {tokenAlive} from "@/Shared/jwtHelper";
const state = () => ({
    visitdetails: localStorage.getItem('visitdetails'),
});
const getters = {
      getvisitdetails(state: { visitdetails: any }){
        return state.visitdetails;
      },
};

const actions = {
     async commitvisitdetails({commit}: any, data: any) {

           commit("setvisitdetails", data);
                    }
};

const mutations = {
    setvisitdetails(state: { visitdetails: any }, templatevisitdata: any){

        state.visitdetails = templatevisitdata;
        localStorage.setItem("visitdetails", templatevisitdata);
      }
};

export default {
    state,
    getters,
    actions,
    mutations
}