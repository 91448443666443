<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.iot devices')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-body">
        <div class="ibox-title style_2">
           <h5 class="float-left">{{$t('navigations.iot devices')}}</h5>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped table-bordered dataTables">
              <thead>
                <tr>
                  <th>{{$t('navigations.category')}}</th>
                  <th>{{$t('navigations.name')}}</th>
                  <th>{{$t('navigations.id')}}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in deviceArray" :key="item.uniqueID">
                  <td>{{ item.category }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.uniqueID }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
  </div>
</template>
<script src="./device.js">
</script>
