/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
<template>
  <div>
    <navebar />
  </div>
</template>

<script>
/* eslint-disable */
import navebar from "./commom/navebar/navbar.vue";
export default {
  name: "App",
  components: {
    navebar,
  }
};
</script>
