<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{$t('navigations.document summary')}}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
<div class="row">
     <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/new_forms.png" alt="New Forms">
              </div>
              <h5>{{ $t('navigations.pending approvals count')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{pendingapprovalcount}}</h1>
              <small>{{ $t('navigations.pending approvals count')}}</small>
          </div>
        </div>
      </div> 
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_users.png" alt="Total Users">
              </div>
              <h5>{{ $t('navigations.approved count')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{approvedCount}}</h1>
              <small>{{ $t('navigations.approved count')}}</small>
          </div>
        </div>
      </div>  
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_sites.png" alt="Total Sites">
              </div>
              <h5>{{ $t('navigations.total sites')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins"> {{ siteCount }}</h1>
          <small>{{ $t('navigations.total sites')}}</small>  
          </div>
        </div>
      </div>  
      <div class="col-lg-3 mb-1 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_patients.png" alt="Total Patients">
              </div>
              <h5>{{ $t('navigations.general documents count')}}</h5>
          </div>
          <div class="ibox-content">
              <h1 class="no-margins">{{generaldocscount}}</h1>
              <small>{{ $t('navigations.general documents count')}}</small>
          </div>
        </div>
      </div>   
</div>
<div class="row">
<div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="mt-1">{{$t('navigations.pending approvals')}}</h5>
          </div> 
          <div class="ibox-content">
            <div class="table-responsive fixed-height">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                     <th
                  class="sort_block"
                  @click="SortSelected('documentName', 1)"
                  id="1"
                >
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.document") }}            
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>
                 <th
                  class="sort_block"
                  @click="SortSelected('approvedByName', 2)"
                  id="2"
                >
                  <div class="d-flex flex-row justify-content-between"> 
                    {{ $t("navigations.approver") }}             
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
                  
                </th>
                 <th
                  class="sort_block"
                  @click="SortSelected('assignedByName', 3)"
                  id="3"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.assigned by") }}           
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>
                 <th
                  class="sort_block"
                  @click="SortSelected('assignedDateTime', 4)"
                  id="4"
                >
                  <div class="d-flex flex-row justify-content-between">     
                    {{ $t("navigations.assigned date") }}         
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
                 
                </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in pendingapprovallist" :key="list.assignedDateTime">
                    
                    <td>{{ list.documentName }}</td>
                    <td>{{ list.approvedByName }}</td>
                    <td>{{ list.assignedByName }}</td>
                    <td>{{ filter(list.assignedDateTime) }}</td>
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-1 mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentPage"
                      :pages="totalPage"
                      :range-size="0"
                      active-color="#618bd7"
                      @update:modelValue="loadPendingPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="mt-1">{{$t('navigations.recent activities')}}</h5>
          </div> 
          <div class="ibox-content">
            <div class="table-responsive fixed-height">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th
                  class="sort_block"
                  @click="SortSelected1('documentName', 5)"
                  id="5"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.document") }}           
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                  
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected1('documentType', 6)"
                  id="6"
                >
                  <div class="d-flex flex-row justify-content-between">
                    {{ $t("navigations.document type") }}              
                    <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span>
                </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected1('typeValue', 7)"
                  id="7"
                >
                 
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.document value") }}           
                                   <span>
                    <i class="fa fa-long-arrow-up"></i>
                    <i class="fa fa-long-arrow-down"></i>
                  </span></div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected1('createdUserName', 8)"
                  id="8"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.created by") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected1('CreatedAt', 9)"
                  id="9"
                >
                  <div class="d-flex flex-row justify-content-between">   
                    {{ $t("navigations.created on") }}           
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in recentstudylist" :key="list.documentReferenceId">
                    <td>{{ list.documentName }}</td>
                    <td>{{ list.documentType }}</td>
                     <td>{{ list.typeValue }}</td>
                    <td>{{ list.createdUserName }}</td>
                    <td>{{ filter(list.CreatedAt) }}</td>   
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
</div>
<div class="row">
<div class="col-lg-6 pr-0 pl-1">
  <div class="ibox">
          <div class="ibox-title style_2">
            <h5>{{ $t("navigations.documents") }}</h5>
          </div>
          <div class="ibox-content p-4">
            <ul class="tree">       
        <span @click="getstudydata()">
        <input type="checkbox">
        <label class="tree_label" >{{studyname}}</label>
      </span>
        <li>
  <ul class="tree">
     

      <li v-for="(site) in sites" :key="site.siteID">
        <input type="checkbox" :id="site.siteID" @change="getpatient(site.siteID,index)" >
        <label class="tree_label" :for="site.siteID">{{site.siteCode}}</label>
        <ul v-for="(patient,index) in patientLists[site.siteID]" :for="site.siteID" :key="patient.patientId">
          <li>
            <input type="checkbox" :id="patient.patientId" @change="getVisits(patient.patientId,index)" />
            <label :for="patient.patientId" class="tree_label">{{patient.subjectId}}</label>
          </li>
        </ul>                 
      </li>
      </ul>  
        </li>
            </ul>
      </div>
  </div>
</div>
<div class="col-lg-6 pr-0 pl-1">
  <div class="ibox">
          <div class="ibox-title style_2">
            <h5>{{ $t("navigations.documents") }}</h5>
          </div>
          <div class="ibox-content p-4">
            <div class="row mb-3">
              <div class="col-md-3 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.document name") }}</label
                  >
                  <input
                    type="text"
                    class="form-control my-md-2 my-lg-0"
                    v-model="SearchData.DocsearchName"
                  />
                </div>
              </div>
              <div class="col-md-4 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.created date") }}</label
                  >
                  <input
                    type="date"
                    max="9999-12-31"
                    class="form-control"
                    v-model="SearchData.CreatedDate"
                  />
                </div>
              </div>
              <div class="col-md-3 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.created by") }}</label
                  >
                  <input
                    type="text"
                    class="form-control my-md-2 my-lg-0"
                    v-model="SearchData.UploadedBy"
                  />
                </div>
              </div>
              <div class="col-lg-2 align-self-end">
                <span
                  type="button"
                  class="save_btn w-100"
                  @click.prevent="getDocuments()"
                  >{{ $t("navigations.search") }}</span
                >
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                   <th
                  class="sort_block"
                  @click="SortSelected2('documentName', 10)"
                  id="10"
                >
                  <div class="d-flex flex-row justify-content-between">  
                    {{ $t("navigations.document") }}            
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected2('createdUser', 11)"
                  id="11"
                >
                  <div class="d-flex flex-row justify-content-between">           
                    {{ $t("navigations.created by") }}   
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                <th
                  class="sort_block"
                  @click="SortSelected2('CreatedAt', 12)"
                  id="12"
                >
                  <div class="d-flex flex-row justify-content-between">    
                    {{ $t("navigations.created date") }}          
                    <span>
                      <i class="fa fa-long-arrow-up"></i>
                      <i class="fa fa-long-arrow-down"></i>
                    </span>
                  </div>
                </th>
                    <th v-if="roleprivileges.includes('DMS Summary Edit Document')">{{ $t("navigations.edit") }}</th>
                    <th v-if="roleprivileges.includes('DMS Summary Delete Document')">{{ $t("navigations.delete") }}</th>
                    <th class="text-center" v-if="roleprivileges.includes('DMS Summary View Document')">
                      {{ $t("navigations.view") }}
                    </th>
                    <th
                      class="text-center"
                      v-if="roleprivileges.includes('DMS Summary Assign Approver')"
                    >
                      {{ $t("navigations.approvals") }}
                    </th>
                    <th class="text-center" v-if="roleprivileges.includes('DMS Summary Download Document')">
                      {{ $t("navigations.download") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in doclist" :key="doc.id">
                    <!-- <td>{{ doc.folderName }}</td> -->
                    <td>
                      {{ doc.documentName }}
                    </td>
                    <td>{{ doc.createdUser }}</td>
                    <td>
                      {{
                        filter(doc.CreatedAt ? doc.CreatedAt.slice(0, 10) : "")
                      }}
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('DMS Summary Edit Document')">
                      <i
                        class="fa fa-edit edit-delete"
                        @click.prevent="editdocument(doc.id)"
                      ></i>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('DMS Summary Delete Document')">
                      <i
                        class="fa fa-trash edit-delete"
                         @click.prevent="deleteAzureDocument(doc.id)"
                      ></i>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('DMS Summary View Document')">
                      <i
                        class="fa fa-eye editicon"
                         @click.prevent="viewDocument(doc.id)"
                      ></i>
                    </td>
                    <td
                      class="text-center"
                      v-if="roleprivileges.includes('DMS Summary Assign Approver')"
                    >
                      <i
                        class="fa fa-check-circle-o edit-delete"
                       @click.prevent="
                          AddApproverPopuup(
                            doc.id,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].documentVersionId,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].versionNumber,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].approval
                          )
                        "
                      ></i>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('DMS Summary Download Document')">
                      <i
                        class="fa fa-download edit-delete"
                        @click.prevent="downloadfile(doc.id)"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-1 mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="doccurrentPage"
                      :pages="doctotalPage"
                      :range-size="0"
                      active-color="#618bd7"
                      @update:modelValue="loadPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>
</div>
</div>
  <!-- Popup library -->
<AddApproverLibrary
    v-if="popactive"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popactive = false"
    @listupdate="getDocuments()"
  >
  </AddApproverLibrary>
  
  <listApproverPopup
    v-if="popupmodal"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popupmodal = false"
    @listupdate="getDocuments()"
  >
  </listApproverPopup>

 <!-- Popup library -->

  <div class="create-folder-modal" v-if="showFolder == true">
    <div class="folder-modal-container">
        <div class="title-class d-flex justify-content-between py-2 px-3">
          <h4 class="my-0">{{ $t("navigations.create folder") }}</h4>
          <div class="cursor-pointer" @click="onclosed('addFolder')">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div class="ibox-content p-4">
          <div class="input-group">
            <input
              type="text"
              placeholder="Enter Folder Name"
              class="form-control"
              v-model="values.FolderName"
              maxlength="30"
            />
            <span class="input-group-append">
              <button
                type="button"
                class="btn btn-primary save_btn"
                @click.prevent="addFolder()"
              >
                {{ $t("navigations.add") }}
              </button>
            </span>
          </div>
          <span class="errorClass" v-if="errors[0] == false">{{
            $t("navigations.please enter a folder name")
          }}</span>
        </div>
      </div>
    </div>
  
  <!-- popup ends -->

  <DocumentPopup
    v-if="uploadpopupmodal"
    :id="documentPopupId"
    :refid="doceditid"
    :docdesc="Description"
    :editorupdate="calldetails"
    documentType="patient"
    @uploadlibactive="uploadmethodactivity()"
  >
  </DocumentPopup>
</template>
<script src="./dms_summary_report.js"></script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";
.linkstyle{
  cursor: pointer;
}

.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}

.titlemsg {
  border-color: #e7eaec;
  margin-bottom: 0;
  padding: 20px 20px;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  margin-left: 0px !important;
  margin-top: 0px !important;
  padding-left: 10px;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton{
width: fit-content;
padding: 3px;
cursor:pointer;
}
.graphbtncontainer{
display: flex;
gap: 5px;
}
img{
  margin-left: 0px !important;
}
.selectdefault{
  left: 0;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.ibox-content h1{
  font-weight:400;
  color:#676a6c
}
</style>
              
          