/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
export default {
  name: "App",
  props:{"studyid": String},
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      selectedstudy: "",
      values: {
        accountId: "string",
        folderId: "string",
        studyRef: "",
        studyName: "",
        sponsor: "",
        status: "New",
        studyId :"",
        studyNotes: "",
        preVisit: "",
        postVisit: "",
        numberOfSite: "",
        numberOfPatient: "",
        duration: 0,
        active: true,
        isDeleted: false
      },
      numerrors: {preVisit: true, postVisit: true, numberOfSite: true, numberOfPatient: true, duration:true},
      validations: [false, false],
      errors: []
      }
},
  mounted() {
    this.selectedstudy = this.studyid
    this.retrieveinfo();
  },
  methods: {
    async numberonlyvalidation(fieldname){
       //let isNumber = Number(this.values[fieldname])?true:false;
       let isNumber=true;
       this.values[fieldname] = this.values[fieldname].replace(/\s/g, '');
       let fieldval = this.values[fieldname];
       console.log("val is",fieldval);
       if(fieldval % 1 != 0)
       {
        isNumber = false;
       }
      console.log(this.values[fieldname]);
      console.log(isNumber);
      if(!isNumber){
        this.values[fieldname] = 0;
     //   this.numerrors[fieldname] = false;
       }
       else{
         if(parseInt(this.values[fieldname]) >= 0){
          this.numerrors[fieldname] = true;
         }
         else{
          this.values[fieldname] = 0;

         }
        
       }
    },
    async addStudy() {
      if(this.selectedstudy == 0) {
        this.save();
      }
      else{
        this.edit();
      }
    },
    async edit() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else{
        const idtoken = store.getters.getIdToken;
        await axios
        .put(
          `${this.baseurl}/management/study/update?api-version=1.0`,
          this.values,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Token is",idtoken)
          alert("Study updated successfully.")
          this.$router.push(`/studylist`);
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
     

      }
     
    },
    // validate function
    async validate() {
     // this.values.studyName = this.values.studyName.replace(/^\s*/, "");
      this.values.studyName = this.values.studyName.replace(/ +/g, ' ').trim();
      this.values.sponsor = this.values.sponsor.replace(/ +/g, ' ').trim();
      this.values.studyNotes = this.values.studyNotes.replace(/ +/g, ' ').trim();
      this.values.studyRef = this.values.studyRef.replace(/ +/g, ' ').trim();

      if(this.values.studyName != ""){
        this.validations[0] = true;
      }
      else{
        this.validations[0] = false;
      }
      if(this.values.studyRef != ""){
        this.validations[1] = true;
      }
      else{
        this.validations[1] = false;
      }
    },
// save function
async save() {
  await this.validate();
  if (this.validations.includes(false)) {
    this.errors = this.validations;
  }
  else {
    if(this.values.preVisit == ""){
      this.values.preVisit = 0
    }
    if(this.values.postVisit == ""){
      this.values.postVisit = 0;
    }
    if(this.values.numberOfSite == ""){
      this.values.numberOfSite = 0;
    }
    if(this.values.numberOfPatient == ""){
      this.values.numberOfPatient = 0;
    }
  const idtoken = store.getters.getIdToken;
  console.log(idtoken);
  console.log("Enter save function");
  console.log(this.values);
  await axios
  .post(
    `${this.baseurl}/management/study/create?api-version=1.0`,
    this.values,
    {
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    }
  )
  .then((res) => {
    console.log(res);
    alert("Study created successfully.")
    this.$router.push(`/studylist`);  
  })
  .catch((err) => {
    console.log(err);
    alert(err.response.data.detail);
  });
}
},
    async retrieveinfo() {
      const idtoken = store.getters.getIdToken;
        console.log("inside signup function " + this.selectedstudy);
        if(this.selectedstudy != 0){
          await axios
        .get(
          `${this.baseurl}/management/study/getbyid/${this.selectedstudy}?api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("Received response using study id.");
          this.values = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
        }
      },
      async onclosed() {
        this.$router.push(`/studylist`);
      },
      async clear() {
        console.log("inside clear function");
        this.values= {
          accountId: this.values.accountId,
          folderId: "string",
          studyName: "",
          sponsor: "",
          status: "",
          studyId :this.values.studyId,
          studyNotes: "",
          duration:0,
          preVisit: 0,
          postVisit: 0,
          numberOfSite: 0,
          numberOfPatient: 0,
          active: false,
          isDeleted: false
        }
      },
  }
}