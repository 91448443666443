/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
export default {
  props: {
    token: {
      type: String,
    },
    resetType: {
      type: String,
    },
    source: {
      type: String,
    },
  },
  name: "ConfirmationScreen",
  beforeRouteLeave(to, from, next) {
    if(to.name==="ResetPassword"){
      alert("Password reset was successful. For security reasons, please refrain from going back  this page.")
      next(false)
    }
  },
  data() {
  return{

  }
    }
}