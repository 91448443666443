<template>
  <!-- first popup starts -->
    <div class="modal__add__wrapper">
        <div class="modal__add__container">
          <div class="modal__add__title d-flex justify-content-between py-2 px-3">
            <h4 class="my-0">Assign approver</h4>
            <div class="cursor-pointer">
                <i class="fa fa-times" @click.prevent="closemodalpop()"></i>
            </div>
          </div>
          <div class="modal__add__content form-group mb-0 p-3">
            <h4 class="mb-3">Select approver</h4>
            <div class="d-flex justify-content-center">
              <Multiselect
                v-model="values.Approver"
                mode="multiple"
                valueProp="email"
                placeholder="Select approver"
                label="userName"
                :options="approverList"
                :hideSelected="false"
                :closeOnSelect="false"
                class="multiselect-theme w-75  ml-1 mr-0"
              >
              </Multiselect>
              <div class="input-group-append">
                <button class="save_btn" @click="updateApprover()" :disabled="values.Approver==''">
                  {{ $t("navigations.add") }}
                </button>
              </div>
            </div>
          </div>          
        </div>
      </div>
      <!-- first popup starts -->
</template>

<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import Multiselect from "@vueform/multiselect";
const idtoken = store.getters.getIdToken;
// import moment from "moment";
// import { ref } from 'vue'
export default {
  name:"AddApproverLibrary",
  props: {
    id: {
      type: String,
      default: "",
    },

    versionId: {
      type: String,
      default: "",
    },

    versionNumber: {
       type: String,
       default: "",
    },

    approval:{
        type:Array,
        default:[],
    },

    
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      baseapi: process.env.VUE_APP_Service_URL,     
      approverList: "",
      doclist: [],
      approverlistsforPopup: "",
      displaySidePopup: false,
      values: { Approver: [] },
      SearchData: {
        docName: "",
        FolderId: "",
        documentStorage: "",
        fromDate: "",
        toDate: "",
        pageSize: 10,
        pageNo: 1,
        
        DocName: "",
        
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pagesize: 10,
      },
      totalCount: 0,
      totalPage: 1,
      pagesize: 10,
      SortProperty:"CreatedAt",
      SortOrder:1,
      FolderId: "",
      pageindex: 1,
    };
  },

  async mounted() {
    document.body.style.overflowY="hidden"
    console.log("reached on this page",this.id)
    this.getreviewers();
  },
  async unmounted() {
    document.body.style.overflowY="auto"
  },
  async beforeMount() {
    this.getApproverById(this.id);
    // this.AddApproverPopupList();
  },
  methods: {   

    async closemodalpop() {
      this.$emit('libactive')
      this.ApproversName = [];
      this.getDocuments();
    },
    async updatemodalpop() {     
      this.$emit('listupdate')
      this.$emit('libactive')
      this.$emit('docsumm')
    },
    async updateApprover() {
      const idtoken = store.getters.getIdToken;
      console.log({
        documentId: this.id,
        //documentDescription: this.azuredata.DocumentDescription,
      });
      await axios
        .put(
          `${this.baseurl}/management/document/addapprover`,
          //`https://localhost:5001/document/addapprover`,
          {
            documentId: this.id,
            versionNumber: this.versionNumber,
            approveList: this.values.Approver,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt", res.data);
          this.docapproverupdate = res.data;
          alert("Approver added successfully");
          this.updatemodalpop();
          this.getDocuments();
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async getApproverById(docId) {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/account-core/user/getuserfordocumentapprover?documentId=${docId}&api-version=1.0`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.approverList = res.data;
          console.log("Approver list is ............", this.approverList);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getreviewers() {      
      this.displaySidePopup = !this.displaySidePopup;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/forms/digitalform/getapprovalusers`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.approverlistsforPopup = res.data;
          console.log("Reviewers", this.approverlistsforPopup);
          // this.createlist();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDocuments() {
      console.log("entererddd......")
      this.doclist = [];
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      if (folderId != 0) {
        this.SearchData.FolderId = folderId;
      } else {
        this.SearchData.FolderId = "";
      }
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentName=` +
            this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&folderId=" +
            this.SearchData.FolderId +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
           "&SortProperty=" +
           this.SortProperty+
           "&SortOrder="+
           this.SortOrder,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt enteredddd......", res.data);
          this.doclist = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.SearchData.pageSize);
          if (this.totalCount % this.SearchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style  scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";


.folder_tree_label{
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position:relative;
}
.folder_tree_label:hover{
  background-color:#f0f0f0;
  cursor:pointer;
}
.folder_tree_label:before{
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/images/folder-close.png");
  text-align: center;
  line-height: .8em;
}
.myStyle {
  color: blue;
}
.innertemplate {
  display: block;
}
.bold-font {
  font-weight:bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl.vtl-disabled {
  background-color: #d0cfcf;
}
  .icon:hover {
      cursor: pointer;
  }
  .muted {
    color: gray;
    font-size: 80%;
  }
  .hideandseek {
  display: none !important;
}
.multiselect-theme {
  --ms-option-bg-selected-pointed: rgb(210, 210, 210);
  --ms-option-bg-selected: rgb(210, 210, 210);
  --ms-option-color-selected: #000;
  --ms-option-color-selected-pointed: #000;
}
.modal__add__wrapper {
  position: fixed;
  inset:0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  /* pointer-events: none; */
  overflow: hidden;
}
.modal__add__container {
  width:min(38%,80%);
  position: fixed;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  overflow-y: hidden;
}
.widthApprve {
  width: 100%;
  padding-left: 50px;
}
.modal__add__title {
  background-color: var(--pop-up-background);
  color:#ffffff;
}
.modal__add__content {
  height:250px;
  overflow-y:scroll;
  overflow-x:hidden
}
</style>
