<template>
  <!-- tree starts -->
    <ul :class="'jstree-anchor jstree-clicked jstree1 ps-0' + patid" v-for="elem in list" :key="elem.folderId">
      <li v-if="elem.parentId == patid" :listdata="callfun(elem)">
        <!-- <input type="checkbox" /> -->
        <label
          :class="
              elem.folderId == boldfolder
                ? 'folder_tree_label bold-font'
                : 'folder_tree_label'
            "
          @click.prevent="collapse(elem.folderId)"
          >{{ elem.folderName }}
        </label>
        <template 
            :class="arrayfolders.includes(elem.folderId)?'innertemplate': 'innertemplate hideandseek'"  
            :id="elem.folderId"
        >
          <TreeBrowser
            class="modify-block"
            v-if="listdata.length != 0"
            :patid="elem.folderId"
            :list="listdata"
            :boldfolder ="boldfolder"
          >
          </TreeBrowser>
        </template>
      </li>
    </ul>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from "axios";
import store from "../../store/index";
// import moment from "moment";
// import { ref } from 'vue'
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    patid: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    boldfolder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      patientid: "",
      listdata: [],
      firstclassname: this.patid,
      folderbyid: "",
      showDetails:false,
      arrayfolders: [],
      baseurl: process.env.VUE_APP_Service_URL,
    };
  },
  async beforeMount() {
    await this.setlist();
    this.arrayfolders = store.getters.getShowDetails;
  },
  methods: {

    async collapse(elemid) {

      if (this.arrayfolders.includes(elemid)) {
        const index = this.arrayfolders.indexOf(elemid);
        this.arrayfolders.splice(index, 1);
      } else {
        this.arrayfolders.push(elemid);
      }
      
      await store.dispatch("setShowDetails", this.arrayfolders);

      if (store.getters.getFolderId === elemid) {
        await Promise.all([
          store.dispatch("setFolderId", ""),
          store.dispatch("setFolderName", ""),
          store.dispatch("setFolderdate", "")
        ]);
        const elemLabel = document.getElementsByTagName("label");
        for (let j = 0; j < elemLabel.length; j++) {
          elemLabel[j].classList.remove("bold-font");
        }
      } 
      else {
        await this.getFolderbyId(elemid);
        await Promise.all([
          store.dispatch("setFolderId", elemid),
          store.dispatch("setFolderName", this.folderbyid.folderName),
          store.dispatch("setFolderdate", this.folderbyid.lastModifiedAt)
        ]);

        const elemClass = document.getElementsByTagName("template");
        const elemLabel = document.getElementsByTagName("label");

        for (let j = 0; j < elemLabel.length; j++) {
          if (elemLabel[j].classList.contains("bold-font")) {
            elemLabel[j].classList.remove("bold-font");
          }
        }
        let detailsSet = false;

        for (let i = 0; i < elemClass.length; i++) {
          if (!elemClass[i].classList.contains("hideandseek")) {
            store.dispatch("setDetails", true);
            detailsSet = true;
            break;
          }
        }
        const elemFont = document.getElementById(elemid).previousElementSibling;

        if (!elemFont.classList.contains("bold-font") && detailsSet) {
          elemFont.classList.toggle("bold-font");
        }
      }
 
    },

    async searchTree(node){
      for (var i = 0; i < node.length; i++) {
        if(node[i].children.length>0)
          {
            if(node[i].tagName=='TEMPLATE'){
              if(!node[i].classList.contains("hideandseek")){
                node[i].classList.add("hideandseek")
              }
            }
            await this.searchTree(node[i].children)
          }
      }
    },
    async setlist() {
      this.listdata = [...this.list];
      console.log("listdata is ");
      console.log(this.listdata);
    },
    async setpatid(idreceived) {
      this.patientid = idreceived.trim();
    },
    async callfun(data) {
      const index = this.listdata.indexOf(data);
      if (index > -1) {
        this.listdata.splice(index, 1);
      }
    },
    async getFolderbyId(elemid) {
      const idtoken = store.getters.getIdToken;
      await axios
       .get(`${this.baseurl}/management/folder/getfolderbyid/` + elemid, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
            this.folderbyid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style  scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";

.folder_tree_label{
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position:relative;
}
.folder_tree_label:hover{
  background-color:#f0f0f0;
  cursor:pointer;
}
.folder_tree_label:before{
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/images/folder-close.png");
  text-align: center;
  line-height: .8em;
}
.myStyle {
  color: blue;
}
.innertemplate {
  display: block;
}
.bold-font {
  font-weight:bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl.vtl-disabled {
  background-color: #d0cfcf;
}
  .icon:hover {
      cursor: pointer;
  }
  .muted {
    color: gray;
    font-size: 80%;
  }
  .hideandseek {
  display: none !important;
}
</style>
