<template>
  <div class="wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>Please verify Personal details</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-body p-3">
      <div class="form-group row">
        <h3>Create password</h3>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Password<sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <div class="input-group date">
            <input
              type="password"
              autocomplete="off"
              class="form-control"
              maxlength="16"
              v-model="values.password"
            />
            <span class="errormsg" v-if="errors[4] == false">
              Please enter a Password</span
            >
            <span class="errormsg" v-if="errors[5] == false"
              >Passwords must contain at least eight characters, including
              uppercase, lowercase letters, special characters and
              numbers.</span
            >
            <span class="errormsg" v-if="errors[8] == false"
              >Password and Confirm password mismatch!!</span
            >
          </div>
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Confirm Password<sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <div class="input-group date">
            <input
              type="password"
              autocomplete="off"
              class="form-control"
              maxlength="16"
              v-model="values.confirmpassword"
            />
            <span class="errormsg" v-if="errors[6] == false">
              Please enter confirm Password</span
            >
            <span class="errormsg" v-if="errors[7] == false"
              >Passwords must contain at least eight characters, including
              uppercase, lowercase letters, special characters and
              numbers.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper wrapper-content" style="margin-top: -35px">
    <div class="ibox-body p-3">
      <div class="form-group row">
        <h3>Your basic Info</h3>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >First Name<sup><i class="fa fa-asterisk imp"></i></sup>
          </label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your first name"
            v-model="values.firstName"
          />
          <span class="errormsg" v-if="errors[0] == false"
            >Invalid first name !</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Last Name <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your last name"
            v-model="values.lastName"
          />
          <span class="errormsg" v-if="errors[1] == false"
            >Invalid last name !</span
          >
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >User Name <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="text"
            class="form-control"
            placeholder="Enter your last name"
            v-model="values.userName"
            maxlength="10"
          />
          <span class="errormsg" v-if="errors[9] == false"
            > Username should be 5 to 10 characters</span>
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label"
            >Login Email <sup><i class="fa fa-asterisk imp"></i></sup
          ></label>
          <input
            type="email"
            class="form-control"
            autocomplete="off"
            disabled="disabled"
            placeholder="Enter your primary email id"
            v-model="values.emailIdPrimary"
          />
        </div>
        <div class="col-lg-4 my-2">
          <label class="col-form-label">Secondary Email</label>
          <input
            type="email"
            class="form-control"
            placeholder="Enter your secondary email id"
            v-model="values.emailIdSecondary"
          />
        </div>

        <div class="col-lg-4 my-2">
          <label class="col-form-label">Select your Country</label>
          <select
            class="form-control m-b"
            name="account"
            v-model="values.country"
          >
            <option>United States</option>
            <option>India</option>
            <option>Japan</option>
            <option>China</option>
          </select>
        </div>
        <div class="col-lg-6 my-2" id="data_1">
          <label class="col-form-label">Date of Birth</label>
          <div class="input-group date">
            <input
              type="date"
              max="9999-12-31"
              class="form-control"
              v-model="values.dateOfBirth"
            />
          </div>
          <span class="errormsg" v-if="errors[2] == false">Invalid Date</span>
        </div>
        <div class="col-lg-6 my-2">
          <label class="col-form-label">Phone Number</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control"
              maxlength="16"
              v-model="values.phoneNumber"
            />
          </div>
          <span class="errormsg" v-if="errors[3] == false"
            >You have entered an invalid phone number!</span
          >
        </div>

        <div class="col-lg-12 my-2">
          <label class="col-form-label col-lg-12">Address 1</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control address"
              v-model="values.addressLineOne"
            />
          </div>
        </div>
        <div class="col-lg-12 my-2">
          <label class="col-form-label col-lg-12">Address 2</label>
          <div class="input-group date">
            <input
              type="text"
              class="form-control address"
              v-model="values.addressLineTwo"
            />
          </div>
        </div>

        <div class="col-lg-6 my-2 d-flex justify-content-lg-end">
          <button
            class="btn save_btn py-2 px-5"
            type="submit"
            @click.prevent="updateUser"
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./userPasswordComponent.js"></script>
<style scoped>
@import "../../assets/style.css";
.wrapper-content {
  width: 100%;
}
textarea {
  width: 100%;
}
.cancel_btn {
  background: var(--secondary-clr) !important;
  color: #ffffff;
}
.cancel_btn:hover {
  color: #fff;
  background-color: #22045a !important;
  border-color: #22045a !important;
}
.cancel_btn:active {
  color: #fff;
  background-color: #532eda !important;
  border-color: #532eda !important;
}
.btn-white {
  background: white;
  border: 1px solid #e7eaec;
}
.btn {
  border-radius: 3px;
  font-size: inherit;
}
.save_btn {
  background-color: #145faf;
  color: white;
}
.imp {
  color: #ff0000;
  font-size: 7px;
}
.impcl {
  color: #ff4747;
  font-size: 25px;
  float: right;
}
.impcl:hover {
  color: #a30202;
  font-size: 25px;
  float: right;
}
.save_btn:hover {
  color: #fff;
  background-color: #2d91fb;
  border-color: #2d91fb;
}
.save_btn:active {
  color: #fff;
  background-color: #618bd7;
  border-color: #618bd7;
}
.col-form-label {
  font-size: 14px;
}
.address {
  width: 100%;
}
</style>