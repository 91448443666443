 <template>
  <!-- testwrapper -->
  <!-- <div v-if="defaultdashboard != 'sitesummary'" class="row wrapper border-bottom white-bg page-heading">
<div class="col-lg-12 text-right">
<h2><button type="button" class="btn btn-outline-success fontprop" @click="setasdefault()">Set as default</button></h2>
</div>
</div> -->
  <!-- testwrapper -->
  <div class="row wrapper border-bottom white-bg page-heading">
		<div class="col-6 col-md-8">
			<h2>{{$t('navigations.site summary')}}</h2>
		</div>
    <div class="col-6 col-md-4 d-flex justify-content-between justify-content-md-end align-items-center">
			<div class="me-1">{{$t('navigations.site code')}}</div>
        <select class="form-select me-1 width-10"
          v-model="siteCode"
          @change="
            querychartData($event.target.value);
            formchartdata($event.target.value);
            patientchartdata($event.target.value);
            querytabledata($event.target.value);
            formstabledata($event.target.value);
            usercount($event.target.value);
            patientcount($event.target.value);
            formcount($event.target.value);
          "
          placeholder="Search"
        >
          <option class="options" value="" disabled hidden
            >{{$t('navigations.filter by site code')}}</option
          >
          <option
            class="options"
            v-for="site in sites"
            :key="site.siteID"
            :value="site.siteID"
            >{{ site.siteCode }}</option
          >
        </select>
        <button type="button"  v-if="defaultdashboard != 'sitesummary'" class="btn btn-setdefault fontprop" @click="setasdefault()">{{$t('navigations.set as default')}}</button>
		</div>
		
			
	
	</div>
  <div class="wrapper wrapper-content">
    <!-- <div class="d-flex justify-content-center justify-content-lg-end mb-lg-2">
      <div class="sitecodelabel">Site Code:</div>
        <select class="form-select width-10"
          v-model="siteCode"
          @change="
            querychartData($event.target.value);
            formchartdata($event.target.value);
            patientchartdata($event.target.value);
            querytabledata($event.target.value);
            formstabledata($event.target.value);
            usercount($event.target.value);
            patientcount($event.target.value);
            formcount($event.target.value);
          "
          placeholder="Search"
        >
          <option class="options" value="" disabled hidden
            >Filter by site code</option
          >
          <option
            class="options"
            v-for="site in sites"
            :key="site.siteID"
            :value="site.siteID"
            >{{ site.siteCode }}</option
          >
        </select>
    </div> -->
    <div class="row">
      <!-- {{sendData.data.datasets[0].data}} and {{sendData.data.datasets[1].data}} and queries {{seconsData.data.datasets[0].data}} -->
      <!--new form table-->
      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="mt-1">{{$t('navigations.queries')}}</h5>
              <div class="graphbtncontainer">
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bar_clr.png"
                    @click.prevent="firstchartchange('bar')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/horiz_bar_clr.png"
                    @click.prevent="firstchartchange('barhorizondal')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Line_clr.png"
                    @click.prevent="firstchartchange('line')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Pie_clr.png"
                    @click.prevent="firstchartchange('pie')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bubble_clr.png"
                    @click.prevent="firstchartchange('scatter')"
                  />
                </div>
              </div>
          </div>
          <div class="ibox-content">
            <apexchart
              v-if="
                firstchart.charttype != 'pie' &&
                  firstchart.charttype != 'barhorizondal'
              "
              width="100%"
              height="250px"
              :type="firstchart.charttype"
              :options="firstchart.chartOptions"
              :series="firstchart.series"
            ></apexchart>
            <apexchart
              v-if="firstchart.charttype == 'barhorizondal'"
              width="100%"
              height="250px"
              type="bar"
              :options="firstchart.chartOptions"
              :series="firstchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="firstchart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="firstchart.charttype"
              :options="firstchart.piechartOptions"
              :series="firstchart.piedataseries"
            ></apexchart>
            <!-- <canvas id="barChart" height="140"></canvas> -->
          </div>
        </div>
      </div>

      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="mt-1">{{$t('navigations.forms')}}</h5>
              <div class="graphbtncontainer">
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bar_clr.png"
                    @click.prevent="secondchartchange('bar')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/horiz_bar_clr.png"
                    @click.prevent="secondchartchange('barhorizondal')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Line_clr.png"
                    @click.prevent="secondchartchange('line')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Pie_clr.png"
                    @click.prevent="secondchartchange('pie')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bubble_clr.png"
                    @click.prevent="secondchartchange('scatter')"
                  />
                </div>
              </div>
          </div>
          <div class="ibox-content">
            <apexchart
              v-if="
                formchart.charttype != 'pie' &&
                  formchart.charttype != 'barhorizondal'
              "
              width="100%"
              height="250px"
              :type="formchart.charttype"
              :options="formchart.chartOptions"
              :series="formchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="formchart.charttype == 'barhorizondal'"
              width="100%"
              height="250px"
              type="bar"
              :options="formbarchart.chartOptions"
              :series="formbarchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="formchart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="formchart.charttype"
              :options="formchart.piechartOptions"
              :series="formchart.piedataseries"
            ></apexchart>
            <!-- <canvas id="barChart" height="140"></canvas> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="mt-1">{{$t('navigations.subjects')}}</h5>
              <div class="graphbtncontainer">
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bar_clr.png"
                    @click.prevent="thirdchartchange('bar')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/horiz_bar_clr.png"
                    @click.prevent="thirdchartchange('barhorizondal')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Line_clr.png"
                    @click.prevent="thirdchartchange('line')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Pie_clr.png"
                    @click.prevent="thirdchartchange('pie')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bubble_clr.png"
                    @click.prevent="thirdchartchange('scatter')"
                  />
                </div>
              </div>
          </div>
          <div class="ibox-content">
            <apexchart
              v-if="
                patientchart.charttype != 'pie' &&
                  patientchart.charttype != 'barhorizondal'
              "
              width="100%"
              height="250px"
              :type="patientchart.charttype"
              :options="patientchart.chartOptions"
              :series="patientchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="patientchart.charttype == 'barhorizondal'"
              width="100%"
              height="250px"
              type="bar"
              :options="patientbarchart.chartOptions"
              :series="patientbarchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="patientchart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="patientchart.charttype"
              :options="patientchart.piechartOptions"
              :series="patientchart.piedataseries"
            ></apexchart>
            <!-- <canvas id="barChart" height="140"></canvas> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 pl-1 mb-1">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="mt-1">{{$t('navigations.summary')}}</h5>
              <div class="graphbtncontainer">
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bar_clr.png"
                    @click.prevent="fourthchartchange('bar')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/horiz_bar_clr.png"
                    @click.prevent="fourthchartchange('barhorizondal')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Line_clr.png"
                    @click.prevent="fourthchartchange('line')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/Pie_clr.png"
                    @click.prevent="fourthchartchange('pie')"
                  />
                </div>
                <div class="graphbutton">
                  <img
                    src="../../assets/images/bubble_clr.png"
                    @click.prevent="fourthchartchange('scatter')"
                  />
                </div>
              </div>
          </div>
          <div class="ibox-content">
            <apexchart
              v-if="
                summarychart.charttype != 'pie' &&
                  summarychart.charttype != 'barhorizondal'
              "
              width="100%"
              height="250px"
              :type="summarychart.charttype"
              :options="summarychart.chartOptions"
              :series="summarychart.dataseries"
            ></apexchart>
            <apexchart
              v-if="summarychart.charttype == 'barhorizondal'"
              width="100%"
              height="250px"
              type="bar"
              :options="summarybarchart.chartOptions"
              :series="summarybarchart.dataseries"
            ></apexchart>
            <apexchart
              v-if="summarychart.charttype == 'pie'"
              width="100%"
              height="250px"
              :type="summarychart.charttype"
              :options="summarychart.piechartOptions"
              :series="summarychart.piedataseries"
            ></apexchart>
            <!-- <canvas id="barChart" height="140"></canvas> -->
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="mt-1">{{$t('navigations.query activity')}}</h5>
          </div> 
          <div class="ibox-content">
            <div class="table-responsive fixed-height">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th>{{$t('navigations.query id')}}</th>
                    <th>{{$t('navigations.subject')}}</th>
                    <th>{{$t('navigations.raised by')}}</th>
                    <th>{{$t('navigations.raised date')}}</th>
                    <th class="text-center" v-if="roleprivileges.includes('Query All Queries')">{{$t('navigations.view')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="list in querylist" :key="list.formId">
                    <td>{{ list.queryId }}</td>
                    <td>{{ list.query_Subject }}</td>
                    <td>
                      <span v-if="list.messages.length <= 1">
                        <span
                          v-for="(message, index) in list.messages"
                          :key="index"
                        >
                          {{ message.raisedByName }}
                        </span>
                      </span>
                      <span v-else>
                        {{ list.messages[0].raisedByName }}
                      </span>
                    </td>
                    <td>
                      <span v-if="list.messages.length <= 1">
                        <span
                          v-for="(message, index) in list.messages"
                          :key="index"
                        >
                          {{ filter(message.raisedDate) }}
                        </span>
                      </span>
                      <span v-else>
                        {{ filter(list.messages[0].raisedDate) }}
                      </span>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('Query All Queries')">
                      <i
                        class="fa fa-eye editicon"
                        @click.prevent="viewquery(list.id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 pr-0 pl-1">
        <div class="ibox-body">
          <div class="ibox-title style_2">
            <h5 class="mt-1">{{$t('navigations.form activity')}}</h5>
          </div>          
          <div class="ibox-content">
            <div class="table-responsive fixed-height">
              <table class="table table-striped table-bordered dataTables">
                <thead>
                  <tr>
                    <th>{{$t('navigations.subject id')}}</th>
                    <th>{{$t('navigations.visit name')}}</th>
                    <th>{{$t('navigations.status')}}</th>
                    <th>{{$t('navigations.date')}}</th>
                    <th class="text-center" v-if="roleprivileges.includes('Forms View Form')">{{$t('navigations.view')}}</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="list in formlist" :key="list.formId">
                    <td>{{ list.patient }}</td>
                    <td>{{ list.visit }}</td>
                    <td>{{ list.status }}</td>
                    <td>{{ filter(list.date) }}</td>
                    <td class="text-center" v-if="roleprivileges.includes('Forms View Form')">
                      <i
                        class="fa fa-eye editicon"
                        @click.prevent="viewform(list.formId)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./site_dashboard.js"></script>
<style scoped>
@import "../../assets/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.widget {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.widgetmargin {
  margin-top: -13px !important;
}
.ibox-body {
  background-color: #ffffff;
  margin: 3rem, 0;
}

.style1 {
  background-color: black !important;
  color: #ffffff;
}
.btn-view {
  color: #fff !important;
  background-color: #bb458f !important;
  border-color: #bb458f !important;
}
.btn-view:hover {
  color: #fff;
  background-color: #666ccb;
  border-color: #666ccb;
}
.btn-view:active {
  color: rgb(255, 255, 255) !important;
  background-color: #dda2c7 !important;
  border-color: #dda2c7 !important;
}
.ibox-title {
  color: inherit;
  margin-bottom: 0;
  padding: 1rem;
  position: relative;
  clear: both;
  right: 0;
  margin-right: 0px;
  width: unset !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}
.padding_bottom {
  padding-bottom: 2rem;
}
.ibox-title h5 {
  height: 0rem;
  padding: 0rem;
}
.ibox-title h5:hover {
  background-color: inherit;
  padding: 0;
  cursor: not-allowed;
}
.titlemsg {
  font-size: 15px;
  font-weight: bold;
  color: var(--primary-color);
}
.switchbtn {
  background-color: #bb458f !important;
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
}
.switchbtn:active {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.switchbtn:hover {
  height: max-content;
  border-color: #bb458f !important;
  color: white !important;
  float: right;
  background-color: #bb458f;
}
.queryhover:hover {
  color: #3c4192 !important;
  -webkit-transition: color ease-in 400ms;
  transition: color ease-in 400ms;
  cursor: pointer;
}

.widgetminheight {
  min-height: 102px;
}
.graphbutton {
  width: fit-content;
  padding: 3px;
}
.graphbutton:hover {
  cursor: pointer;
  border-color: #3c4192;
}
.graphbtncontainer {
  display: flex;
  gap: 5px;
}
img {
  margin-left: 0px !important;
}
.fixed-height {
  min-height: 200px;
  overflow-y: scroll;
  margin-bottom: 30px;
  max-height: 400px;
}
thead tr th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
.selectdefault {
  left: 0;
}
.selectdefaultcontainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.fontprop {
  font-size: 10px !important;
}
.btn-setdefault {
  position: relative;
  padding:0.2rem 0.4rem;
  text-align: center;
  font-size:0.7rem;
  background-color: #ec4758;
  color: white !important;
  border-radius:3px;
}
.ibox-title h5{
    float:none;
}
.width-10{
  width:50%;
}
.form-select{
  border-radius:0!important;
}
.form-select:focus{
  border-color:#145faf!important;
  box-shadow: 0 0 0!important;
}
</style>
