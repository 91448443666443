 /* eslint-disable */
import {inject, toRefs} from "vue";
import axios from "axios";
import config from "../../../config";

export default {
    name: "googlefit",
    props: {
        msg: String,
    },

    data() {
        return {
            user: '',
        }
    },

    methods: {
        async handleClickSignIn() {
            try {
                const googleUser = await this.$gAuth.signIn();
                if (!googleUser) {
                    return null;
                }
                console.log("googleUser", googleUser);
                this.user = googleUser.getBasicProfile().getEmail();
                console.log("getId", this.user);
                console.log("getBasicProfile", googleUser.getBasicProfile());
                console.log("getAuthResponse", googleUser.getAuthResponse());
                console.log(
                    "getAuthResponse",
                    this.$gAuth.instance.currentUser.get().getAuthResponse()
                );

            } catch (error) {
                //on fail do something
                console.error(error);
                return null;
            }
        },

        async handleClickGetAuthCode() {
            try {
                const authCode = await this.$gAuth.getAuthCode();
                console.log("authCode", authCode);
            } catch (error) {
                //on fail do something
                console.error(error);
                return null;
            }
        },

        async handleClickSignOut() {
            try {
                await this.$gAuth.signOut();
                console.log("isAuthorized", this.Vue3GoogleOauth.isAuthorized);
                this.user = "";
            } catch (error) {
                console.error(error);
            }
        },

        handleClickDisconnect() {
            window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
        },
        //---------------------------------------------------------------------------


        async getDataSource() {
            await axios.get(
                `https://www.googleapis.com/fitness/v1/users/me/dataSources`,
                {
                    headers: {
                        Authorization: "Bearer " + "ya29.A0ARrdaM_Yyz7hXN1Gdp2_qNGdtExlmVs64Oc-MEjnFHvdG4hLDhbwlRKOe47zF_hZZpnC0h4RTCUa5wv5_QQJ61XODO_FKKXeZLlGl1NaybmHnoLVdJj2VaAfW3QF5etQhlgSYP-qFCSBrlfWhtf05ZMdBC3mlg",
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log("_______________", res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getStepCount() {

            let data = {
                "aggregateBy": [{
                    "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps"
                }],
                "bucketByTime": {"durationMillis": 86400000}, // This is 24 hours
                "startTimeMillis": config.googlefitvalues.startTimeMillis,   // Start time
                "endTimeMillis": config.googlefitvalues.endTimeMillis  // End Time
            }
            await axios.post(
                `https://www.googleapis.com/fitness/v1/users/me/dataset:aggregate`, {
                    "aggregateBy": [{
                        "dataSourceId": "derived:com.google.step_count.delta:com.google.android.gms:estimated_steps",
                    },
                        {
                            "dataSourceId": "derived:com.google.weight:com.google.android.gms:merge_weight",
                        },
                        {
                            "dataSourceId": "derived:com.google.height:com.google.android.gms:merge_height",
                        },
                        {
                            "dataSourceId": "derived:com.google.calories.expended:com.google.android.gms:merge_calories_expended",
                        },
                        {
                            "dataSourceId": "derived:com.google.heart_minutes:com.google.android.gms:merge_heart_minutes",
                        },
                    ],
                    "bucketByTime": {"durationMillis": 86400000},
                    "startTimeMillis": config.googlefitvalues.startTimeMillis,   // Start time
                    "endTimeMillis": config.googlefitvalues.endTimeMillis  // End Time
                },
                {
                    headers: {
                        Authorization: "Bearer " + "ya29.A0ARrdaM-7vZlj3Iy5Dsy6N9oE_u2YhVDPykDtxAaTq3gcYC74Ijq1Df2OG99CB-mjD99D0EfV5-0JFBohEJzZ48oox8bgj7ej5kvuU0cHzyZ556kxQJAzkRkDQenFinMVz2LW6Cf84kC2NqRT1y9SwCvxJwP_mQ",
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((res) => {
                    console.log("steps", res);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        //---------------------------------------------------------------------------
    },
    setup(props) {
        const { isSignIn } = toRefs(props);
        const Vue3GoogleOauth = inject("Vue3GoogleOauth");

       // const handleClickLogin = () => {};
        return {
            Vue3GoogleOauth,
           // handleClickLogin,
            isSignIn,
        };
    },
};

