<template>
  <ul class="tree">
    <li v-for="(site, index) in sites" :key="site.siteID">
      <input
        type="checkbox"
        :id="site.siteID"
        @change="getpatient(site.siteID, site.siteCode, site.patientNo, index)"
      />
      <label class="tree_label" :for="site.siteID">{{ site.siteCode }}</label>
      <ul
        v-for="(patient, index) in patientLists[index]"
        :for="site.siteID"
        :key="patient.patientId"
      >
        <li>
          <input
            type="checkbox"
            :id="patient.patientId"
            @change="
              getVisits(
                patient.patientId,
                patient.subjectId,
                patient.siteCode,
                index
              )
            "
          />
          <label :for="patient.patientId" class="tree_label">{{
            patient.subjectId
          }}</label>
          <ul
            v-for="visit in visitobj[patient.patientId][index]"
            :for="patient.patientId"
            :key="visit.visitId"
          >
            <li
              :for="patient.patientId"
              @click.prevent="
                visitClick(
                  visit.formId,
                  visit.visitName,
                  visit.sheduleId,
                  visit.patientNo,
                  visit.siteCode
                )
              "
            >
              <input type="checkbox" />
              <label  :class="
                  selectedId == visit.sheduleId
                    ? 'tree_label tree_label_bold'
                    : 'tree_label'
                "
                >{{ visit.visitNo }} - {{ visit.visitName }} -
                {{ filter(visit.visitDate) }} -
                <span
                  class="badge badge-grn"
                  v-if="visit.visitStatus == 'Completed'"
                  >{{ visit.visitStatus }}</span
                >
                <span
                  class="badge badge-danger"
                  v-if="visit.visitStatus == 'Overdue'"
                  >{{ visit.visitStatus }}</span
                >
                <span
                  class="badge badge-warning"
                  v-if="visit.visitStatus == 'Imminent'"
                  >{{ visit.visitStatus }}</span
                >
                <span
                  class="badge badge-genre"
                  v-if="
                    visit.visitStatus == 'Withdrawn' ||
                    visit.visitStatus == 'Planned'
                  "
                  >{{ visit.visitStatus }}</span
                ></label
              >
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
/* eslint-disable */
import moment from 'moment';
import axios from "axios";
import store from "../../store/index";
export default {
  name: "sourcetree",
  components: {},

  data() {
    return {
      selectedId: "",
      sites: [],
      patientLists: [],
      visitobj: {},
      baseapi: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,
      recentPatientId: "",
      recentIndex: "",
    };
  },

  async mounted() {
    this.defaultdate = localStorage.getItem("dateformat");
    window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      this.getsites();
      this.dispatchNull();
    });
    window.addEventListener("formTransferred", (event) => {
      console.log("formTransferred == " + event.detail.storage);
      if (event.detail.storage == true) {
        this.getRefreshedVisits();
      }
    });
    this.getsites();
    await this.dispatchNull();
  },

  methods: {
    async dispatchNull() {
      await store.dispatch("setSourceFormId", "");
      await store.dispatch("setSourceVisitName", "");
      await store.dispatch("setSourcePatientName", "");
      await store.dispatch("setSourceScheduleId", "");
      await store.dispatch("setSourceSiteName", "");
    },
    filter(data) {
      if (data) {
        return moment(data).utc().format(this.defaultdate);
      }
      console.log(data);
    },
    filterMonth(date) {
      if (date) {
        return moment(date).format("DD-MMM-YYYY");
      }
    },
    async getsites() {
      const currentstudyid = store.getters.getStudyIs;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudyid}&getAll=false`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.sites = res.data;
          console.log("Sites check are", this.sites);
          console.log("Current study is", currentstudyid);
          console.log(idtoken);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async getpatient(siteId, siteName, patientName, index) {
      await store.dispatch("setSourceVisitName", "");
      await store.dispatch("setSourceSiteName", "");
      await store.dispatch("setSourcePatientName", "");
      await store.dispatch("setSourceFormId", "");
      await store.dispatch("setSourceSiteName", siteName);
      await store.dispatch("setSourcePatientName", patientName);
      this.patientLists[index] = [];
      console.log("Site id is", siteId);
      const idtoken = store.getters.getIdToken;
      const currentstudyid = store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/listpatient?SiteId=${siteId}&Studyid=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {
          let data = res.data.results;
          await data.forEach((patientdata) => {
            this.visitobj[patientdata.patientId] = [];
          });
          this.patientLists[index] = data;
          console.log("patient list is", this.visitobj);
        });
    },

    async visitClick(formId, visitName, sheduleId, patientName, siteName) {
      this.selectedId = sheduleId;
      console.log("source formId...", formId);
      console.log("source scheduleId...", sheduleId);
      await store.dispatch("setSourceSiteName", "");
      await store.dispatch("setSourcePatientName", "");
      await store.dispatch("setSourceFormId", "");
      await store.dispatch("setSourceVisitName", "");
      await store.dispatch("setSourceScheduleId", "");
      await store.dispatch("setSourceSiteName", siteName);
      await store.dispatch("setSourcePatientName", patientName);
      await store.dispatch("setSourceFormId", formId);
      await store.dispatch("setSourceVisitName", visitName);
      await store.dispatch("setSourceScheduleId", sheduleId);
    },

    async getRefreshedVisits() {
      console.log("In getRefreshedVisits", this.recentPatientId);
      const idtoken = store.getters.getIdToken;
      this.visitobj[this.recentPatientId][this.recentIndex] = [];
      await axios
        .get(
          `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${this.recentPatientId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visitobj[this.recentPatientId][this.recentIndex] = res.data.data;
          console.log("The visit list is", this.visitobj);
          store.dispatch("setIsFormTransfer", false);
          store.dispatch("setSourceFormId", "");
          store.dispatch("setTargetFormId", "");
        })
        .catch((err) => {
          console.log("error is", err);
        });
    },
    async getVisits(patientId, patientName, siteCode, index) {
      console.log("In get Visits", patientId);
      this.recentPatientId = patientId;
      this.recentIndex = index;
      await store.dispatch("setSourceSiteName", "");
      await store.dispatch("setSourcePatientName", "");
      await store.dispatch("setSourceVisitName", "");
      await store.dispatch("setSourceFormId", "");
      await store.dispatch("setSourcePatientName", patientName);
      await store.dispatch("setSourceSiteName", siteCode);
      this.visitobj[patientId][index] = [];
      const idtoken = store.getters.getIdToken;
      console.log("patient ID is", patientId);
      await axios
        .get(
          `${this.baseapi}/management/patientschedule/getpatientshedule?patientID=${patientId}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.visitobj[patientId][index] = res.data.data;
          console.log("The visit list is", this.visitobj);
        })
        .catch((err) => {
          console.log("error is", err);
        });
    },
  },
};
</script>

<style  scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";

.folder_tree_label {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px 0 5px 1rem;
  position: relative;
}
.folder_tree_label:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}
.folder_tree_label:before {
  color: #fff;
  position: relative;
  z-index: 1;
  float: left;
  margin: 0 0.5em 0 -2em;
  width: 1em;
  height: 1em;
  border-radius: 1em;
  content: url("../../assets/images/folder-close.png");
  text-align: center;
  line-height: 0.8em;
}
.myStyle {
  color: blue;
}
.innertemplate {
  display: block;
}
.bold-font {
  font-weight: bold;
}

.vtl.vtl-drag-disabled {
  background-color: #d0cfcf;
}
.vtl.vtl-disabled {
  background-color: #d0cfcf;
}
.icon:hover {
  cursor: pointer;
}
.muted {
  color: gray;
  font-size: 80%;
}
.hideandseek {
  display: none !important;
}
.tree_label_bold {
  font-weight: bold;
}
</style>