/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;
export default {
    name: 'iotpatients',
    components: {
        VPagination,
    },
    data() {
        return {
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
            searchdata: {
                subjectid: "",
                devicename: "",
                FormName: "",
                Pageindex: 1,
                Pagesize: 10,
                SortProperty: "",
            },
            patients: "",
            idToken: "",
            currentPageSize: 0,
            currentPageNumber: 1,
            enableNext: true,
            enablePrevious: true,
            totalLists: 0,
            totalPages: 0,
            lists: [],
            iotPatientlist: [],
            queryCount :0,
            queryDetails :"",
        }
    },
    async mounted() {
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.idToken = store.getters.getIdToken;
            this.getpatients();
            this.searchData();
          });
        this.idToken = store.getters.getIdToken;
        this.getpatients();
        this.searchData();
    },
    methods: {
        filter(data) {
            if (data) {
                return moment(data).format('DD/MM/YYYY hh:mm')
            }
        },
        async viewforms(id) {
            const fromArchive = false
            this.$router.push(`/showform/iotaptents/${id}/${fromArchive}`);
        },
        async getPrevious() {
            if (this.searchdata.Pageindex > 1) {
                console.log("previous page");
                this.searchdata.Pageindex = this.searchdata.Pageindex - 1;
                this.searchData();
            }
        },
        async getNext() {
            if (this.searchdata.Pageindex < this.totalLists) {
                this.searchdata.Pageindex = this.searchdata.Pageindex + 1;
                this.searchData();

            }
        },
        async OnSearchButtonClicked(){

            this.currentPageNumber=1;
            this.searchdata.Pageindex=1;
            this.searchData();
        },
        async searchData() {
            const idtoken = store.getters.getIdToken;
            await axios
            .get(`${this.baseapi}/management/iotdevice/getiotpatients?SubjectCode=${this.searchdata.subjectid}&DeviceName=${this.searchdata.devicename}&FormName=${this.searchdata.FormName}&PageIndex=${this.searchdata.Pageindex}&PageSize=${this.searchdata.Pagesize}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("iot patients data", response.data);
                        this.iotPatientlist = response.data.data;

                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },
          
        async getpatients() {
            console.log("in get patient");
            this.searchdata.PatientId ="";
            const idtoken = store.getters.getIdToken;
            await axios
                .get(
                    `${this.baseapi}/management/patient/listpatient`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    console.log("get patients", res);
                    this.patients = res.data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async loadPage(page) {
            this.currentPageNumber = page;
            this.searchdata.Pageindex = page;
            this.searchData();
        },
    },

}

