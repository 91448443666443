<template>
    <div class="d-flex flex-column justify-content-center align-items-center full-height-100">
        <img class="my-3" src="../../assets/img/IDSLogo.png" alt="IDS Logo">
        <div class="info-block mx-auto">
            <div class="info-block-title text-center py-2">
                <h4 class="my-0">Password Reset Successfull</h4>
            </div>
            <div class="info-block-content py-3 px-3 px-md-5">
                <p>Your password has been successfully updated. You can now securely access your account with your new
                    credentials.</p>
                <h4>Please open your mobile app manually to log in with your updated password.</h4>
                <p>
                    If you encounter any issues or need further assistance, please contact our support team.
                </p>
                <p>For additional account security, we recommend the following steps:</p>
                <ul class="info-block-content-list">
                    <li>Keep your login details confidential and avoid sharing them with others.</li>
                    <li>Regularly update your password and avoid using easily guessable information.</li>
                    <li>Be cautious of any suspicious activities related to your account.</li>
                </ul>
                <p class="mb-0">Thank you for using our services.</p>
                <p>If you have any questions or need further assistance, feel free to reach out to our support team.</p>
                <p>Note: For security purposes, please do not share your password with anyone.</p>
            </div>
        </div>
    </div>
</template>
<script src="./confirmation.js"></script>
<style scoped>
.full-height-100{
    height:100vh;
    height:100dvh
}
.info-block {
    width: min(90%, 50rem);
    border: 1px solid hsl(0, 0%, 84%);
}

.info-block-title {
    background-color: hsl(0, 0%, 84%);
}

.info-block-content {
    background-color: hsl(0, 0%, 100%);
}
.info-block-content-list{
    list-style: disc;
}

</style>