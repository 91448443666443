/* eslint-disable */
// import config from "../../../config";

import axios from "axios";
import moment from "moment";
import store from "../../store/index";

import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import QRCode from 'qrcode'
export default {
  name: "showform",
  components: {
  },
  props:{
      formsesid:{
          type: String,
          default: ''
      }
    },
  async beforeMount() {
    await this.setformid();
  },
  async mounted(){

  },
  data() {
  },
  methods: {
    
  },
};
