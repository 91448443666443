/* eslint-disable */
import jwt_decode from "jwt-decode";
import store from "@/store";
import axios from "axios";
import { VueDraggableNext } from "vue-draggable-next";
import formscount from "../dashlets/newforms.vue";
import totalusers from "../dashlets/TotalUsers.vue";
import totalsites from "../dashlets/totalsites.vue";
import totalsubjects from "../dashlets/totalsubjects.vue";
import openquery from "../dashlets/openquery.vue";
import totalquery from "../dashlets/totalqueries.vue";
import activeforms from "../dashlets/activeforms.vue";
import upcommingvisits from "../dashlets/upcommingvisits.vue";
import overduevisits from "../dashlets/overduevisits.vue";
import completedvisits from "../dashlets/completedvisits.vue";
import siteschart from "../dashlets/siteschart.vue";
import subjectchart from "../dashlets/subjectchart.vue";
import queryactivitytable from "../dashlets/queryactivitytable.vue";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable: VueDraggableNext,
    formscount,
    totalusers,
    totalsites,
    totalsubjects,
    openquery,
    totalquery,
    activeforms,
    upcommingvisits,
    overduevisits,
    completedvisits,
    siteschart,
    subjectchart,
    queryactivitytable,
  },
  data() {
    return {
      dashletdetails:{
    "Forms count":"A Metric used to display total number of forms in current study.",
    "Total users":"A Metric used to display total number of users.",
    "Total sites":"A Metric used to display total number of sites in current study",
    "Total subjects":"A Metric used to display total number of subjects in current study",
    "Open queries":"A Metric used to display total number of Open queries in current study",
    "Total queries":"A Metric used to display total number of queries in current study",
    "Active forms":"A Metric used to display total number of Active forms in current study",
    "Upcomming visits":"A Metric used to display total number of Upcomming visits in current study",
    "Overdue visits":"A Metric used to display total number of Overdue visits in current study",
    "Completed visits":"A Metric used to display total number of Completed visits in current study",
    "Sites chart":"A customisable chart used to display number of new, open, enrolled, completed and terminated sites",
    "Subject chart":"A customisable chart used to display number of new, enrolled, withdrawn, completed and vist ongoing subjects",
    "Query activity table":"A table showing details of query in current study"
      },
      tabname:"",
      opencreatetabmodelvar: false,
      showBlock: "default",
      tabarray: ["default"],
      selectedTab: "document",
      selectedindex: "",
      setsizepopup: false,
      baseurl: process.env.VUE_APP_Service_URL,
      savedashlet: true,
      errors:[false],
      inUseError:false,
      availableDashlets: [
        {
          name: "Forms count",
          component: "formscount",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Total users",
          component: "totalusers",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Total sites",
          component: "totalsites",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Total subjects",
          component: "totalsubjects",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Open queries",
          component: "openquery",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Total queries",
          component: "totalquery",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Active forms",
          component: "activeforms",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Upcomming visits",
          component: "upcommingvisits",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Overdue visits",
          component: "overduevisits",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Completed visits",
          component: "completedvisits",
          minWidth: "25",
          minLength: "150",
          length: "150",
          width: "25",
          MenuType : "count"
        },
        {
          name: "Sites chart",
          component: "siteschart",
          length: "370",
          minWidth: "50",
          minLength: "370",
          width: "50",
          MenuType : "chart"
        },
        {
          name: "Subject chart",
          component: "subjectchart",
          length: "370",
          minWidth: "50",
          minLength: "370",
          width: "50",
          MenuType : "chart"
        },
        {
          name: "Query activity table",
          component: "queryactivitytable",
          minWidth: "50",
          minLength: "350",
          length: "350",
          width: "50",
          MenuType : "table"
        },
      ],
      userDashlets: [],
      usertabDashlets: {  
      "createdUserEmail": "string",
      "createdUserId": "string",
      "createdAt": "2024-03-07T09:49:32.709Z",
      "lastModifiedUserEmail": "string",
      "lastModifiedUserId": "string",
      "lastModifiedAt": "2024-03-07T09:49:32.709Z",
      "tabarray": []
      },
    };
  },
  async mounted() {
    await this.getsaveddashboard();
  },
   computed: {
    currentTabDashlets() {
      // Find the current tab based on `showBlock` (which holds the current tabName)
      const currentTab = this.usertabDashlets.tabarray.find(tab => tab.tabName === this.showBlock);
      return currentTab || null; // Return the current tab object or null if not found
    }
  },
  methods: {
    removetab(){
      if(this.usertabDashlets.tabarray.length==1)
      {
        alert("Atleast one tab should be present")
      }
      else{
        let conformed = confirm("Are you sure you want to delete current tab?");
        if (conformed) {
            this.usertabDashlets.tabarray = this.usertabDashlets.tabarray.filter(item => item['tabName'] !== this.tabname);
            if (this.usertabDashlets.tabarray != null) {
              this.showBlock = this.usertabDashlets.tabarray[0].tabName;
              this.tabname = this.usertabDashlets.tabarray[0].tabName;
              }
            
          } 
      }    
     
    },
    showTooltip(event, tooltipText) {
      const tooltip = document.createElement("div");
      tooltip.setAttribute("id", "dynamic-tooltip");
      tooltip.textContent = this.dashletdetails[tooltipText];
      document.body.appendChild(tooltip);

      // Position tooltip based on event target location
      const { top, left, height } = event.target.getBoundingClientRect();
      tooltip.style.position = "absolute";
      tooltip.style.top = `${top + height - 70}px`; 
      tooltip.style.left = `${left+150}px`; 
      tooltip.style.background='white'; 
      tooltip.style.width='200px'; 
      tooltip.style.padding='20px';
      tooltip.style.border='1px solid #e7e7e7';
      tooltip.style.borderRadius='10px';
      tooltip.style.boxShadow ='6px 3px 14px -3px rgba(0,0,0,0.32)'
      tooltip.style.mozBoxShadow ='6px 3px 14px -3px rgba(0,0,0,0.32)'
      // Add more styling as needed
    },
    hideTooltip() {
      const tooltip = document.getElementById("dynamic-tooltip");
      if (tooltip) {
        tooltip.remove();
      }
    },
    savetab(){
      if(this.tabname==='' || this.tabname.trim().length === 0){
        this.errors[0]=true
      }
      else{
        if(this.usertabDashlets.tabarray.length>4){
          alert("More than 5 tabs cannot be added")
        }
        else{
          this.tabname = this.tabname.replace(/ +/g, ' ').trim();
          this.usertabDashlets.tabarray.forEach((tab)=>{
            if(tab.tabName.toLowerCase()===this.tabname.toLowerCase()){
              this.inUseError=true
              alert("Name is already in use")              
              return; 
            }
          })
          if(this.inUseError==false){
            this.tabarray.push(this.tabName);
            this.usertabDashlets.tabarray.push({
              'tabName':this.tabname,
              'dashletElements':[]
            });
            this.tabname = "";
            
            this.closecreatetab()
          }
          this.inUseError=false
        }
        
      }
   
      
      
    },
    opencreatetabmodel() {
      this.tabname=""
      this.opencreatetabmodelvar = true;
    },
    closecreatetab(){
      if(this.errors[0]==true){
        this.errors[0]=false
      }
      this.opencreatetabmodelvar = false;
    },
    async cleardocs(type) {
      this.selectedTab = type;
    },
    async editsize(index) {
      this.selectedindex = index;
      this.setsizepopup = true;
    },
    async closepopup() {
      this.selectedindex = "";
      this.setsizepopup = false;
    },
    async saveorupdate() {
      if(this.usertabDashlets.tabarray.length==0)
      {
        alert("Please add atleast one tab")
       
      }
      else if(this.usertabDashlets.tabarray.find(tab => tab.tabName === this.showBlock).dashletElements.length == 0){
          alert("Please add atleast one component");
        
      }
     else {
        if (this.savedashlet == true) {
          this.save();
        } else {
          this.update();
        }
      }
    },
    async getsaveddashboard() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/login/listuserdashlets`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data == null || res.data == "") {
            this.userDashlets = [];
            this.usertabDashlets={
              tabarray:[{
                'tabName':'Main',
                'dashletElements':[]
              }]
            }
          } else {
            this.usertabDashlets.tabarray = res.data;
            this.savedashlet = false;
          }
            if (this.usertabDashlets.tabarray != null) {
            this.showBlock = this.usertabDashlets.tabarray[0].tabName;
            this.tabname = this.usertabDashlets.tabarray[0].tabName;
            }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async save() {
      const idtoken = store.getters.getIdToken;
      let values = {
        createdUserEmail: jwt_decode(idtoken).email,
        createdUserId: jwt_decode(idtoken).user_id,
        createdAt: "2024-02-21T05:10:36.661Z",
        lastModifiedUserEmail: "string",
        lastModifiedUserId: "string",
        lastModifiedAt: "2024-02-21T05:10:36.661Z",
        tabarray: this.usertabDashlets['tabarray']
      };
      await axios
        .post(`${this.baseurl}/management/login/createdashlets`, values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          alert("Dashboard created successfully");
          this.savedashlet = false;
        })
        .catch((err) => {
          console.log("errorr...", err);
        });
    },
    async update() {
      const idtoken = store.getters.getIdToken;
      let values = {
        id: "",
        createdUserEmail: jwt_decode(idtoken).email,
        createdUserId: jwt_decode(idtoken).user_id,
        createdAt: "2024-02-21T05:10:36.661Z",
        lastModifiedUserEmail: jwt_decode(idtoken).email,
        lastModifiedUserId: jwt_decode(idtoken).user_id,
        lastModifiedAt: "2024-02-21T05:10:36.661Z",
        tabarray: this.usertabDashlets['tabarray']
      };
      await axios
        .put(`${this.baseurl}/management/login/updatedashlet`, values, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          alert("Dashboard created successfully.");
        })
        .catch((err) => {
          console.log("error...", err);
        });
    },
    deleteDashlet(index) {
      console.log(index)
      this.userDashlets.splice(index, 1);
      this.usertabDashlets.tabarray.find(tab => tab.tabName === this.showBlock).dashletElements.splice(index, 1);
    },
    add: function() {
      this.list.push({ name: "Juan" });
    },
    replace: function() {
      this.list = [{ name: "Edgard" }];
    },
    clone: function(el) {
      return {
        name: el.name + " cloned",
      };
    },
    log: function(evt) {
      window.console.log(evt);
    },
    drop(ev, element) {
      console.log("Event is",ev)
    }
  },
};