/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import { useI18n } from 'vue-i18n';
export default {
  setup(){
    const { t, locale } = useI18n({ useScope: 'global' });
    return { t, locale };
  },
  name: "App",
  props:{"visitId": String},
 async mounted() {
    this.selectedvisit = this.visitId
    this.retrieveinfo();
    this.getVisitType();
},
  data() {
    return {
      //  t, locale : useI18n({ useScope: 'global' }),
      baseurl: process.env.VUE_APP_Service_URL,
      selectedStudy: "",
      selectedvisit: "",
      values: {
        visitId:"",
        isPatientVisit: false,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitType:"",
        //visitPeriod:"",
        folderId:"",
        studyId:"",
        accountId:"e75456d6-710d-4ad0-9eb7-2293623f889a",
        isDeleted:false,    
        visitDayPeriod:"",
        visitWeekPeriod:"",
        visitMonthPeriod:"",
        visitYearPeriod:"",
        temp:"",

      },
      numerrors: {preVisit: true, postVisit: true, visitDayPeriod:true, visitWeekPeriod:true, visitMonthPeriod:true, visitYearPeriod:true,},
      validations: [false, false,false],
      errors: [],
      visitList:[]
    };
  },
  methods: {
    async numberonlyvalidation(fieldname) {
      let isInteger = true;
      this.values[fieldname] = this.values[fieldname].replace(/\s/g, '');
      let fieldval = this.values[fieldname];
    
      if (fieldval === '' || /^\s*$/.test(fieldval)) {
        // Field is empty or contains only whitespace
        this.numerrors[fieldname] = true;
        return;
      }
    
      if (fieldval % 1 !== 0) {
        isInteger = false;
      }
    
      if (!isInteger) {
        this.values[fieldname] = 0;
        // this.numerrors[fieldname] = false;
      } else {
        if (parseInt(this.values[fieldname]) >= 0) {
          this.numerrors[fieldname] = true;
        } else {
          this.values[fieldname] = 0;
        }
      }
    },
    limitInputLength(field, maxLength) {
      const inputValue = this.values[field].toString();
  
      if (inputValue.length > maxLength) {
        this.values[field] = Number(inputValue.slice(0, maxLength));
      }
    },
    async validate() {
      this.values.visitNo = this.values.visitNo.replace(/ +/g, ' ').trim();
      
      this.values.visitFormName = this.values.visitFormName.replace(/ +/g, ' ').trim();
      this.values.visitFormName = this.values.visitFormName.replace(/ +/g, ' ').trim();
      if(this.values.visitNo == ""){
        this.validations[0] = false;
      }
      else{
        this.validations[0] = true;
      }
      if(this.values.visitFormName == ""){
        this.validations[1] = false;
      }
      else{
        this.validations[1] = true;
      }

if(this.values.visitType != ""){
  this.validations[2] = true;
}


   },
    async addVisit() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else {
      if(this.selectedvisit == 0) {
        this.save();
      }
      else{
        this.edit();
      }
    }
    },
    async retrieveinfo() {
     this.values.studyId = store.getters.getVisitStudyIs;
     console.log(this.values.studyId);
      if(this.selectedvisit != 0){
       
        // console.log("edit function " + this.selecteduser);
        const idtoken = store.getters.getIdToken;
        await axios
       .get( 
        //`https://localhost:5001/visit/getbyid/`+this.selectedvisit,
       `${this.baseurl}/management/visit/getbyid/`+this.selectedvisit,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log("get bindable data",res.data);
        if(res.data==="No Visit Found")
        {
          alert("No Visit Found");
        }
        this.values = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
      }
    },
    async onclosed() {
      this.values.studyId = store.getters.getVisitStudyIs;
      this.$router.push(`/visitlist/${this.values.studyId}`);
    },
    async clear() {
      console.log("inside clear function");
      this.values = {
        visitId:this.values.visitId,
        visitNo: "",
        visitFormName: "",
        visitNotes: "",
        active: true,
        preVisit: "",
        postVisit: "",
        visitDayPeriod:"", 
        visitWeekPeriod:"",
        visitMonthPeriod:"",
        visitYearPeriod:"",
        isPatientVisit: false,
 
        visitType:"",
        folderId: this.values.folderId,
        studyId: this.values.studyId,
        accountId: this.values.accountId,
        isDeleted: this.values.isDeleted,    
        temp: this.values.temp,   
      }
    },
// save function
    async save() {
      this.values.visitMonthPeriod =  String(this.values.visitMonthPeriod);
      this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
      this.values.visitYearPeriod = String(this.values.visitYearPeriod);
      this.values.visitDayPeriod = String(this.values.visitDayPeriod);
      this.values.studyId = store.getters.getVisitStudyIs;
      this.values.folderId = store.getters.getVisitStudyIs;
      const idtoken = store.getters.getIdToken;
      console.log("token is",idtoken);
      console.log("passing data");
      await axios
      .post(
        //`https://localhost:5001/visit/create`,
        `${this.baseurl}/management/visit/create`,
        this.values,
        {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        if(res.data.data==="No visit Found")
        {
          alert("No Visit found");
        }
        
        this.userList = res.data.data;
        alert("Visit added successfully.");
        this.$router.push(`/visitlist/${this.values.studyId}`);
      })
      .catch((err) => {
        alert(err.response.data.detail);
      });
      
  },
  async getVisitType(){
    const idtoken = store.getters.getIdToken;
    await axios
    .get(`${this.baseurl}/management/visittype/listvisittypes`,{
      headers: {
        Authorization: "Bearer " + idtoken,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      console.log(res);
      this.visitList = res.data.entities;
      console.log("Result visit is",this.visitList)
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.detail);
    });
  },
  async edit() {
    this.values.visitMonthPeriod =  String(this.values.visitMonthPeriod);
    this.values.visitWeekPeriod = String(this.values.visitWeekPeriod);
    this.values.visitYearPeriod = String(this.values.visitYearPeriod);
    this.values.visitDayPeriod = String(this.values.visitDayPeriod);
    this.values.studyId = store.getters.getVisitStudyIs;
    console.log("edit function",this.values);
    const idtoken = store.getters.getIdToken;
    console.log(idtoken);
    await axios
    .put(
      //`https://localhost:5001/visit/update`,
       `${this.baseurl}/management/visit/update`,
      this.values,
      {
        headers: {
          Authorization: "Bearer " + idtoken,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      console.log(res);
      if(res.data.data==="No Visit Found")
      {
        alert("No Visit Found");
      }
      this.userList = res.data.data;
      console.log("Result is",res.data.data)
      alert("Visit updated successfully.");
      this.$router.push(`/visitlist/${this.values.studyId}`);
    })
    .catch((err) => {
      console.log(err);
      alert(err.response.data.detail);
    });
   
  }
    // save function ends
    // vipinv@datamatica.uk
    // Test@123

  },
};
