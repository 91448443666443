/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";


/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import store from "../../store/index";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
const idtoken = store.getters.getIdToken;
export default {
    name: 'formlisting',
    components: {
        VPagination,
    },
    data() {
        return {
            defaultdate: "",
            baseapi: process.env.VUE_APP_Service_URL,
            baseUrl: `${process.env.VUE_APP_Service_URL}/forms/forms/`,
            searchdata: {
                SiteCode: "",
                PatientId: "",
                FormName: "",
                FormStatus: "",
                Pageindex: 1,
                Pagesize: 10,
                SortProperty: "createdAt",
                SortOrder: 1,
            },
            sites: "",
            patients: "",
            idToken: "",
            selectedType: "",
            selectedId: "",
            statusCheck: "",
            searchItem: "",
            currentPageSize: 0,
            currentPageNumber: 1,
            enableNext: true,
            enablePrevious: true,
            totalLists: 0,
            totalPages: 0,
            lists: [],
            filteredListing: [],
            dataList: [],
            roleprivileges:"",
        }
    },
    async mounted() {
        this.defaultdate = localStorage.getItem("dateformat");
        window.addEventListener("studyIdChanged", (event) => {
            console.log("StudyIDChanged into" + event.detail.storage);
            // window.location.reload();
            this.idToken = store.getters.getIdToken;
            this.roleprivileges = store.getters.getRolesprivilegeData;
            this.getpatients();
            this.getsites();
            this.searchData();
            this.filter();
        });
        this.idToken = store.getters.getIdToken;
        this.roleprivileges =  store.getters.getRolesprivilegeData;
        this.getpatients();
        this.getsites();
        this.searchData();
        this.filter();
    },
    methods: {

        async archive(formId, subId) {
            const idtoken = store.getters.getIdToken;
            if (confirm("Are you sure you want to archive this form?")) {
                await axios.put(
                    `${this.baseUrl}archive`, {
                    createdUserEmail: "string",
                    createdUserId: "string",
                    createdAt: "2022-11-01T05:24:16.754Z",
                    lastModifiedUserEmail: "string",
                    lastModifiedUserId: "string",
                    lastModifiedAt: "2022-11-01T05:24:16.754Z",
                    formId: formId,
                    subjectId: subId
                },
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    })
                    .then((res) => {
                        console.log("archive status", res);
                        alert(this.$t('navigations.form archived successfully'));
                        this.searchData();
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(this.$t('navigations.patient schedule not found'));
                    });
            }
        },

        filter(data) {
            if (data) {
                return moment(data).format(this.defaultdate)
            }
        },

        SortSelected(sortProp, id) {
            console.log('SortPropChanged', this.searchdata.SortOrder);
            const selectedElement = document.getElementById(id)
            const selectedElementID = document.getElementById(id).id
            if (sortProp != null && id == selectedElementID) {
                this.sortDesc = !this.sortDesc
                if (this.searchdata.SortOrder == 0) {
                    // if(selectedElement.classList.contains("sortDesc")){
                    //   return
                    // }
                    if (selectedElement.classList.contains("sortAsc")) {
                        selectedElement.classList.remove("sortAsc")
                        selectedElement.classList.add("sortDesc")
                    }
                    else selectedElement.classList.add("sortDesc")
                }
                else {
                    if (selectedElement.classList.contains("sortDesc")) {
                        selectedElement.classList.remove("sortDesc")
                        selectedElement.classList.add("sortAsc")
                    }
                    // else if(selectedElement.classList.contains("sortAsc"))
                    // {
                    //   return
                    // }          
                    else selectedElement.classList.add("sortAsc")
                }
            }
            this.searchdata.Pageindex = 1;
            this.currentPageNumber = 1;
            this.searchdata.SortProperty = sortProp;
            this.searchdata.SortOrder = this.searchdata.SortOrder == 0 ? 1 : 0;
            this.searchData();
        },

        async viewforms(id) {
            store.dispatch("setFieldName", " ");
            const archive = false;
            this.$router.push(`/showform/forms/${id}/${archive}`);
           // this.$router.push(`/showform/forms/${id}/`);
        },
        async getPrevious() {
            if (this.searchdata.Pageindex > 1) {
                console.log("previous page");
                this.searchdata.Pageindex = this.searchdata.Pageindex - 1;
                this.searchData();
            }
        },
        async getNext() {
            if (this.searchdata.Pageindex < this.totalLists) {
                this.searchdata.Pageindex = this.searchdata.Pageindex + 1;
                this.searchData();

            }
        },
        async SearchButtonClicked() {
            this.currentPageNumber = 1;
            this.searchdata.Pageindex = 1;
            this.searchData();
        },
        async searchData() {
            if (this.searchdata.SiteCode == "All") {
                this.searchdata.SiteCode = ""
            }
            if (this.searchdata.PatientId == "All") {
                this.searchdata.PatientId = ""
            }
            if (this.searchdata.FormStatus == "All") {
                this.searchdata.FormStatus = ""
            }
            const idtoken = store.getters.getIdToken;
            const stdid = store.getters.getStudyIs;
            await axios
                .get(this.baseUrl +
                    "formlist?FormName=" + this.searchdata.FormName +
                    "&FormStatus=" + this.searchdata.FormStatus +
                    "&SiteCode=" + this.searchdata.SiteCode +
                    "&StudyId=" + stdid +
                    "&PatientId=" + this.searchdata.PatientId +
                    "&pageindex=" + this.searchdata.Pageindex +
                    "&pagesize=" + this.searchdata.Pagesize +
                    "&SortProperty=" + this.searchdata.SortProperty + "&SortOrder=" + this.searchdata.SortOrder,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    console.log("search data", response);
                    if (response.data) {
                        this.totalLists = Math.ceil(response.data.totalCount / this.searchdata.Pagesize);
                        console.log(this.totalLists, " total list");
                        this.dataList = response.data.results;
                        //this.filteredListing = response.data.results;
                    } else {
                        console.log("There are currently no list");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.enableNext = false;
                    this.enablePrevious = false;
                });
        },
        async getpatients() {
            this.searchdata.PatientId = "";
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            console.log(this.searchdata.SiteCode, currentstudy);
            await axios
                .get(
                    `${this.baseapi}/management/patient/listpatient?SiteId=${this.searchdata.SiteCode}&Studyid=${currentstudy}`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",

                        },
                    }
                )
                .then((res) => {
                    console.log(" get patients", res);
                    this.patients = res.data.results;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async getsites() {
            const idtoken = store.getters.getIdToken;
            const currentstudy = store.getters.getStudyIs;
            await axios
                .get(
                    `${this.baseapi}/management/site/getallsitesbyuser?studyId=${currentstudy}&sortProperty=siteCode&IsDes=false&getAll=false`,
                    {
                        headers: {
                            Authorization: "Bearer " + idtoken,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((res) => {
                    //console.log("sites", res);
                    this.sites = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //filtering list
        filteredLists() {
            this.filteredListing = []
            if ((this.searchItem) || (this.selectedType) || (this.selectedId) || (this.statusCheck)) {
                this.lists.filter((list) => {
                    if ((this.searchItem == list.formName) || (this.selectedType == list.siteId) || (this.selectedId == list.subjectId) || (this.statusCheck == list.formStatus)) {
                        //push the filtered list to new array                     
                        this.filteredListing.push({
                            visitName: list.visitName,
                            siteId: list.siteId,
                            subjectId: list.subjectId,
                            formName: list.formName,
                            formStatus: list.formStatus
                        })
                        // console.log(this.filteredListing) 
                        return (this.filteredListing)
                    }
                });

            }
            // else  
            // {
            // //if no values are entered
            // alert('Enter a value to search')

            // }
        },
        async loadPage(page) {
            this.currentPageNumber = page;
            this.searchdata.Pageindex = page;
            this.searchData();
        },
    },

}