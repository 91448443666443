<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ templateName }}</h2>
    </div>
    <div class="col-lg-2">
      <i class="fa fa-times close my-1" @click="onclosed()"></i>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-body mb-1">
      <div class="ibox-title style_2 d-flex justify-content-between">
        <h5>Verify Form</h5>
        <div class="d-flex justify-content-between">
          <div 
            role="button"
            tabindex="0"
            @keyup.enter="openNewTab()"
            class="btn-accept position-relative mr-3"
            @click.prevent="openNewTab()"
          >
            <img src="../../assets/img/view_form.png" alt="view template" /><span
              class="pointer"
            >
              View Source Form
            </span></div
          >
          <div 
            v-if="!this.signStatus"
            role="button"
            tabindex="0"
            @keyup.enter="assigntemplate('Approved', postcomment)"
            class="btn-accept position-relative mr-3"
            @click.prevent="assigntemplate('Approved', postcomment)"
          >
            <img src="../../assets/img/icons/approve.png" alt="approve template" /><span
              class="pointer"
            >
              Approve
            </span></div
          >
          <div
             v-if="!this.signStatus"
            role="button"
            tabindex="0"
            @keyup.enter="assigntemplate('Rejected', postcomment)"
            class="btn-reject position-relative mr-3"
            @click.prevent="assigntemplate('Rejected', postcomment)"
          >
            <img src="../../assets/img/icons/reject.png" alt="reject template" /><span
              class="pointer"
            >
              Reject </span
            ></div
          >
          <div
            role="button"
            tabindex="0"
            @keyup.enter="opencomments()"            
            class="btn-comment position-relative mr-3"
            @click.prevent="opencomments()"
          >
            <img src="../../assets/img/icons/comments.png" alt="add comment" /><span
              class="pointer"
            >
              Comments
            </span></div
          >
          <div
            class="sign-btn position-relative mr-3"
            v-if="showSignBtn"
            role="button"
            tabindex="0"
            @keyup.enter="signpopUp=true"
            @click.prevent="signpopUp=true"
          >
          <img src="../../assets/img/icons/sign.png" alt="sign template" />
             <span class="pointer">Sign</span> 
            </div>    
        </div>
      </div>
      <div class="ibox-content"  v-if="!this.signStatus">
        <div class="row">
          <label class="col-form-label col-lg-1">{{ $t("navigations.comments") }}</label>
          <span class="col-lg-1"> </span>
          <textarea class="col-lg-10" v-model="postcomment"></textarea>
        </div>
      </div>
    </div>
    <div class="ibox-sign mb-2" v-if="showSignBlock === true">
      <div class="d-flex align-items-center"
        ><img class="mr-1" src="../../assets/img/icons/sign.png" alt="sign template" />
        <p class="mb-0">This
        form was signed on {{ filter(signdate) }} at {{filterTime(signdate)}} by
        {{ signedby }} </p> 
      </div>
    </div>
    <dynamicform
      :templateid="tempId"
      :requiredvalidate="false"
      :requiredsubmit="false"
      :requiredlanguage="false"
      :fromUrlStatus="urlStatus"
      :isSigned="signStatus"

    />
  </div>
  <!-- modal starts -->
  <div class="comment-block-modal-wrapper" v-if="showCommentBlock">
    <div class="comment-block-modal-container">
        <div class="comment-block-modal-title text-white d-flex justify-content-between py-2 px-3">         
          <h4 class="my-0">Comments</h4>
          <div role="button"  @keyup.enter="showCommentBlock=false" @click.prevent="showCommentBlock=false">          
          <i class="fa fa-times my-1"></i>  
          </div>
        </div>
        <div class="modal-body chat-discussion">
          <!-- {{commnetlist}} -->
          <div v-for="chats in commnetlist" :key="chats" class="chat-message">
            <div class="message ">
              <a class="message-author" role="button"> {{chats.approvedBy}} </a> &nbsp;
              <span class="message-date text-right">
                {{filterTime(chats.assignedDate)}} - {{filterDate(chats.assignedDate)}}
              </span>
              <span class="message-content">
                {{chats.comments}}
              </span>
            </div>
          </div>        

          </div>
    </div>
  </div>
  <!-- modal ends -->
  <signaturePopUp
    v-if="signpopUp == true"
    status="Signed"
    fromSrcUrl="digitalForm"
    :formId="tempId"
    modalText="Sign"
    @signatureclosemodel="signpopUp = false"
    @docSigned="showSignBtn=false"
  />
</template>

<script src="./view_digital_form.js"></script>

<style>
@import "./modal.css";
.modal-container {
    background: none !important;
    margin-top: 65px !important;
    width :90%;
}
.textwidth {
  width: 75%;
}
.btn-view .pointer,
.btn-accept .pointer,
.btn-reject .pointer,
.btn-comment .pointer,
.sign-btn .pointer {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.btn-view:hover .pointer,
.btn-accept:hover .pointer,
.btn-reject:hover .pointer,
.btn-comment:hover .pointer,
.sign-btn:hover .pointer{
  visibility: visible;
  -webkit-transition: visibility 0.4s ease-in;
  transition: visibility 0.4s ease-in;
}
.modal-container {
  background: #fff;
  width: min(50%, 90%);
  border-radius: 4px;
  position: fixed;
  transition: all 0.3s ease-in-out;
  height: auto !important;
  margin-top: 200px;
}
/* MODAL */
.comment-block-modal-wrapper {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 3000;
}
.comment-block-modal-container {
  position: fixed;
  width: min(500px, 90%);
  border-radius: 4px;
  background: #fff;
  overflow-x: hidden;
}
.comment-block-modal-title {
  background-color: var(--pop-up-background);
  color: white;
}
.chat-discussion{
  padding: 10px !important;
  background: #f8fafb !important;
}
</style>
