/* eslint-disable */
const state = () => ({
    //queryType :  localStorage.getItem('query'),
    queryType:'',
   });
   
   const getters = {
       getQueryType( state :{queryType : any}){
           return state.queryType;
       },
   };
   
   const actions ={
       async setQueryType({commit} : any , data : any){
           commit("setQueryType" , data);
       }
   
   };
   
   const mutations ={
       setQueryType(state : {queryType : any } , queryValue : any ){
           state.queryType = queryValue;
           localStorage.setItem("query", queryValue);
       },
   };
   
   export default {
       state,
       getters,
       actions,
       mutations
   }