/* eslint-disable */
const state = () => ({
 fieldName :  localStorage.getItem('field'),
});

const getters = {
    getFieldName( state :{fieldName : any}){
        return state.fieldName;
    },
};

const actions ={
    async setFieldName({commit} : any , data : any){
        commit("setFieldName" , data);
    }

};

const mutations ={
    setFieldName(state : {fieldName : any } , fieldData : any ){
        state.fieldName = fieldData;
        localStorage.setItem("field", fieldData);
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}