/* eslint-disable */
// import jwtInterceptor from "../Shared/jwtInterceptor";
import axios from 'axios';
import store from "../../store/index";
export default {
    name: 'device',
    data() {
        return {
            baseapi: process.env.VUE_APP_Service_URL,
            deviceArray:[],
        }
    },
    async mounted() {
        this.getdevices();
    },
    methods: {
        async getdevices(){
            const idtoken = store.getters.getIdToken;
            await axios
            .get(`${this.baseapi}/management/iotdevice/getall`,
                {
                    headers: {
                        Authorization: "Bearer " + idtoken,
                        "Content-Type": "application/json",
                    },
                }
            ).then((res)=>{
                this.deviceArray=res.data
                console.log(this.deviceArray)
                })
            .catch((err) =>{
                console.log(err);
            })
        }
    }
}