<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-12">
      <h2>{{ $t("navigations.folder") }}</h2> 
    </div>
  </div>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-12 col-lg-5 mb-1 mr-0 pr-0">
        <div class="ibox-body">
          <div class="ibox-title style_2 d-flex justify-content-between">
            <h5 class="float-left">{{ $t("navigations.folder list") }}</h5>
            <div class="position-relative" v-if="roleprivileges.includes('Documents Create Folder')">
              <img
                class="cursor-pointer"
                src="../../assets/img/add_folder.png"
                alt="create folder"
                @click="createFolderfunc('addFolder')"
              />
              <div class="tool-tip">{{ $t("navigations.add folder") }}</div>
            </div>
          </div>
          <div class="ibox-content">
            <ul class="jstree1">
              <div>
                <TreeBrowser
                  v-if="folderlist.length != 0"
                  :list="folderlist"
                  patid=""
                  :boldfolder ="bold"
                >
                </TreeBrowser>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="ibox mb-2" v-if="folderNames">
          <div class="ibox-title style_2">
            <h5>{{ $t("navigations.folder details") }}</h5>           
          </div>
          <div class="ibox-content p-3">
            <div class="row">
              <div v-if="retrieve" class="col-4">
                <small class="stats-label">{{
                  $t("navigations.folder name")
                }}</small>
                <h4>{{ folderNames }}</h4>
              </div>
              <div v-if="!retrieve" class="col-4">
                <input
                  type="text"
                  placeholder="Enter Folder Name"
                  class="form-control1"
                  v-model="updateFolder.folderName"
                  maxlength="30"
                />
                <span class="errorClass" v-if="errors[1] == false">{{
                  $t("navigations.please enter a folder name")
                }}</span>
              </div>
              <div class="col-4">
                <small class="stats-label">{{
                  $t("navigations.modified date")
                }}</small>
                <h4>{{ filter(lastModifiedAt) }}</h4>
              </div>
              <div v-if="retrieve && folderNames" class="col-4 text-right">
                <a
                  href="#"
                  v-if="roleprivileges.includes('Documents Create Folder')"
                  class="mr-4 position-relative"
                  @click="updateFolderName()"
                  ><img
                    src="../../assets/img/edit_doc.png"
                    alt="Edit folder name"
                  />
                  <span class="tool-tip">{{ $t("navigations.edit") }}</span>
                </a>
                <a 
                 v-if="roleprivileges.includes('Documents Delete Folder')"
                  class="position-relative" href="#" 
                  @click="deleteFolder()"
                  ><img
                    src="../../assets/img/delete_doc.png"
                    alt="Delete folder"
                  />
                  <span class="tool-tip">{{
                    $t("navigations.delete")
                  }}</span></a
                >
              </div>
              <div v-if="!retrieve" class="col-4 text-right">
                <a
                  class="mr-4 position-relative"
                  href="#"
                  @click="editFolder()"
                >
                  <img src="../../assets/img/save.png" alt="Save folder name" />
                  <span class="tool-tip">{{ $t("navigations.save") }}</span>
                </a>
                <a class="position-relative" href="#" @click="closebutton()"
                  ><img
                    src="../../assets/img/cancel.png"
                    alt="Cancel function"
                  />
                  <span class="tool-tip">{{
                    $t("navigations.cancel")
                  }}</span></a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="ibox">
          <div class="ibox-title style_2" :class="{'d-flex justify-content-between align-items-center':folderNames}">
            <h5 class="my-0">{{ $t("navigations.documents") }}</h5>
            <div
                 class="position-relative" 
                 v-if="folderNames && roleprivileges.includes('Documents Upload Document')">
              <img
                class="cursor-pointer"
                src="../../assets/img/upload_files.png"
                alt="upload document"
                @click="enablepopup('update')"
              />
              <div class="tool-tip">{{ $t("navigations.upload document") }}</div>

              <!-- <i class="fa fa-times close-block" @click="onclosed()"></i> -->
            </div>
          </div>
          <div class="ibox-content p-4">
            <div class="row mb-3">
              <div class="col-md-3 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.document name") }}</label
                  >
                  <input
                    type="text"
                    class="form-control my-md-2 my-lg-0"
                    v-model="SearchData.DocsearchName"
                  />
                </div>
              </div>
              <div class="col-md-4 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.created date") }}</label
                  >
                  <input
                    type="date"
                    max="9999-12-31"
                    class="form-control"
                    v-model="SearchData.CreatedDate"
                  />
                </div>
              </div>
              <div class="col-md-3 mr-0 pr-0">
                <div class="form-group mb-md-0">
                  <label class="mb-0">
                    {{ $t("navigations.uploaded by") }}</label
                  >
                  <input
                    type="text"
                    class="form-control my-md-2 my-lg-0"
                    v-model="SearchData.UploadedBy"
                  />
                </div>
              </div>
              <div class="col-lg-2 align-self-end">
                <span
                  type="button"
                  class="save_btn w-100"
                  @click.prevent="OnSearchButtonClicked()"
                  >{{ $t("navigations.search") }}</span
                >
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th
                      class="sort_block"
                      width="20%"
                      @click="SortSelected('folderName', 1)"
                      id="1"
                    >
                    <div class="d-flex justify-content-between">
                      {{ $t("navigations.folder") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                      
                    </div>
                    
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('documentName', 2)"
                      id="2"
                    > 
                      <div class="d-flex justify-content-between">
                        {{ $t("navigations.document") }}
                        <span>
                          <i class="fa fa-long-arrow-up"></i>
                          <i class="fa fa-long-arrow-down"></i>
                        </span>
                      </div>
                    
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('createdUser', 3)"
                      id="3"
                    >{{ $t("navigations.created by") }}
                      <span >
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                      
                    </th>
                    <th
                      class="sort_block"
                      @click="SortSelected('CreatedAt', 4)"
                      id="4"
                    >
                      {{ $t("navigations.created date") }}
                      <span>
                        <i class="fa fa-long-arrow-up"></i>
                        <i class="fa fa-long-arrow-down"></i>
                      </span>
                    </th>
                    <th v-if="roleprivileges.includes('Documents Upload Document')">{{ $t("navigations.edit") }}</th>
                    <th v-if="roleprivileges.includes('Documents Delete Document')">{{ $t("navigations.delete") }}</th>
                    <th v-if="roleprivileges.includes('Documents View Document')" class="text-center">
                      {{ $t("navigations.view") }}
                    </th>
                    <th
                      class="text-center"
                      v-if="roleprivileges.includes('Documents Assign Approver')"
                    >
                      {{ $t("navigations.approvals") }}
                    </th>
                    <th class="text-center" v-if="roleprivileges.includes('Documents Download Document')">
                      {{ $t("navigations.download") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="doc in doclist" :key="doc.id">
                    <td>{{ doc.folderName }}</td>
                    <td>
                      {{ doc.documentName }}
                    </td>
                    <td>{{ doc.createdUser }}</td>
                    <td>
                      {{
                        filter(doc.CreatedAt ? doc.CreatedAt.slice(0, 10) : "")
                      }}
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('Documents Upload Document')">
                      <i
                        class="fa fa-edit edit-delete"
                        @click="
                          editdocument(
                            doc.id,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].documentVersionId,doc.documentName
                          )
                        "
                      ></i>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('Documents Delete Document')">
                      <i                        
                        class="fa fa-trash edit-delete"
                        @click="deleteAzureDocument(doc.id)"
                      ></i>
                    </td>
                    <td v-if="roleprivileges.includes('Documents View Document')" class="text-center">
                      <i
                        class="fa fa-eye editicon"
                        @click="viewDocument(doc.id)"
                      ></i>
                    </td>
                    <td
                      class="text-center"
                      v-if="roleprivileges.includes('Documents Assign Approver')"
                    >
                      <i
                        class="fa fa-check-circle-o edit-delete"
                        @click="
                          AddApproverPopuup(
                            doc.id,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].documentVersionId,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].versionNumber,
                            doc.documentVersions[
                              doc.documentVersions.length - 1
                            ].approval
                          )
                        "
                      ></i>
                    </td>
                    <td class="text-center" v-if="roleprivileges.includes('Documents Download Document')">
                      <i
                        class="fa fa-download edit-delete"
                        @click="downloadfile(doc.id)"
                      >
                      </i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-1 mx-0">
              <div class="col-lg-12 mx-0 px-0">
                <div
                  class="
                    dataTables_paginate
                    paging_simple_numbers
                    pagination
                    float-right
                  "
                >
                  <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                    <v-pagination
                      v-model="currentPage"
                      :pages="totalPage"
                      :range-size="0"
                      active-color="#618bd7"
                      @update:modelValue="loadPage"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup library -->

  <AddApproverLibrary
    v-if="popactive"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popactive = false"
    @listupdate="getDocuments()"
  >
  </AddApproverLibrary>

  <listApproverPopup
    v-if="popupmodal"
    :id="documentPopupId"
    :versionId="PopupVersionId"
    :versionNumber="PopupVersionNumber"
    @libactive="popupmodal = false"
    @listupdate="getDocuments()"
  >
  </listApproverPopup>

  <!-- Popup library -->

  <div class="create-folder-modal" v-if="showFolder == true">
    <div class="folder-modal-container">
      <div class="title-class d-flex justify-content-between py-2 px-3">
        <h4 class="my-0">{{ $t("navigations.create folder") }}</h4>
        <div class="cursor-pointer" @click="onclosed('addFolder')">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="ibox-content p-4">
        <div class="input-group">
          <input
            type="text"
            placeholder="Enter Folder Name"
            class="form-control"
            v-model="values.FolderName"
            maxlength="30"
          />
          <span class="input-group-append">
            <button
              type="button"
              class="btn btn-primary save_btn"
              @click="addFolder()"
            >
              {{ $t("navigations.add") }}
            </button>
          </span>
        </div>
        <span class="errorClass" v-if="errors[0] == false">{{
          $t("navigations.please enter a folder name")
        }}</span>
      </div>
    </div>
  </div>

  <!-- popup ends -->

  <DocumentPopup
    v-if="uploadpopupmodal"
    :id="documentPopupId"
    :refid="doceditid"
    :versId="docverId"
    :docdesc="Description"
    :editorupdate="calldetails"
    :documentType="doctype"
    :documenttypeid="storageId"
    :documentName="documentname"
    @uploadlibactive="uploadmethodactivity()"
  >
  </DocumentPopup>
</template>
<script>
/* eslint-disable */
// import axios from "axios";
import moment from "moment";
import axios from "axios";
import store from "../../store/index";
import TreeBrowser from "../Tree/TreeBrowser.vue";
import VPagination from "@hennge/vue3-pagination";
import AddApproverLibrary from "../AddApproverLibrary/AddApproverLibrary.vue";
import listApproverPopup from "../listApprover/listApproverPopup.vue";
import DocumentPopup from "../DocumentPopup/DocumentPopup.vue";
import { ref, computed, onMounted } from "vue";
export default {
  // props: { userid: String },
  data() {
    return {
      documentname:"",
      doctype: "general",
      popupmodal: false,
      popactive: false,
      uploadpopupmodal: false,
      calldetails: "update",
      docverId: "",
      doceditid: "",
      Description: "",
      defaultdate: "",
      savefolder: false,
      roleprivileges: "",
      currentdocId: "",
      fileuploadurl: process.env.VUE_APP_File_upload_URL+"/document",
      baseurl: process.env.VUE_APP_Service_URL,
      baseapi: process.env.VUE_APP_Service_URL,
      folderlist: [],
      loaddata: false,
      versionNumber: "",
      documentPopupId: "",
      values: {
        FolderName: "",
        AncestorIds: [],
        Approver: [],
        ParentId: "",
        storageId: "",
      },
      approveList: [],
      approverlist: "",
      azuredata: {
        DocumentId: "",
        DocumentVersionId: "",
        DocumentTitle: "",
        DocumentDescription: "",
        Document: "",
        DocumentTypeName: "general",
      },
      retrieve: true,
      updateFolder: {
        id: "",
        folderName: "",
        ancestorIds: [],
        parentId: "",
        storageId: "",
      },
      folderbyid: "",
      errors: [],
      validations: [],
      showFolder: false,
      showDoc: false,
      xlsFile: "",
      documentVersionId: [],
      updateDocument: {
        documentName: "",
      },
      SearchData: {
        DocName: "",
        FolderId: "",
        DocsearchName: "",
        CreatedDate: "",
        UploadedBy: "",
        DocumentTypeId: "",
        pageNo: 1,
        pageSize: 10,
      },
      Version: {
        fileName: "",
        uploadedBy: "",
        uploadedOn: "",
        uploadedUserId: "",
      },
      doclist: "",
      docsId: "",
      documentName: "",
      documentById: "",
      docversioncount: "",
      ShowDocName: true,
      totalPage: 1,
      currentPage: 1,
      totalCount: 0,
      pageindex: 1,
      pagesize: 10,
      storagevalue: "",
      SortProperty: "CreatedAt",
      SortOrder: 1,
      bold:"",
    };
  },
  components: {
    TreeBrowser,
    VPagination,
    DocumentPopup,
    AddApproverLibrary,
    listApproverPopup,
  },
  async mounted() {
    store.dispatch("setStorageId", 0);
    const storageId = store.getters.getStorageId;
    console.log("storageid......", storageId);
    //this.storagevalue=storageId;
    this.getreviewers();
    this.defaultdate = localStorage.getItem("dateformat");
    await this.setroleprivileges();
  },
  async beforeMount() {
    await store.dispatch("setFolderId", 0);
    await store.dispatch("setFolderName", null);
    await store.dispatch("setFolderdate", null);
    this.getfolder("general");
    store.dispatch("setDetails", false);
    const folderId = store.getters.getFolderId;
    const folderName = store.getters.getFolderName;
    const lastModifiedAt = store.getters.getFolderdate;
    const storageId = store.getters.getStorageId;
    this.getDocuments();
    this.filter();
    console.log("mounted");
  },
  watch: {
    "$store.getters.getFolderName": function (val) {
      this.getDocuments();
    },
    "$store.getters.getFolderId": function(newVal) {
      this.bold = newVal;
    },
  },
  computed: {
    showDetails() {
      return store.getters.getDetails;
    },
    folderNames() {
      this.setgetdetails();
      return store.getters.getFolderName;
    },
    lastModifiedAt() {
      return store.getters.getFolderdate;
    },
  },
  methods: {
    async uploadmethodactivity() {
      this.uploadpopupmodal = false;
      this.getDocuments();
    },
    async enablepopup(editorcreate) {
      this.calldetails = editorcreate;
      this.documentPopupId = 0;
      this.doceditid = 0;
      this.Description = "";
      this.uploadpopupmodal = true;
    },
    async editdocument(docId, verId,documentName) {
      console.log("in edit first page..........",docId);
      this.calldetails = "edit";
      this.doceditid = docId;
      this.docverId = verId;
      this.documentPopupId = 0;
      this.Description = "";
      this.uploadpopupmodal = true;
      this.documentname = documentName
    },
    async setgetdetails() {
      if (store.getters.getFolderName) {
        this.savefolder = true;
      } else {
        this.savefolder = false;
      }
    },
    async setroleprivileges() {
      this.roleprivileges = await store.getters.getRolesprivilegeData;
      console.log(this.roleprivileges);
    },
    async createFolderfunc(value) {
      this.values.FolderName=""
      this.azuredata.DocumentDescription = "";
      this.values.Approver = [];
      document.body.style.overflowY = "hidden";
      if (value == "addFolder") {
        this.showFolder = !this.showFolder;
      } else this.uploadpopupmodal = true;
    },
    async onclosed(value) {
      this.azuredata.DocumentDescription = "";
      this.values.Approver = [];
      this.values.FolderName = "";
      document.body.style.overflowY = "auto";
      if (value == "addFolder") {
        this.showFolder = false;
      } else this.showDoc = false;
    },
    async hideorshow(elid) {
      alert("tree" + elid);
      const list = document.getElementsByClassName("tree" + elid).classList;
      console.log("classlist is ----------------------------------------");
      console.log(list);
    },
    async previewFiles(event) {
      this.azuredata.Document = event.target.files[0];
      console.log("upload file is", this.azuredata.Document.name);
    },
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate);
      }
    },
    click() {
      console.log("clickkkkk", this.azuredata.DocumentDescription);
      if (this.azuredata.DocumentDescription != null) {
        this.azuredata.DocumentDescription = "";
        console.log("entered clickk");
      }
    },
    async downloadfile(docId) {
      this.ShowDocName = false;
      await this.getDocumentById(docId);
      //await this.getdmsdocument(docId);
      console.log("doc ID", this.Document.documentReferenceId);
      console.log("get file to dwnld", this.Document.documentName);
      //this.docversioncount = this.documentById.documentVersions.length;
      const fileName =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;
      console.log("get version to dwnld", fileName);
      var a = document.createElement("a");

      a.href = `${this.fileuploadurl}/file/${this.Document.documentReferenceId}?versionid=${fileName}`;

      a.download = "FILENAME";

      a.click();
    },
    SortSelected(sortProp, id) {
      console.log("SortPropChanged", sortProp);
      const selectedElement = document.getElementById(id);
      const selectedElementID = document.getElementById(id).id;
      if (sortProp != null && id == selectedElementID) {
        this.sortDesc = !this.sortDesc;
        if (this.SortOrder == 0) {
          if (selectedElement.classList.contains("sortAsc")) {
            selectedElement.classList.remove("sortAsc");
            selectedElement.classList.add("sortDesc");
          } else selectedElement.classList.add("sortDesc");
        } else {
          if (selectedElement.classList.contains("sortDesc")) {
            selectedElement.classList.remove("sortDesc");
            selectedElement.classList.add("sortAsc");
          } else selectedElement.classList.add("sortAsc");
        }
      }
      this.pageindex = 1;
      this.currentPage = 1;
      this.SortProperty = sortProp;
      this.SortOrder = this.SortOrder == 0 ? 1 : 0;
      this.getDocuments();
    },
    async getdmsdocument() {

      await axios
        .get(
          `${this.fileuploadurl}/getallfiles?id=${this.Document.documentReferenceId}`,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,

              "Content-Type": "application/json",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          this.documentById = res.data;
          console.log("Docdownld............", this.documentById);
        })
        .catch((err) => {
          alert(this.$t("navigations.document is not available"));
          console.log(err);
        });
    },

    async AddOrEdit() {
      console.log("docId", this.azuredata);
      if (this.azuredata.DocumentId == 0) {
        this.submitFile();
      } else if (this.azuredata.Document != 0) {
        this.updateFile();
      } else {
        if (this.azuredata.DocumentDescription != 0) {
          this.dmsDocDetailsEdit();
        } else if (
          this.azuredata.Document == 0 &&
          this.azuredata.DocumentDescription == 0
        ) {
          alert(this.$t("navigations.please add document description or document"));
        }
      }
    },
    async DocDetailsEdit() {
      console.log("id issssss" + this.Document.documentId);
      const idtoken = store.getters.getIdToken;
      console.log({
        id: this.Document.documentId,
        documentDescription: this.azuredata.DocumentDescription,
      });
      await axios
        .put(
          `${this.baseurl}/management/document/update`,
          {
            id: this.Document.documentId,
            documentDescription: this.azuredata.DocumentDescription,
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt", res.data);
          this.docdiscriptionupdate = res.data;
          alert(this.$t("navigations.document updated successfully"));
          this.showDoc = false;
          document.body.style.overflowY = "auto";
          this.getDocuments();
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async dmsDocDetailsEdit() {
      console.log("DocccId", this.Document.documentReferenceId);
      await axios
        .put(
          `${this.fileuploadurl}/updatedocumentdetails?docId=${this.Document.documentReferenceId}&docdesc=${this.azuredata.DocumentDescription}`
        )
        .then((res) => {
          console.log("Docccssss", this.azuredata.DocumentDescription);
          console.log("resulttttttttt", res.data);
          this.doclist = res.data;
          this.DocDetailsEdit();
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async OnSearchButtonClicked() {
      this.pageindex = 1;
      this.currentPage = 1;
      this.getDocuments();
    },
    async getDocuments() {
      this.doclist = [];
      const storageId = "general";
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      if (folderId != 0) {
        this.SearchData.FolderId = folderId;
      } else {
        this.SearchData.FolderId = "";
      }
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentName=` +
            this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&folderId=" +
            this.SearchData.FolderId +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("getDocument details", res);
          this.doclist = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.pagesize);
          if (this.totalCount % this.pagesize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async getDocumentsrefresh() {
      this.doclist = [];
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      console.log("searchhhhhhh", this.Document);
      if (folderId != 0) {
        this.SearchData.FolderId = folderId;
      } else {
        this.SearchData.FolderId = "";
      }
      await axios
        .get(
          ` ${this.baseurl}/management/document/listdocument?documentName=` +
            this.SearchData.DocsearchName +
            "&uploadedDate=" +
            this.SearchData.CreatedDate +
            "&uploadedBy=" +
            this.SearchData.UploadedBy +
            "&documentTypeId=" +
            storageId +
            "&folderId=" +
            this.SearchData.FolderId +
            "&pageindex=" +
            this.pageindex +
            "&pagesize=" +
            this.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            this.SortOrder,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt", res.data);
          this.doclist = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPage = parseInt(this.totalCount / this.SearchData.pageSize);
          if (this.totalCount % this.SearchData.pageSize != 0) {
            this.totalPage = this.totalPage + 1;
          }
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async AddApproverPopuup(doc, versionId, versionNumber, approvallength) {
      this.documentPopupId = doc;
      this.PopupVersionId = versionId;
      this.PopupVersionNumber = versionNumber;
      console.log("Hellooooiii", doc, versionId, approvallength);
      if (approvallength.length == 0 || approvallength == null) {
        this.popactive = true;
      } else {
        this.popupmodal = true;
      }
    },
    async getreviewers() {
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseapi}/forms/digitalform/getapprovalusers`, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.approverlist = res.data;

          console.log("Reviewers", this.approverlist);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async GetDocumentDetailsfoUpdate(docId) {
      this.createFolderfunc("uploadDoc");
      this.ShowDocName = true;
      await this.getDocumentById(docId);
      console.log("fjfffffffffff", this.Document);
      this.azuredata.DocumentDescription = this.Document.documentDescription;
      this.azuredata.DocumentName = this.Document.documentName;
      this.azuredata.DocumentId = this.Document.documentReferenceId;
    },
    async updateFile() {
      
      this.getDocumentById(this.currentdocId);
      console.log("getvalue..", this.Document);
      var formsData = new FormData();
      formsData.append("DocumentId", this.azuredata.DocumentId);
      formsData.append("Document", this.azuredata.Document);
      formsData.append("DocumentDesc", this.azuredata.DocumentDescription);
      this.azuredata.DocumentId = this.Document.documentReferenceId;
      console.log("referenceid", this.Document.documentReferenceId);
      console.log("final data", this.azuredata);
      await axios
        .put(`${this.fileuploadurl}/newfile`, formsData, {
          headers: {
            Authorization: "Bearer " + this.idToken,

            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("retreieved data", response);
          this.documentUpdateId = response.data.data;
          console.log("updateId isssssssssssssss", this.documentUpdateId);
          console.log("dataaaaaaaaaaaaa", response.data);
          this.documentVersionId = [];
          this.documentVersionId = response.data.data;
          console.log("retreieved fileName", this.documentVersionId);
          console.log("retreieved fileName", this.filename);
          console.log("retreieved docid", this.documentId);
          this.openEdit();
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
        });
    },
    async openEdit() {
      console.log("id issssss" + this.Document.documentId);
      console.log("editeddd", this.Document.documentReferenceId);
      const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      console.log({
        id: this.Document.documentId,
        //documentReferenceId: this.Document.documentReferenceId,
        // documentType:  "general",
        documentName: this.azuredata.Document.name,
        documentDescription: this.azuredata.DocumentDescription,
        // folderId: this.Document.folderId,
        //folderName: this.Document.folderName,
        documentVersions: [
          {
            documentVersionId: this.documentVersionId,
            versionNumber: 0,
            versionDescription: "string",
            status: "Pending",
            fileName: "string",
            uploadedBy: "string",
            uploadedOn: "2022-06-17T11:03:54.866Z",
            uploadedUserId: "",
            approveList: this.values.Approver,
          },
        ],
      });
      await axios
        .put(
          `${this.baseurl}/management/document/update`,
          {
            id: this.Document.documentId,

            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            // folderId: this.Document.folderId,
            // folderName: this.Document.folderName,
            documentVersions: {
              documentVersionId: this.documentVersionId,
              versionNumber: 0,
              versionDescription: "string",
              status: "Pending",
              fileName: "string",
              uploadedBy: "string",
              uploadedOn: "2022-06-17T11:03:54.866Z",
              uploadedUserId: "",
              approveList: this.values.Approver,
            },
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("resulttttttttt", res.data);
          this.doclistupdate = res.data;
          alert(this.$t("navigations.document updated successfully"));
          this.showDoc = false;
          document.body.style.overflowY = "auto";
          this.azuredata.Document = "";
          this.getDocumentsrefresh();
        })
        .catch((err) => {
          console.log("error..", err);
        });
    },
    async getDocumentById(docId) {
      this.currentdocId = docId;
      console.log("docccccccccccccc..", docId);
      console.log("docccccccccccccc..", this.currentdocId);
      const idtoken = store.getters.getIdToken;
      await axios
        .get(
          `${this.baseurl}/management/document/getdocumentbyid?id=` + docId,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.Document = res.data;
          this.loaddata = true;
          this.documentName = this.Document.documentName;
        });
    },
    async viewDocument(docId) {
      this.ShowDocName = false;
      await this.getDocumentById(docId)
      const versionId =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].documentVersionId;
      const filename =
        this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].fileName;
        console.log(this.Document.documentVersions[
          this.Document.documentVersions.length - 1
        ].fileName,"FileName")
      this.$router.push(
        `/documentview/${this.Document.documentReferenceId}/${filename}/${versionId}`
      );
    },
    async deleteAzureDocument(docId) {
      this.ShowDocName = false;
      const idtoken = store.getters.getIdToken;
      console.log("get doc to delete", this.currentdocId);
      await this.getDocumentById(docId);
      console.log("value", this.Document.documentReferenceId);
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this document?")
        )
      ) {
        await axios
          .delete(
            `${this.baseurl}/management/document/delete?id=${docId}`,
            {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
          )
          .then((response) => {
            console.log(response.data);
            alert(this.$t("navigations.document deleted successfully"));
            this.deleteDocument(docId);
          })
          .catch((err) => {
            console.log("nope", err);
          });
      }
    },
    async deleteDocument(docId) {
      const idtoken = store.getters.getIdToken;
      console;
      await axios
        .delete(`${this.baseurl}/management/document/delete?Id=` + docId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.getfolder("general");
          this.getDocuments();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async submitFile() {
      const folderId = store.getters.getFolderId;
      var formData = new FormData();
      formData.append("Document", this.azuredata.Document);
      formData.append("DocumentTypeName", this.azuredata.DocumentTypeName);
      formData.append("DocumentTitle", this.azuredata.DocumentTitle);
      formData.append(
        "DocumentDescription",
        this.azuredata.DocumentDescription
      );

      console.log("final data", this.azuredata);
      await this.validateDocumentIsNull();
      if (this.validations[2]) {
        if (folderId != 0) {
          await axios
            .post(`${this.fileuploadurl}/create`, formData, {
              headers: {
                Authorization: "Bearer " + this.idToken,

                "Content-Type": "multipart/form-data",
              },
            })

            .then((response) => {
              console.log("retreieved data", response);
              this.documentId = response.data.data[0];
              this.documentVersionId = [];
              this.documentVersionId = response.data.data[1];
              console.log("retreieved fileName", this.documentVersionId);
              console.log("retreieved docid", this.documentId);
              this.Createdocument();
            })
            .catch((err) => {
              console.log("error", err);
              console.log(err.data);
            });
          //}
        } else {
          alert(this.$t("navigations.please select a folder"));
        }
      }
    },
    async Createdocument() {
      //const storageId = store.getters.getStorageId;
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      await this.getFolderbyId();
      console.log("create patientdoc in db");
      await axios
        .post(
          `${this.baseurl}/management/document/createdocument`,
          {
            documentReferenceId: this.documentId,
            documentType: "general",
            documentTypeId: "general",
            documentName: this.azuredata.Document.name,
            documentDescription: this.azuredata.DocumentDescription,
            folderId: folderId,
            folderName: this.folderbyid.folderName,
            documentVersions: [
              {
                documentVersionId: this.documentVersionId,
                versionNumber: 0,
                versionDescription: "string",
                status: "Pending",
                fileName: "string",
                uploadedBy: "string",
                uploadedOn: "2022-06-17T11:03:54.866Z",
                uploadedUserId: "",
                approveList: this.values.Approver,
              },
            ],
          },
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          alert(this.$t("navigations.document created successfully"));
          console.log("retreieve data", response);
          this.showDoc = false;
          document.body.style.overflowY = "auto";
          this.azuredata.Document = "";
          this.getDocumentsrefresh();
        })
        .catch((err) => {
          console.log("error", err);
          console.log(err.data);
        });
    },
    async validateDocumentIsNull() {
      if (this.azuredata.Document != "") {
        this.validations[2] = true;
      } else {
        this.validations[2] = false;
      }
    },
    async closebutton() {
      console.log("CLOSE MODEL");
      this.errors[1] = true;
      this.retrieve = true;
    },
    getupdatedfolder(doctype) {
      this.folderlist = [];
      console.log("helooooooo", doctype);
      const idtoken = store.getters.getIdToken;
      axios
        .get(
          `${this.baseurl}/management/folder/listfolder?storageId=${doctype}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("reached");
          this.folderlist = res.data;
          console.log("folder list is", this.folderlist);
        });
    },
    getfolder(doctype) {
      this.folderlist = [];
      console.log("helooooooo", doctype);
      const idtoken = store.getters.getIdToken;
      axios
        .get(
          `${this.baseurl}/management/folder/listfolder?storageId=${doctype}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log("reached");
          this.folderlist = res.data;
          console.log("folder list is", this.folderlist);
        });
    },
    async updateFolderName() {
      this.retrieve = false;
      this.updateFolder.folderName = this.folderNames;
    },
    async validateIsNull() {
      this.values.FolderName = this.values.FolderName.replace(/ +/g, ' ').trim();
      if (this.values.FolderName == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else if (this.values.FolderName.trim("") == "") {
        this.validations[0] = false;
        this.errors = this.validations;
      } else {
        this.validations[0] = true;
        this.errors = this.validations;
      }
    },
    async validateNameIsNull() {
      this.updateFolder.folderName = this.updateFolder.folderName.replace(/ +/g, ' ').trim();
      if (this.updateFolder.folderName == "") {
        this.validations[1] = false;
        this.errors = this.validations;
      } else if (this.updateFolder.folderName.trim("") == "") {
        this.validations[1] = false;
        this.errors = this.validations;
      } else {
        this.validations[1] = true;
        this.errors = this.validations;
      }
    },
    async getFolderbyId() {
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      await axios
        .get(`${this.baseurl}/management/folder/getfolderbyid/` + folderId, {
          headers: {
            Authorization: "Bearer " + idtoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.folderbyid = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async trim() {
      this.values.FolderName = this.values.FolderName.replace(
        /^\s+|\s+$/gm,
        ""
      );
    },
    async addFolder() {
      const idtoken = store.getters.getIdToken;
      const folderId = store.getters.getFolderId;
      // const storageId = store.getters.getStorageId;
      console.log("folderrrrr is", folderId);
      this.values.storageId = "general";
      console.log("fold VALUES is", this.values);
      await this.validateIsNull();
      if (this.errors[0]) {
        if (this.savefolder == false) {
          this.values.ParentId = "";
          this.values.storageId = "general";
          this.values.AncestorIds = [];
          this.trim();
          await axios
            .post(`${this.baseurl}/management/folder/create`, this.values, {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res);
              alert(this.$t("navigations.folder created successfully"));
              this.showFolder = false;
              this.values.FolderName = "";
              document.body.style.overflowY = "auto";
              this.getupdatedfolder("general");
            })
            .catch((err) => {
              console.log(err);
              alert(this.$t("navigations.folder name already exist"));
            });
        } else {
          this.addSubFolder();
        }
      }
    },
    async addSubFolder() {
      const folderId = store.getters.getFolderId;
      const idtoken = store.getters.getIdToken;
      //  const storageId = store.getters.getStorageId;
      await this.getFolderbyId(folderId);
      this.values.ParentId = folderId;
      this.values.storageId = "general";
      this.values.AncestorIds = [folderId];
      this.folderbyid.ancestorIds.forEach((element) => {
        const index = this.folderbyid.ancestorIds.indexOf(element);
        this.values.AncestorIds[index + 1] = this.folderbyid.ancestorIds[index];
      });
      console.log("Folder ids are", this.folderbyid.ancestorIds);
      await this.validateIsNull();
      if (this.errors[0]) {
        this.trim();
        await axios
          .post(
            `${this.baseurl}/management/folder/create`,
            this.values,

            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            alert(this.$t("navigations.folder created successfully"));
            this.showFolder = false;
            this.values.FolderName = "";
            document.body.style.overflowY = "auto";
            this.getfolder("general");
          })
          .catch((err) => {
            console.log(err);
            alert(this.$t("navigations.folder name already exists"));
          });
      }
    },
    async editFolder() {
      const folderId = store.getters.getFolderId;
      // const storageId = store.getters.getStorageId;
      this.retrieve = false;
      await this.getFolderbyId();
      this.updateFolder.id = folderId;
      this.updateFolder.ancestorIds = this.folderbyid.ancestorIds;
      this.updateFolder.parentId = this.folderbyid.parentId;
      this.updateFolder.storageId = "general";
      console.log("entered edit method", this.updateFolder);
      const idtoken = store.getters.getIdToken;
      await this.validateNameIsNull();
      console.log(" edit method", this.updateFolder);
      if (this.errors[1]) {
        this.trim();
        console.log("entered edit methoddddddddddd", this.updateFolder);
        await axios
          .put(`${this.baseurl}/management/folder/update`, this.updateFolder, {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            this.folderId = res.data.results;
            alert(this.$t("navigations.folder updated successfully"));
            this.closebutton();
            this.getfolder("general");
          })
          .catch((err) => {
            console.log(err);
            alert(this.$t("navigations.folder name already exist"));
          });
      }
    },
    async deleteFolder() {
      const folderId = store.getters.getFolderId;
      if (
        confirm(
          this.$t("navigations.are you sure you want to delete this folder?")
        )
      ) {
        const idtoken = store.getters.getIdToken;
        await axios
          .delete(
            `${this.baseurl}/management/folder/deletefolder?Id=${folderId}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log("deleted", res);
            alert(this.$t("navigations.folder deleted successfully"));
            store.dispatch("setDetails", false);
            store.dispatch("setFolderId", "");
            store.dispatch("setFolderName", "");
            store.dispatch("setFolderdate", "");
            this.getfolder("general");
          })
          .catch((err) => {
            if (
              err.response.data.messages[0].includes(
                "This folder contains 1 or more subfolders, folder with subfolders cannot be deleted"
              )
            ) {
              alert(
                this.$t("navigations.this folder contains 1 or more subfolders, folder with subfolders cannot be deleted")
              );
            }
            if (
              err.response.data.messages[0].includes(
                "This folder contains 1 or more documents, folder with document cannot be deleted"
              )
            ) {
              alert(
                this.$t("navigations.this folder contains 1 or more documents, folder with document cannot be deleted")
              );
            }
          });
      }
    },
    loadPage: function (page) {
      this.currentPage = page;
      this.pageindex = page;
      this.getDocuments();
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
.linkstyle {
  cursor: pointer;
}
.form-control1 {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.428571;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-danger1 {
  color: #fff;
  background-color: #ed5565;
  border-color: #ed5565;
  position: relative;
  width: 70px;
  height: 30px;
  padding: 3px 0;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 1.428571429;
  border: 0px solid #bb458f;
}
.btn-infos {
  color: #fff;
  background-color: #23c6c8;
  border-color: #23c6c8;
  position: relative;
  width: 70px;
  height: 30px;
  padding: 3px 0;
  border-radius: 30px;
  text-align: center;
  font-size: 15px;
  line-height: 3.428571;
  border: 0 solid #bb458f;
}
.btn-xs1 {
  font-size: 0.7rem;
  padding: 0.2rem 0.4rem;
}
.btnnew {
  border-radius: 3px;
  font-size: inherit;
  padding: 6px;
}
.edit-delete,
.edit-delete:hover {
  color: var(--primary-color) !important;
  cursor: pointer !important;
  font-size: 18px;
}
.small-width {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.close-block {
  opacity: 0.5;
  font-weight: 700;
  font-size: 0.8rem !important;
}
.title-class {
  background-color: #618bd7;
}
.title-class > * {
  color: #ffffff;
}
.create-folder-modal {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 18%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 3000;
}
.folder-modal-container {
  background: #fff;
  width: min(40%, 90%);
  border-radius: 4px;
  position: fixed;
  overflow-x: hidden;
  pointer-events: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.position-relative {
  position: relative;
}
.position-relative .tool-tip {
  visibility: hidden;
  width: max-content;
  background-color: #616161;
  color: #fff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.3rem 0.8rem;
  right: 0;
  top: -35px;
  font-size: 0.8rem;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  -webkit-transition: visibility 75ms ease-in;
  transition: visibility 75ms ease-in;
}
.position-relative:hover .tool-tip {
  visibility: visible;
  -webkit-transition: visibility 30ms ease-in;
  transition: visibility 30ms ease-in;
}
</style>
