<template>
  <viewForm
  :formsesid="formsesid"
  templatebasedon="general">

  </viewForm>
</template>

<script src="./viewformtemplate.js"></script>

