<template>
  <div class="row wrapper border-bottom white-bg page-heading">
    <div class="col-lg-10">
      <h2>{{ $t("navigations.forms") }}</h2>
    </div>
  </div>
  <div class="wrapper wrapper-content">
    <div class="ibox-content mb-1 px-3 py-2">
      <div class="form-group row mb-1">
        <div class="d-flex flex-column flex-lg-row my-2 my-lg-0 mb-lg-0">
          <!-- <select
            class="form-control mx-lg-2 my-2 my-lg-0"
            name="account"
            placeholder="Study"
            v-model="bindData.StudyId"
          >
            <option value="" disabled selected>Search by study</option>
            <option value="">All</option>
            <option
              v-for="study in studyes"
              :key="study.siteID"
              :value="study.studyId"
            >
              {{ study.studyRef }}
            </option>
          </select> -->
          <div class="form-group w-100 mb-md-0 pl-0 pr-1">
            <label class="mb-0">{{ $t("navigations.visit no") }}</label>
            <input
              v-model="this.bindData.VisitNo"
              type="text"
              class="form-control my-lg-0"
            />
          </div>
          <div class="form-group w-100 mb-md-0 pr-1">
            <label class="mb-0">{{ $t("navigations.visit name") }}</label>
            <input
              v-model="this.bindData.VisitName"
              type="text"
              class="form-control my-lg-0"
            />
          </div>
          <div class="col-12 col-md-2 px-sm-0 px-md-1 align-self-end">
            <span
              @click="search()"
              class="save_btn w-100"
            >
              {{ $t("navigations.search") }}
            </span>
          </div>            
        </div>
      </div>
    </div>
    <div class="ibox-body">
      <div class="ibox-title style_2">
        <h5 class="float-left">{{ $t("navigations.forms") }}</h5>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped table-bordered dataTables">
            <thead>
              <tr>
                <th>{{ $t("navigations.study") }}</th>
                <th>{{ $t("navigations.visit no") }}</th>
                <th>{{ $t("navigations.visit name") }}</th>
                <th>{{ $t("navigations.version") }}</th>
                <th v-if="roleprivileges.includes('Data Entry Fill Form')" class="text-center">{{ $t("navigations.fill form") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in returnList" :key="item.visitNo">
                <td>{{ item.studyRef }}</td>
                <td>{{ item.visitNo }}</td>
                <td>{{ item.visitFormName }}</td>
                <td>{{ item.version }}</td>
                <td class="text-center" v-if="roleprivileges.includes('Fill Form')">
                  <a v-if="item.template_ID" @click="goToTemplate(item.isPatientVisit,item.template_ID,item.studyId,item.visitId)"
                    ><i
                      class="fa fa-file-text-o edit-delete"
                      style="color: #a13b7c"
                    ></i
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row mt-3 mx-0">
            <div class="col-lg-12 mx-0 px-0">
              <div class="dataTables_paginate paging_simple_numbers pagination float-right">
                <div class="col-lg-12 d-flex justify-content-lg-end px-0">
                <v-pagination
                    v-model="currentPage"
                    :pages="totalPages"
                    :range-size="0"
                    active-color="#618bd7"
                    @update:modelValue="loadPage"
                  /> 
                </div>
              </div>
            </div>             
          </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import store from "../../store/index";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: "visitForm",
  components: {
    VPagination,
  },
  data() {
    return {
      roleprivileges: "",
      baseUrl: `${process.env.VUE_APP_Service_URL}/management/visit/`,
      baseapi: process.env.VUE_APP_Service_URL,
      idToken: "",
      studyes: [],
      returnList: [],
      totalCount: 0,
      totalPages:0,
      currentPage:1,
      bindData: {
        pageindex: 1,
        StudyId: "",
        VisitNo: "",
        VisitName: "",
        SortProperty: "",
        SortOrder: 0,
      },

      searchData: {
        pagesize: 10,
        pageindex: 1,
        StudyId: "",
        VisitNo: "",
        VisitName: "",
        SortProperty: "",
        SortOrder: 0,
      },
    };
  },
  async mounted() {
        window.addEventListener("studyIdChanged", (event) => {
      console.log("StudyIDChanged into" + event.detail.storage);
      // window.location.reload();
this.idToken = store.getters.getIdToken;
     this.getAndSetData();
     this.setStudyDropDown();
    });
    await this.setroleprivileges(); 
    this.idToken = store.getters.getIdToken;
    await this.getAndSetData();
    await this.setStudyDropDown();
  },
  methods: {
    async setroleprivileges(){
      this.roleprivileges = await store.getters.getRolesprivilegeData;
    },
    async search() {
      this.setSearchData();
      this.getAndSetData();
    },
    async goToTemplate(isPatientVisit,templateid,studyid,visitid){
    this.$router.push(`/form/${isPatientVisit}/${templateid}/${studyid}/${visitid}`);
    },
    async setStudyDropDown() {
      const stdvalnow = store.getters.getStudyIs;
      await axios
        .get(`${this.baseapi}/management/study/getallstudy`, {
          headers: {
            Authorization: "Bearer " + this.idToken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res);
          this.studyes = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
        this.bindData.StudyId=stdvalnow;
    },
    setSearchData() {
      this.searchData.StudyId = this.bindData.StudyId;
      this.searchData.VisitNo = this.bindData.VisitNo;
      this.searchData.VisitName = this.bindData.VisitName;
      this.searchData.pageindex = 1;
      this.bindData.pageindex = 1;
      this.currentPage=1;
      console.log("Set Fields to serchData");
    },
    //search data items are passed as parameters
    async getAndSetData() {
      const stdvalnow = store.getters.getStudyIs;
      await axios
        .get(
          this.baseUrl +
            "listvisit?pageindex=" +
            this.searchData.pageindex +
            "&pagesize=" +
            this.searchData.pagesize +
            "&SortProperty=" +
            this.SortProperty +
            "&SortOrder=" +
            1 +
            "&StudyId=" +
            stdvalnow+
            // this.searchData.StudyId +
            "&VisitNo=" +
            this.searchData.VisitNo +
            "&VisitName=" +
            this.searchData.VisitName,
          {
            headers: {
              Authorization: "Bearer " + this.idToken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.returnList = res.data.results;
          this.totalCount = res.data.totalCount;
          this.totalPages=parseInt(this.totalCount / this.searchData.pagesize)
           if((this.totalCount % this.searchData.pagesize) !=0){
            this.totalPages = this.totalPages+1
          }

          if (this.totalCount == null || this.totalCount === 0) {
            console.log("TOTAL COUNT IS",this.totalCount)
        alert(this.$t('navigations.no visit form is added to this visit'));
      }
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getnextpage() {
      if (this.totalCount / 10 > this.searchData.pageindex) {
        this.searchData.pageindex++;
        this.bindData.pageindex++;
        await this.getAndSetData();
      }
    },
    async getpreviouspage() {
      console.log("PreviousPage");
      if (this.searchData.pageindex != 1) {
        this.bindData.pageindex--;
        this.searchData.pageindex--;
        await this.getAndSetData();
      }
    },
    editCreateSite(id) {
      this.$router.push(`/managesite/${id}`);
    },
    loadPage: function (page) {
      this.currentPage = page;
     this.searchData.pageindex = page;
      this.getAndSetData();
    },
  },
};
</script>

<style>
@import "../../assets/style.css";
@import "../../assets/formpage.css";
.previousbtn,.nextbtn{
  margin:0;
  border-radius:0px;
  border:0.5px solid #eeeeee;
}
.page-links{
  border-radius:0px;
}
</style>