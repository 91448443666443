<template>
  <ul class="tree">
    <li v-for="(site, index) in sites" :key="site.siteID">
      <input
        type="checkbox"
        :id="site.siteID"
        @click="getpatient(site.siteID, site.siteCode, site.patientNo, index)"
      />
      <label :class=" $store.getters.getFromSiteId == site.siteID
                    ? 'tree_label tree_label_bold'
                    : 'tree_label'" 
             :for="site.siteID">{{ site.siteCode }}</label>
      <ul
        v-for="(patient) in patientLists[index]"
        :for="site.siteID"
        :key="patient.patientId"
        @click="getPatientNamebyId(patient.patientId)"
      >
        <li>
          <input type="checkbox" />
          <label
            :id="patient.patientId"
            @click.prevent="
              patientClick(
                patient.patientId,
                patient.subjectId,
                patient.siteCode,
                patient.siteId,
                index
              )
            "
            :for="patient.patientId"
            :class="
              selectedId == patient.patientId
                ? 'tree_label tree_label_bold'
                : 'tree_label'
            "
            >{{ patient.subjectId }}</label
          >
        </li>
      </ul>
    </li>
  </ul>
</template>
<script src="./TreeSiteFrom.js"></script>
<style scoped>
@import "../../assets/css/style.css";
@import "../../assets/bootstrap.css";
@import "../../assets/font-awesome/css/font-awesome.css";
@import "./tree.css";
.linkstyle {
  cursor: pointer;
}
</style>