<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
                <div class="ibox-title style_2 d-flex justify-content-between">
                  <div class="label label-success float-right ibox-tools p-1">{{$t('navigations.next month')}}</div>
                  <h5>{{$t('navigations.upcoming visits')}}</h5>
                </div>
                <div class="ibox-content contentheight">
                    <h1 class="no-margins">{{plannedCount}}</h1>
                    <small>{{$t('navigations.upcoming visits')}}</small>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'upcommingvisits',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      plannedCount:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getPlannedImminentCount();
		});
    await this.getPlannedImminentCount();
  },
    methods:{
        async  getPlannedImminentCount(){
            const idtoken = store.getters.getIdToken;
      const currentstuid =  store.getters.getStudyIs;
      let status = "Planned,Imminent" 
      await axios.get(`${this.baseapi}/management/patientschedule/plannedimminentcount?StudyId=${currentstuid}&DaysAfter=30&Status=${status}` , 
      {
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					})
      .then((res) => {
        this.plannedCount = res.data.Imminent + res.data.Planned;
      })
      .catch((err) => { console.log("error.." ,err);
      })
    },
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>