<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
          <div class="ibox-title style_2 d-flex justify-content-between">
              <div class="ibox-tools">
                <img src="../../assets/img/total_patients.png" alt="Total Patients">
              </div>
              <h5>{{ $t('navigations.total subjects')}}</h5>
          </div>
          <div class="ibox-content contentheight">
              <h1 class="no-margins">{{patientscountstd}}</h1>
              <small>{{ $t('navigations.total subjects')}}</small>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'totalsubjects',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      patientscountstd:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.getpatientscount();
		});
    await this.getpatientscount();
  },
    methods:{
        async getpatientscount() {
            const idtoken = store.getters.getIdToken;
            const currentstudyid = await store.getters.getStudyIs;
      await axios
        .get(
          `${this.baseapi}/management/patient/getpatientcountinstudy?StudyId=${currentstudyid}`,
          {
            headers: {
              Authorization: "Bearer " + idtoken,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          this.patientscountstd = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>