/* eslint-disable */
const state = () => ({
    storageId :  localStorage.getItem('siteId'),
    storageCode: localStorage.getItem('siteCode'),
    formList: []
   // storageVersion:localStorage.getItem('siteCode')
   });
   
   const getters = {
       getStorageId( state :{storageId : any}){
           return state.storageId;
       },
       getStorageCode( state :{storageCode : any}){
        return state.storageCode;
    },
    getformList( state :{formList : any}){
        return state.formList;
    },
    // getStorageVersion( state :{storageVersion : any}){
    //     return state.storageVersion;
    // },
   };
   
   const actions ={
       async setStorageId({commit} : any , data : any){
           commit("setStorageId" , data);
       },
       async setStorageCode({commit} : any , data : any){
        commit("setStorageCode" , data);
    },
    async setformList({commit} : any , data : any){
        commit("setformList" , data);
    },
    // async setStorageVersion({commit} : any , data : any){
    //     commit("setStorageVersion" , data);
    // }
   
   };
   
   const mutations ={
    setStorageId(state : {storageId : any } , siteData : any ){
           state.storageId = siteData;
           localStorage.setItem("siteId", siteData);
       },
       setStorageCode(state : {storageCode : any } , siteCodeData : any ){
            state.storageCode = siteCodeData;
            localStorage.setItem("siteCode", siteCodeData);
        },
        setformList(state : {formList : any } , formArray : any ){
            state.formList = formArray;
         },
    // setStorageVersion(state : {storageVersion : any } , storageVersion : any ){
    //     state.storageVersion = storageVersion;
    //     localStorage.setItem("siteCode", storageVersion);
    // },
   };
   
   export default {
       state,
       getters,
       actions,
       mutations
   }