/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
import moment from "moment";
import VPagination from "@hennge/vue3-pagination";
import Digitalformview from "../View_digital_form/view_digital_form.vue";
import jwt_decode from "jwt-decode";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
  name: "patientList",
  components: {
    VPagination,
    Digitalformview
  },
  data(){
    return{
      baseapi:`${process.env.VUE_APP_Service_URL}/forms/digitalform`,
      email:"",
      defaultdate:"",
      pageIndex:1,
      pageSize:10,
      currentPage:1,
      templateName:"",
      Status: "",
      formlist:[],
      idtoken: "",
      totalItems: 1,
      totalCount: 0,
    }
  },
  async mounted(){
    //console.clear();
    this.defaultdate = localStorage.getItem("dateformat");
    this.idtoken = store.getters.getIdToken;
    this.email=jwt_decode(this.idtoken).email,
    localStorage.setItem("formStatus","")
    this.studyId = store.getters.getStudyIs
    this.getformslist();
    window.addEventListener("studyIdChanged", async (event) => {
      this.studyId = store.getters.getStudyIs
      await this.getformslist();
    });
  },
  methods:{
    filter(data) {
      if (data) {
        return moment(data).format(this.defaultdate)
      }
    },
    async onSearchButtonClicked(){
      this.pageIndex=1;
      this.currentPage=1;
      this.getformslist();
    },
    async getformslist(){
      if (this.Status == "All") {
        this.Status = "";
      }
      console.log("Selecte dstatus",this.Status);
      await axios
          .get(`${this.baseapi}/listdigitalform?&studyId=${this.studyId}&reviewer=${this.email}&status=${this.Status}&templateName=${this.templateName}&pageindex=${this.pageIndex}&pagesize=${this.pageSize}`, {
          headers: {
            Authorization: "Bearer " + this.idtoken,
            "Content-Type": "application/json",  
          },
        })
        .then((res) => {
          this.formlist=res.data.entities;
          this.totalItems = parseInt(res.data.totalCount / this.pageSize);
          if (res.data.totalCount % this.pageSize != 0) {
            this.totalItems = this.totalItems + 1;
          }
         
          console.log("The forms are",this.formlist);
          
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    loadPage:function(page) {
      this.currentPage = page;
      this.pageIndex=page;
      this.getformslist();
    },
    async viewtemplate(tempid,formid,formstatus, comments){
      if(comments == '' || comments == null){
        comments = "null";
      }
      localStorage.setItem("formStatus",formstatus);
      this.$router.push(`/digitalformview/digitalform/${tempid}/${formid}/${comments}`);
    }

  }
}
