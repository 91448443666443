<template>
    <div class="dashlet">
      <div class="mb-1 pr-0 pl-1 dashlet">
        <div class="ibox-body dashlet">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<div class="ibox-tools">
							<img src="../../assets/img/total_queries.png" alt="Total Queries Widget">
						</div>
						<h5>{{$t('navigations.total query')}}</h5>
					</div>
					<div class="ibox-content contentheight">
						<h1 class="no-margins">{{ totalquerycount }}</h1>
						<small>{{$t('navigations.total query')}}</small>
					</div>
				</div>
      </div>
    </div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  export default {
    name: 'totalquery',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      totalquerycount:"",
    }
  },
  async mounted() {
    window.addEventListener("studyIdChanged", async (event) => {
      await this.gettotalquerycount();
		});
    await this.gettotalquerycount();
  },
    methods:{
        async gettotalquerycount() {
            const idtoken = store.getters.getIdToken;
            const currentstudyid = await store.getters.getStudyIs;
			await axios
				.get(
					`${this.baseapi}/forms/query/getallcounts?studyId=${currentstudyid}`,
					{
						headers: {
							"Access-Control-Allow-Origin": "localhost:8080",
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then((res) => {
					this.totalquerycount = res.data.openQuries + res.data.closedQuries;
					console.log(idtoken);
				})
				.catch((err) => {
					console.log(err);
				});
		},
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .contentheight{
    height: calc(100% - 48px);
  }
</style>