/* eslint-disable */
import store from "../../store/index";
import axios from "axios";
export default {

  props: { "userid": String },
  async mounted() {
    console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
    await this.Disablemenu();
    this.selecteduser = this.userid
    console.log("user id", this.selecteduser)
    await this.retrieveinfo();


  },
  async unmounted(){
    await this.Enablemenu();
  },
  data() {
    return {
      baseurl: process.env.VUE_APP_Service_URL,
      localurl: process.env.VUE_APP_redirect,

      selecteduser: "",
      values: {
        id: "",
        userName: "",
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        active: true,
        emailIdPrimary: "",
        emailIdSecondary: "",
        phoneNumber: "",
        userType: "",
        role:[],
        addressLineOne: "",
        addressLineTwo: "",
        country: "",
        userId: "",
        password: "",
        confirmpassword: "",
        ispersonalinfoverified: "",
        isVerified: "",
      },
      validations: [false, false, false, false, false, false, false, false, false,false],
      errors: []
    };
  },
  methods: {
    async Disablemenu() {
      store.dispatch("setNavbar", false);
      const navbardefault = document.querySelector("#page-wrapper")
      navbardefault.style.width="100%"
      navbardefault.style.paddingRight="0px"
    },
    async Enablemenu() {
      store.dispatch("setNavbar", true);
    },
    async validate() {
      this.Validatepassword(this.values.password);
      this.Validatename(this.values.firstName, 0);
      this.Validatename(this.values.lastName, 1);
      this.values.userName = this.values.userName == null ? this.values.userName : this.values.userName.replace(/ +/g, ' ').trim();
      this.ValidateUserName(this.values.userName, 9);
      this.validPhone(this.values.phoneNumber);
      this.validDob(this.values.dateOfBirth);
      this.Validateconfirmpassword(this.values.confirmpassword);
      this.Validatematchpasswords(this.values.password, this.values.confirmpassword);

    },
    async Validatepassword(passwd) {

      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if ((passwd == "") || (passwd == '') || (passwd == null)) {
        this.validations[5] = true;
        this.validations[4] = false;

      }
      else if ((passwd.length < 8) || (!reLow.test(passwd)) || (!reNum.test(passwd)) || (!spcl.test(passwd)) || (!reUpp.test(passwd))) {
        this.validations[4] = true;

        this.validations[5] = false;
      }
      else {
        this.validations[4] = true;
        this.validations[5] = true;
      }

    },

    async Validateconfirmpassword(passwd) {

      let reNum = /[0-9]/;
      let reLow = /[a-z]/;
      let reUpp = /[A-Z]/;
      let spcl = /(?=.*[!@#$%^&*])/;
      if ((passwd == "") || (passwd == '') || (passwd == null)) {
        this.validations[7] = true;
        this.validations[6] = false;

      }
      else if ((passwd.length < 8) || (!reLow.test(passwd)) || (!reNum.test(passwd)) || (!spcl.test(passwd)) || (!reUpp.test(passwd))) {
        this.validations[6] = true;

        this.validations[7] = false;
      }
      else {
        this.validations[6] = true;
        this.validations[7] = true;
      }

    },

    async Validatematchpasswords(passwd, confirmpaswd) {
      if (this.validations[4] == true && this.validations[5] == true && this.validations[6] == true && this.validations[7] == true) {
        console.log("validate paswds")
        if (passwd == confirmpaswd) {
          this.validations[8] = true;
          console.log(" paswds ok")
        }
        else {
          this.validations[8] = false;
          console.log("paswds mismatch")
        }
      }
      else {
        console.log("final condtn paswds")
        this.validations[8] = true;
      }
    },
    async validPhone(phno) {
      if (phno == "") {
        this.validations[3] = true;
      }
      else if (phno > 999999999 && phno < 10000000000) {
        this.validations[3] = true;
      }
      else {
        this.validations[3] = false;
      }
    },
    async validDob(dob) {
      console.log("date is", dob);
      let myDate = new Date(dob);
      let today = new Date();
      if (dob == "") {
        this.validations[2] = true;
      }
      else if (myDate > today) {
        this.validations[2] = false;
      }
      else {
        this.validations[2] = true;
      }
    },
    async Validatename(name, errindex) {
      let letters = /^[A-Za-z\s]*$/;
      if (name == "") {
        this.validations[errindex] = false;
      }
      else if (!letters.test(name)) {
        this.validations[errindex] = false;
      }
      else {
        this.validations[errindex] = true;
      }
    },
    async ValidateUserName(name, errindex) {
      if (name == null) {
        this.validations[errindex] = false;
      }
      else if(name.length < 5 || name.length > 10){
        this.validations[errindex] = false;
      }
      else {
        this.validations[errindex] = true;
      }
    },
    async updateUser() {
      await this.validate();
      if (this.validations.includes(false)) {
        this.errors = this.validations;
      }
      else {
        const idtoken = store.getters.getIdToken;
        console.log("values are:", this.values);
        this.values.isPersonalInfoVerified = true;
        this.values.isVerified = true;
        if (this.values.dateOfBirth === "") {
          this.values.dateOfBirth = null;
        }
        await axios
          .put(
            `${this.baseurl}/account-core/user/updateuser`,
            {
              itemId: this.values.id,
              userName: this.values.userName,
              firstName: this.values.firstName,
              lastName: this.values.lastName,
              dateOfBirth: this.values.dateOfBirth,
              active: this.values.active,
              emailIdPrimary: this.values.emailIdPrimary,
              emailIdSecondary: this.values.emailIdSecondary,
              phoneNumber: this.values.phoneNumber,
              userType: this.values.userType,
              role: this.values.role,
              addressLineOne: this.values.addressLineOne,
              addressLineTwo: this.values.addressLineTwo,
              country: this.values.country,
              userId: this.values.userId,
              password: this.values.password,
              isVerified: true,
              isPersonalInfoVerified: true
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            console.log(res);
            if (res.data.data === "No User Found") {
              alert("Hello");
            }
            this.userList = res.data.data;
            this.$router.push(`/`);
          })
          .catch((err) => {
            if(err.response.data.detail == "Username already taken !"){
              alert(err.response.data.detail)
            }
            else if(err.response.data.messages[0].includes("The user already exists.")){
              alert("User already exists with same email id!");
            }
            else{
              alert("Something went wrong!")
            }
            console.log(err);
          });
      }
    },
    async verifyuser() {
      console.log("verify", this.values.id)
      if (this.values.isVerified != true) {
        const idtoken = store.getters.getIdToken;
        await axios
          .post(
            `${this.baseurl}/account-core/user/verifyuser`,
            {
              itemId: this.values.id
            },
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )

          .then((res) => {
            console.log(res, "verified");

          })
          .catch((err) => {
            console.log(err);
          });
      }
      else {
        //this.$router.push(`/`);
      }

    },
    async retrieveinfo() {
// this.selecteduser is the token from email
      console.log("id from apiiiiiiiiiiiiiii ");

      if (this.selecteduser != "") {

        const idtoken = store.getters.getIdToken;
        await axios
          .get(
            `${this.baseurl}/account-core/user/gettokenstatus?token=${this.selecteduser}`,
          // .get(
          //   `https://localhost:5001/user/gettokenstatus?token=${this.selecteduser}`,
            {
              headers: {
                Authorization: "Bearer " + idtoken,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {

            console.log("hi user from db", res.data);
            if (res.data === "No User Found") {
              alert("No User Found");
            }
            res.data.dateOfBirth = res.data.dateOfBirth === null ? "" : res.data.dateOfBirth.slice(0, 10);
            this.values = res.data;
            this.verifyuser();
            console.log("values from  db", this.values)
            this.values.password = "";
            // if (this.values.isPersonalInfoVerified) {
            //   alert("User already verified");
            //   this.$router.push(`/`);
            // }
          })
          .catch((err) => {
            console.log(err);
            alert("User already verified!");
            this.$router.push(`/`);
          });
      }
    }


  },
};
