<template>
    <div class="pr-0 pl-1 graphminwidth">
				<div class="ibox-body">
					<div class="ibox-title style_2 d-flex justify-content-between">
						<h5 class="mt-1">{{$t('navigations.sites')}}</h5>
						<div class="graphbtncontainer">
							<div class="graphbutton">
								<img
									src="../../assets/images/bar_clr.png"
									@click.prevent="firstchartchange('bar')" alt="Vertical Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/horiz_bar_clr.png"
									@click.prevent="firstchartchange('barhorizondal')" alt="Horizaontal Bar Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Line_clr.png"
									@click.prevent="firstchartchange('line')" alt="Line Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/Pie_clr.png"
									@click.prevent="firstchartchange('pie')" alt="Pie Chart Button"
								/>
							</div>
							<div class="graphbutton">
								<img
									src="../../assets/images/bubble_clr.png"
									@click.prevent="firstchartchange('scatter')" alt="Scatter Chart Button"
								/>
							</div>
						</div>
					</div>
					<div class="ibox-content">
						<div>
						<apexchart
								v-if="
									firstchart.charttype != 'pie' &&
										firstchart.charttype != 'barhorizondal'
								"
								width="100%"
								height="250px"
								:type="firstchart.charttype"
								:options="firstchart.chartOptions"
								:series="firstchart.dataseries"
							></apexchart>
							<apexchart
								v-if="firstchart.charttype == 'barhorizondal'"
								width="100%"
								height="250px"
								type="bar"
								:options="firstbarchart.chartOptions"
								:series="firstbarchart.dataseries"
							></apexchart>
							<apexchart
								v-if="firstchart.charttype == 'pie'"
								width="100%"
								height="250px"
								:type="firstchart.charttype"
								:options="firstpiechartoptions"
								:series="firstchart.piedataseries"
							></apexchart>
						</div>
					</div>
				</div>
			</div>
  </template>
  
  <script>
  /* eslint-disable */
  import axios from "axios";
  import store from "@/store";
  import Chart from "chart.js";
  
  export default {
    name: 'siteschart',
    data() {
    return {
      baseapi: process.env.VUE_APP_Service_URL,
      activeformcount:"",
      firstpiechartoptions: {
				chart: {
					width: "100%",
					type: "pie",
				},
				labels: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'), this.$t('navigations.terminated')],
				responsive: [
					{
						breakpoint: 480,
						options: {
							pie: {
								size: 200,
							},
							chart: {
								toolbar: {
									show: false,
								},
								width: 200,
							},
							legend: {
								position: "bottom",
							},
						},
					},
				],
			},
      firstbarchart: {
				charttype: "bar",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							horizontal: true,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'), this.$t('navigations.terminated')],
					},
				},
				series: [
					{
						name:this.$t('navigations.sites'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
			},

			//horizondalbarchart first ends
			firstchart: {
				charttype: "line",
				chartOptions: {
					stroke: {
						width: 2,
					},
					chart: {
						height: "100%",
						width: "100%",
						toolbar: {
							show: false,
						},
						id: "firstchart-example",
					},
					dataLabels: {
						enabled: false,
					},
					plotOptions: {
						bar: {
							borderRadius: 4,
							horizontal: false,
						},
					},
					xaxis: {
						categories: [this.$t('navigations.new'), this.$t('navigations.open'), this.$t('navigations.enrolled'), this.$t('navigations.completed'),this.$t('navigations.terminated')],
					},
				},
				series: [
					{
						name:this.$t('navigations.sites'),
						data: [0, 0, 0, 0],
					},
				],
				dataseries: "",
				piechartOptions: "",
				piedataseries: [2, 3, 2, 1],
			},
    }
  },
  async mounted() {
	window.addEventListener("studyIdChanged", async (event) => {
		await this.GetChartData();
		});
    await this.GetChartData();
  },
    methods:{
        async GetChartData() {
            const idtoken = store.getters.getIdToken;
            const currentstudyid = await store.getters.getStudyIs;
			await axios
				.get(
					`${this.baseapi}/management/site/sitesummery?studyId=${currentstudyid}`,
					{
						headers: {
							Authorization: "Bearer " + idtoken,
							"Content-Type": "application/json",
						},
					}
				)
				.then(async (res) => {
					console.log(res);
					this.firstchart.dataseries = [
						{
							name: this.$t('navigations.sites'),
							data: [
								res.data.New == null ? 0 : res.data.New,
								res.data.Open == null ? 0 : res.data.Open,
								res.data.Enrolled == null ? 0 : res.data.Enrolled,
								res.data.Completed == null ? 0 : res.data.Completed,
								res.data.Terminated == null ? 0 : res.data.Terminated,
							],
						},
					];
					this.firstchart.piedataseries = this.firstchart.dataseries[0].data;
				})
				.catch((err) => {
					console.log(err);
				});
		},
        async firstchartchange(val) {
			if (val == "barhorizondal") {
				await this.switchfirstbar(true);
				this.firstchart.charttype = "bar";
			} else {
				await this.switchfirstbar(false);
				this.firstchart.charttype = val;
			}
		},
        async switchfirstbar(res) {
			this.firstchart.chartOptions.plotOptions = { bar: { horizontal: res } };
			this.firstchart.charttype = "";
		},
    }
  };
  </script>
  
  <style>
  .dashlet{
    width:100%;
    height:100%;
  }
  .graphbutton {
	width: fit-content;
	padding: 3px;
}
.graphbutton:hover {
	cursor: pointer;
	border-color: #3c4192;
}
.graphbtncontainer {
	display: flex;
	gap: 5px;
}
img {
	margin-left: 0px !important;
}
.graph-block {
	background-color:#ffffff;
	border-radius: 7px;
	box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -1px 6px 40px -40px rgba(0, 0, 0, 0.75);
}
.graph-block__content {
	padding: 0.5rem 1.3rem;
}
.graphminwidth{
    min-width: 27rem;
}
.site-block {
	position: relative;
}
</style>